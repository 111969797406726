import Navigation from "../../../Components/Navigation/Navigation";
import React, { useEffect, useState } from 'react';
import Routes from "../../../Components/Dispathcer/Main/Routes/Routes";
import './MainPage.scss';
import InfoRoutesComponent from "../../../Components/Dispathcer/Main/InfoRoutes/InfoRoutesComponent";

export default function MainPage() {
    const [selectedDriverId, setSelectedDriverId] = useState(null);

    useEffect(() => {
        document.title = 'Main page';
    }, []);

    const handleDriverClick = (driverId) => {
        setSelectedDriverId(driverId);
        // Do additional logic if needed
    };

    return (
        <div className="dispatcher__main__container">
            <Navigation />
            {/* Pass the callback function to the Routes component */}
            <Routes onDriverClick={handleDriverClick} />
            {/* Pass selectedDriverId to InfoRoutesComponent */}
            <InfoRoutesComponent driverId={selectedDriverId} />
        </div>
    );
}
