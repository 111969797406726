// forgotPasswordActions.js

import axios from 'axios';

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';

export const forgotPasswordRequest = () => ({
    type: FORGOT_PASSWORD_REQUEST,
});

export const forgotPasswordSuccess = () => ({
    type: FORGOT_PASSWORD_SUCCESS,
});

export const forgotPasswordFailure = (error) => ({
    type: FORGOT_PASSWORD_FAILURE,
    payload: error,
});

export const sendForgotPasswordLink = (email) => {
    return async (dispatch) => {
        dispatch(forgotPasswordRequest());

        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/smtp/sendForgotPasswordLink`, {
                email: email,
            });

            dispatch(forgotPasswordSuccess());

        } catch (error) {
            dispatch(forgotPasswordFailure(error.message));
        }
    };
};


export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const resetPasswordRequest = () => ({
    type: RESET_PASSWORD_REQUEST,
});

export const resetPasswordSuccess = () => ({
    type: RESET_PASSWORD_SUCCESS,
});

export const resetPasswordFailure = (error) => ({
    type: RESET_PASSWORD_FAILURE,
    payload: error,
});

export const updatePassword = (newPassword, linkParam) => {
    return async (dispatch) => {
        dispatch(resetPasswordRequest());
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/smtp/updatePassword`, {
                link: linkParam,
                newPassword: newPassword,
            });
            dispatch(resetPasswordSuccess());
        } catch (error) {
            dispatch(resetPasswordFailure(error.message));
        }
    };
};
