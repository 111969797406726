import React, {useState, useRef} from 'react';
import './HelpComponent.scss'
import {BiSearch} from 'react-icons/bi';
import {LuListFilter} from 'react-icons/lu';
import {IoIosMore} from 'react-icons/io';
import {MdOutlineExpandLess} from "react-icons/md";
import {MdOutlineExpandMore} from "react-icons/md";
import {MdKeyboardArrowLeft} from "react-icons/md";
import {MdKeyboardArrowRight} from "react-icons/md";
import {LuCalendar} from "react-icons/lu";
import {fetchAllReports, changeCurrentStatus, deleteReport} from "../../../../store/actions/helpActions";
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {DateRangePicker} from 'react-date-range';
import 'react-date-range/dist/styles.css'; // import the styles
import 'react-date-range/dist/theme/default.css';
import LoaderComponent from '../../../LoaderComponent/LoaderComponent';

export default function HelpComponent() {
    const dispatch = useDispatch();
    const [selectedStatus, setSelectedStatus] = useState(null);

    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [sortOrder, setSortOrder] = useState('asc');
    //eslint-disable-next-line
    const [sortField, setSortField] = useState('date');
    const [isLoading, setIsLoading] = useState(true);

    const [dateRange, setDateRange] = useState({
        startDate: null,
        endDate: null,
        key: 'selection',
    });
    const handleDateChange = (ranges) => {
        setCurrentPage(1);
        setDateRange(ranges.selection);
    };
    const [selectedDateRangeColor] = useState('');
//   , setSelectedDateRangeColor
    // Step 2: Define a CSS class with the desired color
    const dateRangeColorClass = selectedDateRangeColor ? `rdrInRange ${selectedDateRangeColor}` : 'rdrIn';
    const handleApplyDateRange = () => {
        setShowDateRangePickerModal(false);
        setShowBackdrop(false);

        // Update the date range in the state
        setDateRange({
            startDate: dateRange.startDate,
            endDate: dateRange.endDate,
            key: 'selection',
        });

        // Format the date range as 'Mon DD - Mon DD'
        const formattedStart = dateRange.startDate
            ? dateRange.startDate.toLocaleDateString(undefined, {month: 'short', day: 'numeric'})
            : '';
        const formattedEnd = dateRange.endDate
            ? dateRange.endDate.toLocaleDateString(undefined, {month: 'short', day: 'numeric'})
            : '';
        setFormattedDateRange(`${formattedStart} - ${formattedEnd}`);
    };


    const [showDateRangePickerModal, setShowDateRangePickerModal] = useState(false);
    const [showBackdrop, setShowBackdrop] = useState(false);
    const [formattedDateRange, setFormattedDateRange] = useState('');

    const toggleDateRangePickerModal = () => {
        setShowDateRangePickerModal((prev) => !prev);
        setShowBackdrop(!showBackdrop);
    };

    const handleBackdropClick = () => {
        setShowDateRangePickerModal(false);
        setShowBackdrop(false);
        // Reset the date range when the user clicks outside the date range picker
        setDateRange({
            startDate: null,
            endDate: null,
            key: 'selection',
        });
    };


    const descriptionMaxLength = 50;
    const [truncateDescriptions] = useState(true); 
    // , setTruncateDescriptions
    const [pageSize, setPageSize] = useState(10);     
    const allReports = useSelector(state => state.accidents.allReports);

    const [expandedReportId, setExpandedReportId] = useState(null);
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const [selectedReportId, setSelectedReportId] = useState(null);


    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    useEffect(() => {
        dispatch(fetchAllReports())
            .then(() => {
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching reports: ', error);
                setIsLoading(false);
            });
    }, [dispatch]);


    const getDescriptionContent = (description) => {
        return truncateDescriptions
            ? `${description.slice(0, descriptionMaxLength)}...`
            : description;
    };
    const handlePageSizeChange = (size) => {
        setPageSize(size);
        setCurrentPage(1);
    };
    const toggleDescriptionTruncation = (reportId) => {
        setExpandedReportId((prevId) => (prevId === reportId ? null : reportId));
    };

    const filteredReports = allReports.filter(report => {
        const searchString = searchQuery.toLowerCase();
        return (
            report.driver.toLowerCase().includes(searchString) ||
            report.problem.toLowerCase().includes(searchString) ||
            report?.status?.toLowerCase().includes(searchString) ||
            report.date.toLowerCase().includes(searchString) ||
            report.description.toLowerCase().includes(searchString)
        );
    });
    const sortedReports = [...filteredReports]
        .filter(report => {
            const startDate = dateRange.startDate;
            const endDate = dateRange.endDate;
            const reportDate = new Date(report.date);

            return (!startDate || reportDate >= startDate) && (!endDate || reportDate <= endDate);
        })
        .sort((a, b) => {
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);

            if (sortOrder === 'asc') {
                return dateA - dateB;
            } else {
                return dateB - dateA;
            }
        });

    // const [showModal, setShowModal] = useState(false);
    // const modalRef = useRef(null);
    const currentReports = sortedReports.slice(startIndex, endIndex);


    const totalPages = Math.ceil(filteredReports.length / pageSize);
    const paginationOptions = Array.from(
        {length: Math.min(10, Math.ceil(filteredReports.length / 10))},
        (_, index) => (index + 1) * 10
    );
    const handleSortChange = (field) => {
        setSortField(field);
        setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
    };

    const [showMoreActionsModal, setShowMoreActionsModal] = useState(false);
    const moreActionsModalRef = useRef(null);
    const handleMoreActionsClick = (reportId) => {
        setShowMoreActionsModal(!showMoreActionsModal);
        setSelectedReportId(selectedReportId === reportId ? null : reportId);
    };


    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (moreActionsModalRef.current && !moreActionsModalRef.current.contains(event.target)) {
                setShowMoreActionsModal(false);
                setSelectedReportId(null);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [showMoreActionsModal]);


    useEffect(() => {
        if (selectedReportId !== null && selectedStatus !== null) {
            dispatch(
                changeCurrentStatus({
                    reportId: selectedReportId,
                    statusId: selectedStatus,
                })
            );
            // Reset selected report and status after making the change

            setShowMoreActionsModal(false);
            setSelectedReportId(null);
            setSelectedStatus(null);

        }
    }, [selectedReportId, selectedStatus, dispatch]);
    const handleStatusChange = (statusId) => {
        // Call your changeCurrentStatus action here
        if (selectedReportId !== null) {
            dispatch(
                changeCurrentStatus({
                    reportId: selectedReportId,
                    statusId: statusId,
                })
            );

            // Close the modal and reset selected report
            setShowMoreActionsModal(false);

            setSelectedReportId(null);
        }
    };

    useEffect(() => {
        dispatch(fetchAllReports());

    }, [dispatch, selectedStatus, selectedReportId]);

    const handleDeleteReport = (reportId) => {
        // Викликати екшн deleteReport та передати reportId
        dispatch(deleteReport(reportId));
    };

    return (
        <>
            <div className={`help__page ${dateRangeColorClass}`}>

                <div className="help__container">

                    <div className="help__panel">
                        <div className="help__title">Support</div>
                        <div className="help__right__item">

                            {/*<div className="help__filter">*/}
                            {/*    <LuListFilter/>*/}
                            {/*    <p>Filters</p>*/}
                            {/*</div>*/}
                            <div className="help__calendar" onClick={toggleDateRangePickerModal}>
                                <LuCalendar/>{formattedDateRange}
                            </div>
                            {showDateRangePickerModal && (
                                <>
                                    <div className="backdrop" onClick={handleBackdropClick}></div>

                                    <div className="date-range-picker-modal">
                                        <div className='date-range__body'>
                                            <DateRangePicker ranges={[dateRange]} onChange={handleDateChange}/>
                                            <div className='date__button__container'>
                                                <button onClick={handleBackdropClick} className='date__cancel'>Cancel
                                                </button>
                                                <button onClick={handleApplyDateRange} className='date__apply'>Apply
                                                </button>
                                            </div>
                                        </div>

                                    </div>

                                </>
                            )}
                            <div className="help__search">
                                <BiSearch/>
                                <input
                                    type="text"
                                    placeholder="Search"
                                    value={searchQuery}
                                    onChange={(e) => {
                                        setCurrentPage(1);
                                        setSearchQuery(e.target.value);
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    <div className='table__wrapper__help'>
                        <div className='table__container__help'>
                            <table>
                                <thead>
                                <tr>
                                    <th>Driver</th>
                                    <th>Problem</th>
                                    <th className='report__status__header'>Status</th>
                                    <th
                                        style={{display: 'flex', alignItems: 'center', width: 'auto'}}
                                        onClick={() => handleSortChange('date')}
                                    >
                                        Date
                                        <div className='table__sort__help'>
                                            <div className="table__sort__less"
                                                 style={{opacity: sortOrder === 'desc' ? 0.5 : 1}}>
                                                <MdOutlineExpandLess/>
                                            </div>
                                            <div className="table__sort__less"
                                                 style={{opacity: sortOrder === 'asc' ? 0.5 : 1}}>
                                                <MdOutlineExpandMore/>
                                            </div>
                                        </div>
                                    </th>
                                    <th>Description</th>
                                    <th></th>
                                </tr>
                                </thead>
                                {isLoading ? (
                                    <LoaderComponent/> // Тут відображається лоадер
                                ) : (
                                    <tbody>
                                    {currentReports.map(report => (
                                        <tr key={report.id}>
                                            <td onClick={() => toggleDescriptionTruncation(report.id)}
                                                data-cell="Driver">{report.driver}</td>
                                            <td onClick={() => toggleDescriptionTruncation(report.id)}
                                                data-cell="Problem">{report.problem}</td>
                                            <td onClick={() => toggleDescriptionTruncation(report.id)}
                                                data-cell="Status">
                                                <p style={{
                                                    borderColor: report.statusColor,
                                                    color: report.statusColor
                                                }}>{report.selectedStatus}</p>
                                            </td>
                                            <td onClick={() => toggleDescriptionTruncation(report.id)}
                                                data-cell="Date">{report.date}</td>
                                            <td onClick={() => toggleDescriptionTruncation(report.id)}
                                                data-cell="Description">
                                                {expandedReportId === report.id ? report.description : getDescriptionContent(report.description)}
                                                {expandedReportId === report.id && (
                                                    <div className='report__img__cont'>
                                                        {report.files.map(file => (
                                                            <div key={file.id}>
                                                                <a href={`//${file.link}`} target="_blank"
                                                                   rel="noopener noreferrer">
                                                                    <img src={`//${file.link}`}
                                                                         alt={`report ${file.id}`}/>
                                                                </a>

                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </td>
                                            <td className='table__tools__help' data-cell="Actions">
                                                <IoIosMore onClick={() => handleMoreActionsClick(report.id)}/>
                                                {selectedReportId === report.id && (
                                                    <div className="more-actions-modal-help" ref={moreActionsModalRef}>
                                                        <div className="more-actions-content-help">
                                                            {report.statuses.map(status => (
                                                                <div
                                                                    key={status.id}
                                                                    className="more-action-item-help"
                                                                    onClick={() => handleStatusChange(status.id)}
                                                                >
                                                                    {status.value}
                                                                </div>
                                                            ))}
                                                            <div className="more-action-item-help"
                                                                 onClick={() => handleDeleteReport(report.id)}>
                                                                Delete
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                )}
                            </table>
                        </div>
                    </div>
                    {filteredReports.length > 0 ? (
                        <div className="pagination">
                            <select onChange={(e) => handlePageSizeChange(e.target.value)} className='per__page'>
                                {paginationOptions.map((option) => (
                                    <option key={option} value={option}>
                                        {`${option} per page`}
                                    </option>
                                ))}
                            </select>
                            <div className="pagination__pages">
                                <button
                                    onClick={() => handlePageChange(currentPage - 1)}
                                    disabled={currentPage === 1}
                                >
                                    <MdKeyboardArrowLeft/>
                                </button>
                                <span>{currentPage}</span>
                                of {totalPages}
                                <button
                                    onClick={() => handlePageChange(currentPage + 1)}
                                    disabled={currentPage === totalPages}
                                >
                                    <MdKeyboardArrowRight/>
                                </button>
                            </div>

                        </div>
                    ) : (
                        <div className="pagination"></div>
                    )}
                </div>

            </div>
        </>
    );
} 