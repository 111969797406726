// reducer.js
import {
    GET_ALL_DROPDOWNS_REQUEST,
    GET_ALL_DROPDOWNS_SUCCESS,
    GET_ALL_DROPDOWNS_FAILURE,
    ADD_DROPDOWN_REQUEST,
    ADD_DROPDOWN_SUCCESS,
    ADD_DROPDOWN_FAILURE,
    DELETE_VALUE_REQUEST,
    DELETE_VALUE_SUCCESS,
    DELETE_VALUE_FAILURE,
} from '../actions/adminDropdownsActions';

export const CLEAR_DROPDOWN_ERROR = 'CLEAR_DROPDOWN_ERROR';

export const clearDropdownError = () => ({
    type: CLEAR_DROPDOWN_ERROR,
});  

const initialState = {
    data: [],
    loading: false,
    error: null,
};

const dropdownReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_DROPDOWNS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };

        case GET_ALL_DROPDOWNS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
            };

        case GET_ALL_DROPDOWNS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case ADD_DROPDOWN_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };

        case ADD_DROPDOWN_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
            };

        case ADD_DROPDOWN_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case CLEAR_DROPDOWN_ERROR:
            return {
                ...state,
                error: null,
            };
        case 'UPDATE_DROPDOWN_SUCCESS':
            return {
                ...state,
                loading: false,
                error: null,
            };
        case 'UPDATE_DROPDOWN_ERROR':
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
            case DELETE_VALUE_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case DELETE_VALUE_SUCCESS:
            console.log('DELETE_VALUE_SUCCESS', action.payload);
            const updatedValues = state?.values?.filter(value => value.id !== action.payload);
            return {
                ...state,
                loading: false,
                values: updatedValues,
            };
        case DELETE_VALUE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default dropdownReducer;
