import React, {useRef, useState, useEffect} from 'react';
import Navigation from "../../../Components/Navigation/Navigation";
import TrailersComponent from "../../../Components/Dispathcer/Vehicle/Trailers/TrailersComponent";
import TruckComponent from "../../../Components/Dispathcer/Vehicle/Trucks/TruckComponent";
import {BiSearch} from "react-icons/bi";
import {LuListFilter} from "react-icons/lu";
import {LuPlus} from "react-icons/lu";
import {IoCloseOutline} from 'react-icons/io5';
import './VehiclePage.scss';
import NewTruck from '../../../Components/Dispathcer/Vehicle/Trucks/NewTruckComponentModal/NewTruckComponentModal';
import NewTrailer from '../../../Components/Dispathcer/Vehicle/Trailers/NewTrailersComponentModal/NewTrailersComponentModal';


export default function VehiclePage() {
    const [showTrailers, setShowTrailers] = useState(false);
    const [isTrailers, setIsTrailers] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalType, setModalType] = useState('truck');
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredTrucks, setFilteredTrucks] = useState([]); // New state variable

    const modalRef = useRef(null);

    const handleShowTrailers = () => {
        setShowTrailers(true);
        setIsTrailers(true);
    };

    const handleShowTrucks = () => {
        setShowTrailers(false);
        setIsTrailers(false);
    };

    const handleNewTruck = () => {
        setModalType(isTrailers ? 'trailer' : 'truck');
        setShowModal(true);
    };

    const handleCloseModalClick = () => {
        setShowModal(false);
    };



    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setShowModal(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);
    return (
        <div className="trucks__page">
            <Navigation/>
            <div className="trucks__container">
                <div className="trucks__title">
                    Vehicle information
                </div>
                <div className="trucks__panel">
                    <div className="trucks__choice">
                        <div className="trucks__">
                            <button className={`customers__approved ${!isTrailers ? 'active' : ''}`}
                                    onClick={handleShowTrucks}>Trucks
                            </button>
                        </div>
                        <div className="trailers__">
                            <button className={`customers__blacklist ${isTrailers ? 'active' : ''}`}
                                    onClick={handleShowTrailers}>Trailers
                            </button>
                        </div>
                    </div>
                    <div className="trucks__right__item">
                        <div className="trucks__add" onClick={handleNewTruck}>
                            {isTrailers ? 'New trailer' : 'New truck'}
                            <LuPlus/>
                        </div>
                        {/*<div className="trucks__filter">*/}
                        {/*    <LuListFilter/>*/}
                        {/*    <p>Filters</p>*/}
                        {/*</div>*/}
                        <div className="trucks__search">
                    <BiSearch />
                    <input
                        type="text"
                        placeholder="Search"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>
                    </div>
                </div>
                {showTrailers ? (
                    <TrailersComponent searchTerm={searchTerm} />
                ) : (
                    <TruckComponent searchTerm={searchTerm} filteredTrucks={filteredTrucks} setFilteredTrucks={setFilteredTrucks} />
                )}
                {showModal && (
  <div className={`modal__add__${modalType}`} ref={modalRef}>
    <div className={`modal-content__add__${modalType}`}>
      <div className={`heading__modal__${modalType}`}> 
        <div className={`title__modal__${modalType}`}>
          {`Add ${modalType}`}
        </div>
        <div className={`close__modal__${modalType}`} onClick={handleCloseModalClick}>
          <IoCloseOutline />
        </div>
      </div>
      {modalType === 'truck' ? (
        <NewTruck onClose={handleCloseModalClick} onCancel={handleCloseModalClick} />
      ) : (
        <NewTrailer onClose={handleCloseModalClick} onCancel={handleCloseModalClick} />
      )}
    </div>
  </div>
)}
            </div>
        </div>
    );
}
