// reducer.js
import {
    ASK_PHOTO_REPORT_REQUEST,
    ASK_PHOTO_REPORT_SUCCESS,
    ASK_PHOTO_REPORT_FAILURE,
    CLOSE_NOTIFICATION_REQUEST,
    CLOSE_NOTIFICATION_SUCCESS,
    CLOSE_NOTIFICATION_FAILURE,
} from '../actions/notificationActions';

const initialState = {
    loading: false,
    success: false,
    error: null,
};

const photoReportReducer = (state = initialState, action) => {
    switch (action.type) {
        case ASK_PHOTO_REPORT_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
                error: null,
            };

        case ASK_PHOTO_REPORT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                error: null,
            };

        case ASK_PHOTO_REPORT_FAILURE:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        case CLOSE_NOTIFICATION_REQUEST:
            return {
                ...state,
                loading: true,
                success: false,
                error: null,
            };

        case CLOSE_NOTIFICATION_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                error: null,
            };

        case CLOSE_NOTIFICATION_FAILURE:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };

        default:
            return state;
    }
};

export default photoReportReducer;
