// trailerActions.js
import axios from "axios";
export const FETCH_TRAILERS_SUCCESS = 'FETCH_TRAILERS_SUCCESS';

export const fetchTrailersSuccess = (data) => ({
    type: FETCH_TRAILERS_SUCCESS,
    payload: data,
});


export const FETCH_SINGLE_TRAILER_SUCCESS = 'FETCH_SINGLE_TRAILER_SUCCESS';

export const fetchSingleTrailerSuccess = (data) => ({
    type: FETCH_SINGLE_TRAILER_SUCCESS,
    payload: data,
});


export const FETCH_TRAILER_PHOTOS_SUCCESS = 'FETCH_TRAILER_PHOTOS_SUCCESS';
 
// Action creator для успішного отримання фото трека
export const fetchTrailerPhotosSuccess = (photos) => ({
    type: FETCH_TRAILER_PHOTOS_SUCCESS,
    payload: photos,
});

export const photosSavedSuccessfully = () => {
    return {
        type: 'PHOTOS_SAVED_SUCCESSFULLY',
    };
};


export const CREATE_TRAILER_REQUEST = 'CREATE_TRAILER_REQUEST';
export const CREATE_TRAILER_SUCCESS = 'CREATE_TRAILER_SUCCESS';
export const CREATE_TRAILER_FAILURE = 'CREATE_TRAILER_FAILURE';

export const createTrailer = (trailerData) => ({
    type: CREATE_TRAILER_REQUEST,
    payload: trailerData,
});

export const createTrailerSuccess = (newTrailer) => ({
    type: CREATE_TRAILER_SUCCESS,
    payload: newTrailer,
});

export const createTrailerFailure = (error) => ({
    type: CREATE_TRAILER_FAILURE,
    payload: error,
});

export const FETCH_DROPDOWNS_REQUEST = 'FETCH_DROPDOWNS_REQUEST';
export const FETCH_DROPDOWNS_SUCCESS = 'FETCH_DROPDOWNS_SUCCESS';
export const FETCH_DROPDOWNS_FAILURE = 'FETCH_DROPDOWNS_FAILURE';

export const fetchDropdownsRequest = () => ({
    type: FETCH_DROPDOWNS_REQUEST,
});

export const fetchDropdownsSuccess = (data) => ({
    type: FETCH_DROPDOWNS_SUCCESS,
    payload: data,
});


export const fetchDropdownsFailure = (error) => ({
    type: FETCH_DROPDOWNS_FAILURE,
    payload: error,
});


export const UPDATE_TRAILER_REQUEST = 'UPDATE_TRAILER_REQUEST';
export const UPDATE_TRAILER_SUCCESS = 'UPDATE_TRAILER_SUCCESS';
export const UPDATE_TRAILER_FAILURE = 'UPDATE_TRAILER_FAILURE';

export const updateTrailerRequest = () => ({
    type: UPDATE_TRAILER_REQUEST,
});

export const updateTrailerSuccess = (updatedTrailer) => ({
    type: UPDATE_TRAILER_SUCCESS,
    payload: updatedTrailer,
});

export const updateTrailerFailure = (error) => ({
    type: UPDATE_TRAILER_FAILURE,
    payload: error,
})


export const FETCH_FILES_DROPDOWNS_REQUEST = 'FETCH_FILES_DROPDOWNS_REQUEST';
export const FETCH_FILES_DROPDOWNS_SUCCESS = 'FETCH_FILES_DROPDOWNS_SUCCESS';
export const FETCH_FILES_DROPDOWNS_FAILURE = 'FETCH_FILES_DROPDOWNS_FAILURE';

export const fetchFilesDropdownsRequest = () => {
    return {
        type: FETCH_FILES_DROPDOWNS_REQUEST
    };
};

export const fetchFilesDropdownsSuccess = (data) => {
    return {
        type: FETCH_FILES_DROPDOWNS_SUCCESS,
        payload: data
    };
};

export const fetchFilesDropdownsFailure = (error) => {
    return {
        type: FETCH_FILES_DROPDOWNS_FAILURE,
        payload: error
    };
};

export const fetchTrailers = () => {
    return async (dispatch) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_GET_TRAILER_INFO_API_URL}`, {
                credentials: 'include',
            });
            const data = await response.json();
            dispatch(fetchTrailersSuccess(data));
        } catch (error) {
            console.error('Error fetching trailers:', error);
        }
    };
};


export const deleteTrailer = (trailerId) => {
    return async (dispatch) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_DELETE_TRAILER_API_URL}/${trailerId}`, {
                method: 'DELETE',
                credentials: 'include',
            }); 
            console.log('Response:', trailerId);
            

            if (response.ok) {
                // Якщо запит успішний, оновлюємо інформацію про треки
                dispatch(fetchTrailers());
            } else {
                throw new Error('Failed to delete trailer');
            }
        } catch (error) {
            console.error('Error deleting trailer:', error);
        }
    };
};

export const UPLOAD_FILE_REQUEST = 'UPLOAD_FILE_REQUEST';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_FAILURE = 'UPLOAD_FILE_FAILURE';

export const uploadFileRequest = () => ({
    type: UPLOAD_FILE_REQUEST,
});

export const uploadFileSuccess = () => ({
    type: UPLOAD_FILE_SUCCESS,
});

export const uploadFileFailure = (error) => ({
    type: UPLOAD_FILE_FAILURE,
    payload: error,
});


export const DELETE_FILE_REQUEST = 'DELETE_FILE_REQUEST';
export const DELETE_FILE_SUCCESS = 'DELETE_FILE_SUCCESS';
export const DELETE_FILE_FAILURE = 'DELETE_FILE_FAILURE';




export const DELETE_PHOTO_REQUEST = 'DELETE_PHOTO_REQUEST';
export const DELETE_PHOTO_SUCCESS = 'DELETE_PHOTO_SUCCESS';
export const DELETE_PHOTO_FAILURE = 'DELETE_PHOTO_FAILURE';

export const deletePhotoRequest = () => ({
    type: DELETE_PHOTO_REQUEST,
});

export const deletePhotoSuccess = (photoId) => ({
    type: DELETE_PHOTO_SUCCESS,
    payload: photoId,
});

export const deletePhotoFailure = (error) => ({
    type: DELETE_PHOTO_FAILURE,
    payload: error,
});

export const deletePhoto = (trailerId, photoId) => {
    return async (dispatch) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_DELETE_TRAILER_PHOTO_API_URL}/${photoId}`, {
                method: 'DELETE',
                credentials: 'include',
            });
            if (response.ok) {
                // Отримати дані з відповіді після видалення
                const responseData = await response.json();

                // Вивести дані в консоль
                console.log('Server response after photo deletion:', responseData);

                // Викличте actionCreator або виконайте необхідні дії
                dispatch({ type: 'PHOTO_DELETED_SUCCESS', photoId });
            } else {
                throw new Error('Failed to delete photo');
            }
        } catch (error) {
            console.error('Error deleting photo:', error);
            // Викличте actionCreator або виконайте необхідні дії
            // Наприклад, додайте дію, яка сповіщає про помилку видалення фотографії
            dispatch({ type: 'PHOTO_DELETED_FAILURE', error });
        }
    };
};


export const deleteFileRequest = () => ({
    type: DELETE_FILE_REQUEST,
});

export const deleteFileSuccess = () => ({
    type: DELETE_FILE_SUCCESS,
});

export const deleteFileFailure = (error) => ({
    type: DELETE_FILE_FAILURE,
    payload: error,
});

export const fetchSingleTrailer = (trailerId) => {
    return async (dispatch) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_FETCH_SINGLE_TRAILER_API_URL}/${trailerId}`, {
                credentials: 'include',
            });
            const data = await response.json();
            dispatch(fetchSingleTrailerSuccess(data));
        } catch (error) {
            console.error('Error fetching single trailer:', error);
        }
    };
};

export const fetchTrailerPhotos = (trailerId) => {
    return async (dispatch) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_FETCH_TRAILER_PHOTOS_API_URL}/${trailerId}/managePhotos`, {
                credentials: 'include',
            });
            const data = await response.json();
            dispatch(fetchTrailerPhotosSuccess(data));
            return data;
        } catch (error) {
            console.error('Error fetching trailer photos:', error);
            throw error;
        }
    };
};

export const uploadPhotosTrailer = async (trailerId, photos) => {
    try {
        const formData = new FormData();

        photos.forEach((photo, index) => {
            formData.append('photo', photo.file);
        });

        const response = await axios.post(`${process.env.REACT_APP_UPLOAD_PHOTOS_TRAILER_API_URL}/${trailerId}/uploadPhotos`, formData, {
            withCredentials: true,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        if (response.status === 200) {
            const responseData = response.data;
            console.log('Server response after photo upload:', responseData);
            return responseData;
        } else {
            throw new Error('Failed to upload photos');
        }
    } catch (error) {
        console.error('Error uploading photos:', error); 
        throw error;
    }
};


export const savePhotos = (trailerId, photos) => {
    console.log('Saving photos:', photos);
    return async (dispatch) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_SAVE_PHOTOS_TRAILER_API_URL}/${trailerId}/managePhotos`, photos, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            console.log('Response data after saving photos:', response.data);

            if (response.status === 200) {
                dispatch(photosSavedSuccessfully());
            } else {
                throw new Error('Failed to save photos');
            }
        } catch (error) {
            console.error('Error saving photos:', error);
        }
    };
};

export const createTrailerAction = (trailerData) => {
    return async (dispatch) => {
        try {
            dispatch(createTrailer(trailerData));
            const response = await fetch(`${process.env.REACT_APP_CREATE_TRAILER_API_URL}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(trailerData),
                credentials: 'include',
            });

            if (!response.ok) {
                throw new Error('Failed to create trailer');
            }
            const newTrailer = await response.json();
            dispatch(createTrailerSuccess(newTrailer));
        } catch (error) {
            dispatch(createTrailerFailure(error));
        }
    }; 
};


export const fetchDropdowns = () => {
    return async (dispatch) => {
        try {
            dispatch(fetchDropdownsRequest());
            const response = await axios.get(`${process.env.REACT_APP_FETCH_DROPDOWNS_TRAILER_API_URL}`, {
                withCredentials: true,
            });
            const data = response.data;
            dispatch(fetchDropdownsSuccess(data));
        } catch (error) {
            console.error('Error fetching dropdowns:', error);
            dispatch(fetchDropdownsFailure(error));
        }
    };
};


export const updateTrailer = (trailerId, updatedTrailerData) => {
    return async (dispatch) => {
        try {
            dispatch(updateTrailerRequest());

            const response = await axios.put(`${process.env.REACT_APP_UPDATE_TRAILER_API_URL}`, updatedTrailerData, {
                withCredentials: true,
            });

            const updatedTrailer = response.data;
            dispatch(updateTrailerSuccess(updatedTrailer));
        } catch (error) {
            console.error('Error updating trailer:', error);
            dispatch(updateTrailerFailure(error.message || 'Failed to update trailer'));
        }
    };
};


export const fetchFilesDropdowns = () => {
    return (dispatch) => {
        dispatch(fetchFilesDropdownsRequest());
        axios.get(`${process.env.REACT_APP_FETCH_FILES_DROPDOWNS_TRAILER_API_URL}`, {
            withCredentials: true,
        })
            .then(response => {
                const data = response.data;
                dispatch(fetchFilesDropdownsSuccess(data));
            })
            .catch(error => {
                dispatch(fetchFilesDropdownsFailure(error.message));
            });
    };
}; 


export const uploadFile = (file, description, nextMaintenance, dropdownId, trailerId) => {
    return async (dispatch) => {
        try {
            dispatch(uploadFileRequest());
            const formData = new FormData();
            formData.append('file', file);
            formData.append('description', description);
            formData.append('nextMaintenance', nextMaintenance);
            formData.append('dropdownId', dropdownId);

            const response = await axios.post(`${process.env.REACT_APP_UPLOAD_FILE_TRAILER_API_URL}/${trailerId}/uploadFiles`, formData, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            dispatch(uploadFileSuccess());
            console.log('File uploaded successfully:', response.data);
        } catch (error) {
            dispatch(uploadFileFailure(error.message || 'Failed to upload file'));
        }
    };
};

export const deleteFile = (fileId) => {
    return async (dispatch) => {
        try {
            dispatch(deleteFileRequest());
            const response = await axios.delete(`${process.env.REACT_APP_DELETE_FILE_TRAILER_API_URL}/file/${fileId}`, {
                withCredentials: true,
            });

            dispatch(deleteFileSuccess());
            console.log('File deleted successfully:', response.data);
        } catch (error) {
            dispatch(deleteFileFailure(error.message || 'Failed to delete file'));
        }
    };
};


 