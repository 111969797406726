import './TruckProfileComponent.scss'
import Navigation from "../../../../Navigation/Navigation";
import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {fetchSingleTrack, deleteTrack} from "../../../../../store/actions/trackActions";
import {useDispatch, useSelector} from 'react-redux';
import {BsThreeDotsVertical} from "react-icons/bs";
import {GoAlert} from "react-icons/go";
import {BiSearch} from "react-icons/bi";
import {LuListFilter} from "react-icons/lu";
import {LuDownload} from "react-icons/lu";
import {GoTrash} from "react-icons/go";

import {BsCardList} from "react-icons/bs";
import {FaArrowLeft} from "react-icons/fa6";
import {useNavigate} from 'react-router-dom';
import EditPhotoTruckComponentModal from "../EditPhotoTruckComponentModal/EditPhotoTruckComponentModal";
import LoaderComponent from "../../../../LoaderComponent/LoaderComponent";
import SliderPhotostComponent from "../../SliderPhotostComponent/SliderPhotostComponent";
import AddFIlesTruckComponentModal from "../AddFilesComponentTruckModal/AddFilesComponentTruckModal";
import {FaCheck} from "react-icons/fa6";
import {IoCloseOutline} from 'react-icons/io5';
import { deleteFile } from '../../../../../store/actions/trackActions';

import {
    MdKeyboardArrowDown,
    MdKeyboardArrowUp,
    MdKeyboardArrowRight,
    MdKeyboardArrowLeft,
    MdOutlineExpandMore,
    MdOutlineExpandLess
} from "react-icons/md";
import NewTruck from '../NewTruckComponentModal/NewTruckComponentModal';

export default function TruckProfileComponent() {
    const {trackId} = useParams();
    const dispatch = useDispatch();
    const singleTrackInfo = useSelector((state) => state.track?.singleTrackInfo);
    const filesDropdowns = useSelector((state) => state.track.singleTrackInfo?.filesDropdowns);
    const [isMenuOpen, setIsMenuOpen] = useState(true);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isEditPhotoModalOpen, setIsEditPhotoModalOpen] = useState(false);
    const [isAddDocumentModalOpen, setIsAddDocumentModalOpen] = useState(false);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const track = singleTrackInfo;
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredDocuments, setFilteredDocuments] = useState([]);
    const [selectedDropdownOption, setSelectedDropdownOption] = useState('');
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [isSelectOpen, setIsSelectOpen] = useState(false);
    const filepath = useSelector(state => state.track?.singleTrackInfo?.files);


    const handleDownloadClick = (fileId) => {
        const file = filepath.find(file => file.id === fileId);

        if (file && file.link) {
            const protocolLink = `//${file.link}`;

            // Fetch the file content with credentials included
            fetch(protocolLink, {
                credentials: 'include'
            })
                .then(response => response.blob())
                .then(blob => {
                    // Create a blob from the file content
                    const blobUrl = URL.createObjectURL(blob);

                    // Create a temporary link element
                    const link = document.createElement('a');
                    link.href = blobUrl;
                    link.target = '_blank'; // Open in a new tab/window
                    link.download = file.documentName; // Set the downloaded file name
                    document.body.appendChild(link);

                    // Trigger a click event on the link to start the download
                    link.click();

                    // Remove the link element from the DOM
                    document.body.removeChild(link);

                    // Revoke the blob URL to free up resources
                    URL.revokeObjectURL(blobUrl);
                })
                .catch(error => {
                    console.error('Error fetching file content:', error);
                });
        } else {
            console.error('File or link not found');
        }
    };

    // const handleDeleteClick = (fileId) => {
    //     dispatch(deleteFile(fileId));
    // };


    const handleAddDocumentClick = () => {
        setIsAddDocumentModalOpen(true);
    };
    const handleFileListUpdate = () => {
        dispatch(fetchSingleTrack(trackId));
    };

    const handleBackClick = () => {
        navigate('/dispatcher/vehicle');
    };
    useEffect(() => {
        dispatch(fetchSingleTrack(trackId))
            .then(() => setIsLoading(false))
            .catch((error) => {
                setIsLoading(false);
            });

        if (!isEditModalOpen) {
        }
    }, [dispatch, trackId, isEditModalOpen]);
    const handleCheckboxChange = (fileId) => {
        // Toggle the selected state of the checkbox
        if (selectedCheckboxes.includes(fileId)) {
            setSelectedCheckboxes(selectedCheckboxes.filter(id => id !== fileId));
        } else {
            setSelectedCheckboxes([...selectedCheckboxes, fileId]);
        }
    };
    const handleSelectAllCheckboxChange = () => {
        // Toggle the selected state of the "Select All" checkbox
        setSelectAll(!selectAll);

        // If "Select All" is checked, select all checkboxes; otherwise, clear all checkboxes
        setSelectedCheckboxes(selectAll ? [] : filteredDocuments.map(file => file.id));
    };


    const [currentPage, setCurrentPage] = useState(1);
    const [documentsPerPage, setPageSize] = useState(10);

    // Calculate start and end indices for the current page
    const startIndex = (currentPage - 1) * documentsPerPage;
    const endIndex = startIndex + documentsPerPage;

    // Get documents for the current page
    const currentDocuments = filteredDocuments.slice(startIndex, endIndex);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const paginationOptions = Array.from(
        {length: Math.min(10, Math.ceil(filteredDocuments.length / 10))}, // Show options up to a maximum of 10
        (_, index) => (index + 1) * 10
    );
    const handlePageSizeChange = (size) => {
        setPageSize(size);
        setCurrentPage(1);
    };
    const handleEditClick = () => {
        setIsEditModalOpen(true);
    };

    const handleSaveChanges = () => {
        // Fetch the updated track information, or any other logic to refresh images
        dispatch(fetchSingleTrack(trackId))
            .then(() => {
                setIsEditModalOpen(false);
            })
            .catch((error) => {
                // Handle error if necessary
                console.error('Error fetching updated track information:', error);
            });
    };


    const [sortOrder, setSortOrder] = useState('asc');
    const [sortField, setSortField] = useState('');

    const handleSortIconClick = (field, direction) => {
        if (sortField === field && sortOrder === direction) {
            // Prevent double-click on the same icon
            return;
        }
        setSortOrder(direction);
        setSortField(field);
    };
    useEffect(() => {
        if (track?.files) {
            // Filter documents based on the search term
            const filtered = track.files.filter(file =>
                (file.documentName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    file.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    file.uploadData.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    file.nextMaintenance.toLowerCase().includes(searchTerm.toLowerCase())) &&
                (!selectedDropdownOption || file.dropdownId === parseInt(selectedDropdownOption))
            );
            // Sort the filtered documents based on the selected field and order
            const sorted = filtered.slice().sort((a, b) => {
                if (sortField === 'documentName') {
                    return sortOrder === 'asc' ? a.documentName.localeCompare(b.documentName) : b.documentName.localeCompare(a.documentName);
                } else if (sortField === 'description') {
                    return sortOrder === 'asc' ? a.description.localeCompare(b.description) : b.description.localeCompare(a.description);
                } else if (sortField === 'uploadData') {
                    return sortOrder === 'asc' ? a.uploadData.localeCompare(b.uploadData) : b.uploadData.localeCompare(a.uploadData);
                } else if (sortField === 'to') {
                    return sortOrder === 'asc' ? a.nextMaintenance.localeCompare(b.nextMaintenance) : b.nextMaintenance.localeCompare(a.nextMaintenance);
                }
                return 0; // Default case
            });

            setFilteredDocuments(sorted);
        }
    }, [searchTerm, track?.files, selectedDropdownOption, sortField, sortOrder]);

    useEffect(() => {
        if (!isEditModalOpen) {
            // Викликати fetchSingleTrack тут при закритті модального вікна
            dispatch(fetchSingleTrack(trackId))
                .then(() => setIsLoading(false))
                .catch((error) => {
                    setIsLoading(false);
                });
        }
    }, [isEditModalOpen, dispatch, trackId]);


    useEffect(() => {
        if (!isEditModalOpen) {
            // Викликати fetchSingleTrack тут при успішному оновленні модального вікна редагування треку
            dispatch(fetchSingleTrack(trackId));
        }
    }, [isEditModalOpen, dispatch, trackId]);


    const handleBatchDownloadClick = () => {
        if (selectedCheckboxes.length === 0) {
            console.error('No files selected for batch download');
            return;
        }

        // Collect file IDs for selected checkboxes
        const filesToDownload = selectedCheckboxes.map(fileId => {
            const file = filepath.find(f => f.id === fileId);
            return file;
        });

        // Download each selected file
        filesToDownload.forEach(file => {
            if (file && file.link) {
                const protocolLink = `//${file.link}`;

                // Fetch the file content with credentials included
                fetch(protocolLink, {
                    credentials: 'include'
                })
                    .then(response => response.blob())
                    .then(blob => {
                        // Create a blob from the file content
                        const blobUrl = URL.createObjectURL(blob);

                        // Create a temporary link element
                        const link = document.createElement('a');
                        link.href = blobUrl;
                        link.target = '_blank'; // Open in a new tab/window
                        link.download = file.documentName; // Set the downloaded file name
                        document.body.appendChild(link);

                        // Trigger a click event on the link to start the download
                        link.click();

                        // Remove the link element from the DOM
                        document.body.removeChild(link);

                        // Revoke the blob URL to free up resources
                        URL.revokeObjectURL(blobUrl);
                    })
                    .catch(error => {
                        console.error(`Error fetching file content for ${file.documentName}:`, error);
                    });
            } else {
                console.error('File or link not found');
            }
        });

        // Clear the selected checkboxes
        setSelectedCheckboxes([]);
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const modalEditTrackStyle = {
        display: isMenuOpen ? 'none' : 'block'
    };

    const managerPhotosClick = () => {
        setIsEditPhotoModalOpen(true);
    };


    const handleDeleteClick = () => {
        dispatch(deleteTrack(trackId));
    };

    if (!singleTrackInfo) {
        return null;
    }

    const handleDropdownChange = (event) => {
        setSelectedDropdownOption(event.target.value);
    };

    const toggleCustomSelect = () => {
        setIsDropdownOpen(!isDropdownOpen);
        setIsSelectOpen(!isSelectOpen);
    };

    const handleBatchDeleteClick = () => {
        if (selectedCheckboxes.length === 0) {
            console.error('No files selected for batch delete');
            return;
        }
    
        selectedCheckboxes.forEach(fileId => {
            console.log('Deleting file:', fileId);
            dispatch(deleteFile(fileId))
                .then(() => {
                    
                    dispatch(fetchSingleTrack(trackId));
                })
                .catch(error => {
                    console.error(`Error deleting file ${fileId}:`, error);
                });
        });

        setSelectedCheckboxes([]);
    };
    const handleDelClick = async (fileId) => {
        await dispatch(deleteFile(fileId));
        handleFileListUpdate();
    };

    const handleTdClick = (file) => {
        const fileTruck = file;
        const link = `//${fileTruck}`;
        window.open(link, '_blank');
    };


    return (
        <>
            <div className="one__truck__page">
                <Navigation/>
                <div className="one__truck__container">
                    {isLoading ? (
                        <LoaderComponent/>
                    ) : (
                        <div>
                            <div className="one__truck__title">
                                <button onClick={handleBackClick}><FaArrowLeft/></button>
                                Documents
                            </div>
                            <div className="one__truck__table">

                                <div className="one__truck__table__body">
 
                                    <div key={track.id} className="one__truck__table__row">
                                        <div className='one__truck__img__description'>
                                            <div className="one__truck__table__body__item__img">
                                                <SliderPhotostComponent photos={track.photos}/>
                                            </div>
                                            <div className='name__detailed__description'>
                                                <div className='name__description'>
                                                    <div className='truck__name__dots'>
                                                        <div className='truck__name__year'>
                                                            <div className="one__truck__table__body__item name">
                                                            {track.main.unitNumber}
                                                            </div>
                                                            {/* <div className="one__truck__table__body__item name year">
                                                                {track.title.year}
                                                            </div> */}
                                                        </div>
                                                        <div className="one__truck__table__body__item">
                                                            {track.title.comment}
                                                        </div>

                                                    </div>
                                                    <div className="vvvv" onClick={() => toggleMenu(trackId)}>
                                                        <BsThreeDotsVertical/>

                                                        {/* Використовуємо стиль для елемента modal__edit__track */}
                                                        <div className="modal__edit__track" style={modalEditTrackStyle}>
                                                            <div className="modal-content__edit__track">
                                                                <div className="d__menu__track">
                                                                    <div className="d__menu__item__track"
                                                                         onClick={managerPhotosClick}>
                                                                        Manage photos
                                                                    </div>

                                                                    <div className="d__menu__item__track"
                                                                         onClick={() => handleEditClick(track.id)}>
                                                                        Edit information
                                                                    </div>

                                                                    <div className="d__menu__item__track"
                                                                         onClick={() => handleDeleteClick(track.id)}>
                                                                        Delete this
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {isEditPhotoModalOpen && (
                                                        <EditPhotoTruckComponentModal
                                                            onClose={() => setIsEditPhotoModalOpen(false)}
                                                            onSaveChanges={handleSaveChanges}
                                                        />
                                                    )}
                                                    {isEditModalOpen && (
                                                        <div className={`modal__add__truck`}>
                                                            <div className={`modal-content__add__truck`}>
                                                                <div className={`heading__modal__truck`}>
                                                                    <div className={`title__modal__truck`}>
                                                                        {track ? 'Edit truck' : 'Add truck'}
                                                                    </div>
                                                                    <div className={`close__modal__truck`}
                                                                    >
                                                                        <IoCloseOutline
                                                                            onClick={() => setIsEditModalOpen(false)}/>
                                                                    </div>
                                                                </div>
                                                                <NewTruck
                                                                    trackId={trackId}
                                                                    track={track}
                                                                    onClose={(updatedData) => {
                                                                        setIsEditModalOpen(false);

                                                                    }}
                                                                    onSuccess={() => {
                                                                        dispatch(fetchSingleTrack(trackId))
                                                                        console.log('Data saved successfullylog');
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className='ID__license'>
                                        <div className="model__year">
                                        <div className="trucks__table__body__item upper">
                                                <span>Model</span>
                                                {track.title.model}
                                            </div>
                                            <div className="trucks__table__body__item lower">
                                                <span>Year</span>
                                                {track.title.year}
                                            </div>
                                        </div>
                                        <div className='detailed__description'>
                                            <div className="trucks__table__body__item upper">
                                                <span>License plate</span>
                                                {track.main.licensePlate}
                                            </div>
                                            <div className="trucks__table__body__item lower">
                                                <span>State</span>
                                                {track.main.state}
                                            </div>
                                        </div>
                                        <div className='VIN__Unit'>
                                            <div className="trucks__table__body__item upper">
                                                <span>VIN Code</span>
                                                {track.main.vinNumber}
                                            </div>
                                            {/* <div className="trucks__table__body__item lower">
                                                <span>Unit </span>
                                                {track.main.unitNumber}
                                            </div> */}<div className="trucks__table__body__item lower">
                                                <span>Sleeper</span>
                                                {track.main.sleeper}
                                            </div>
                                        </div>
                                        <div className='insurance__sleeper'>
                                            <div className="trucks__table__body__item upper lower">
                                                <span>Insurance</span>
                                                {track.main.insurance}
                                            </div>
                                            <div className="trucks__table__body__item lower">
                                                <span>Annual Inspection</span>
                                                {track.main.annualInspection}
                                            </div>
                                        </div>
                                        <div className='transmission__annual'>
                                            <div className="trucks__table__body__item upper lower">
                                                <span>Transmission</span>
                                                {track.main.transmission}
                                            </div>
                                            <div className="trucks__table__body__item lower">
                                                <span>Mileage</span>
                                                {track.main.mileage}
                                            </div>
                                        </div>
                                        <div className='state__number'>
                                            
                                        </div>
                                        <div className='DPF__mileage'>
                                            <div className="trucks__table__body__item upper lower">
                                                <span>DPF System</span>
                                                {track.main.dpfSystem}
                                            </div>
                                             <div className="trucks__table__body__item upper lower">
                                                <span>Driver</span>
                                                {track.main.driver || 'none'}
                                            </div>
                                        </div>
                                        <div className='driver__trailer'>
                                           
                                            <div className="trucks__table__body__item upper">
                                                <span>Trailer</span>
                                                {track.main.trailer || 'none'}
                                            </div>
                                        </div>
                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            {Object.values(track.title.filesMessages).map(fileMessage => (
                                <div className="alert__message" key={fileMessage.message}>
                                    <p style={{ color: fileMessage.color }}>
                                        <GoAlert />
                                        {fileMessage.message}
                                    </p>
                                </div>
                            ))}



                            <div className='tools__panel'>


                                <div className="one__truck__right__item">

                                    <div className="drivers__search__component">
                                    <div className={`custom-select ${isDropdownOpen ? 'active' : ''}`}
     onClick={toggleCustomSelect}>
    <div className="selected-options">
        {selectedDropdownOption ? (
            <div className="selected-option">
                {filesDropdowns.find(option => option.id === selectedDropdownOption)?.value}{isSelectOpen ?
                <MdKeyboardArrowUp/> : <MdKeyboardArrowDown/>}
            </div>
        ) : (
            <div className="selected-option">All categories{isSelectOpen ?
                <MdKeyboardArrowUp/> : <MdKeyboardArrowDown/>}</div>
        )}
    </div>
    <div className="select-icon"></div>
    <div className="options-list">
        <div key="all-categories" // Додано ключ для "All categories"
             className={`option-item ${!selectedDropdownOption ? 'active' : ''}`}
             onClick={() => handleDropdownChange({target: {value: null}})}> 
            <label>
                All categories
            </label>
            <div className="checkbox">
                {!selectedDropdownOption && <FaCheck/>} 
            </div>
        </div>
        {filesDropdowns.map(option => (
            <div key={option.id}
                 className={`option-item ${option.id === selectedDropdownOption ? 'active' : ''}`}
                 onClick={() => handleDropdownChange({target: {value: option.id}})}>

                <label>
                    {option.value}
                </label>
                <div className="checkbox">
                    {option.id === selectedDropdownOption && <FaCheck/>}
                </div>
            </div>
        ))}
    </div>
</div>


                                    </div>


                                    <div className="one__truck__search">
                                        <BiSearch/>
                                        <input
                                            type="text"
                                            placeholder="Search"
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                        />
                                    </div>
                                    <div className="one__truck__filter">
                                        <LuListFilter/>
                                        <p>Filters</p>
                                    </div>
                                </div>
                                <div className='one__truck__rightright__item'>
                                    <div className='icons__dwlnd__trsh'>
                                        <LuDownload onClick={handleBatchDownloadClick}/>
                                        <GoTrash onClick={handleBatchDeleteClick}/>
                                    </div>
                                    <div className='add__document' onClick={handleAddDocumentClick}>
                                        Add document
                                        <BsCardList/>
                                    </div>
                                </div>


                            </div>

                            <div className='table__wrapper__docs'>
                                <div className='table__container__docs'>
                                    <table>
                                        <thead>
                                        <tr>
                                            <th onClick={(event) => {
                                                // Перевірка, чи клікнуто на чекбокс
                                                if (event.target.type === 'checkbox') {
                                                    return;
                                                }

                                                handleSortIconClick('documentName', sortOrder === 'asc' ? 'desc' : 'asc');
                                            }}>

                                                <div className='table__sort__docs'>
                                                    <div className="form_group__remember__container">
                                                        <input
                                                            type="checkbox"
                                                            id="selectAllCheckbox"
                                                            name="selectAllCheckbox"
                                                            checked={selectAll}
                                                            onChange={handleSelectAllCheckboxChange}
                                                        />
                                                        <label htmlFor="selectAllCheckbox"></label>
                                                    </div>
                                                    Document name
                                                    <div className='table__sort__icons'>
                                                        <div
                                                            className={`table__sort__less ${sortField === 'documentName' && sortOrder === 'desc' ? 'active' : ''}`}
                                                            style={{opacity: sortField === 'documentName' && sortOrder === 'asc' ? 0.5 : 1}}
                                                        >
                                                            <MdOutlineExpandLess/>
                                                        </div>
                                                        <div
                                                            className={`table__sort__more ${sortField === 'documentName' && sortOrder === 'asc' ? 'active' : ''}`}
                                                            style={{opacity: sortField === 'documentName' && sortOrder === 'desc' ? 0.5 : 1}}
                                                        >
                                                            <MdOutlineExpandMore/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </th>
                                            <th onClick={() => handleSortIconClick('description', sortOrder === 'asc' ? 'desc' : 'asc')}>
                                                <div className='table__sort__docs'>
                                                    Description
                                                    <div className='table__sort__icons'>
                                                        <div
                                                            className={`table__sort__less ${sortField === 'description' && sortOrder === 'desc' ? 'active' : ''}`}
                                                            style={{opacity: sortField === 'description' && sortOrder === 'asc' ? 0.5 : 1}}
                                                        >
                                                            <MdOutlineExpandLess/>
                                                        </div>
                                                        <div
                                                            className={`table__sort__more ${sortField === 'description' && sortOrder === 'asc' ? 'active' : ''}`}
                                                            style={{opacity: sortField === 'description' && sortOrder === 'desc' ? 0.5 : 1}}
                                                        >
                                                            <MdOutlineExpandMore/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </th>
                                            <th onClick={() => handleSortIconClick('to', sortOrder === 'asc' ? 'desc' : 'asc')}>
                                                <div className='table__sort__docs'>
                                                    To be updated
                                                    <div className='table__sort__icons'>
                                                        <div
                                                            className={`table__sort__less ${sortField === 'to' && sortOrder === 'desc' ? 'active' : ''}`}
                                                            style={{opacity: sortField === 'to' && sortOrder === 'desc' ? 0.5 : 1}}
                                                        >
                                                            <MdOutlineExpandLess/>
                                                        </div>
                                                        <div
                                                            className={`table__sort__more ${sortField === 'to' && sortOrder === 'asc' ? 'active' : ''}`}
                                                            style={{opacity: sortField === 'to' && sortOrder === 'asc' ? 0.5 : 1}}
                                                        >
                                                            <MdOutlineExpandMore/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </th>

                                            <th onClick={() => handleSortIconClick('uploadData', sortOrder === 'asc' ? 'desc' : 'asc')}>
                                                <div className='table__sort__docs'>
                                                    Upload date
                                                    <div className='table__sort__icons'>
                                                        <div
                                                            className={`table__sort__less ${sortField === 'uploadData' && sortOrder === 'asc' ? 'active' : ''}`}
                                                            style={{opacity: sortField === 'uploadData' && sortOrder === 'desc' ? 0.5 : 1}}
                                                        >
                                                            <MdOutlineExpandLess/>
                                                        </div>
                                                        <div
                                                            className={`table__sort__more ${sortField === 'uploadData' && sortOrder === 'desc' ? 'active' : ''}`}
                                                            style={{opacity: sortField === 'uploadData' && sortOrder === 'asc' ? 0.5 : 1}}
                                                        >
                                                            <MdOutlineExpandMore/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </th>
                                        </tr>

                                        </thead>

                                        <tbody>
                                        {currentDocuments.map((file) => (
                                            <tr key={file.id}>
                                                <td data-cell="MC Number" >
                                                    <div className="form_group__remember__container">
                                                        <input
                                                            type="checkbox"
                                                            id={`checkbox_${file.id}`}
                                                            name={`checkbox_${file.id}`}
                                                            checked={selectedCheckboxes.includes(file.id)}
                                                            onChange={() => handleCheckboxChange(file.id)}
                                                        />
                                                        <label onClick={() => handleTdClick(file.link)} style={{cursor: 'pointer'}}
                                                            htmlFor={`checkbox_${file.id}`}>{file.documentName}</label>
                                                    </div>
                                                </td>
                                                <td data-cell="Company Name">{file.description}</td>
                                                <td data-cell="Phone Number">{file.nextMaintenance}</td>
                                                <td data-cell="Register Date">{file.uploadData}</td>
                                                <td className='table__tools__docs' data-cell="Actions">
                                                    <div className="documents__table__body__item actions__document">
                                                        <div className="actions__document__download"
                                                             onClick={() => handleDownloadClick(file.id)}>
                                                            <LuDownload/>
                                                        </div>
                                                        <div className="actions__document__trash"
                                                         onClick={() => handleDelClick(file.id)}>
                                                        <GoTrash/>
                                                    </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>

                                    </table>
                                </div>
                            </div>
                            {filteredDocuments.length > 0 && (
                                <div className="pagination">
                                    <select onChange={(e) => handlePageSizeChange(e.target.value)}
                                            className='per__page'>
                                        {paginationOptions.map((option) => (
                                            <option key={option} value={option}>
                                                {`${option} per page`}
                                            </option>
                                        ))}
                                    </select>

                                    <div className="pagination__pages">
                                        <button
                                            onClick={() => handlePageChange(currentPage - 1)}
                                            disabled={currentPage === 1} // Disable the button if it's the first page
                                        >
                                            <MdKeyboardArrowLeft/>
                                        </button>
                                        <span>{currentPage}</span>
                                        of {Math.ceil(filteredDocuments.length / documentsPerPage)}
                                        <button
                                            onClick={() => handlePageChange(currentPage + 1)}
                                            disabled={currentPage === Math.ceil(filteredDocuments.length / documentsPerPage)} // Disable the button if it's the last page
                                        >
                                            <MdKeyboardArrowRight/>
                                        </button>
                                    </div>
                                </div>
                            )}


                        </div>

                    )}
                    {isAddDocumentModalOpen && (
                        <AddFIlesTruckComponentModal
                            closeModal={() => {
                                setIsAddDocumentModalOpen(false);
                                handleFileListUpdate();
                            }}
                            trackId={trackId}
                        />
                    )}
                </div>
            </div>

        </>
    )
}
