import './DropdownOptionsComponent.scss'
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllDropdowns, updateDropdown } from '../../../../store/actions/adminDropdownsActions';
import DropdownOptionsTruckComponent from '../DropdownOptionsTruckComponent/DropdownOptionsTruckComponent';
import DropdownOptionsTrailerComponent from '../DropdownOptionsTrailerComponent/DropdownOptionsTrailerComponent';
import DropdownOptionsAddComponent from '../DropdownOptionsAddComponent/DropdownOptionsAddComponent';
import LoaderComponent from '../../../LoaderComponent/LoaderComponent';
import DropdownOptionsDriverFilesComponent from '../DropdownOptionsDriverFilesComponent/DropdownOptionsDriverFilesComponent';
import '../DropdownOptionsDriverFilesComponent/DropdownOptionsDriverFilesComponent.scss'
export default function DropdownOptionsHeadComponent() {
    const [selectedOption, setSelectedOption] = useState('Truck');
    const dispatch = useDispatch();
    const dropdownData = useSelector((state) => state.dropdowns?.data);
    const [isAddComponentVisible, setAddComponentVisible] = useState(false);
    const [isRequestMade, setRequestMade] = useState(false);
    const [loading, setLoading] = useState(false); // Стан для відображення процесу завантаження

    const handleAddClick = () => {
        setAddComponentVisible(!isAddComponentVisible);
    };

    useEffect(() => {
        if (!isRequestMade) {
            setLoading(true);
            dispatch(getAllDropdowns()).then(() => setLoading(false));
            setRequestMade(true);
        }
    }, [dispatch, isRequestMade]);

    const handleOptionClick = (option) => {
        setSelectedOption(option);
    };

    const updateDropdownValue = async (editedValue) => {
        setLoading(true);
        await dispatch(updateDropdown(editedValue));
        setLoading(false);
    };

    return (
        <>
            {loading && <LoaderComponent />}
            <div className="DropDownOptionsContainer">
                <div className="DropDownOptionsBtnContainer">
                    <div
                        className={`DropDownOptionsBtnTruck ${selectedOption === 'Truck' ? 'active__drpdwn' : ''}`}
                        onClick={() => handleOptionClick('Truck')}
                    >
                        Truck
                    </div>
                    <div
                        className={`DropDownOptionsBtnTrailer ${selectedOption === 'Trailer' ? 'active__drpdwn' : ''}`}
                        onClick={() => handleOptionClick('Trailer')}
                    >
                        Trailer
                    </div>
                    <div
                        className={`DropDownOptionsBtnDriverFiles ${selectedOption === 'DriverFiles' ? 'active__drpdwn' : ''}`}
                        onClick={() => handleOptionClick('DriverFiles')}
                    >
                        Driver Files
                    </div>
                    <div className="DropDownOptionsBtnValAdd" onClick={handleAddClick}>
                        {isAddComponentVisible ? 'Close' : 'Add Value'}
                    </div>
                </div>

                <div className="addValuesDropBlock">
                    {isAddComponentVisible && <DropdownOptionsAddComponent dropdownData={dropdownData} />}
                </div>
                <div className="AdditionalComponentContainer">
                    {selectedOption === 'Truck' && <DropdownOptionsTruckComponent dropdownData={dropdownData} updateDropdownValue={updateDropdownValue} />}
                    {selectedOption === 'Trailer' && <DropdownOptionsTrailerComponent dropdownData={dropdownData} updateDropdownValue={updateDropdownValue} />}
                    {selectedOption === 'DriverFiles' && <DropdownOptionsDriverFilesComponent dropdownData={dropdownData} updateDropdownValue={updateDropdownValue} />}
                </div>
            </div>
        </>
    );
}
