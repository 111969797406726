// actions.js
import axios from 'axios';
import {logout} from "./authActions";
// types.js
export const GET_ALL_DROPDOWNS_REQUEST = 'GET_ALL_DROPDOWNS_REQUEST';
export const GET_ALL_DROPDOWNS_SUCCESS = 'GET_ALL_DROPDOWNS_SUCCESS';
export const GET_ALL_DROPDOWNS_FAILURE = 'GET_ALL_DROPDOWNS_FAILURE';
 

export const getAllDropdownsRequest = () => ({
    type: GET_ALL_DROPDOWNS_REQUEST,
});

export const getAllDropdownsSuccess = (data) => ({
    type: GET_ALL_DROPDOWNS_SUCCESS,
    payload: data,
});

export const getAllDropdownsFailure = (error) => ({
    type: GET_ALL_DROPDOWNS_FAILURE,
    payload: error,
});

export const getAllDropdowns = () => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            // Ось ваша логіка для отримання даних
            axios.get(`${process.env.REACT_APP_BASE_URL}/admin/getAllDropdowns`, { withCredentials: true })
                .then((response) => {
                    dispatch(getAllDropdownsSuccess(response.data));
                    resolve(response.data); // Віддаємо дані через resolve
                })
                .catch((error) => {
                    if (error.response && error.response.status === 401) {
                        dispatch(logout());
                    } else {
                        dispatch(getAllDropdownsFailure(error.message));
                    }
                    reject(error); // Відхиляємо проміс у разі помилки
                });
        });
    };
};


//Add new dropdown
export const ADD_DROPDOWN_REQUEST = 'ADD_DROPDOWN_REQUEST';
export const ADD_DROPDOWN_SUCCESS = 'ADD_DROPDOWN_SUCCESS';
export const ADD_DROPDOWN_FAILURE = 'ADD_DROPDOWN_FAILURE';

export const addDropdownRequest = () => ({
    type: ADD_DROPDOWN_REQUEST,
});

export const addDropdownSuccess = () => ({
    type: ADD_DROPDOWN_SUCCESS,
});

export const addDropdownFailure = (error) => ({
    type: ADD_DROPDOWN_FAILURE,
    payload: error,
});

export const addDropdown = (newDropdown) => {
    return (dispatch) => {
        dispatch(addDropdownRequest());
        axios.post(`${process.env.REACT_APP_BASE_URL}/admin/addDropdown`, newDropdown, { withCredentials: true })
            .then(() => {
                dispatch(addDropdownSuccess());
                // Після додавання нового значення, оновіть список dropdowns
                dispatch(getAllDropdowns());
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    dispatch(logout());
                } else {
                    dispatch(addDropdownFailure(error.message));
                }
            });
    };
};



export const updateDropdown = (dropdownData) => {
    return (dispatch) => {
        console.log(dropdownData);
        axios.post(`${process.env.REACT_APP_BASE_URL}/admin/updateDropdown`, dropdownData, {
            withCredentials: true
        })
            .then((response) => {
                dispatch({ type: 'UPDATE_DROPDOWN_SUCCESS', payload: response.data });
                // Після успішного оновлення в базі даних, оновіть дані в Redux-сховищі для відображення нових значень
                dispatch(getAllDropdowns());
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    dispatch(logout())
                } else {
                    dispatch({ type: 'UPDATE_DROPDOWN_ERROR', payload: error });
                }
            });
    };
};

export const DELETE_VALUE_REQUEST = 'DELETE_VALUE_REQUEST';
export const DELETE_VALUE_SUCCESS = 'DELETE_VALUE_SUCCESS';
export const DELETE_VALUE_FAILURE = 'DELETE_VALUE_FAILURE';

export const deleteValue = (type, id) => {
    return async (dispatch) => {
        dispatch({ type: DELETE_VALUE_REQUEST });
        try {
            await axios.delete(`${process.env.REACT_APP_BASE_URL}/admin/deleteDropdown`, {
                data: { type, id },
                withCredentials: true,
            });

            dispatch({
                type: DELETE_VALUE_SUCCESS,
                payload: { type, id },
            });
        } catch (error) {
            console.error('Error deleting value:', error);
            throw error;
        }
    };
};
