import React, {useEffect, useState, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {MdOutlinePlace} from 'react-icons/md';
import {getAllDrivers} from '../../../../store/actions/MainActions';
import LoaderComponent from '../../../LoaderComponent/LoaderComponent';

const AllDriversComponent = ({searchQuery, onDriverClick}) => {
    const dispatch = useDispatch();
    const driversData = useSelector(state => state.main);
    const [isLoading, setIsLoading] = useState(true);
    //eslint-disable-next-line
    const [hoveredDriverId, setHoveredDriverId] = useState(null);
    const [selectedDriverId, setSelectedDriverId] = useState(null);

    const dispatchAction = useCallback(() => {
        const all = true;
        dispatch(getAllDrivers(all))
            .then(() => setIsLoading(false))
            .catch(error => {
                console.error('Error loading drivers:', error);
                setIsLoading(false);
            });
    }, [dispatch]);

    useEffect(() => {
        dispatchAction();
    }, [dispatchAction]);



    const handleClick = (driverId) => {
        onDriverClick(driverId);
        setSelectedDriverId(driverId);
    };

    const filteredDrivers = driversData?.drivers?.filter(
        (driver) =>
            (driver?.driverName || '').toLowerCase().includes(
                (searchQuery || '').toLowerCase()
            )
    );


    return (
        <div className="routes__container">
            {isLoading ? (
                <LoaderComponent/>
            ) : (
                filteredDrivers && filteredDrivers.length > 0 ? (
                    filteredDrivers.map((driver) => (
                        <div
                            className={`route ${selectedDriverId === driver.id ? 'selected' : ''} ${hoveredDriverId === driver.id ? 'opaque' : ''}`}
                            key={driver.id}
                            onClick={() => handleClick(driver.id)}
                        >
                            <div className='single__route__container'>
                                <div className='route__name__status'>
                                    <div className='route__name'>
                                        Driver:
                                        <span>{driver.driverName}</span>
                                    </div>
                                    {driver.statuses && driver.statuses.some(status => status !== null) ? (
                                        <div className='route__statuses'>
                                            {driver.statuses.map((status, index) => (
                                                status !== null && (
                                                    <div
                                                        key={index}
                                                        className='route__status'
                                                        style={{color: status.color}}
                                                    >
                                                        {status.value}
                                                    </div>
                                                )
                                            ))}
                                        </div>
                                    ) : null}
                                </div>
                                {driver.currentLocation !== null && (
                                    <div className='route__location'>
                                        <MdOutlinePlace/> {driver.currentLocation}
                                    </div>
                                )}
                            </div>
                        </div>
                    ))
                ) : (
                    <p>No matching drivers found.</p>
                )
            )}
        </div>
    );
};

export default AllDriversComponent;
