const initialState = {
    settingsData: null,
    error: null,
    updating: false,
};

const settingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_SETTINGS_REQUEST':
        case 'UPDATE_SETTINGS_REQUEST':
        case 'UPDATE_PROFILE_PHOTO_REQUEST':
            return {
                ...state,
                updating: true,
                error: null,
            };
        case 'FETCH_SETTINGS_SUCCESS':
        case 'UPDATE_SETTINGS_SUCCESS':
        case 'UPDATE_PROFILE_PHOTO_SUCCESS':
            return {
                ...state,
                settingsData: action.payload,
                updating: false,
                error: null,
            };
        case 'FETCH_SETTINGS_FAILURE':
        case 'UPDATE_SETTINGS_FAILURE':
        case 'UPDATE_PROFILE_PHOTO_FAILURE':
            return {
                ...state,
                settingsData: null,
                updating: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default settingsReducer;
