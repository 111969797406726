import Navigation from "../../../Components/Navigation/Navigation";
import HelpComponent from "../../../Components/Dispathcer/Help/HelpComponent/HelpComponent";
import "./HelpPage.scss";

export default function HelpPage() {
    return (
        <div className="help__container__dispatcher">
            <Navigation />
            <HelpComponent />
        </div>
    );
} 