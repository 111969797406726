import './MessageModalAskReport.scss';
import React, {useEffect} from 'react';
import {IoClose} from "react-icons/io5";

export default function MessageModalAskReport({isOpen, onClose}) {

    useEffect(() => {
        if (isOpen) {
            const timer = setTimeout(() => {
                onClose();
            }, 5000); // 5 seconds
            return () => clearTimeout(timer);
        }
    }, [isOpen, onClose]);
    return (
        <>
            <div className={`messageAskReport ${isOpen ? 'open' : ''}`}>
                <div className="modalContentAskReport">
                    <div className="headModalAskReport">
                        <div className="titleModalAskReport">
                            Ask photo report
                        </div>
                        <div className="closeAskReportModal" onClick={onClose}>
                            <IoClose/>
                        </div>
                    </div>
                    <div className="messageContainerAskReport">
                        The message to the driver has been successfully sent!
                    </div>
                </div>
            </div>
        </>
    );
}
