import React from 'react';
import './MainComponents.scss';
import backKpoSvg from '../../../assets/svg/back_kpo.svg';

export default function MainComponents() {
    return (
        <>
            <div className="AdminMainPage">
                <div className="titleAdminMainPage">
                    <h1>Main Page</h1>
                </div>
                <div className="containerLogoEmpty">
                    <div className="imgContainerAdminMain">
                        <img src={backKpoSvg} alt=""/>
                    </div>
                </div>
            </div>
        </>
    );
}
