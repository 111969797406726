import './NewTruckComponentModal.scss';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {fetchTruckDropdowns, createTrack} from '../../../../../store/actions/trackActions';
import {updateTrack} from '../../../../../store/actions/trackActions';
import {fetchTrackInfo} from '../../../../../store/actions/trackActions';
import {CiCircleAlert} from "react-icons/ci";
import {FaCheck} from "react-icons/fa6";
import {MdKeyboardArrowDown} from "react-icons/md";
import {MdKeyboardArrowUp} from "react-icons/md";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import LoaderComponent from '../../../../LoaderComponent/LoaderComponent';
export default function NewTruck({onClose, onSuccess, track}) {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const dropdownData = useSelector((state) => state.track.dropdownData);
    const dropdownDat = useSelector((state) => state.track);
    const [annualInspection, setAnnualInspection] = useState(track?.main?.annualInspection || null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isSelectOpen, setIsSelectOpen] = useState(false);
    const [isInsuranceDropdownOpen, setIsInsuranceDropdownOpen] = useState(false);
    const [isTransmissionDropdownOpen, setIsTransmissionDropdownOpen] = useState(false);
    const [isDpfSystemDropdownOpen, setIsDpfSystemDropdownOpen] = useState(false);
    const [isSleeperDropdownOpen, setIsSleeperDropdownOpen] = useState(false);
    const [isDriverDropdownOpen, setIsDriverDropdownOpen] = useState(false);
    const [isTrailerDropdownOpen, setIsTrailerDropdownOpen] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedInsuranceOption, setSelectedInsuranceOption] = useState('');
    const [selectedTransmissionOption, setSelectedTransmissionOption] = useState('');
    const [selectedDpfSystemOption, setSelectedDpfSystemOption] = useState('');
    const [selectedSleeperOption, setSelectedSleeperOption] = useState('');
    const [selectedDriverOption, setSelectedDriverOption] = useState('');
    const [selectedTrailerOption, setSelectedTrailerOption] = useState(track?.main?.trailerId || null);
    const scrollToTop = () => {
        const modalContainer = document.querySelector('.form__modal__truck__add');
        if (modalContainer) {
            modalContainer.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }
    };
    const toggleCustomSelect = (dropdown) => {
        switch (dropdown) {
            case 'insurance':
                setIsInsuranceDropdownOpen(prevState => !prevState);
                break;
            case 'transmission':
                setIsTransmissionDropdownOpen(prevState => !prevState);
                break;
            case 'dpfSystem':
                setIsDpfSystemDropdownOpen(prevState => !prevState);
                break;
            case 'sleeper':
                setIsSleeperDropdownOpen(prevState => !prevState);
                break;
            case 'driver':
                setIsDriverDropdownOpen(prevState => !prevState);
                break;
            case 'trailer':
                setIsTrailerDropdownOpen(prevState => !prevState);
                break;
            default:
                break;
        }
    };

    useEffect(() => {

    }, [selectedCategory]);

    useEffect(() => {
        dispatch(fetchTruckDropdowns());
    }, [dispatch]);

    const dropdownFields = [
        "insurance",
        "transmission",
        "dpfSystem",
        "sleeper",

    ];
    const driverFields = [
        "driverId",

    ]
    const trailerFields = [
        "trailerId"
    ]
    const formatDateTime = (date) => {
        const formattedDate = date ? `${date.getUTCFullYear()}-${(date.getUTCMonth() + 1).toString().padStart(2, '0')}-${date.getUTCDate().toString().padStart(2, '0')}` : '';
        console.log(formattedDate);
        return formattedDate;
    };


    const [formData, setFormData] = useState({
        model: "",
        year: "",
        mileage: "",
        licensePlate: "",
        vinNumber: "",
        unitNumber: "",
        annualInspection: "",
        state: "",
        driverId: "",
        trailerId: "",
        comment: "",
        dropdowns: dropdownFields.reduce((acc, field) => {
            return {
                ...acc,
                [field]: "",
            };
        }, {}),

    });

    const handleDateChange = (date) => {
        // Оновлюємо дату при зміні вибраної дати
        setAnnualInspection(date);

        // Оновлюємо внутрішній стан formData
        setFormData((prevData) => ({
            ...prevData,
            annualInspection: date ? date.toISOString().split('T')[0] : '', // Якщо дата не вибрана, встановлюємо порожній рядок
        }));
    };

    useEffect(() => {
        // Конвертуємо дату з формату строки до об'єкту Date
        if (track?.main?.annualInspection) {
            setAnnualInspection(new Date(track.main.annualInspection));
        }
    }, [track]);

    const [errors, setErrors] = useState({});

    const handleInputChange = (e) => {
        const category = e.target.value;
        setSelectedCategory(category);

        const {name, value} = e.target;


        if (dropdownFields.includes(name)) {
            switch (name) {
                case 'insurance':
                    setSelectedInsuranceOption(value);
                    if (track) {
                        const fieldName = "main"
                        const nestedFieldName = "insurance"
                        setFormData((prevData) => ({
                            ...prevData,
                            [fieldName]: {
                                ...prevData[fieldName],
                                [nestedFieldName]: value,
                            },
                        }));

                    }
                    break;
                case 'transmission':
                    setSelectedTransmissionOption(value);

                    if (track) {
                        const fieldName = "main"
                        const nestedFieldName = "transmission"
                        setFormData((prevData) => ({
                            ...prevData,
                            [fieldName]: {
                                ...prevData[fieldName],
                                [nestedFieldName]: value,
                            },
                        }));

                    }
                    break;
                case 'dpfSystem':
                    setSelectedDpfSystemOption(value);
                    if (track) {
                        const fieldName = "main"
                        const nestedFieldName = "dpfSystem"
                        setFormData((prevData) => ({
                            ...prevData,
                            [fieldName]: {
                                ...prevData[fieldName],
                                [nestedFieldName]: value,
                            },
                        }));

                    }
                    break;
                case 'sleeper':
                    setSelectedSleeperOption(value);
                    if (track) {
                        const fieldName = "main"
                        const nestedFieldName = "sleeper"
                        setFormData((prevData) => ({
                            ...prevData,
                            [fieldName]: {
                                ...prevData[fieldName],
                                [nestedFieldName]: value,
                            },
                        }));

                    }
                    break;

                default:
                    break;
            }

            setFormData((prevData) => ({
                ...prevData,
                dropdowns: {
                    ...prevData.dropdowns,
                    [name]: value,
                },
            }));
        } else if (driverFields.includes(name)) {
            setSelectedDriverOption(value);
            setFormData((prevData) => ({
                ...prevData,
                driverId: value,
            }));

        } else if (trailerFields.includes(name)) {
            setSelectedTrailerOption(value);
            setFormData((prevData) => ({
                ...prevData,
                trailerId: value,
            }));

        }

        // Обновите остальные поля данных формы
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        // Удалите сообщение об ошибке при начале ввода 
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));
    };
    const validateForm = () => {
        const newErrors = {};

        // Validate required fields
        if (!formData.model) {
            newErrors.model = 'Model is required';
        } else if (formData.model.length > 30) {
            newErrors.model = 'must be less than 30 characters';
        }

        if (!formData.year) {
            newErrors.year = 'Year is required';
        } else if (isNaN(formData.year)) {
            newErrors.year = 'must be a valid integer';
        } else if (formData.year.toString().length !== 4) {
            newErrors.year = 'must be a four-digit number';
        }

        if (!formData.mileage) {
            newErrors.mileage = 'Mileage is required';
        } else if (isNaN(formData.mileage)) {
            newErrors.mileage = 'must be a valid integer';
        }

        if (!formData.licensePlate) {
            newErrors.licensePlate = 'License Plate is required';
        } else if (formData.licensePlate.length > 30) {
            newErrors.licensePlate = 'must be less than 30 characters';
        }

        if (!formData.vinNumber) {
            newErrors.vinNumber = 'VIN number is required';
        } else if (formData.vinNumber.length > 30) {
            newErrors.vinNumber = 'VIN number must be less than 30 characters';
        }

        if (!formData.unitNumber) {
            newErrors.unitNumber = 'Unit Number is required';
        } else if (formData.unitNumber.length > 30) {
            newErrors.unitNumber = 'Unit Number must be less than 30 characters';
        }

        if (!formData.annualInspection) {
            newErrors.annualInspection = 'Annual Inspection is required';
        }

        if (!formData.state) {
            newErrors.state = 'State is required';
        } else if (formData.state.length > 30) {
            newErrors.state = 'State must be less than 30 characters';
        }

        // Optional fields
        if (formData.driverId && isNaN(formData.driverId)) {
            newErrors.driverId = 'Driver ID must be a valid integer';
        }
        if (formData.trailerId && isNaN(formData.trailerId)) {
            newErrors.trailerId = 'Trailer ID must be a valid integer';
        }
        if (formData.comment && formData.comment.length > 255) {
            newErrors.comment = 'Comment must be less than 255 characters';
        }

        // Dropdowns
        const dropdownErrors = {};
        const dropdowns = track ? formData.dropdowns : formData;
        if (!dropdowns.insurance || isNaN(dropdowns.insurance)) {
            dropdownErrors.insurance = 'Insurance is required';
        }
        if (!dropdowns.transmission || isNaN(dropdowns.transmission)) {
            dropdownErrors.transmission = 'Transmission is required';
        }
        if (!dropdowns.sleeper || isNaN(dropdowns.sleeper)) {
            dropdownErrors.sleeper = 'Sleeper is required';
        }
        if (!dropdowns.dpfSystem || isNaN(dropdowns.dpfSystem)) {
            dropdownErrors.dpfSystem = 'DPF System is required';
        }
        Object.assign(newErrors, dropdownErrors);

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    const formattedDate = new Date(annualInspection).toISOString().split('T')[0];

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Отримайте копію formData
        const formDataCopy = { ...formData };

        // Заберіть коментар, якщо він порожній або null
        if (!formDataCopy.comment) {
            delete formDataCopy.comment;
        }

        // Перевірте, чи існує дата annualInspection та встановіть її в формі
        if (annualInspection) {
            // Конвертація дати з європейського часового поясу (якщо вона є) до американського часового поясу
            const adjustedDate = new Date(annualInspection.getTime() - (annualInspection.getTimezoneOffset() * 60000));
            
            // Встановіть дату для американського часового поясу
            formDataCopy.annualInspection = adjustedDate.toISOString().split('T')[0];
        }

        if (validateForm(formDataCopy)) {
            try {
                if (track) {
                    // Якщо трек визначено, оновіть трек
                    setIsLoading(true);
                    dispatch(updateTrack(formDataCopy));
                    console.log(formDataCopy);
                    await dispatch(fetchTrackInfo());
                    onClose(formDataCopy);
                } else {
                    // Якщо трек не визначено, створіть новий трек
                    setIsLoading(true);
                    console.log(formDataCopy);
                    await dispatch(createTrack(formDataCopy));
                    await dispatch(fetchTrackInfo());
                    onClose(formDataCopy);
                }
                setIsLoading(true);
                await dispatch(fetchTrackInfo());

                onClose();
            } catch (error) {
                console.error('Error creating/updating track:', error);
            }
        } else {
            // Прокрутіть вгору, якщо є помилки форми
            scrollToTop();
        }
    };





    const handleCancel = () => {
        onClose();
    };

    useEffect(() => {
        const fetchData = async () => {
            if (dropdownData) {
                if (track) {
                    const selectedInsurance = dropdownData?.insurance?.find(item => item.value === track?.main?.insurance);

                    if (selectedInsurance) {
                        setSelectedInsuranceOption(selectedInsurance.id);
                    }

                    const selectedTransmission = dropdownData?.transmission?.find(item => item.value === track?.main?.transmission);
                    if (selectedTransmission) {
                        setSelectedTransmissionOption(selectedTransmission.id);
                    }

                    const selectedDpfSystem = dropdownData?.dpfSystem?.find(item => item.value === track?.main?.dpfSystem);
                    if (selectedDpfSystem) {
                        setSelectedDpfSystemOption(selectedDpfSystem.id);
                    }

                    const selectedSleeper = dropdownData?.sleeper?.find(item => item.value === track?.main?.sleeper);
                    if (selectedSleeper) {
                        setSelectedSleeperOption(selectedSleeper.id);
                    }
                    const selectedDriver = track?.main?.driverId;

                    if (selectedDriver) {
                        setSelectedDriverOption(selectedDriver);

                    }
                    const selectedTrailer = dropdownData?.trailer?.find(item => item.value === track?.main?.trailer);
                    if (selectedTrailer) {
                        setSelectedTrailerOption(selectedTrailer.id);
                    }

                }
            }
        };


        fetchData();
    }, [track, dropdownData]);

    useEffect(() => {
        if (dropdownData) {
            if (track) {
                const InsuranceMatch = dropdownData?.insurance?.find(item => item.value === track?.main?.insurance);
                const formInsurance = (InsuranceMatch?.id);
                const TransmissionMatch = dropdownData?.transmission?.find(item => item.value === track?.main?.transmission);
                const formTransmission = (TransmissionMatch?.id);
                const DpfSystemMatch = dropdownData?.dpfSystem?.find(item => item.value === track?.main?.dpfSystem);
                const formDpfSystem = (DpfSystemMatch?.id);
                const SleeperMatch = dropdownData?.sleeper?.find(item => item.value === track?.main?.sleeper);
                const formSleeper = (SleeperMatch?.id);
                const DriverMatch = dropdownData?.driver?.find(item => item.value === track?.main?.driver);

                const formDriver = (selectedDriverOption);
                const TrailerMatch = dropdownData?.trailer?.find(item => item.value === track?.main?.trailer);
                const formTrailer = (TrailerMatch?.id);

                setFormData({
                    id: track.id,
                    model: track.title.model,
                    year: track.title.year,
                    mileage: track.main.mileage,
                    licensePlate: track.main.licensePlate,
                    vinNumber: track.main.vinNumber,
                    unitNumber: track.main.unitNumber,
                    annualInspection: track.main.annualInspection,
                    state: track.main.state,
                    driverId: formDriver,
                    trailerId: selectedTrailerOption,
                    trailer: track.main.trailer,
                    comment: track.title.comment,
                    insurance: track.main.insurance,
                    transmission: track.main.transmission,
                    dpfSystem: track.main.dpfSystem,
                    sleeper: track.main.sleeper,
                    dropdowns: {
                        insurance: formInsurance,
                        transmission: formTransmission,
                        dpfSystem: formDpfSystem,
                        sleeper: formSleeper,
                    }
                });

            }
        }
    }, [track, dropdownData]);


    return (
        <div className="add_trucks">
            <div className="form__modal__truck__add">
                <form onSubmit={handleSubmit}>
                    <div className="left__right__columns">
                        <div className="left__column">
                            <div className="form__group__add__truck">
                                <label>Model {errors.model &&
                                    <p className="error-message">{errors.model}<CiCircleAlert/></p>}</label>
                                <input
                                    type="text"
                                    name="model"
                                    value={formData.model}
                                    onChange={handleInputChange}
                                    placeholder="Enter the truck model"

                                />
                            </div>
                            <div className="form__group__add__truck">
                                <label>Mileaege {errors.mileage &&
                                    <p className="error-message">{errors.mileage}<CiCircleAlert/></p>}</label>
                                <input
                                    type="text"
                                    name="mileage"
                                    value={formData.mileage}
                                    onChange={handleInputChange}
                                    placeholder="Enter the truck mileage"

                                />
                            </div>
                            <div className="form__group__add__truck">
                                <label>Insurance {errors.insurance &&
                                    <p className="error-message">{errors.insurance}<CiCircleAlert/></p>}</label>

                                <div
                                    className={`vehicle__select custom-select ${isInsuranceDropdownOpen ? 'active' : ''}`}
                                    onClick={() => toggleCustomSelect('insurance')}>
                                    <div className="selected-options">
                                        <div
                                            className="selected-option">{selectedInsuranceOption ? dropdownData?.insurance && dropdownData.insurance.find((item) => item.id === selectedInsuranceOption)?.value : 'Not selected'}</div>
                                        <div className="selected-option vehicle__arrow">{isDropdownOpen ?
                                            <MdKeyboardArrowUp/> : <MdKeyboardArrowDown/>}</div>
                                    </div>
                                    <div className="select-icon">
                                    </div>
                                    <div className="options-list vehicle__list">
                                        {dropdownData?.insurance && dropdownData.insurance.map((item) => (
                                            <div key={item.id}
                                                 className={`option-item ${item.id === selectedInsuranceOption ? 'active' : ''}`}
                                                 onClick={(e) => handleInputChange({
                                                     target: {
                                                         value: item.id,
                                                         name: 'insurance'
                                                     }
                                                 })}>
                                                <label>
                                                    {item.value}
                                                </label>
                                                <div className="checkbox">
                                                    {item.id === selectedInsuranceOption && <FaCheck/>}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="form__group__add__truck">
                                <label>Transmission {errors.transmission &&
                                    <p className="error-message">{errors.transmission}<CiCircleAlert/></p>}</label>

                                <div
                                    className={`vehicle__select custom-select ${isTransmissionDropdownOpen ? 'active' : ''}`}
                                    onClick={() => toggleCustomSelect('transmission')}>
                                    <div className="  selected-options">
                                        <div
                                            className="selected-option">{selectedTransmissionOption ? dropdownData?.transmission && dropdownData.transmission.find((item) => item.id === selectedTransmissionOption)?.value : 'Not selected'}</div>
                                        <div className="selected-option vehicle__arrow">{isDropdownOpen ?
                                            <MdKeyboardArrowUp/> : <MdKeyboardArrowDown/>}</div>
                                    </div>
                                    <div className="select-icon">
                                    </div>
                                    <div className="options-list vehicle__list">
                                        {dropdownData?.transmission && dropdownData.transmission.map((item) => (
                                            <div key={item.id}
                                                 className={`option-item ${item.id === selectedTransmissionOption ? 'active' : ''}`}
                                                 onClick={(e) => handleInputChange({
                                                     target: {
                                                         value: item.id,
                                                         name: 'transmission'
                                                     }
                                                 })}>
                                                <label>
                                                    {item.value}
                                                </label>
                                                <div className="checkbox">
                                                    {item.id === selectedTransmissionOption && <FaCheck/>}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="form__group__add__truck">
                                <label>Annual Inspection {errors.annualInspection &&
                                    <p className="error-message">{errors.annualInspection}<CiCircleAlert/></p>}</label>

                                <DatePicker
                                    selected={annualInspection}
                                    onChange={handleDateChange}
                                    dateFormat="MM/dd/yyyy"
                                    placeholderText="Enter the truck annual inspection"
                                />
                            </div>
                            <div className="form__group__add__truck">
                                <label>DPF system {errors.dpfSystem &&
                                    <p className="error-message">{errors.dpfSystem}<CiCircleAlert/></p>}</label>

                                <div
                                    className={` vehicle__select custom-select ${isDpfSystemDropdownOpen ? 'active' : ''}`}
                                    onClick={() => toggleCustomSelect('dpfSystem')}>
                                    <div className="selected-options">
                                        <div
                                            className="selected-option">{selectedDpfSystemOption ? dropdownData?.dpfSystem && dropdownData.dpfSystem.find((item) => item.id === selectedDpfSystemOption)?.value : 'Not selected'}</div>
                                        <div className="selected-option vehicle__arrow">{isDropdownOpen ?
                                            <MdKeyboardArrowUp/> : <MdKeyboardArrowDown/>}</div>
                                    </div>
                                    <div className="select-icon">
                                    </div>
                                    <div className="options-list vehicle__list">
                                        {dropdownData?.dpfSystem && dropdownData.dpfSystem.map((item) => (
                                            <div key={item.id}
                                                 className={`option-item ${item.id === selectedDpfSystemOption ? 'active' : ''}`}
                                                 onClick={(e) => handleInputChange({
                                                     target: {
                                                         value: item.id,
                                                         name: 'dpfSystem'
                                                     }
                                                 })}>
                                                <label>
                                                    {item.value}
                                                </label>
                                                <div className="checkbox">
                                                    {item.id === selectedDpfSystemOption && <FaCheck/>}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="form__group__add__truck">
    <label>Driver</label>

    <div
        className={` vehicle__select custom-select ${isDriverDropdownOpen ? 'active' : ''}`}
        onClick={() => toggleCustomSelect('driver')}
    >
        <div className="selected-options">
            <div className="selected-option">
                {selectedDriverOption ? dropdownData?.driver && dropdownData.driver.find((item) => item.id === selectedDriverOption)?.value : 'Not selected'}
            </div>
            <div className="selected-option vehicle__arrow">
                {isDropdownOpen ? <MdKeyboardArrowUp/> : <MdKeyboardArrowDown/>}
            </div>
        </div>
        <div className="select-icon"></div>
        <div className="options-list vehicle__list">
            {/* Додано елемент "Not selected" */}
            <div
                className={`option-item ${selectedDriverOption === null ? 'active' : ''}`}
                onClick={(e) => handleInputChange({
                    target: {
                        value: null, // Використовуйте значення null для позначення "Not selected"
                        name: 'driverId'
                    }
                })}
            >
                <label>Not selected</label>
                <div className="checkbox">{selectedDriverOption === null && <FaCheck/>}</div>
            </div>
            {/* Решта варіантів */}
            {dropdownData?.driver && dropdownData.driver.map((item) => (
                <div
                    key={item.id}
                    className={`option-item ${item.id === selectedDriverOption ? 'active' : ''}`}
                    onClick={(e) => handleInputChange({
                        target: {
                            value: item.id,
                            name: 'driverId'
                        }
                    })}
                >
                    <label>{item.value}</label>
                    <div className="checkbox">{item.id === selectedDriverOption && <FaCheck/>}</div>
                </div>
            ))}
        </div>
    </div>
</div>

                            <div className="form__group__add__truck comment">
                                <label>Comment {errors.comment &&
                                    <p className="error-message">{errors.comment}<CiCircleAlert/></p>}</label>
                                <textarea
                                    name="comment"
                                    value={formData.comment}
                                    onChange={handleInputChange}
                                    placeholder="Enter the truck comment"

                                ></textarea>
                            </div>
                        </div>
                        <div className="right__column">
                            <div className="form__group__add__truck">
                                <label>Year {errors.year &&
                                    <p className="error-message">{errors.year}<CiCircleAlert/></p>}</label>
                                <input
                                    type="text"
                                    name="year"
                                    value={formData.year}
                                    onChange={handleInputChange}
                                    placeholder="Enter the truck year"

                                />
                            </div>
                            <div className="form__group__add__truck">
                                <label>License Plate {errors.licensePlate &&
                                    <p className="error-message">{errors.licensePlate}<CiCircleAlert/></p>}</label>
                                <input
                                    type="text"
                                    name="licensePlate"
                                    value={formData.licensePlate}
                                    onChange={handleInputChange}
                                    placeholder="Enter the track licensePlate"

                                />
                            </div>
                            <div className="form__group__add__truck">
                                <label>VIN number {errors.vinNumber &&
                                    <p className="error-message">{errors.vinNumber}<CiCircleAlert/></p>}</label>
                                <input
                                    type="text"
                                    name="vinNumber"
                                    value={formData.vinNumber}
                                    onChange={handleInputChange}
                                    placeholder="Enter the truck VIN Number"

                                />
                            </div>
                            <div className="form__group__add__truck">
                                <label>Unit Number {errors.unitNumber &&
                                    <p className="error-message">{errors.unitNumber}<CiCircleAlert/></p>}</label>
                                <input
                                    type="text"
                                    name="unitNumber"
                                    value={formData.unitNumber}
                                    onChange={handleInputChange}
                                    placeholder="Enter the truck Unit Number"

                                />
                            </div>
                            <div className="form__group__add__truck">
                                <label>Sleeper {errors.sleeper &&
                                    <p className="error-message">{errors.sleeper}<CiCircleAlert/></p>}</label>

                                <div
                                    className={` vehicle__select custom-select ${isSleeperDropdownOpen ? 'active' : ''}`}
                                    onClick={() => toggleCustomSelect('sleeper')}>
                                    <div className="selected-options">
                                        <div
                                            className="selected-option">{selectedSleeperOption ? dropdownData?.sleeper && dropdownData.sleeper.find((item) => item.id === selectedSleeperOption)?.value : 'Not selected'}</div>
                                        <div className="selected-option vehicle__arrow">{isDropdownOpen ?
                                            <MdKeyboardArrowUp/> : <MdKeyboardArrowDown/>}</div>
                                    </div>
                                    <div className="select-icon">
                                    </div>
                                    <div className="options-list vehicle__list">
                                        {dropdownData?.sleeper && dropdownData.sleeper.map((item) => (
                                            <div key={item.id}
                                                 className={`option-item ${item.id === selectedSleeperOption ? 'active' : ''}`}
                                                 onClick={(e) => handleInputChange({
                                                     target: {
                                                         value: item.id,
                                                         name: 'sleeper'
                                                     }
                                                 })}>
                                                <label>
                                                    {item.value}
                                                </label>
                                                <div className="checkbox">
                                                    {item.id === selectedSleeperOption && <FaCheck/>}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="form__group__add__truck">
                                <label>State {errors.state &&
                                    <p className="error-message">{errors.state}<CiCircleAlert/></p>}</label>
                                <input
                                    type="text"
                                    name="state"
                                    value={formData.state}
                                    onChange={handleInputChange}
                                    placeholder="Enter the truck state"

                                />
                            </div>
                            <div className="form__group__add__truck trailer">
    <label>Trailer</label>

    <div
        className={` vehicle__select custom-select ${isTrailerDropdownOpen ? 'active' : ''}`}
        onClick={() => toggleCustomSelect('trailer')}
    >

        <div className="selected-options">
            <div className="selected-option">
                {selectedTrailerOption ? dropdownData?.trailer && dropdownData.trailer.find((item) => item.id === selectedTrailerOption)?.value : 'Not selected'}
            </div>
            <div className="selected-option vehicle__arrow">
                {isDropdownOpen ? <MdKeyboardArrowUp/> : <MdKeyboardArrowDown/>}
            </div>
        </div>
        <div className="select-icon"></div>
        <div className="options-list vehicle__list">
            {/* Додано елемент "Not selected" */}
            <div
                className={`option-item ${selectedTrailerOption === null ? 'active' : ''}`}
                onClick={(e) => handleInputChange({
                    target: {
                        value: null, // Використовуйте значення null для позначення "Not selected"
                        name: 'trailerId'
                    }
                })}
            >
                <label>Not selected</label>
                <div className="checkbox">{selectedTrailerOption === null && <FaCheck/>}</div>
            </div>
            {/* Решта варіантів */}
            {dropdownData?.trailer && dropdownData.trailer.map((item) => (
                <div
                    key={item.id}
                    className={`option-item ${item.id === selectedTrailerOption ? 'active' : ''}`}
                    onClick={(e) => handleInputChange({
                        target: {
                            value: item.id,
                            name: 'trailerId'
                        }
                    })}
                >
                    <label>{item.value}</label>
                    <div className="checkbox">{item.id === selectedTrailerOption && <FaCheck/>}</div>
                </div>
            ))}
        </div>
    </div>
</div>

                            {isLoading ? (
            <LoaderComponent />
        ) : (
                            <div className="buttons__creaete__cancel">
                                <div className="form__group__add__truck__btn create">
                                    {track ? (
                                        <button type="submit">Update truck</button>
                                    ) : (
                                        <button type="submit">Create truck</button>
                                    )}
                                </div>
                                <div className="form__group__cancel__truck__btn">
                                    <button type="button" onClick={handleCancel}>Cancel</button>
                                </div>
                            </div>
        )}
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}