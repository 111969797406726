import {Link} from 'react-router-dom';
import './NotFound.scss';
import {useTranslation} from 'react-i18next';
import NotFoundIcon from '../../assets/svg/notFound.svg';
import ErrorIcon from '../../assets/svg/error.svg';

export default function NotFound() {
    const {t} = useTranslation();
    return (
        <div className="not__found__container">
            <div className="nf__img__container">
                <img src={NotFoundIcon} alt="Not Found"/>
                <img src={ErrorIcon} alt="Error"/>
            </div>
            <div className="nf__text">
                {t('Page not found')}
            </div>
            <div className="btn__back">
                <Link to="/">
                    <button>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 20 14" fill="none">
                            <path
                                d="M0.89896 6.39896C0.567013 6.7309 0.567013 7.2691 0.89896 7.60104L6.30833 13.0104C6.64027 13.3424 7.17846 13.3424 7.51041 13.0104C7.84235 12.6785 7.84235 12.1403 7.51041 11.8083L2.70208 7L7.51041 2.19167C7.84235 1.85973 7.84235 1.32154 7.51041 0.989592C7.17846 0.657647 6.64027 0.657647 6.30833 0.989592L0.89896 6.39896ZM19.5 6.15L1.5 6.15V7.85L19.5 7.85V6.15Z"
                                fill="white"/>
                        </svg>
                        {t('Back')}
                    </button>
                </Link>
            </div>
        </div>
    );
}
