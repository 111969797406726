import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import './StatusComponent.scss';
import {fetchDriversStatuses, setDriverStatusAction,} from '../../../../store/actions/MainActions';

export default function StatusComponent() {
    const dispatch = useDispatch();
    const [statuses, setStatuses] = useState([]);
    const driverData = useSelector(state => state?.main?.statusesList);

    useEffect(() => {
        dispatch(fetchDriversStatuses());
    }, [dispatch]);

    useEffect(() => {
        setStatuses(driverData?.statuses || []);
    }, [driverData.statuses]);


    const handleStatusClick = async (statusId) => {
        let newStatusId = null;
        if (statusId !== driverData?.selectedStatusId) {
            newStatusId = statusId;
        }
        const statusData = {
            statusId: newStatusId,
        };

        await dispatch(setDriverStatusAction(statusData));
        dispatch(fetchDriversStatuses());
    };

    return (
        <>
            <div className="status__driver__main">
                <div className='status__driver__container'>
                    {statuses?.map(status => (
                        <div
                        key={status.id}
                        className={`status__driver ${status.id === driverData?.selectedStatusId ? 'active' : ''}`}
                        onClick={() => handleStatusClick(status.id)}
                        style={status.id === driverData?.selectedStatusId ? {backgroundColor: driverData?.selectedStatusColor} : {}}
                    >
                        {status.value}
                    </div>
                    
                    ))}
                </div>
            </div>
        </>
    );
}
