import './Login.scss';
import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import logoLogin from '../../assets/img/logo/kpo-login.png';
import Form from '../../Components/Login/Form/Form';
import {useTranslation} from 'react-i18next';
import {Navigate} from 'react-router-dom';

export default function Login() {
    const {t} = useTranslation();

    useEffect(() => {
        document.title = 'KPO - Logistics';
    }, []);

    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
    const userRole = useSelector((state) => state.auth.userData?.roleName);


    if (isLoggedIn) {
        switch (userRole) {
            case 'ADMIN':
                return <Navigate to="/admin/main"/>;
            case 'DISPATCHER':
                return <Navigate to="/dispatcher/main"/>;
            case 'DRIVER':
                return <Navigate to="/driver/main"/>;
            default:
                return <Navigate to="/dashboard"/>;

        }
    }

    function handleRedirect() {
        const url = 'https://kpologistics.com/';
        window.open(url, '_blank');
    }

    return (
        <div className="login__container">
            <div className="left__login__container"></div>
            <div className="right__login__container">
                <div className="right__login__inner">
                    <div className="logo__login__container">
                        <img src={logoLogin} alt="logo"/>
                    </div>
                    <div className="form__container">
                        <Form/>
                        <div className="back__site__container" onClick={handleRedirect}>
                            {t('Back to site')}
                        </div>
                    </div>
                    <div>

                    </div>
                </div>
            </div>
        </div>);
}
