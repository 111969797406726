import React, {useState, useEffect} from 'react';
import './DropdownOptionsTrailerComponent.scss';
import {MdDone} from "react-icons/md";
import {useDispatch} from 'react-redux';
import {updateDropdown} from "../../../../store/actions/adminDropdownsActions";
import LoaderComponent from "../../../LoaderComponent/LoaderComponent";
import { deleteValue } from '../../../../store/actions/adminDropdownsActions';
import {IoClose} from "react-icons/io5";

export default function DropdownOptionsTrailerComponent({dropdownData}) {
    const dispatch = useDispatch();
    const [editedValueForDropdowns, setEditedValueForDropdowns] = useState(null);
    const [editedValueForFilesDropdowns, setEditedValueForFilesDropdowns] = useState(null);
    const [loading, setLoading] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [deleteType, setDeleteType] = useState(null);
    const [deleteValues, setDeleteValues] = useState(null);
    const [showDeleteMessage, setShowDeleteMessage] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    useEffect(() => {
        if (showDeleteMessage) {
            const timer = setTimeout(() => {
                setShowDeleteMessage(false);
            }, 2600);
    
            return () => clearTimeout(timer);
        }
    }, [showDeleteMessage]);
    
    if (!dropdownData || !Array.isArray(dropdownData)) {
        return null;
    }

    const trailersDropdowns = dropdownData.find(item => item.type === 'trailersDropdowns');
    const trailersFilesDropdowns = dropdownData.find(item => item.type === 'trailersFilesDropdowns');
console.log(trailersFilesDropdowns);
    const handleEditValue = (id, value, type, dropdownName) => {
        if (type === 'trailersDropdowns') {
            setEditedValueForDropdowns({id, value, type, dropdownName});
        } else if (type === 'trailersFilesDropdowns') {
            setEditedValueForFilesDropdowns({id, value, type, dropdownName});
        }
    };

    const handleUpdateValue = async (editedValue) => {
        if (editedValue) {
            setLoading(true); // Почати процес завантаження
            try {
                await dispatch(updateDropdown({
                    id: editedValue.id,
                    type: editedValue.type,
                    dropdownName: editedValue.dropdownName,
                    value: editedValue.value,
                }));
                setEditedValueForDropdowns(null);
                setEditedValueForFilesDropdowns(null);
            } catch (error) {
                console.error('Помилка при оновленні значення:', error);
            } finally {
                setLoading(false); // Завершити процес завантаження
            }
        }
    };

    const handleInputChange = (event, editedValue, setEditedValue) => {
        setEditedValue({...editedValue, value: event.target.value});
    };

    const handleDoneClick = (editedValue, event) => {
        event.stopPropagation();
        handleUpdateValue(editedValue);
    };

 const handleDeleteConfirmation = (type, id, value) => {
        setDeleteId(id);
        setDeleteType(type);
        setDeleteValues(value);
        setConfirmDelete(true);
    };

    const handleDeleteValue = async (type, id) => {
        setLoading(true);
        try {
            await dispatch(deleteValue(type, id));
            setShowDeleteMessage(true);
            
            
        } catch (error) {
            if (error?.response?.status === 500) {
console.log("afht")
setShowErrorMessage(true);
            } 
        } finally {
            setLoading(false);
            setConfirmDelete(false); 
        }
    };

    return (
        <>
            {loading && <LoaderComponent/>}
            <div className="typeTitleTrailer">Select items</div>
            <div className="dropdownOptionsTrailer">
                {trailersDropdowns?.value.map((dropdown, index) => (
                    <div key={index} className="DropItemsTrailer">
                        <div className="DropItemsTrailer__type">
                            Type:
                            <span> {dropdown.dropdownName}</span>
                        </div>
                        <div className='DropItemsTrailer__list__container'>
                            <p>Values</p>
                            <div className="DropItemsTrailer__items">
                                <ul>
                                    {dropdown.dropdownValue.map((item, index) => (
                                        <div className='DropItemsTrailer__list__c'>
                                        <li
                                            key={item.id}
                                            className={`DropItemsTrailer__elem ${editedValueForDropdowns && editedValueForDropdowns.id === item.id ? 'editing' : ''}`}
                                            style={{display: 'flex'}}
                                            onClick={() => handleEditValue(item.id, item.value, trailersDropdowns.type, dropdown.dropdownName)}
                                            
                                        >
                                            
                                            {editedValueForDropdowns && editedValueForDropdowns.id === item.id ? (
                                                <input
                                                    type="text"
                                                    value={editedValueForDropdowns.value}
                                                    onChange={(event) => handleInputChange(event, editedValueForDropdowns, setEditedValueForDropdowns)}
                                                    onClick={(e) => e.stopPropagation()}
                                                />
                                            ) : (
                                                item.value
                                            )}
                                            {editedValueForDropdowns && editedValueForDropdowns.id === item.id && (
                                                <div className="ddddggg"
                                                     onClick={(event) => handleDoneClick(editedValueForDropdowns, event)}>
                                                    <MdDone/></div>
                                            )}
                                            
                                        </li>
                                        {dropdown.dropdownValue.length > 1 && (
                                        <div className="delete__cross">
                                            <IoClose onClick={() => handleDeleteConfirmation(trailersDropdowns.type, item?.id, item.value)} />
                                            </div>
                                        )}
                                        </div>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="typeTitleTrailer">Documents category</div>
            <div className="dropdownOptionsTrailer">
                <div className="DropItemsTrailer">
                    <div className="DropItemsTrailer__type">Documents</div>
                    <ul className="DropItemsTrailer__items">
                        {trailersFilesDropdowns?.value.map((dropdown, index) => (
                            <div className='DropItemsTrailer__list__c'>
                            <li
                                style={{display: 'flex'}}
                                key={index}
                                className={`DropItemsTrailer__elem ${editedValueForFilesDropdowns && editedValueForFilesDropdowns.id === dropdown.id ? 'editing' : ''}`}
                                onClick={() => handleEditValue(dropdown.id, dropdown.value, trailersFilesDropdowns.type, dropdown.dropdownName)}
                            >
                                {editedValueForFilesDropdowns && editedValueForFilesDropdowns.id === dropdown.id ? (
                                    <input
                                        type="text"
                                        value={editedValueForFilesDropdowns.value}
                                        onChange={(event) => handleInputChange(event, editedValueForFilesDropdowns, setEditedValueForFilesDropdowns)}
                                        onClick={(e) => e.stopPropagation()}
                                    />
                                ) : (
                                    
                                    dropdown.value
                                )}
                                {editedValueForFilesDropdowns && editedValueForFilesDropdowns.id === dropdown.id && (
                                    <div className="ddddggg" style={{cursor: 'pointer'}}
                                         onClick={(event) => handleDoneClick(editedValueForFilesDropdowns, event)}>
                                        <MdDone/></div>
                                )}
                                
                            </li>
                            {trailersFilesDropdowns.value.length > 1 && (
                            <div className="delete__cross">
                            <IoClose onClick={() => handleDeleteConfirmation(trailersFilesDropdowns.type, dropdown?.id, dropdown.value)} />
                            
                                        </div>
                            )}
                                        </div>
                        ))}
                        
                    </ul>
                    {confirmDelete && (
                <div className="admin__modal">
                    <div className="modal-content">
                        <span className="close" onClick={() => setConfirmDelete(false)}><IoClose/></span>
                        <span className="modal__text">Confirm deletion of "{deleteValues}"?</span>
                        <div className="modal__confirm" onClick={() => handleDeleteValue(deleteType, deleteId)}>Delete</div>
                    </div>
                </div>
            )}
            {showDeleteMessage && (
    <div className="admin__modal">
        <div className="modal-content">
            <div className='modal__title'>
            <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/></svg>
    </div>
    <div className='modal__text'>
Deleted successfully!
        </div>
        </div>
    </div>
)}
{showErrorMessage && (
    <div className="admin__modal">
        <div className="modal-content">
        <span className="close" onClick={() => setShowErrorMessage(false)}><IoClose/></span>   
        <div className="modal__text"> 
        <span>Error</span>
        </div>
        <div className='modal__second__text'>
        <span>Unbind the value before deleting</span>
        </div>
        </div>
    </div>
)}

                </div>
            </div>
        </>
    );
}
