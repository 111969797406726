import React, {useEffect, useRef, useState} from 'react';
import { useSelector} from 'react-redux';
import Swiper from 'swiper';
import 'swiper/swiper-bundle.css';
import noImagess from '../../../../assets/img/noImaget.svg';
import './SliderPhotosMainComponent.scss';
import {LuDownload} from "react-icons/lu";
import ReactPlayer from 'react-player';
import { FaCirclePlay } from "react-icons/fa6";
import LoaderComponent from '../../../LoaderComponent/LoaderComponent';

const SliderPhotostMainComponent = ({photos}) => {
    const swiperRef = useRef(null);
    const modalSwiperRef = useRef(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalPhotoIndex, setModalPhotoIndex] = useState(0);
    const videoRefs = useRef([]);
    // Додайте це після useState для modalPhotoIndex
const [modalActiveIndex, setModalActiveIndex] = useState(modalPhotoIndex);
console.log(modalPhotoIndex)
const driverData = useSelector((state) => state?.main?.main_content.loadsList);
    console.log(driverData)
    const [loadingStates, setLoadingStates] = useState([]);
console.log(loadingStates)
useEffect(() => {
    if (loadingStates.length === 0 && photos) {
      setLoadingStates(Array.from({ length: photos.length }).fill(true));
    }
  }, [loadingStates, photos]);
  const handleImageLoad = (index) => {
    setLoadingStates((prevLoadingStates) =>
      prevLoadingStates.map((state, i) => (i === index ? false : state))
    );
  };
  const handleVideoLoad = (index) => {
    setLoadingStates((prevLoadingStates) =>
      prevLoadingStates.map((state, i) => (i === index ? false : state))
    );
  };
    const handleDownloadClick = () => {
        const selectedPhoto = photos[modalActiveIndex];
        console.log(selectedPhoto)
    console.log(selectedPhoto)
        if (selectedPhoto && selectedPhoto.link) {
            const protocolLink = `//${selectedPhoto.link}`;
    
            fetch(protocolLink, {
                credentials: 'include'
            })
                .then(response => response.blob())
                .then(blob => {
                    const blobUrl = URL.createObjectURL(blob);
    
                    const link = document.createElement('a');
                    link.href = blobUrl;
                    link.target = '_blank';
                    const extension = selectedPhoto.link.split('.').pop(); // Отримати розширення відео
                    link.download = `downloaded_video_${modalActiveIndex}.${extension}`; // Завантажити з правильним розширенням
                    document.body.appendChild(link);
    
                    link.click();
    
                    document.body.removeChild(link);
                    URL.revokeObjectURL(blobUrl);
                })
                .catch(error => {
                    console.error('Error fetching file content:', error);
                });
        } else {
            console.error('Selected photo or link not found');
        }
    };
    

    useEffect(() => {
        let swiperInstance;

        if (photos?.length > 0) {
            swiperInstance = new Swiper(swiperRef?.current, {
               
            spaceBetween: 1,
            loop: false,
            navigation: {
                nextEl: '.swiper-button-next main',
                prevEl: '.swiper-button-prev main',
            },
            breakpoints: {
                768: {
                    slidesPerView: 4,
                },
            },  
                on: {
                    slideChange: () => {
                        setActiveIndex(swiperInstance?.activeIndex);
                    },
                },
            });

            if (swiperRef.current && swiperRef.current.swiper) {
                swiperRef.current.swiper = swiperInstance;
            }
            
            return () => swiperInstance.destroy();
        }
    }, [photos]);

    const goToPrevSlide = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev();
        }
    };

    const goToNextSlide = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext();
        }
    };

    const handlePaginationClick = (index) => {
        if (swiperRef.current && swiperRef.current?.swiper) {
            swiperRef.current.swiper.slideTo(index);
        }
    };

    const openModal = (index) => {
        setModalOpen(true);
        setModalPhotoIndex(index);
        setModalActiveIndex(index);
        
        
    
        setTimeout(() => {
            modalSwiperRef.current = new Swiper('.modal-content__slider__photo .swiper-container', {
                initialSlide: index,
                loop: false,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                on: {
                    slideChange: () => {
                        const activeIndex = modalSwiperRef.current?.activeIndex;
                        setModalActiveIndex(activeIndex);
                    },
                },
            });
        }, 0);
    };
    

    const closeModal = () => {
        setModalOpen(false);
        if (modalSwiperRef.current) {
            modalSwiperRef.current.destroy();
        }
    };
    const handleThumbnailClick = (index) => {
        setModalActiveIndex(index);
        openModal(index);
    };
    return (
        <>
           <div className="custom-slider slider-main">
        {photos?.length > 0 ? (
          <div className="swiper-container hh_slider" ref={swiperRef}>
            <div className="swiper-wrapper">
              {photos?.map((photo, index) => (
                <div key={index} className="swiper-slide fl__slider main">
                  <div className="image-wrapper" >
                    {loadingStates[index]  &&
                    <div className='loader__backdrop'>
                     <div className="loader photo"></div>
                     </div>
                     }
                    {photo.link && /\.(mp4|ogg|webm|MOV|mov)$/i.test(photo.link) ? (
                      <div className="swiper-video-wrapper">
                       <video
  ref={(video) => (videoRefs.current[index] = video)}
  className="swiper-video"
  src={`//${photo.link}`}
  alt={`Loading ${photo.id}`}
  onClick={() => openModal(index)}
  onLoadedData={() => handleVideoLoad(index)} // Викликати handleVideoLoad при завантаженні даних
/>
                        <div className="swipeer-video-wrapper-icon">
                          <FaCirclePlay onClick={() => openModal(index)} />
                        </div>
                      </div>
                    ) : (
                        <img
                        src={photo.link ? `//${photo.link}` : noImagess}
                        alt={`Loading ${photo.id}`}
                        onClick={() => openModal(index)}
                        onLoad={() => handleImageLoad(index)}
                      />
                    )}
                  </div>
                </div>
              ))}
                            
                        </div>

                        <div className="swiper-button-prev main" onClick={goToPrevSlide}></div>
                        <div className="swiper-button-next main" onClick={goToNextSlide}></div>
                        
                    </div>
                ) : (
                    <div className="no-image">
                        <img src={noImagess} alt="Landscape view"/>
                    </div>
                )}
            </div>

            {modalOpen && (
                <div className="modal__slider__photo" onClick={closeModal}>
                    <div className="modal-content__slider__photo" onClick={(e) => e.stopPropagation()}>
            {/* <span className="close__slider__photo" onClick={closeModal}>
                &times;
            </span> */} 
                        <div className="swiper-container">
                            <div className="swiper-wrapper">
                                {photos?.map((photo, index) => (
                                    <div key={index}
                                         className={`swiper-main swiper-slide${index === modalPhotoIndex ? ' active' : ''}`}>
                                            <div className='swiper__button_container'>
                                            <span className="close__slider__photo" onClick={closeModal}>
                &times;
            </span>   
            {photo.link && /\.(mp4|ogg|webm|MOV|mov)$/i.test(photo.link) ? (
            <ReactPlayer
            url={`//${photo.link}`}
            controls
            
            onClick={() => openModal(index)}
        />
        ) : (
            <img
                src={photo.link ? `//${photo.link}` : noImagess}
                alt={` ${photo.id}`}
                onClick={() => openModal(index)}
            />
        )}
                                        
                                        
                                        <LuDownload onClick={handleDownloadClick}/>
                        
                        </div>
                                    </div>
                                ))}
                                
                            </div>
                            <div className="swiper-pagination"></div>
                            <div className="swiper-button-prev"
                                 onClick={() => modalSwiperRef.current.slidePrev()}></div>
                            <div className="swiper-button-next"
                                 onClick={() => modalSwiperRef.current.slideNext()}></div>
                                 
                        </div>
                        
                        <div className="thumbnail-container">
    {photos.map((photo, index) => (
        <div
            key={index}
            className={`thumbnail${index === modalActiveIndex ? ' active' : ''}`}
            onClick={() => modalSwiperRef.current.slideTo(index)}
        >
            {photo.link && /\.(mp4|ogg|webm|MOV|mov)$/i.test(photo.link) ? (
               <div className='swiper-video-thumbnail-wrapper'>
               <video className='swiper-video-thumbnail'
   
                   
                   src={`//${photo.link}`}
                   alt={`Truck ${photo.id}`}
                   onClick={() => openModal(index)}
               />
               <div className='swiper-video-thumbnail-wrapper-icon'>
               <FaCirclePlay onClick={() => openModal(index)}/>
                   </div>
               </div>
            ) : (
                <img
                    className="modal__slide__show__img"
                    src={photo.link ? `//${photo.link}` : noImagess}
                    alt={`Truck ${photo.id}`}
                    onClick={() => openModal(index)}
                />
            )}
        </div>
    ))}
</div>

                        
                    </div>
                </div>
            )}

        </>
    );
};

export default SliderPhotostMainComponent;