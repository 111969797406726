// actions.js
import axios from 'axios';
import {logout} from "./authActions";

// Типи дій
export const ASK_PHOTO_REPORT_REQUEST = 'ASK_PHOTO_REPORT_REQUEST';
export const ASK_PHOTO_REPORT_SUCCESS = 'ASK_PHOTO_REPORT_SUCCESS';
export const ASK_PHOTO_REPORT_FAILURE = 'ASK_PHOTO_REPORT_FAILURE';

// Дії
export const askPhotoReportRequest = () => ({
    type: ASK_PHOTO_REPORT_REQUEST,
});

export const askPhotoReportSuccess = () => ({
    type: ASK_PHOTO_REPORT_SUCCESS,
});

export const askPhotoReportFailure = (error) => ({
    type: ASK_PHOTO_REPORT_FAILURE,
    payload: error,
});

// Асинхронна дія для виклику запиту
export const askPhotoReport = (pointId) => {
    return (dispatch) => {
        dispatch(askPhotoReportRequest());
        axios
            .post(
                `${process.env.REACT_APP_BASE_URL}/main/dispatcher/point/${pointId}/askPhotoReport`,
                {},
                {
                    withCredentials: true,
                }
            )
            .then((response) => {
                dispatch(askPhotoReportSuccess());
                console.log(response.data);
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    dispatch(logout())
                } else {
                    dispatch(askPhotoReportFailure(error.message));
                    console.error(error);
                }
            });
    };
};

//CLose notification for driver

export const CLOSE_NOTIFICATION_REQUEST = 'CLOSE_NOTIFICATION_REQUEST';
export const CLOSE_NOTIFICATION_SUCCESS = 'CLOSE_NOTIFICATION_SUCCESS';
export const CLOSE_NOTIFICATION_FAILURE = 'CLOSE_NOTIFICATION_FAILURE';

// Дії
export const closeNotificationRequest = () => ({
    type: CLOSE_NOTIFICATION_REQUEST,
});

export const closeNotificationSuccess = () => ({
    type: CLOSE_NOTIFICATION_SUCCESS,
});

export const closeNotificationFailure = (error) => ({
    type: CLOSE_NOTIFICATION_FAILURE,
    payload: error,
});

export const closeNotification = (pointId) => {
    return (dispatch) => {
        dispatch(closeNotificationRequest());
        axios
            .post(
                `${process.env.REACT_APP_BASE_URL}/main/driver/point/${pointId}/closeNotification`,
                {},
                {
                    withCredentials: true,
                }
            )
            .then((response) => {
                dispatch(closeNotificationSuccess());
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    dispatch(logout())
                } else {
                    dispatch(closeNotificationFailure(error.message));
                    console.error(error);
                }
            });
    };
};