import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import './RouteDetails.scss';
import {GoPencil} from 'react-icons/go';
import {getMainContent, updateNotesAction} from '../../../../store/actions/MainActions';
import AddLoadModalComponent from './AddLoadModalComponent/AddLoadModalComponent';
import LoaderComponent from '../../../LoaderComponent/LoaderComponent';
import {FaPlus} from 'react-icons/fa6';
import { IoIosRefresh } from "react-icons/io";
import {FaCheck} from "react-icons/fa";
import LoadDetails from '../LoadDetailsComponent/LoadDetailsComponent';
import {MdKeyboardArrowDown, MdKeyboardArrowUp} from "react-icons/md";

export default function RouteDetails({driverId}) {
    const [driverInformation] = useState(false);
    const dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const driverData = useSelector((state) => state?.main?.main_content);
    const [selectedOption, setSelectedOption] = useState('All categories');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isNoteEditMode, setIsNoteEditMode] = useState(false); 
    const [originalNote, setOriginalNote] = useState('');
    const [noteText, setNoteText] = useState('');
    const [isNoteLoading, setIsNoteLoading] = useState(false);
    const [options, setOptions] = useState(['All categories', 'In transit', 'Completed']);

    const toggleCustomSelect = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };
    const limit = 3;

    const handleClick = () => {
        dispatch(getMainContent(driverId, driverInformation,limit));
    };
    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setIsDropdownOpen(false);
    };
    

    useEffect(() => {
        if (driverData?.routeDetails?.noteForDriver) {
            setOriginalNote(driverData.routeDetails.noteForDriver);
            setNoteText(driverData.routeDetails.noteForDriver);
        }
    }, [driverData?.routeDetails?.noteForDriver]);
    useEffect(() => {
        if (driverId !== null) {
            dispatch(getMainContent(driverId, driverInformation))
                .then(() => setIsLoading(false))
                .catch((error) => {
                    console.error('Error loading data:', error);
                    setIsLoading(false);
                });

        }
    }, [dispatch, driverId, driverInformation]);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleNoteEdit = async () => {
        setIsNoteEditMode(!isNoteEditMode);

        if (isNoteEditMode) {

            try {
                setIsNoteLoading(true);
                await dispatch(
                    updateNotesAction({
                        driverId: driverId,
                        value: noteText,
                    })
                );
                const limit = 3;
                dispatch(getMainContent(driverId, driverInformation, limit));

                setIsNoteLoading(false);
            } catch (error) {
                console.error('Error updating note:', error);
                setNoteText(originalNote);
                setIsNoteLoading(false);
            }
        }
    };


    const handleNoteChange = (e) => {
        setNoteText(e.target.value);
    };

    const handleKeyPress = async (e) => {
        if (isNoteEditMode && e.key === 'Enter') {
            e.preventDefault();
            await handleNoteEdit();
        }
    };

    return (
        <div className="loader__main">
            {isLoading ? (
                <LoaderComponent className="loader__component__main"/>
            ) : (

                <div>
                    {isNoteLoading ? (
                        <LoaderComponent className="loader__component__main"/>
                    ) : null}
                    <div className="note__for__driver__container">
                        <div className="note__container">
                            <div className="note__name__textarea">
                                <div className="note__name" onClick={handleNoteEdit}>
                                    Note for driver
                                    {isNoteEditMode ? <FaCheck/> : <GoPencil/>}
                                </div>
                                <div className="note__texarea">
                  <textarea
                      value={isNoteEditMode ? noteText : driverData?.routeDetails?.noteForDriver}
                      onChange={handleNoteChange}
                      readOnly={!isNoteEditMode}
                      onKeyPress={handleKeyPress}
                  />
                                </div>
                            </div>
                            <div className="note__driver__info">
                                <div className="note__driver__name">{driverData?.routeDetails?.driverName}</div>
                                <div className="driver__summary">
                                    <div>Summary</div>
                                    <div>total amount: <span>{driverData?.routeDetails?.totalAmount}$</span></div>
                                    <div>total miles: <span
                                        className='driver__miles'>{driverData?.routeDetails?.totalMiles}</span></div>
                                    <div>rate per mile: <span>{driverData?.routeDetails?.ratePerMile}$</span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='load__container'>
                        <div className='load__tools__container'>
                            <div className='load__title__buttons'>
                                <span className='load__title'>Load details</span>
                                <button onClick={openModal}>Add load<FaPlus/></button>
                                <button id="refreshButton" onClick={handleClick}>Refresh<IoIosRefresh /></button>
                                {/* <button>Multiple load<FaPlus /></button> */}
                            </div>
                            <div className='load__select'>
                                <div className='loads__search__component'>
                                    <div className={`load-select custom-select ${isDropdownOpen ? 'active' : ''}`}
                                         onClick={toggleCustomSelect}>
                                        <div className="load-selected-option selected-options">
                                            <div className="selected-option">{selectedOption}</div>
                                            {isDropdownOpen ? <MdKeyboardArrowUp/> : <MdKeyboardArrowDown/>}
                                        </div>
                                        <div className="select-icon"></div>
                                        <div className="load-options-list options-list">
                                        <div className="option-item" onClick={() => handleOptionClick('All categories')}>
    <label>All</label>
    {selectedOption === 'All categories' && <div className="checkbox"><FaCheck/></div>}
</div>

                                            <div className="option-item"
                                                 onClick={() => handleOptionClick('In transit')}>
                                                <label>In transit</label>
                                                {selectedOption === 'In transit' &&
                                                    <div className="checkbox"><FaCheck/></div>}
                                            </div>
                                            <div className="option-item" onClick={() => handleOptionClick('Completed')}>
                                                <label>Completed</label>
                                                {selectedOption === 'Completed' &&
                                                    <div className="checkbox"><FaCheck/></div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <LoadDetails driverId={driverId} selectedOption={selectedOption}/>

                    </div>
                    {isModalOpen && <AddLoadModalComponent closeModal={closeModal} driverId={driverId}/>}
                </div>
            )}
        </div>
    );
}
