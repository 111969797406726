import React, {useRef, useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import noImages from '../../../../assets/img/noImaget.svg';
import {BsThreeDotsVertical} from "react-icons/bs";
import {GoAlert} from "react-icons/go";
import {useDispatch, useSelector} from 'react-redux';
import {fetchTrackInfo, deleteTrack} from "../../../../store/actions/trackActions";
import './TruckComponent.scss';
import NewTruck from './NewTruckComponentModal/NewTruckComponentModal';
import {MdKeyboardArrowLeft} from "react-icons/md";
import {MdKeyboardArrowRight} from "react-icons/md";
import LoaderComponent from "../../../LoaderComponent/LoaderComponent";
import {IoCloseOutline} from 'react-icons/io5';

export default function TruckComponent({searchTerm}) {

    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const dispatch = useDispatch();
    const trackInfo = useSelector((state) => state.track.trackInfo);
    const trac = useSelector((state) => state.track);
    console.log(trac);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [selectedTrackId, setSelectedTrackId] = useState(null);
    const [, setEditModalContentVisibility] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    //eslint-disable-next-line
    const [showModal, setShowModal] = useState(false);
    const filteredTrucks = trackInfo?.filter((truck) =>
        truck.title.model?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        truck.title.comment?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        truck.title.year?.toString().includes(searchTerm) ||
        truck.main.vehicleId?.toString().includes(searchTerm) ||
        truck.main.vinNumber?.toString().includes(searchTerm) ||
        truck.main.insurance?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        truck.main.transmission?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        truck.main.state?.toString().includes(searchTerm) ||
        truck.main.dpfSystem?.toString().includes(searchTerm) ||
        truck.main.driver?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        truck.main.licensePlate?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        truck.main.unitNumber?.toString().includes(searchTerm) ||
        truck.main.sleeper?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        truck.main.annualInspection?.toString().includes(searchTerm) ||
        truck.main.number?.toString().includes(searchTerm) ||
        truck.main.mileage?.toString().includes(searchTerm) ||
        truck.main.trailer?.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const navigate = useNavigate();
    const handleTrackClick = (trackId) => {
        navigate(`/dispatcher/vehicle/track/${trackId}`);
    };
    useEffect(() => {
        dispatch(fetchTrackInfo())
            .then(() => setIsLoading(false))
            .catch((error) => {
                setIsLoading(false);
            });
    }, [dispatch]);

    useEffect(() => {
    }, [trackInfo]);


    const toggleMenu = (trackId) => {
        setIsMenuOpen(!isMenuOpen);
        setSelectedTrackId(trackId);
    };
    const handleEditClick = (trackId) => {
        setIsEditModalOpen(true);
        setSelectedTrackId(trackId);
        setEditModalContentVisibility(true);
    };
    const handleDeleteClick = (trackId) => {
        setIsLoading(true);
        dispatch(deleteTrack(trackId))
        .then(() => {
            setIsLoading(false);
        }).catch((error) => {
            // Обробляйте помилки, якщо потрібно
            console.error('Помилка видалення треку:', error);
            setIsLoading(false); // Встановлюємо isLoading на false в разі помилки також
        });
    };
    
    const handleMenuClick = (event, trackId) => {
        event.stopPropagation();
        toggleMenu(trackId);
        setModalVisibility(!isModalVisible);
    };
    const handlePageSizeChange = (size) => {
        setPageSize(size);
        setCurrentPage(1);
    };
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };
    const indexOfLastItem = currentPage * pageSize;
    const indexOfFirstItem = indexOfLastItem - pageSize;
    const currentItems = filteredTrucks.slice(indexOfFirstItem, indexOfLastItem);

    const paginationOptions = Array.from(
        {length: Math.min(10, Math.ceil(filteredTrucks.length / 10))},
        (_, index) => (index + 1) * 10
    );


    const [isEditModalOpen, setIsEditModalOpen] = useState(false);


    useEffect(() => {
        const handleDocumentClick = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setModalVisibility(false);
                setEditModalContentVisibility(false); // Сховати modal-content__edit__track при закритті модального вікна
            }
        };

        document.addEventListener('click', handleDocumentClick);

        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, []);

    const [isModalVisible, setModalVisibility] = useState(false);
    const [, setClickedInsideModal] = useState(false);

    const modalRef = useRef(null);

    useEffect(() => {
        const handleDocumentClick = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setModalVisibility(false);
                setEditModalContentVisibility(false);
                setClickedInsideModal(false);
            } else {
                setClickedInsideModal(true);
            }
        };

        document.addEventListener('click', handleDocumentClick);

        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, []);
    const handleUpdateList = async () => {

        await dispatch(fetchTrackInfo());

    };
    const handleCloseModalClick = () => {
        setIsEditModalOpen(false);
    };
    const handleNewTruck = () => {

        setShowModal(true);
    };
    return (
        <div className="trucks__table">
            {isLoading ? (
                <LoaderComponent/>
            ) : (
                <div className="trucks__table__body">
                    {currentItems.map((track) => (
                        <div key={track.id} className="trucks__table__row" onClick={() => handleTrackClick(track.id)}>
                            <div className='truck__img__description'>
                                <div className="trucks__table__body__item__img">
                                    <img src={track.photo.url ? `//${track.photo.url}` : noImages}
                                         alt={`Truck ${track.id}`}/>
                                </div>
                                <div className='name__detailed__description'>
                                    <div className='name__description'>
                                        <div className='truck__name__dots'>
                                            <div className='truck__name__year'>
                                                <div className="trucks__table__body__item name">
                                                {track.main.unitNumber}
                                                </div>
                                                {/* <div className="one__truck__table__body__item name year">
                                                    {track.title.year}
                                                </div> */}
                                            </div>
                                            <div className="trucks__table__body__item">
                                                {track.title?.comment}
                                            </div>
                                            {track.title?.filesMessages && Array.isArray(track.title.filesMessages) && track.title.filesMessages.map(fileMessage => (
                                                <div key={fileMessage?.message}>
                                                    <div className="alert__message" style={{color: fileMessage.color}}>
                                                        <GoAlert/>{fileMessage?.message}
                                                    </div>
                                                </div>
                                            ))}


                                        </div>
                                        <div className="vvvv" onClick={(event) => handleMenuClick(event, track.id)}>
                                            <BsThreeDotsVertical/>
                                            {selectedTrackId === track.id && (

                                                <div className="modal__edit__track" ref={modalRef}>
                                                    <div
                                                        className="modal-content__edit__track"
                                                        style={{
                                                            display: isModalVisible && !isEditModalOpen ? 'block' : 'none',
                                                        }}
                                                    >

                                                        <div className="d__menu__item__track"
                                                             onClick={() => handleEditClick(track.id)}>
                                                            Edit
                                                        </div>

                                                        <div className="d__menu__item__track"
                                                             onClick={() => handleDeleteClick(track.id)}>
                                                            Delete
                                                        </div>

                                                    </div>

                                                    {isEditModalOpen && (
                                                        <div className={`modal__add__truck`} ref={modalRef}>
                                                            <div className={`modal-content__add__truck`}>
                                                                <div className={`heading__modal__truck`}>
                                                                    <div className={`title__modal__truck`}>
                                                                        {track ? 'Edit truck' : 'Add truck'}
                                                                    </div>
                                                                    <div className={`close__modal__truck`}
                                                                         onClick={handleCloseModalClick}>
                                                                        <IoCloseOutline/>
                                                                    </div>
                                                                </div>
                                                                <NewTruck
                                                                    trackId={selectedTrackId}
                                                                    track={track}
                                                                    onClose={handleCloseModalClick}
                                                                    onCancel={handleCloseModalClick}
                                                                    onUpdateList={handleUpdateList}
                                                                />
                                                            </div>
                                                        </div>

                                                    )}
                                                </div>

                                            )}
                                        </div>
                                    </div>
                                    <div className='ID__license'>
                                        <div className="model__year">
                                        <div className="trucks__table__body__item upper">
                                                <span>Model</span>
                                                {track.title.model}
                                            </div>
                                            <div className="trucks__table__body__item lower">
                                                <span>Year</span>
                                                {track.title.year}
                                            </div>
                                        </div>
                                        <div className='detailed__description'>
                                            <div className="trucks__table__body__item upper">
                                                <span>License plate</span>
                                                {track.main.licensePlate}
                                            </div>
                                            <div className="trucks__table__body__item lower">
                                                <span>State</span>
                                                {track.main.state}
                                            </div>
                                        </div>
                                        <div className='VIN__Unit'>
                                            <div className="trucks__table__body__item upper">
                                                <span>VIN Code</span>
                                                {track.main.vinNumber}
                                            </div>
                                            {/* <div className="trucks__table__body__item lower">
                                                <span>Unit </span>
                                                {track.main.unitNumber}
                                            </div> */}<div className="trucks__table__body__item lower">
                                                <span>Sleeper</span>
                                                {track.main.sleeper}
                                            </div>
                                        </div>
                                        <div className='insurance__sleeper'>
                                            <div className="trucks__table__body__item upper lower">
                                                <span>Insurance</span>
                                                {track.main.insurance}
                                            </div>
                                            <div className="trucks__table__body__item lower">
                                                <span>Annual Inspection</span>
                                                {track.main.annualInspection}
                                            </div>
                                        </div>
                                        <div className='transmission__annual'>
                                            <div className="trucks__table__body__item upper lower">
                                                <span>Transmission</span>
                                                {track.main.transmission}
                                            </div>
                                            <div className="trucks__table__body__item lower">
                                                <span>Mileage</span>
                                                {track.main.mileage}
                                            </div>
                                        </div>
                                        <div className='state__number'>
                                            
                                        </div>
                                        <div className='DPF__mileage'>
                                            <div className="trucks__table__body__item upper lower">
                                                <span>DPF System</span>
                                                {track.main.dpfSystem}
                                            </div>
                                             <div className="trucks__table__body__item upper lower">
                                                <span>Driver</span>
                                                {track.main.driver || 'none'}
                                            </div>
                                        </div>
                                        <div className='driver__trailer'>
                                           
                                            <div className="trucks__table__body__item upper">
                                                <span>Trailer</span>
                                                {track.main.trailer || 'none'}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    ))}
                    {filteredTrucks.length > 0 ? (
                        <div className="pagination">
                            <select onChange={(e) => handlePageSizeChange(e.target.value)} className='per__page'>
                                {paginationOptions.map((option) => (
                                    <option key={option} value={option}>
                                        {`${option} per page`}
                                    </option>
                                ))}
                            </select>


                            <div className="pagination__pages">
                                <button
                                    onClick={() => handlePageChange(currentPage - 1)}
                                    disabled={currentPage === 1}
                                >
                                    <MdKeyboardArrowLeft/>
                                </button>
                                <span>{currentPage}</span>
                                of {Math.ceil(filteredTrucks.length / pageSize)}
                                <button
                                    onClick={() => handlePageChange(currentPage + 1)}
                                    disabled={currentPage === Math.ceil(filteredTrucks.length / pageSize)}
                                >
                                    <MdKeyboardArrowRight/>
                                </button>
                            </div>

                        </div>
                    ) : (
                        <div className="pagination"></div>
                    )}

                </div>
            )}
        </div>

    )
}