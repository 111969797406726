import React, {useState} from 'react';
import './AddCustomers.scss';
import {useDispatch} from 'react-redux';
import {addCustomer} from '../../../../store/actions/customersActions';

export default function AddCustomers({onCustomerAdded}) {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        clientName: '',
        clientEmail: '',
        phoneNumber: '',
        mcNumber: '',
        companyName: '',
    });

    const [modalOpen, setModalOpen] = useState(true);
    const [formErrors, setFormErrors] = useState({});

    const validateForm = () => {
        const errors = {};

        if (!formData.clientName.trim()) {
            errors.clientName = 'This field is empty';
        } else if (formData.clientName.length > 50) {
            errors.clientName = 'Maximum length is 50 characters';
        }


        if (!formData.clientEmail.trim()) {
            errors.clientEmail = 'This field is empty';
        } else if (!/^\S+@\S+\.\S+$/.test(formData.clientEmail.trim())) {
            errors.clientEmail = 'Invalid email address';
        }
        if (!formData.phoneNumber.trim()) {
            errors.phoneNumber = "This field is empty";
        } else if (formData.phoneNumber.length !== 14 && formData.phoneNumber.length !== 30) {
            errors.phoneNumber = "Invalid US phone number";
        } 
        


        if (!formData.mcNumber.trim()) {
            errors.mcNumber = 'This field is empty';
        } else if (formData.mcNumber.length > 20) {
            errors.mcNumber = 'Maximum length is 20 characters';
        }


        if (!formData.companyName.trim()) {
            errors.companyName = 'This field is empty';
        } else if (formData.companyName.length > 20) {
            errors.companyName = 'Maximum length is 20 characters';
        }


        setFormErrors(errors);

        return Object.keys(errors).length === 0;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!validateForm()) {
            return;
        }

        try {
            console.log(formData)
            await dispatch(addCustomer(formData));
            onCustomerAdded();
            setFormData({
                clientName: '',
                clientEmail: '',
                phoneNumber: '',
                mcNumber: '',
                companyName: '',
            });
            setTimeout(() => {
                setModalOpen(false);
            }, 2000);
        } catch (error) {
            console.error('Error adding data:', error);
        }
    };

    const handleChange = (event) => {
        const {name, value} = event.target;

        // Apply phone number mask only if it's the phoneNumber field
        let formattedValue = value;
        if (name === "phoneNumber") {
            formattedValue = formatPhoneNumber(value.replace(/\D/g, ""));
        }

        // Update form data with the formatted value
        setFormData({
            ...formData,
            [name]: formattedValue,
        });

        // Validate phone number only if it's the phoneNumber field
        if (name === "phoneNumber") {
            validatePhoneNumber(formattedValue);
        }

        setFormData({
            ...formData,
            [name]: formattedValue,
        });

        // Validate form
        validateForm();
    };

    const formatPhoneNumber = (value) => {
        let formattedValue = "";
        for (let i = 0; i < Math.min(20, value.length); i++) {
            switch (i) {
                case 0:
                    formattedValue += `(${value[i]}`;
                    break;
                case 3:
                    formattedValue += `) ${value[i]}`;
                    break;
                case 7:
                    formattedValue += ` ${value[i]}`;
                    break;
                case 10:
                    formattedValue += `+ (${value[i]}`;
                    break;
                case 13:
                    formattedValue += `) ${value[i]}`;
                    break;
                    case 17:
                        formattedValue += ` ${value[i]}`;
                        break;
                default:
                    formattedValue += value[i];
                    break;
            }
        }
        return formattedValue;
    };
    

    const validatePhoneNumber = (value) => {
        const errors = {...formErrors};

        if (!value) {
            errors.phoneNumber = "This field is empty";
        
            
        } else {
            delete errors.phoneNumber; // Clear error if valid
        }

        // Update form errors
        setFormErrors(errors);


    };


    return (
        <div className={`add_customers ${modalOpen ? 'visible' : ''}`}>
            <div className="form__modal__customer__add">
                <form onSubmit={handleSubmit}>
                    <div className={`form__group__add__customer`}>
                        <label>
                            Client name
                            <span className="error-message">{formErrors.clientName}</span>
                        </label>
                        <input className={`${formErrors.clientName ? 'error-input' : ''}`}
                               type="text"
                               name="clientName"
                               value={formData.clientName}
                               placeholder="Enter client name"
                               onChange={handleChange}
                        />
                    </div>

                    {/* Додайте клас error-input, якщо поле має помилку валідації */}
                    <div className={`form__group__add__customer`}>
                        <label>
                            Client Email
                            <span className="error-message">{formErrors.clientEmail}</span>
                        </label>
                        <input className={`${formErrors.clientEmail ? 'error-input' : ''}`}
                               type="text"
                               name="clientEmail"
                               value={formData.clientEmail}
                               placeholder="Enter client email"
                               onChange={handleChange}
                        />
                    </div>

                    {/* Додайте клас error-input, якщо поле має помилку валідації */}
                    <div className={`form__group__add__customer`}>
                        <label>
                            Phone number
                            <span className="error-message">{formErrors.phoneNumber}</span>
                        </label>
                        <input className={`${formErrors.phoneNumber ? 'error-input' : ''}`}
                               type="text"
                               name="phoneNumber"
                               value={formData.phoneNumber}
                               placeholder="Enter phone number"
                               onChange={handleChange}
                        />
                    </div>

                    {/* Додайте клас error-input, якщо поле має помилку валідації */}
                    <div className={`form__group__add__customer`}>
                        <label>
                            MC Number
                            <span className="error-message">{formErrors.mcNumber}</span>
                        </label>
                        <input className={`${formErrors.mcNumber ? 'error-input' : ''}`}
                               type="text"
                               name="mcNumber"
                               value={formData.mcNumber}
                               placeholder="Enter MC number"
                               onChange={handleChange}
                        />
                    </div>

                    {/* Додайте клас error-input, якщо поле має помилку валідації */}
                    <div className={`form__group__add__customer`}>
                        <label>
                            Company name
                            <span className="error-message">{formErrors.companyName}</span>
                        </label>
                        <input className={`${formErrors.companyName ? 'error-input' : ''}`}
                               type="text"
                               name="companyName"
                               placeholder="Enter company name"
                               value={formData.companyName}
                               onChange={handleChange}
                        />
                    </div>

                    <div className="form__group__add__customer__btn">
                        <button type="submit">Add client</button>
                    </div>
                </form>
            </div>
        </div>
    );
}