import React, {useEffect, useRef, useState} from 'react';
import Swiper from 'swiper';
import 'swiper/swiper-bundle.css';
import noImagess from '../../../../assets/img/noImaget.svg';
import './SliderPhotostComponent.scss';
import {LuDownload} from "react-icons/lu";
const SliderPhotostComponent = ({photos}) => {
    const swiperRef = useRef(null);
    const modalSwiperRef = useRef(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalPhotoIndex, setModalPhotoIndex] = useState(0);
    // Додайте це після useState для modalPhotoIndex
const [modalActiveIndex, setModalActiveIndex] = useState(modalPhotoIndex);


const handleDownloadClick = () => { 
    const selectedPhoto = photos[modalActiveIndex];

    if (selectedPhoto && selectedPhoto.link) {
        const protocolLink = `//${selectedPhoto.link}`;

        fetch(protocolLink, {
            credentials: 'include'
        })
            .then(response => response.blob())
            .then(blob => {
                const blobUrl = URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = blobUrl;
                link.target = '_blank';
                link.download = `downloaded_photo_${modalActiveIndex}.jpg`;
                document.body.appendChild(link);

                link.click();

                document.body.removeChild(link);
                URL.revokeObjectURL(blobUrl);
            })
            .catch(error => {
                console.error('Error fetching file content:', error);
            });
    } else {
        console.error('Selected photo or link not found');
    }
};

    useEffect(() => {
        let swiperInstance;

        if (photos.length > 0) {
            swiperInstance = new Swiper(swiperRef.current, {
                on: {
                    slideChange: () => {
                        setActiveIndex(swiperInstance.activeIndex);
                    },
                },
            });

            return () => swiperInstance.destroy();
        }
    }, [photos]);

    const goToPrevSlide = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev();
        }
    };

    const goToNextSlide = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext();
        }
    };

    const handlePaginationClick = (index) => {
        if (swiperRef.current && swiperRef.current?.swiper) {
            swiperRef.current.swiper.slideTo(index);
        }
    };

    const openModal = (index) => {
        setModalOpen(true);
        setModalPhotoIndex(index);
        setModalActiveIndex(index);
    
        setTimeout(() => {
            modalSwiperRef.current = new Swiper('.modal-content__slider__photo .swiper-container', {
                initialSlide: index,
                loop: false,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                on: {
                    slideChange: () => {
                        const activeIndex = modalSwiperRef.current?.activeIndex;
                        setModalActiveIndex(activeIndex);
                    },
                },
            });
        }, 0);
    };
    

    const closeModal = () => {
        setModalOpen(false);
        if (modalSwiperRef.current) {
            modalSwiperRef.current.destroy();
        }
    };

    return (
        <>
            <div className="custom-slider">
                {photos.length > 0 ? (
                    <div className="swiper-container hh_slider" ref={swiperRef}>
                        <div className="swiper-wrapper">
                            {photos.map((photo, index) => (
                                <div key={index} className="swiper-slide fl__slider">
                                    <img
                                        src={photo.link ? `//${photo.link}` : noImagess}
                                        alt={`Truck ${photo.id}`}
                                        onClick={() => openModal(index)}
                                    />
                                </div>
                            ))}
                        </div>

                        <div className="swiper-button-prev" onClick={goToPrevSlide}></div>
                        <div className="swiper-button-next" onClick={goToNextSlide}></div>
                        <div className="swiper-pagination">
                            {photos.map((_, index) => (
                                <span
                                    key={index}
                                    className={`swiper-pagination-bullet${
                                        index === activeIndex ? ' active' : ''
                                    }`}
                                    onClick={() => handlePaginationClick(index)}
                                ></span>
                            ))}
                        </div>
                    </div>
                ) : (
                    <div className="no-image">
                        <img src={noImagess} alt="Landscape view"/>
                    </div>
                )}
            </div>

            {modalOpen && (
                <div className="modal__slider__photo" onClick={closeModal}>
                    <div className="modal-content__slider__photo" onClick={(e) => e.stopPropagation()}>
            {/* <span className="close__slider__photo" onClick={closeModal}>
                &times;
            </span> */}
                        <div className="swiper-container">
                            <div className="swiper-wrapper">
                                {photos.map((photo, index) => (
                                    <div key={index}
                                         className={`swiper-slide${index === modalPhotoIndex ? ' active' : ''}`}>
                                            <div className='swiper__button_container'>
                                            <span className="close__slider__photo" onClick={closeModal}>
                &times;
            </span>   
                                        <img className="modal__slide__show__main"
                                             src={photo.link ? `//${photo.link}` : noImagess}
                                             alt={`Truck ${photo.id}`}
                                        />
                                        
                                        
                                        <LuDownload onClick={handleDownloadClick}/>
                        
                        </div>
                                    </div>
                                ))}
                                
                            </div>
                            <div className="swiper-pagination"></div>
                            <div className="swiper-button-prev"
                                 onClick={() => modalSwiperRef.current.slidePrev()}></div>
                            <div className="swiper-button-next"
                                 onClick={() => modalSwiperRef.current.slideNext()}></div>
                                 
                        </div>
                        
                        <div className="thumbnail-container">
                        {photos.map((photo, index) => (
    <div
        key={index}
        className={`thumbnail${index === modalActiveIndex ? ' active' : ''}`}
        onClick={() => modalSwiperRef.current.slideTo(index)}
    >
        <img className="modal__slide__show__img"
            src={photo.link ? `//${photo.link}` : noImagess}
            alt={`Truck ${photo.id}`}
        />
    </div>
))}
                        </div>
                        
                    </div>
                </div>
            )}

        </>
    );
};

export default SliderPhotostComponent;