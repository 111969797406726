import React, {useState, useEffect, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {updateUserPassword} from '../../../../../store/actions/settingsActions';
import './EditPasswordModal.scss';
import {IoCloseOutline} from 'react-icons/io5';
import showIcon from '../../../../../../src/assets/svg/show.svg';
import hideIcon from '../../../../../../src/assets/svg/hide.svg';
import EditPasswordSuccessModal from "./EditPasswordSuccessModal/EditPasswordSuccessModal";

const EditPasswordModal = ({onClose}) => {
    const dispatch = useDispatch();
    const [serverMessage, setServerMessage] = useState('');
    const updating = useSelector((state) => state.settings.updating);

    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');

    const [oldPasswordError, setOldPasswordError] = useState('');
    const [newPasswordError, setNewPasswordError] = useState('');
    const [confirmNewPasswordError, setConfirmNewPasswordError] = useState('');
    const [passwordMatchError, setPasswordMatchError] = useState('');
    const [successModalOpen, setSuccessModalOpen] = useState(false);



    const closeSuccessModal = () => {
        setSuccessModalOpen(false);
    };


    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);

    const validateOldPassword = useCallback(() => {
        setOldPasswordError(oldPassword === '' ? 'This field is required' : '');
    }, [oldPassword]);

    const validateNewPassword = useCallback(() => {
        setNewPasswordError(newPassword === '' ? 'This field is required' : '');
    }, [newPassword]);

    const validateConfirmNewPassword = useCallback(() => {
        setConfirmNewPasswordError(confirmNewPassword === '' ? 'This field is required' : '');
    }, [confirmNewPassword]);

    const checkPasswordMatch = useCallback(() => {
        if (newPassword !== confirmNewPassword) {
            setPasswordMatchError('New password and the confirmed password do not match');
        } else {
            setPasswordMatchError('');
        }
    }, [newPassword, confirmNewPassword]);

    const togglePassword = () => {
        setShowPassword(!showPassword);
    };

    const togglePassword2 = () => {
        setShowPassword2(!showPassword2);
    };

    const handleUpdatePassword = async () => {
        validateOldPassword();
        validateNewPassword();
        validateConfirmNewPassword();
        checkPasswordMatch();

        if (oldPasswordError || newPasswordError || confirmNewPasswordError || passwordMatchError) {
            return;
        }

        try {
            const responseData = await dispatch(updateUserPassword(oldPassword, newPassword));

            if (responseData && responseData.data && responseData.data.message) {
                setServerMessage(responseData.data.message);
                if (responseData.status === 200) {
                    onClose();
                }
            }


        } catch (error) {
            console.error('Помилка при оновленні паролю:', error);

            if (error.response && error.response.data && error.response.data.message) {
                setServerMessage(error.response.data.message);
            } else {
                console.log('Виникла помилка при оновленні паролю');
                setServerMessage('Виникла помилка при оновленні паролю');
            }
        }


    };

    useEffect(() => {
        validateOldPassword();
        validateNewPassword();
        validateConfirmNewPassword();
        checkPasswordMatch();
    }, [oldPassword, newPassword, confirmNewPassword, validateOldPassword, validateNewPassword, validateConfirmNewPassword, checkPasswordMatch]);

    return (
        <div className="modal__overlay__edit__password">
            <div className="modal__content__edit__password">
                <div className="modal__head__pass__info">
                    <div className="title">Change password</div>
                    <div className="close" onClick={onClose}>
                        <IoCloseOutline/>
                    </div>
                </div>
                <div className="form__group__profile form_group__container password__type">
                    <label htmlFor="oldPassword">
                        Old Password
                        <span className="error-message">{oldPasswordError}</span>
                    </label>
                    <input
                        type="password"
                        id="oldPassword"
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                        onBlur={validateOldPassword}
                    />
                </div>
                <div className="form__group__profile form_group__container password__type">
                    <label htmlFor="newPassword">
                        New password
                        <span className="error-message">{newPasswordError}</span>
                    </label>
                    <div className="password-wrapper">
                        <input
                            type={showPassword ? 'text' : 'password'}
                            id="newPassword"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            onBlur={validateNewPassword}
                        />
                        <span className="toggle-icon" onClick={togglePassword}>
                            {showPassword ? (
                                <img src={showIcon} alt="hide-password"/>
                            ) : (
                                <img src={hideIcon} alt="show-password"/>
                            )}
                        </span>
                    </div>

                </div>
                <div className="form__group__profile form_group__container password__type">
                    <label htmlFor="confirmNewPassword">
                        Confirm new password
                        <span className="error-message">{confirmNewPasswordError}</span>

                    </label>
                    <div className="password-wrapper">
                        <input
                            type={showPassword2 ? 'text' : 'password'}
                            id="confirmNewPassword"
                            value={confirmNewPassword}
                            onChange={(e) => setConfirmNewPassword(e.target.value)}
                            onBlur={validateConfirmNewPassword}
                        />
                        <span className="toggle-icon" onClick={togglePassword2}>
                            {showPassword2 ? (
                                <img src={showIcon} alt="hide-password"/>
                            ) : (
                                <img src={hideIcon} alt="show-password"/>
                            )}
                        </span>
                    </div>

                    <div className="message__password">
                        {serverMessage && <p>{serverMessage}</p>}
                        {passwordMatchError && <p className="error">{passwordMatchError}</p>}
                    </div>

                </div>


                {successModalOpen && (
                    <EditPasswordSuccessModal onClose={closeSuccessModal} />
                )}

                <div className="btn__group__edit__info">
                    <button
                        className="btn__save__edit__info"
                        disabled={
                            updating ||
                            oldPassword === '' ||
                            newPassword === '' ||
                            confirmNewPassword === '' ||
                            oldPasswordError !== '' ||
                            newPasswordError !== '' ||
                            confirmNewPasswordError !== '' ||
                            passwordMatchError !== ''
                        }
                        onClick={handleUpdatePassword}
                    >
                        {updating ? 'Loading...' : 'Save changes'}
                    </button>
                    <button className="btn__close__edit__info" onClick={onClose}>
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
}

export default EditPasswordModal;