import axios from 'axios';
import {logout} from "./authActions";


// actionTypes.js
export const BLOCK_USER_REQUEST = 'BLOCK_USER_REQUEST';
export const BLOCK_USER_SUCCESS = 'BLOCK_USER_SUCCESS';
export const BLOCK_USER_FAILURE = 'BLOCK_USER_FAILURE';
// actions.js


export const blockUser = (email, isBlocked) => {
    return (dispatch) => {
        dispatch({ type: BLOCK_USER_REQUEST });
        axios.post(`${process.env.REACT_APP_BASE_URL}/admin/blockUser`,
            {
                email: email,
                blocked: isBlocked
            },
            {
                withCredentials: true
            }
        )
            .then((response) => {
                dispatch({ type: BLOCK_USER_SUCCESS, payload: response.data });
            })
            .catch((error) => {
                console.log("Error occurred:", error);
                if (error.response && error.response.status === 401) {
                   dispatch(logout());
                    console.log("Error 401: Unauthorized access", error.response);
                } else {
                    dispatch({ type: BLOCK_USER_FAILURE, payload: error.message });
                }
            });
    };
};


export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';
export const changePassword = (email, newPassword) => {
    return (dispatch) => {
        dispatch({ type: CHANGE_PASSWORD_REQUEST });
        axios.post(`${process.env.REACT_APP_BASE_URL}/admin/changePassword`,
            {
                email: email,
                password: newPassword
            },
            {
                withCredentials: true
            }
        )
            .then((response) => {
                dispatch({ type: CHANGE_PASSWORD_SUCCESS, payload: response.data });
            })
            .catch((error) => {
                console.log("Error occurred:", error);
                if (error.response && error.response.status === 401) {
                    dispatch(logout());
                    console.log("Error 401: Unauthorized access", error.response);
                } else {
                    dispatch({ type: CHANGE_PASSWORD_FAILURE, payload: error.message });
                }
            });
    };
};
