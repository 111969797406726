import './DriversProfilePage.scss';
import Navigation from "../../../Components/Navigation/Navigation";
import Adddrivers from "../../../Components/Dispathcer/DriversProfile/Adddriversmodal/Adddriversmodal";
import {LuPlus} from 'react-icons/lu';
import {BiSearch} from 'react-icons/bi';
import {LuListFilter} from 'react-icons/lu';
import AllDriversComponent from '../../../Components/Dispathcer/DriversProfile/AllDriversComponent/AllDriversComponent';
import MyDriversComponent from '../../../Components/Dispathcer/DriversProfile/MyDriversComponent/MyDriversComponent';
import React, {useState} from 'react';


export default function DriversProfilePage() {
    const [isModalOpen, setModalOpen] = useState(false);
    const [showMyDrivers, setShowMyDrivers] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };
    const openModal = () => {
        setModalOpen(true);
    };
    const closeModal = () => {
        setModalOpen(false);
    };
    const handleMyDriversClick = () => {
        setShowMyDrivers(true);
    };

    const handleAllDriversClick = () => {
        setShowMyDrivers(false);
    };

    return (
        <div className="drivers__page">
            <>

                <Navigation/>

                <div className="drivers__container">
                    <div className="drivers__title">Drivers profile</div>
                    <div className="drivers__panel">
                        <div className="drivers__choice">
                            <button
                                className={`drivers__approved ${showMyDrivers ? 'selected' : ''}`}
                                onClick={handleMyDriversClick}
                            >
                                My drivers
                            </button>
                            <button
                                className={`drivers__blacklist ${!showMyDrivers ? 'selected' : ''}`}
                                onClick={handleAllDriversClick}
                            >
                                All drivers
                            </button>
                        </div>

                        {isModalOpen && <Adddrivers onClose={closeModal}/>}
                        <div className="drivers__right__item">
                            <div className="drivers__add" onClick={openModal}>
                                Add new driver
                                <LuPlus/>
                            </div>
                            {/*<div className="drivers__filter">*/}
                            {/*    <LuListFilter/>*/}
                            {/*    <p>Filters</p>*/}
                            {/*</div>*/}
                            <div className="drivers__search">
                                <BiSearch/>
                                <input
                                    type="text"
                                    placeholder="Search"
                                    value={searchTerm}
                                    onChange={handleSearch}
                                />
                            </div>
                        </div>

                    </div>
                    {showMyDrivers ? (
                        <MyDriversComponent searchTerm={searchTerm}/>
                    ) : (
                        <AllDriversComponent searchTerm={searchTerm}/>
                    )}

                </div>


            </>
        </div>
    );
}
