import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {CiCircleAlert} from 'react-icons/ci';
import {IoClose, IoEyeOutline, IoEyeOffOutline} from 'react-icons/io5';
import {addDriver} from '../../../../store/actions/DriversProfileActions';
import InputMask from 'react-input-mask';
import './Adddriversmodal.scss';
import LoaderComponent from '../../../LoaderComponent/LoaderComponent';


const Adddrivers = ({ onClose }) => {
    const dispatch = useDispatch();
    const [emptyFields, setEmptyFields] = useState([]);
    const [passwordError, setPasswordError] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [isLoading, setLoading] = useState(false);

    const togglePasswordVisibility = (field) => {
        if (field === 'password') {
            setShowPassword(!showPassword);
        } else if (field === 'confirmPassword') {
            setShowConfirmPassword(!showConfirmPassword);
        }
    };

    const [formData, setFormData] = useState({
        userId: '', // Залишити userId порожнім
        firstName: '',
        lastName: '',
        phoneNumber: '',
        dob: null,
        email: '',
        password: '',
        confirmPassword: '',
    });

    const requiredFields = [
        'userId', // Залишити userId порожнім
        'firstName',
        'lastName',
        'phoneNumber',
        'dob',
        'email',
        'password',
        'confirmPassword',
    ];

    const handleInputChange = (name, value) => {
        let formattedValue = value;

        if (name === 'phoneNumber') {
            formattedValue = value.trim();
        } else if (name === 'dob') {
            formattedValue = value;
        } else {
            formattedValue = value.trim ? value.trim() : value;
        }

        setFormData((prevData) => ({
            ...prevData,
            [name]: formattedValue,
        }));

        if (name === 'confirmPassword' && formData.password !== formattedValue) {
            setPasswordError('Password and the confirmed password do not match');
        } else {
            setPasswordError('');
        }

        setEmptyFields((prevEmptyFields) => prevEmptyFields.filter((field) => field !== name));
    };

    const handleRegisterDriver = async (e) => {
        e.preventDefault();
        const emptyFields = requiredFields.filter((field) => {
            const value = formData[field];
            return !value || (typeof value === 'string' && !value.trim());
        });

        setEmptyFields(emptyFields);

        if (emptyFields.length > 0) {
            return;
        }

        if (formData.password !== formData.confirmPassword) {
            setPasswordError('Password and the confirmed password do not match');
            return;
        }

        setLoading(true);

        const formattedData = {
            userId: formData.userId.trim(), // Залишити userId порожнім
            firstName: formData.firstName.trim(),
            lastName: formData.lastName.trim(),
            email: formData.email.trim(),
            phoneNumber: formData.phoneNumber,
            dateOfBirth: formData.dob instanceof Date ? formData.dob.toISOString().split('T')[0] : null,
            password: formData.password.trim(),
        };

        try {
            await dispatch(addDriver(formattedData));
            onClose();
        } catch (error) {
            console.error('Error registering driver:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="backdrop__add_driver__dis">
            <div className="add-drivers-modal__add_driver__dis">
                <div className="add-drivers-modal__header">
                    New driver
                    <div className="add-drivers-modal__close" onClick={onClose}>
                        <IoClose/>
                    </div>
                </div>
                <form onSubmit={handleRegisterDriver}>
                    <div className="add-drivers-modal__body">
                        {requiredFields.map((field) => (
                            <div key={field} className="add-drivers__form__group">
                                <label htmlFor={field}>
                                    {field === 'dob'
                                        ? 'Date of Birth'
                                        : field === 'email'
                                            ? 'Email'
                                            : field === 'phoneNumber'
                                                ? 'Phone number'
                                                : field === 'password'
                                                    ? 'Password'
                                                    : field === 'confirmPassword'
                                                        ? 'Confirm Password'
                                                        : field === 'firstName'
                                                            ? 'First name'
                                                            : field === 'lastName'
                                                                ? 'Last name'
                                                                : 'Driver id'}
                                    {emptyFields.includes(field) && (
                                        <span className="error-message">
                                            This field is empty <CiCircleAlert/>
                                        </span>
                                    )}
                                </label>
                                {field === 'password' || field === 'confirmPassword' ? (
                                    <div className="password-input-container">
                                        <input
                                            type={showPassword ? 'text' : 'password'}
                                            id={field}
                                            name={field}
                                            placeholder={
                                                field === 'password' || field === 'confirmPassword'
                                                    ? 'Password'
                                                    : 'Enter ' + field
                                            }
                                            value={formData[field]}
                                            onChange={(e) => handleInputChange(field, e.target.value)}
                                        />
                                        <div
                                            className="password-visibility-toggle"
                                            onClick={() => togglePasswordVisibility(field)}
                                        >
                                            {showPassword || showConfirmPassword ? <IoEyeOutline/> : <IoEyeOffOutline/>}
                                        </div>
                                    </div>
                                ) : field === 'phoneNumber' ? (
                                    <InputMask
                                        mask="+1 (999) 999-9999"
                                        maskChar=""
                                        type="tel"
                                        id={field}
                                        name={field}
                                        placeholder="Phone number"
                                        value={formData[field]}
                                        onChange={(e) => handleInputChange(field, e.target.value)}
                                    />
                                ) : field === 'dob' ? (
                                    <DatePicker
                                        id={field}
                                        name={field}
                                        placeholderText="Date of Birth"
                                        selected={formData[field]}
                                        style={{ width: '100%' }}
                                        onChange={(date) => handleInputChange(field, date)}
                                        dateFormat="MM-dd-yyyy"
                                        showYearDropdown
                                        scrollableYearDropdown
                                        yearDropdownItemNumber={100}
                                        minDate={new Date("1900-01-01")}
                                        maxDate={new Date()}
                                    />


                                ) : (
                                    <input
                                        type={field === 'email' ? 'email' : 'text'}
                                        id={field}
                                        name={field}
                                        placeholder={
                                            field === 'dob'
                                                ? 'Date of Birth'
                                                : field === 'phoneNumber'
                                                    ? '+1 (XXX) XXX-XXXX'
                                                    : 'Enter ' + field.toLowerCase()
                                        }
                                        value={formData[field]}
                                        onChange={(e) => handleInputChange(field, e.target.value)}
                                    />
                                )}
                            </div>
                        ))}


                        {passwordError && (
                            <div className="error-message-password">
                                {passwordError} <CiCircleAlert/>
                            </div>
                        )}
                        <div className="add-drivers__form__btn__group">
                            {isLoading ? (
                                <LoaderComponent/>
                            ) : (
                                <button className="btn__register__driver" type="submit">
                                    Register driver
                                </button>
                            )}
                            <button className="btn__cancel_reg__driver" onClick={onClose}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Adddrivers;