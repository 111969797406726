import Navigation from "../../../Components/Navigation/Navigation";
import './MainPageAdmin.scss';
import MainComponents from "../../../Components/Admin/MainComponents/MainComponents";

export default function MainPageAdmin() {

    return (
        <div className="MainPageAdmin">
            <Navigation />
            <MainComponents />
        </div>
    );
}