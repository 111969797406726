import React, {useRef, useState, useEffect} from 'react';
import Navigation from '../../../Components/Navigation/Navigation';
import './CustomersPage.scss';
import {LuPlus} from 'react-icons/lu';
import {BiSearch} from 'react-icons/bi';
import AddCustomers from '../../../Components/Dispathcer/Customers/AddCustomers/AddCustomers';
import {IoCloseOutline} from 'react-icons/io5';
import {LuListFilter} from 'react-icons/lu';
import {useDispatch, useSelector} from 'react-redux';
import {fetchCustomers, deleteCustomer, addToBlacklist} from '../../../store/actions/customersActions';
import {IoIosMore} from 'react-icons/io';
import {MdOutlineExpandLess} from "react-icons/md";
import {MdOutlineExpandMore} from "react-icons/md";
import {MdKeyboardArrowLeft} from "react-icons/md";
import {MdKeyboardArrowRight} from "react-icons/md";
import LoaderComponent from "../../../Components/LoaderComponent/LoaderComponent";
import AddCustomerBlackList from "./AddCustomerBlackList/AddCustomerBlackList";

export default function CustomersPage() {
    const dispatch = useDispatch();
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedCustomerId, setSelectedCustomerId] = useState(null);
    const [sortColumn, setSortColumn] = useState('registerDate');
    const [sortDirection, setSortDirection] = useState('desc');
    const [showModal, setShowModal] = useState(false);
    const [showAddToBlacklistModal, setShowAddToBlacklistModal] = useState(false);
    const descriptionMaxLength = 20;
    const [truncateDescriptions] = useState(true); 
    const [expandedRowId, setExpandedRowId] = useState(null);

    useEffect(() => {
        dispatch(fetchCustomers(false));
    }, [dispatch]);
    const [isBlacklisted, setIsBlacklisted] = useState(false);
    const getDescriptionContent = (description) => {
        if (truncateDescriptions && description.length > descriptionMaxLength) {
            return `${description.substring(0, descriptionMaxLength)}...`;
        } else {
            return description;
        }
    };
    
    const handleBlacklistClick = () => {
        dispatch(fetchCustomers(true));
        if (!isBlacklisted) {
            setIsBlacklisted(true);
        }
    };
    const handleRowClick = (customerId) => {
        setExpandedRowId(expandedRowId === customerId ? null : customerId);
    };
    
    const handleApprovedClick = () => {
        dispatch(fetchCustomers(false));
        if (isBlacklisted) {
            setIsBlacklisted(false);
        }
    };

    const customers = useSelector(state => state.customers.customers);
    const loading = useSelector(state => state.customers.loading);
    const error = useSelector(state => state.customers.error);


    const modalRef = useRef(null);

    const handleAddClientClick = () => {
        setShowModal(true);
    };

    const handleCloseModalClick = () => {
        setShowModal(false);
    };

    const handleOpenModalClick = () => {
        setShowModal(true);
    };
    const handleCustomerAdded = () => {
        dispatch(fetchCustomers(isBlacklisted));
        setShowModal(false);
    };

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setShowModal(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    const handlePageSizeChange = (size) => {
        setPageSize(size);
        setCurrentPage(1);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1); // При пошуку, повертаємося на першу сторінку
    };

    const indexOfLastItem = currentPage * pageSize;
    const indexOfFirstItem = indexOfLastItem - pageSize;
    const filteredCustomers = customers.filter((customer) =>
        Object.values(customer).some(
            (value) =>
                value &&
                value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
    );


    const handleMoreActionsClick = (customerId) => {
        setShowMoreActionsModal(!showMoreActionsModal);
        setSelectedCustomerId(selectedCustomerId === customerId ? null : customerId);
    };
    const handleAddToBlacklist = (customerId) => {
        setSelectedCustomerId(customerId);
        setShowAddToBlacklistModal(true);

    };

    const handleCloseAddToBlacklistModalClick = () => {
        setShowAddToBlacklistModal(false);
    };

    const handleDeleteCustomer = (customerId) => {
        dispatch(deleteCustomer(customerId, true));
        setSelectedCustomerId(null);
    };


    const handleSortLessClick = () => {
        setSortDirection('asc');
    };

    const handleSortMoreClick = () => {
        setSortDirection('desc');
    };
    const handleSortClick = () => {
        setSortColumn('registerDate');
        setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    };

    const sortedCustomers = [...filteredCustomers].sort((a, b) => {
        const valueA = a[sortColumn];
        const valueB = b[sortColumn];

        if (sortDirection === 'asc') {
            return valueA < valueB ? -1 : 1;
        } else {
            return valueA > valueB ? -1 : 1;
        }
    });
    const currentItems = sortedCustomers.slice(indexOfFirstItem, indexOfLastItem);


    const [showMoreActionsModal, setShowMoreActionsModal] = useState(false);
    const moreActionsModalRef = useRef(null);


    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (moreActionsModalRef.current && !moreActionsModalRef.current.contains(event.target)) {
                setShowMoreActionsModal(false);
                setSelectedCustomerId(null);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [showMoreActionsModal]);


    const paginationOptions = Array.from(
        {length: Math.min(10, Math.ceil(sortedCustomers.length / 10))},
        (_, index) => (index + 1) * 10
    );
    return (
        <>
            <div className="customers__page">
                <Navigation/>
                <div className="customers__container">
                    <div className="customers__title">Customers</div>
                    <div className="customers__panel">
                        <div className="customers__choice">
                            <button className={`customers__approved ${!isBlacklisted ? 'active' : ''}`}
                                    onClick={handleApprovedClick}>
                                Approved
                            </button>
                            <button className={`customers__blacklist ${isBlacklisted ? 'active' : ''}`}
                                    onClick={handleBlacklistClick}>
                                Blacklist   
                            </button>
                        </div>    
                        <div className="customers__right__item">
                            <div className="customers__add" onClick={handleAddClientClick}>
                                Add Сlient
                                <LuPlus/>
                            </div>
                            {/*<div className="customers__filter">*/}
                            {/*    <LuListFilter/>*/}
                            {/*    <p>Filters</p>*/}
                            {/*</div>*/}
                            {customers.length > 0 && (
                                <div className="customers__search">
                                    <BiSearch/>
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        value={searchTerm}
                                        onChange={handleSearch}
                                    />
                                </div>
                            )}
                        </div>
                    </div>


                    <div className='table__wrapper__customers'>
                        <div className='table__container__customers'>
                            <table>
                                <thead>
                                <tr>
                                    <th>Client name</th>
                                    <th>Client email</th>
                                    <th>MC number</th>
                                    <th>Company name</th>
                                    <th>Phone number</th>
                                    {currentItems.some(customer => customer.comment && customer.comment.trim() !== "") && (
                                        <th>Comment</th>
                                    )}
                                    <th onClick={handleSortClick}
                                        style={{display: 'flex', alignItems: 'center', width: 'auto'}}>
                                        Register date
                                        <div className='table__sort__customers'>
                                            <div
                                                className={`table__sort__less ${sortDirection === 'desc' ? 'opacity__less__more' : ''}`}
                                                onClick={handleSortLessClick}>
                                                <MdOutlineExpandLess/>
                                            </div>
                                            <div
                                                className={`table__sort__less ${sortDirection === 'asc' ? 'opacity__less__more' : ''}`}
                                                onClick={handleSortMoreClick}>
                                                <MdOutlineExpandMore/>
                                            </div>
                                        </div>
                                    </th>
                                </tr>
                                </thead>

                                <tbody>
                                {loading ? (
                                    <tr>
                                        <td colSpan="7">
                                            <LoaderComponent/>
                                        </td>
                                    </tr>
                                ) : error ? (
                                    <tr>
                                        <td colSpan="7">
                                            <p>{error}</p>
                                        </td>
                                    </tr>
                                ) : (
                                    currentItems.map(customer => (
                                        <tr key={customer.id} onClick={() => handleRowClick(customer.id)}>
                                            <td data-cell="Client Name">{customer.clientName}</td>
                                            <td data-cell="Client Email">{customer.clientEmail}</td>
                                            <td data-cell="MC Number">{customer.mcNumber}</td>
                                            <td data-cell="Company Name">{customer.companyName}</td>
                                            <td data-cell="Phone Number">{customer.phoneNumber}</td>
                                            {customer.comment && customer.comment.trim() !== "" && (
    <td data-cell="Comment">
        {expandedRowId === customer.id ? customer.comment : getDescriptionContent(customer.comment)}
    </td>
)}



                                            <td data-cell="Register Date">{customer.registerDate}</td>
                                            <td className='table__tools__customers' data-cell="Actions">
                                                <IoIosMore onClick={() => handleMoreActionsClick(customer.id)}/>
                                                {selectedCustomerId === customer.id && (
                                                    <div className="more-actions-modal" ref={moreActionsModalRef}>
                                                        <div className="more-actions-content customers">
                                                            {!isBlacklisted && (
                                                                <div className="more-action-item"
                                                                     onClick={() => handleAddToBlacklist(customer.id)}>
                                                                    Add to blacklist
                                                                </div>
                                                            )}

                                                            <div className="more-action-item"
                                                                 onClick={() => handleDeleteCustomer(customer.id)}>
                                                                Delete
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </td>
                                        </tr>
                                    ))
                                )}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    {customers.length > 0 && (
                        <div className="pagination">

                            <select onChange={(e) => handlePageSizeChange(e.target.value)} className='per__page'>
                                {paginationOptions.map((option) => (
                                    <option key={option} value={option}>
                                        {`${option} per page`}
                                    </option>
                                ))}
                            </select>


                            <div className="pagination__pages">
                                <button
                                    onClick={() => handlePageChange(currentPage - 1)}
                                    disabled={currentPage === 1} // Disable the button if it's the first page
                                >
                                    <MdKeyboardArrowLeft/>
                                </button>
                                <span>{currentPage}</span>
                                of {Math.ceil(filteredCustomers.length / pageSize)}
                                <button
                                    onClick={() => handlePageChange(currentPage + 1)}
                                    disabled={currentPage === Math.ceil(filteredCustomers.length / pageSize)} // Disable the button if it's the last page
                                >
                                    <MdKeyboardArrowRight/>
                                </button>
                            </div>
                        </div>

                    )}

                </div>


                {showModal && (
                    <div className="modal__add__customer" ref={modalRef}>
                        <div className="modal-content__add__customer">
                            <div className="heading__modal__customer">
                                <div className="title__modal__customer">
                                    New client
                                </div>
                                <div className="close__modal__customer" onClick={handleCloseModalClick}>
                                    <IoCloseOutline/>
                                </div>
                            </div>
                            <AddCustomers onCustomerAdded={handleCustomerAdded}/>
                            <div className="form__group__cancel__customer__btn">
                                <button onClick={handleCloseModalClick}>Cancel</button>
                            </div>
                        </div>
                    </div>
                )}
                {showAddToBlacklistModal && (
                    <AddCustomerBlackList closeModal={handleCloseAddToBlacklistModalClick}
                                          customerId={selectedCustomerId}
                    />
                )}
            </div>

        </>
    );
}
