import Navigation from "../../Components/Navigation/Navigation";
import './ProfilePage.scss'
import InfoProfileComponent from "../../Components/Profile/InfoProfileComponent/InfoProfileComponent";



export default function ProfilePage() {


    return (
        <div className="profile__container">
        <>
            <Navigation/>
            <InfoProfileComponent />
        </>
      </div>
    )
}
