import logoLogin from "../../../assets/img/logo/kpo-login.png";
import "./ResetPasswordComponent.scss";
import React, {useState, useEffect, useCallback} from "react";
import {useNavigate} from 'react-router-dom';
import {useLocation} from 'react-router-dom';
import {IoEyeOffOutline, IoEyeOutline} from "react-icons/io5";
import { useDispatch } from 'react-redux';
import {updatePassword} from "../../../store/actions/forgotPasswordActions";
import ModalResetPasswordComponent from "./ModalResetPasswordComponent/ModalResetPasswordComponent";

export default function ResetPasswordComponent() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [emailParam, setEmailParam] = useState("");
    const [linkParam, setLinkParam] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [passwordEntered, setPasswordEntered] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [passwordMismatch, setPasswordMismatch] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const {search} = location;
    const dispatch = useDispatch();

    const handleModalClose = () => {
        setIsModalOpen(false);
        navigate('/');
    };

    useEffect(() => {
        const params = new URLSearchParams(search);
        const emailParam = params.get('email');
        const linkParam = params.get('link');
        if (!emailParam || !linkParam) {
            navigate('/');
        } else {
            setEmailParam(emailParam);
            setLinkParam(linkParam);
        }

    }, [search, navigate]);


    const handleBackToSiteClick = () => {
        navigate('/');
    };


    const getPasswordStrengthClass = useCallback((condition) => {
        const lowercaseRegex = /[a-z]/;
        const uppercaseRegex = /[A-Z]/;
        const numberRegex = /\d/;
        const specialCharRegex = /[!@#$%^&*]/;

        switch (condition) {
            case "lowercase":
                return lowercaseRegex.test(newPassword) ? "password__condition__valid" : "password__condition__invalid";
            case "uppercase":
                return uppercaseRegex.test(newPassword) ? "password__condition__valid" : "password__condition__invalid";
            case "number":
                return numberRegex.test(newPassword) ? "password__condition__valid" : "password__condition__invalid";
            case "specialChar":
                return specialCharRegex.test(newPassword) ? "password__condition__valid" : "password__condition__invalid";
            case "length":
                return newPassword.length >= 8 ? "password__condition__valid" : "password__condition__invalid";
            default:
                return "password__condition__invalid";
        }
    }, [newPassword]);


    const areAllConditionsMet = () => {
        return (
            getPasswordStrengthClass("lowercase") === "password__condition__valid" &&
            getPasswordStrengthClass("uppercase") === "password__condition__valid" &&
            getPasswordStrengthClass("number") === "password__condition__valid" &&
            getPasswordStrengthClass("specialChar") === "password__condition__valid" &&
            getPasswordStrengthClass("length") === "password__condition__valid"
        );
    };

    const handlePasswordInputChange = (e) => {
        setNewPassword(e.target.value);
        setPasswordEntered(true);
        setPasswordMismatch(false);
    };

    const handleConfirmPasswordInputChange = (e) => {
        setConfirmNewPassword(e.target.value);
        setPasswordMismatch(newPassword !== e.target.value);
    };

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleResetPasswordClick = async () => {
        if (newPassword !== confirmNewPassword) {
            setPasswordMismatch(true);
            return;
        }

        setPasswordMismatch(false);
        try {
            await dispatch(updatePassword(newPassword, linkParam));
            setIsModalOpen(true);
        } catch (error) {
            // Обробляйте помилки за потреби
        }
    };

    useEffect(() => {
        // Перевірка всіх умов валідації та встановлення isFormValid
        setIsFormValid(
            getPasswordStrengthClass("lowercase") === "password__condition__valid" &&
            getPasswordStrengthClass("uppercase") === "password__condition__valid" &&
            getPasswordStrengthClass("number") === "password__condition__valid" &&
            getPasswordStrengthClass("specialChar") === "password__condition__valid" &&
            getPasswordStrengthClass("length") === "password__condition__valid" &&
            !passwordMismatch
        );
    }, [newPassword, confirmNewPassword, passwordMismatch, getPasswordStrengthClass]);


    return (
        <>
        <div className="reset__password__container">
            <div className="reset__password__logo__container">
                <img src={logoLogin} alt="logo"/>
            </div>
            <div className="reset__password__text__container">Reset password</div>
            <div className="reset__container__user__email">{emailParam}</div>
            <div className="reset__password__form__container">
                <div className="form__group__reset__password">
                    <label>New Password</label>
                    <input
                        type={showPassword ? "text" : "password"}
                        value={newPassword}
                        onChange={handlePasswordInputChange}
                        className={getPasswordStrengthClass()}
                        placeholder="Enter new password"
                    />
                    <span className="password-toggle" onClick={toggleShowPassword}>
                        {showPassword ? <IoEyeOffOutline/> : <IoEyeOutline/>}
                    </span>
                    {passwordEntered && !areAllConditionsMet() && (
                        <div className="password__conditions">
                            <p className={`password__condition ${getPasswordStrengthClass("lowercase")}`}>
                                At least one lowercase letter
                            </p>
                            <p className={`password__condition ${getPasswordStrengthClass("uppercase")}`}>
                                At least one uppercase letter
                            </p>
                            <p className={`password__condition ${getPasswordStrengthClass("number")}`}>
                                At least one number
                            </p>
                            <p className={`password__condition ${getPasswordStrengthClass("specialChar")}`}>
                                At least one special character
                            </p>
                            <p className={`password__condition ${getPasswordStrengthClass("length")}`}>
                                At least 8 characters
                            </p>
                        </div>
                    )}
                </div>

                <div className="form__group__reset__password">
                    <label>Confirm New Password</label>
                    <input
                        type={showPassword ? "text" : "password"}
                        value={confirmNewPassword}
                        onChange={handleConfirmPasswordInputChange}
                        className={passwordMismatch ? "password__condition__invalid" : ""}
                        placeholder="Confirm new password"
                    />
                    <span className="password-toggle" onClick={toggleShowPassword}>
                        {showPassword ? <IoEyeOffOutline/> : <IoEyeOutline/>}
                    </span>
                    {passwordMismatch && (
                        <div className="password-mismatch-message">
                            New password and the confirmed password do not match
                        </div>
                    )}
                </div>
            </div>
            {isModalOpen && (
                <ModalResetPasswordComponent
                    onClose={handleModalClose}
                />
            )}
            <div className="reset__password__button__container">
                <button className="reset__password__button" onClick={handleResetPasswordClick} disabled={!isFormValid}>
                    Reset password
                </button>
                <div className="reset__password__button__cancel" onClick={handleBackToSiteClick}>
                    Home
                </div>
            </div>
        </div>
</>
    );
}
