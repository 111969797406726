import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteLoad } from '../../../../../store/actions/MainActions';
import { IoClose } from 'react-icons/io5';
import './DeleteLoadModal.scss';
import LoaderComponent from '../../../../LoaderComponent/LoaderComponent';

export default function AddLoadModalComponent({ closeModal, loadId }) {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            await dispatch(deleteLoad(loadId));
            setIsLoading(false);
            closeModal(); // Optionally close modal after successful deletion
        } catch (error) {
            console.error('Error deleting load:', error);
            setIsLoading(false);
            // Handle error as needed
        }
    };

    return (
        <>
            <div className="modal-overlay__add__load">
                <div className="modal-content__add__load">
                    <div className="modal__add__load__head">
                        <p>Are you sure you want to delete this load?</p>
                        <div className="modal__close__load" onClick={closeModal}>
                            <IoClose />
                        </div>
                    </div>
                    <div className="modal__add__load__body">
                        <p className='load__body__content'>All data for this load will be deleted including photos, reports and comments, do you want to continue?</p>
                        <p className='load__body__content b'>this action cannot be undone, please be careful.</p>
                        <div className="form__group__delete__load__btn">
                            <div className="delete__load__btn" type="submit" onClick={handleSubmit} disabled={isLoading}>
                                {isLoading ? <LoaderComponent /> : null}
                                {isLoading ? 'Deleting...' : 'Delete load'}
                            </div>
                            <div className="cancel__delete__load__btn" onClick={closeModal} type="button">
                                Cancel
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
