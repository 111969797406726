import React, {useState, useEffect, useRef} from 'react';
import './EditDriverInfoComponent.scss';

import {updateDriver} from "../../../../store/actions/DriversProfileActions";
import {FaCheck} from "react-icons/fa";
import {useDispatch, useSelector} from 'react-redux';
import {MdKeyboardArrowDown, MdKeyboardArrowUp} from "react-icons/md";
import {IoClose} from "react-icons/io5";
import {CiCircleAlert} from "react-icons/ci";
import {fetchDispatchers} from "../../../../store/actions/analyticsActions";
import LoaderComponent from "../../../LoaderComponent/LoaderComponent";
import InputMask from 'react-input-mask';

export const EditDriverInfoComponent = ({driverData, onClose, id}) => {
        const dispatch = useDispatch();
        const dispatchersList = useSelector(state => state.analytics.dispatchers);
        const [isDropdownOpen, setIsDropdownOpen] = useState(false);
        const [isSelectOpen, setIsSelectOpen] = useState(false);
        const [isLoading, setIsLoading] = useState(true);
        const [selectedDispatcher, setSelectedDispatcher] = useState('');
        const [selectedDispatcherId, setSelectedDispatcherId] = useState('');
        //eslint-disable-next-line
        const [isSaving, setIsSaving] = useState(false);
        const phoneNumberRef = useRef(null);

        useEffect(() => {
            const fetchData = async () => {
                try {
                    await dispatch(fetchDispatchers());
                    setIsLoading(false);
                } catch (error) {
                    console.error('Error fetching dispatchers:', error);
                    setIsLoading(false);
                }
            };

            fetchData();
        }, [dispatch]);

        useEffect(() => {
            if (driverData && driverData.driverData && driverData.driverData.main) {
                const {driverName, phoneNumber, email, dispatcherName} = driverData.driverData.main;
                setFormData({
                    driverName: driverName || "",
                    phoneNumber: phoneNumber || "",
                    email: email || "",
                    dispatcherName: dispatcherName || "",
                });
                setSelectedDispatcher(dispatcherName || '');
            }
        }, [driverData]);

        const [formData, setFormData] = useState({
            driverName: driverData?.main?.driverName || "",
            phoneNumber: driverData?.main?.phoneNumber || "",
            email: driverData?.main?.email || "",
            dispatcherName: driverData?.main?.dispatcherName || "",
            userId: driverData?.main?.userId || "",
        });

        const toggleCustomSelect = () => {
            setIsDropdownOpen(!isDropdownOpen);
            setIsSelectOpen(!isSelectOpen);
        };

        const handleDispatcherSelect = (dispatcher) => {

            const dispatcherId = dispatcher.main?.id || dispatcher.id;
            const dispatcherName = dispatcher.main?.dispatcherName || dispatcher.dispatcherName;

            setSelectedDispatcher(dispatcherName);
            if (dispatcherName !== selectedDispatcher) {
                setSelectedDispatcherId(dispatcherId);
            }

            if (dispatcherName !== selectedDispatcher) {
                setFormData(prevFormData => ({
                    ...prevFormData,
                    dispatcherId: dispatcherId
                }));
            }

            toggleCustomSelect();
        };


        useEffect(() => {
            setSelectedDispatcher(formData.dispatcherName);
        }, [formData.dispatcherName]);

        const [validationErrors, setValidationErrors] = useState({
            driverName: "",
            phoneNumber: "",
            email: "",
            dispatcherId: "",
        });


        const handleSaveChanges = async () => {

            const errors = {};

            if (!formData.driverName || !/^[A-Z][a-z]+(\s[A-Z][a-z]+)?(-[A-Z][a-z]+)?$/.test(formData.driverName)) {
                errors.driverName = "Enter the driver's first and last name in the correct format.";
            }


            if (!formData.phoneNumber) {
                errors.phoneNumber = "Please enter a phone number.";
            }

            if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) {
                errors.email = "Enter a valid email address";
            }


            setValidationErrors(errors);

            if (Object.keys(errors).length > 0) {
                return;
            }

            // Всі поля заповнені, відправити дані
            const updatedData = {
                driverName: formData.driverName,
                phoneNumber: formData.phoneNumber,
                email: formData.email,
                dispatcherId: selectedDispatcherId || formData.dispatcherId || driverData?.main?.dispatcherId,
                userId: formData.userId,
            };

            setIsSaving(true);

            try {
                // Виклик функції оновлення водія
                await dispatch(updateDriver(id, updatedData));
                setIsSaving(false);
                onClose();
            } catch (error) {
                setIsSaving(false);
                console.error('Error updating driver:', error);
            }
        };


        return (
            <>
                {isLoading ? (
                    <LoaderComponent/>
                ) : (
                    <div className="modal__edit__driver__info">
                        <div className="modal-content__edit__driver__info">
                            <div className="modal__edit__driver__head">
                                <div className="modal__edit__driver__title">
                                    Driver info
                                </div>
                                <div className="close__modal__edit__driver" onClick={onClose}>
                                    <IoClose/>
                                </div>
                            </div>


                            <div className="modal__edit__driver__body">

                                <div className="form__edit__driver__modal__info">
                                    <form>
                                        <div className="form__group__edit__driver__info">
                                            <div className="form__label__edit__driver__info">
                                                Driver name
                                                {validationErrors.driverName && (
                                                    <div
                                                        className="validation-error">{validationErrors.driverName}<CiCircleAlert/>
                                                    </div>
                                                )}
                                            </div>
                                            <input
                                                type="text"
                                                className="form__input__edit__driver__info"
                                                placeholder="Driver name"
                                                value={formData.driverName}
                                                onChange={(e) => {
                                                    setFormData({...formData, driverName: e.target.value});
                                                    setValidationErrors({...validationErrors, driverName: ""});
                                                }}
                                            />
                                        </div>
                                        <div className="form__group__edit__driver__info">
                                            <div className="form__label__edit__driver__info">
                                                Driver ID
                                            </div>
                                            <input
                                                type="text"
                                                className="form__input__edit__driver__info"
                                                placeholder="User ID"
                                                value={formData.userId}
                                                onChange={(e) => setFormData({
                                                    ...formData,
                                                    userId: e.target.value
                                                })}
                                            />
                                        </div>

                                        <div className='form__group__edit__driver__profile__select'>
                                            Dispatcher
                                            <div
                                                className={`driver__profile custom-select ${isDropdownOpen ? 'active' : ''}`}
                                                onClick={toggleCustomSelect}>
                                                <div className="selected-options">
                                                    <div className="selected-option driver__arrow">
                                                        {selectedDispatcher} {isSelectOpen ? <MdKeyboardArrowUp/> :
                                                        <MdKeyboardArrowDown/>}
                                                    </div>
                                                </div>
                                                <div className="select-icon"></div>
                                                <div className="options-list driver__profile__select">
                                                    {dispatchersList.map((dispatcher) => (
                                                        <div className="option-item" key={dispatcher.id}
                                                             onClick={() => handleDispatcherSelect(dispatcher)}>
                                                            <label>{dispatcher.dispatcherName}</label>
                                                            <div
                                                                className={`checkbox ${dispatcher.id === selectedDispatcherId ? 'selected' : ''}`}>
                                                                {dispatcher.id === selectedDispatcherId &&
                                                                    <FaCheck/>}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>


                                            <div className="form__group__edit__driver__info">
                                                <div className="form__label__edit__driver__info">
                                                    Phone number
                                                </div>
                                                <InputMask
                                                    mask="+9 (999) 999-9999"
                                                    maskChar="_"
                                                    type="text"
                                                    className="form__input__edit__driver__info"
                                                    placeholder="Phone name"
                                                    value={formData.phoneNumber}
                                                    onChange={(e) => setFormData({
                                                        ...formData,
                                                        phoneNumber: e.target.value
                                                    })}
                                                    inputRef={phoneNumberRef}
                                                />


                                            </div>
                                            <div className="form__group__edit__driver__info">
                                                <div className="form__label__edit__driver__info">
                                                    Email
                                                    {validationErrors.email && (
                                                        <div
                                                            className="validation-error">{validationErrors.email}<CiCircleAlert/>
                                                        </div>
                                                    )}
                                                </div>
                                                <input
                                                    type="text"
                                                    className="form__input__edit__driver__info"
                                                    placeholder="Email"
                                                    value={formData.email}
                                                    onChange={(e) => setFormData({
                                                        ...formData,
                                                        email: e.target.value
                                                    })}
                                                />
                                            </div>
                                        </div>

                                    </form>
                                </div>
                                <div className="btn__group__edit__driver__profile">
                                    <div className="btn__edit__save__driver__info" onClick={handleSaveChanges}>Change
                                        information
                                    </div>
                                    <div className="btn__edit__cancel__driver__profile" onClick={onClose}>Cancel</div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </>
        )
            ;
    }
;

export default EditDriverInfoComponent;
