import React, { useState } from 'react';
import RouteDetails from '../RouteDetails/RouteDetails';
import DriverInformation from '../DriverInformation/DriverInformationComponent';
import './InfoRoutesComponent.scss';
import backKpoSvg from '../../../../../src/assets/svg/back_kpo.svg'

export default function InfoRoutesComponent({ driverId }) {
    const [selectedComponent, setSelectedComponent] = useState('RouteDetails');
    const [isComponentChanging, setIsComponentChanging] = useState(false);

    const handleButtonClick = (component) => {
        if (!isComponentChanging) {
            setIsComponentChanging(true);
                setSelectedComponent(component);
                setIsComponentChanging(false);
        }
    };
    if (driverId === null) {
        return <div className="driver__data__not__found">
            Choose any driver
            <div className="driver__data__not__found__logo">
            <img src={backKpoSvg} alt="Back KPO" />
            </div>
        </div>;
      }
    return (
        <div className="info__container">
            <div className="buttons__container">
                <button
                    onClick={() => handleButtonClick('RouteDetails')}
                    className={selectedComponent === 'RouteDetails' ? 'selected' : ''}
                >
                    Route Details
                </button>
                <button
                    onClick={() => handleButtonClick('DriverInformation')}
                    className={selectedComponent === 'DriverInformation' ? 'selected' : ''}
                >
                    Driver Information
                </button>
            </div>
            <div className="info__display">
                {selectedComponent === 'RouteDetails' ? (
                    <RouteDetails driverId={driverId} />
                ) : (
                    <DriverInformation driverId={driverId} />
                )}
            </div>
        </div>
    );
}
