import {
    FETCH_DROPDOWNS_REQUEST,
    FETCH_DROPDOWNS_SUCCESS,
    FETCH_DROPDOWNS_FAILURE,
    CREATE_ACCIDENT_REPORT_REQUEST,
    CREATE_ACCIDENT_REPORT_SUCCESS,
    CREATE_ACCIDENT_REPORT_FAILURE,
    FETCH_ALL_REPORTS_REQUEST,
    FETCH_ALL_REPORTS_SUCCESS,
    FETCH_ALL_REPORTS_FAILURE,
    CHANGE_CURRENT_STATUS_REQUEST,
    CHANGE_CURRENT_STATUS_SUCCESS,
    CHANGE_CURRENT_STATUS_FAILURE,
    DELETE_REPORT_REQUEST,
    DELETE_REPORT_SUCCESS,
    DELETE_REPORT_FAILURE,
} from '../actions/helpActions';

const initialState = {
    dropdowns: [],
    loading: false, 
    error: null,
    creatingReport: false,
    createdReport: null,
    createReportError: null,
    allReports: [],
    fetchingAllReports: false,
    fetchAllReportsError: null,
    changeStatusLoading: false,
};

const accidentsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_DROPDOWNS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case FETCH_DROPDOWNS_SUCCESS:
            return {
                ...state,
                loading: false,
                dropdowns: action.payload,
                error: null
            };
        case FETCH_DROPDOWNS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            }; 
        case CREATE_ACCIDENT_REPORT_REQUEST:
            return {
                ...state,
                creatingReport: true,
                createdReport: null,
                createReportError: null
            };
        case CREATE_ACCIDENT_REPORT_SUCCESS:
            return {
                ...state,
                creatingReport: false,
                createdReport: action.payload,
                createReportError: null,
                allReports: [...state.allReports, action.payload],
            };
        case CREATE_ACCIDENT_REPORT_FAILURE:
            return {
                ...state,
                creatingReport: false,
                createdReport: null,
                createReportError: action.payload
            };
        case FETCH_ALL_REPORTS_REQUEST:
            return {
                ...state,
                fetchingAllReports: true,
                fetchAllReportsError: null
            };
        case FETCH_ALL_REPORTS_SUCCESS:
            return {
                ...state,
                fetchingAllReports: false,
                allReports: action.payload,
                fetchAllReportsError: null
            };
        case FETCH_ALL_REPORTS_FAILURE:
            return {
                ...state,
                fetchingAllReports: false,
                fetchAllReportsError: action.payload
            };
            case CHANGE_CURRENT_STATUS_REQUEST:
            return {
                ...state,
                changeStatusLoading: true,
                error: null,
            };
        case CHANGE_CURRENT_STATUS_SUCCESS:
            return {
                ...state,
                changeStatusLoading: false,
                error: null,
            };
        case CHANGE_CURRENT_STATUS_FAILURE:
            return {
                ...state,
                changeStatusLoading: false,
                error: action.payload,
            };
            case DELETE_REPORT_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case DELETE_REPORT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                drivers: state?.accidents?.allReports.filter(report => report.id !== action.payload.reportId),
            };
        case DELETE_REPORT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default accidentsReducer;