import './PhotoReportCancelModal.scss'
import {IoClose} from 'react-icons/io5';
import React, { useState } from 'react';
export default function PhotoReportCancelModal({currentPoint, onClose, onSend, onCommentChange}) {
    const [comment, setComment] = useState('');

    const handleCommentChange = (e) => {
        const value = e.target.value;
        setComment(value);
        onCommentChange(value);
    };

    return (
        <div className="photo-report-cancel-modal">
            <div className="modal-content">
                <div className="photo__report__cancel__head">
                    <div className="title__modal__cancel__photo">
                        Photoreport cancel
                    </div>
                    <div className="close__modal__cancel__photo">
                        <div onClick={onClose}><IoClose/></div>
                    </div>
                </div>

                <div className="photo__report__cancel__body">

                    
                        <div className="title__modal__cancel__photo__reason">Reason</div>
                        <div className='input__modal__cancel__photo'>
                        <textarea
                        value={comment} 
                        onChange={handleCommentChange} 
                            id="cancelReason"
                            name="cancelReason"
                            placeholder="write your reason"
                            
                        />
                        </div>

                

                <div className="btns__photo__report__cancel">
                    <div onClick={onSend} className="btn__photo__report__cancel__send">Send</div>
                    <div onClick={onClose} className="btn__photo__report__cancel__cansel">Cancel</div>
                </div>
            </div>
        </div>
</div>
)
    ;
}