import {
    FETCH_TRUCK_DROPDOWNS_SUCCESS,
    FETCH_TRACK_INFO_SUCCESS,
    FETCH_SINGLE_TRACK_SUCCESS,
    FETCH_TRACK_PHOTOS_SUCCESS,
    FETCH_DROPDOWNS_FILES_TRUCK_REQUEST,
    FETCH_DROPDOWNS_FILES_TRUCK_SUCCESS,
    FETCH_DROPDOWNS_FILES_TRUCK_FAILURE,
    UPLOAD_FILES_REQUEST,
    UPLOAD_FILES_SUCCESS,
    UPLOAD_FILES_FAILURE,
    DELETE_PHOTO_REQUEST,
    DELETE_PHOTO_SUCCESS,
    DELETE_PHOTO_FAILURE,
    DELETE_FILE_REQUEST,
    DELETE_FILE_SUCCESS,
    DELETE_FILE_FAILURE,
} from '../actions/trackActions';

const initialState = {
    dropdownData: {},
    trackInfo: [],
    singleTrackInfo: null,
    trackPhotos: [],
    photosSaved: false,
    dropdowns: [],
    loading: false,
    error: null,
    uploading: false,
    uploadSuccess: false,
    deletingPhoto: false,
    deletingFile: false,
};

const truckReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_TRUCK_DROPDOWNS_SUCCESS:
            return {
                ...state,
                dropdownData: action.payload,
            };
        case FETCH_TRACK_INFO_SUCCESS:
            return {
                ...state,
                trackInfo: action.payload,
            };
        case FETCH_SINGLE_TRACK_SUCCESS:
            return {
                ...state,
                singleTrackInfo: action.payload,
            };
        case FETCH_TRACK_PHOTOS_SUCCESS:
            return {
                ...state,
                trackPhotos: action.payload,
            };
        case FETCH_DROPDOWNS_FILES_TRUCK_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case FETCH_DROPDOWNS_FILES_TRUCK_SUCCESS:
            return {
                ...state,
                loading: false,
                dropdowns: action.payload,
                error: null,
            };
        case FETCH_DROPDOWNS_FILES_TRUCK_FAILURE:
            return {
                ...state,
                loading: false,
                dropdowns: [],
                error: action.payload,
            };
        case UPLOAD_FILES_REQUEST:
            return {
                ...state,
                uploading: true,
                uploadSuccess: false,
                error: null,
            };
        case UPLOAD_FILES_SUCCESS:
            return {
                ...state,
                uploading: false,
                uploadSuccess: true,
                error: null,
            };
        case UPLOAD_FILES_FAILURE:
            return {
                ...state,
                uploading: false,
                uploadSuccess: false,
                error: action.payload,
            };
        case DELETE_PHOTO_REQUEST:
            return {
                ...state,
                deletingPhoto: true,
            };
        case DELETE_PHOTO_SUCCESS:
            return {
                ...state,
                deletingPhoto: false,
                photos: state.photos.filter(photo => photo.id !== action.payload),
            };
        case DELETE_PHOTO_FAILURE:
            return {
                ...state,
                deletingPhoto: false,
            };
            case DELETE_FILE_REQUEST:
            return {
                ...state,
                deletingFile: true,
            };
        case DELETE_FILE_SUCCESS:
            return {
                ...state,
                deletingFile: false,
                files: state.files.filter(file => file.id !== action.payload),
            };
        case DELETE_FILE_FAILURE:
            return {
                ...state,
                deletingFile: false,
            };
        
        default:
            return state;
    }
};

export default truckReducer;
