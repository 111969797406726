import {
    GET_ALL_DRIVERS_REQUEST,
    GET_ALL_DRIVERS_SUCCESS,
    GET_ALL_DRIVERS_FAILURE,
    GET_MAIN_CONTENT_REQUEST,
    GET_MAIN_CONTENT_SUCCESS,
    GET_MAIN_CONTENT_FAILURE,
    UPDATE_DRIVER_NOTES_REQUEST,
    UPDATE_DRIVER_NOTES_SUCCESS,
    UPDATE_DRIVER_NOTES_FAILURE,
    DRIVER_FIND_MAIN_CONTENT_REQUEST,
    DRIVER_FIND_MAIN_CONTENT_SUCCESS,
    DRIVER_FIND_MAIN_CONTENT_FAILURE,
    GET_DRIVERS_STATUSES_REQUEST,
    GET_DRIVERS_STATUSES_SUCCESS,
    GET_DRIVERS_STATUSES_FAILURE,
    FIND_NOTES_REQUEST,
    FIND_NOTES_SUCCESS,
    FIND_NOTES_FAILURE,
    SET_DRIVER_STATUS_REQUEST,
    SET_DRIVER_STATUS_SUCCESS,
    SET_DRIVER_STATUS_FAILURE,
    CREATE_LOAD_REQUEST,
    CREATE_LOAD_SUCCESS,
    CREATE_LOAD_FAILURE,
    FETCH_LOAD_STATUSES_REQUEST,
    FETCH_LOAD_STATUSES_SUCCESS,
    FETCH_LOAD_STATUSES_FAILURE,
    GET_POINT_SUCCESS,
    GET_POINT_FAILURE,
    CREATE_PHOTO_REPORT_REQUEST,
    CREATE_PHOTO_REPORT_SUCCESS,
    CREATE_PHOTO_REPORT_FAILURE,
    APPROVE_POINT_REQUEST,
    APPROVE_POINT_SUCCESS,
    APPROVE_POINT_FAILURE,
    HIDE_LOAD_REQUEST,
    HIDE_LOAD_SUCCESS,
    HIDE_LOAD_FAILURE,
    UPLOAD_FILE_FOR_LOAD_REQUEST,
    UPLOAD_FILE_FOR_LOAD_SUCCESS,
    UPLOAD_FILE_FOR_LOAD_FAILURE,
    CHANGE_DRIVER_REQUEST,
    CHANGE_DRIVER_SUCCESS,
    CHANGE_DRIVER_FAILURE,
    FETCH_LOAD_REQUEST,
    FETCH_LOAD_SUCCESS,
    FETCH_LOAD_FAILURE,
    UPDATE_LOAD_REQUEST,
    UPDATE_LOAD_SUCCESS,
    UPDATE_LOAD_FAILURE,
    DELETE_LOAD_REQUEST,
    DELETE_LOAD_SUCCESS,
    DELETE_LOAD_FAILURE,
} from '../actions/MainActions.js';

const initialState = {
    loading: false,
    load: null,
    updateDriverNotesError: null,
    isUpdatingDriverNotes: false,
    isUpdatingDriverStatus: true,
    updateDriverStatusError: null,
    error: null,
    drivers: [],
    main_content: [],
    loadsList: [],
    statusesList: [],
    driverNotes: [],
    data: null,
    statuses: [],
    PointPhotos: [],
    photosSaved: false,
    approvingPoint: false,
    uploading: false,
    uploadSuccess: false,
};
const alldriversReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_DRIVERS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case GET_ALL_DRIVERS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                drivers: action.payload,
            };
        case GET_ALL_DRIVERS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_MAIN_CONTENT_REQUEST:
            return {...state, loading: true, error: null};

        case GET_MAIN_CONTENT_SUCCESS:
            return {...state, loading: false, main_content: action.payload, error: null};
        case GET_MAIN_CONTENT_FAILURE:
            return {...state, loading: false, error: action.payload};

        case UPDATE_DRIVER_NOTES_REQUEST:
            return {
                ...state,
                isUpdatingDriverNotes: true,
                updateDriverNotesError: null,
            };
        case UPDATE_DRIVER_NOTES_SUCCESS:
            return {
                ...state,
                isUpdatingDriverNotes: false,
                main_content: [...state.main_content, action.payload],
                updateDriverNotesError: null,
            };

        case UPDATE_DRIVER_NOTES_FAILURE:
            return {
                ...state,
                isUpdatingDriverNotes: false,
                updateDriverNotesError: action.payload,
            };
        case DRIVER_FIND_MAIN_CONTENT_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case DRIVER_FIND_MAIN_CONTENT_SUCCESS:
            return {
                ...state,
                loading: false,
                loadsList: action.payload,
                error: null,
            };
        case DRIVER_FIND_MAIN_CONTENT_FAILURE:
            return {
                ...state,
                loading: false,
                loadsList: [],
                error: action.payload,
            };
        case GET_DRIVERS_STATUSES_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case GET_DRIVERS_STATUSES_SUCCESS:
            return {
                ...state,
                loading: false,
                statusesList: action.payload,
                error: null,
            };
        case GET_DRIVERS_STATUSES_FAILURE:
            return {
                ...state,
                loading: false,
                statusesList: [],
                error: action.payload,
            };
        case FIND_NOTES_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case FIND_NOTES_SUCCESS:
            return {
                ...state,
                loading: false,
                driverNotes: action.payload,
                error: null,
            };
        case FIND_NOTES_FAILURE:
            return {
                ...state,
                loading: false,
                driverNotes: [],
                error: action.payload,
            };
        case SET_DRIVER_STATUS_REQUEST:
            return {
                ...state,
                isUpdatingDriverStatus: true,
                updateDriverStatusError: null,
            };
        case SET_DRIVER_STATUS_SUCCESS:
            return {
                ...state,
                isUpdatingDriverStatus: false,
                statusesList: [...state.statusesList, action.payload],

                updateDriverStatusError: null,
            };

        case SET_DRIVER_STATUS_FAILURE:
            return {
                ...state,
                isUpdatingDriverStatus: false,
                updateDriverStatusError: action.payload,
            };

        case CREATE_LOAD_REQUEST:
            return {
                ...state,
                loading: true
            };
        case CREATE_LOAD_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null
            };
        case CREATE_LOAD_FAILURE:
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload
            };
        case FETCH_LOAD_STATUSES_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case FETCH_LOAD_STATUSES_SUCCESS:
            return {
                ...state,
                loading: false,
                statuses: action.payload,
                error: '',
            };
        case FETCH_LOAD_STATUSES_FAILURE:
            return {
                ...state,
                loading: false,
                statuses: [],
                error: action.payload,
            };


        case GET_POINT_SUCCESS:
            return {
                ...state,
                loading: false,
                main_content: action.payload,
                error: null
            };
        case GET_POINT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case CREATE_PHOTO_REPORT_REQUEST:
            return {
                ...state,
                loading: true,
                photosSaved: false,
                error: null,
            };

        case CREATE_PHOTO_REPORT_SUCCESS:
            return {
                ...state,
                loading: false,
                photosSaved: true,
                error: null,
            };

        case CREATE_PHOTO_REPORT_FAILURE:
            return {
                ...state,
                loading: false,
                photosSaved: false,
                error: action.payload,
            };
        case APPROVE_POINT_REQUEST:
            return {
                ...state,
                approvingPoint: true,
                error: null,
            };
        case APPROVE_POINT_SUCCESS:
            return {
                ...state,
                approvingPoint: false,
                // Update state as needed with the successful response
                // e.g., You might want to update the status of the point
            };
        case APPROVE_POINT_FAILURE:
            return {
                ...state,
                approvingPoint: false,
                error: action.payload,
            };
        case HIDE_LOAD_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case HIDE_LOAD_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null
            };
        case HIDE_LOAD_FAILURE:
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload
            };
        case UPLOAD_FILE_FOR_LOAD_REQUEST:
            return {
                ...state,
                uploading: true,
                uploadSuccess: false,
                error: null,
            };
        case UPLOAD_FILE_FOR_LOAD_SUCCESS:
            return {
                ...state,
                uploading: false,
                uploadSuccess: true,
                error: null,
            };
        case UPLOAD_FILE_FOR_LOAD_FAILURE:
            return {
                ...state,
                uploading: false,
                uploadSuccess: false,
                error: action.payload,
            };
        case CHANGE_DRIVER_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case CHANGE_DRIVER_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case CHANGE_DRIVER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case FETCH_LOAD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case FETCH_LOAD_SUCCESS:
            return {
                ...state,
                loading: false,
                load: action.payload,
                error: '',
            };
        case FETCH_LOAD_FAILURE:
            return {
                ...state,
                loading: false,
                load: null,
                error: action.payload,
            };
        case UPDATE_LOAD_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case UPDATE_LOAD_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                load: action.payload
            };
        case UPDATE_LOAD_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
            case DELETE_LOAD_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case DELETE_LOAD_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                loads: state.main.main_content.loadsList.filter(load => load.loadId !== action.payload.loadId),
            };
        case DELETE_LOAD_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }


};

export default alldriversReducer;