import React, {useEffect, useState} from 'react';
import {MdOutlineExpandLess, MdOutlineExpandMore, MdKeyboardArrowLeft, MdKeyboardArrowRight} from 'react-icons/md';
import {GoTrash} from 'react-icons/go';
import './MyDriversComponent.scss';
import {useDispatch, useSelector} from "react-redux";
import {getDrivers, deleteDriver} from "../../../../store/actions/DriversProfileActions";
import {useNavigate} from "react-router-dom";
import LoaderComponent from '../../../LoaderComponent/LoaderComponent';

export default function MyDriversComponent({searchTerm}) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const driversData = useSelector(state => state.driver.drivers);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [sortBy, setSortBy] = useState('driverName'); // Default sort column
    const [sortOrder, setSortOrder] = useState('asc');
    const [isLoading, setIsLoading] = useState(true);
    const handleSortClick = (column) => {
        setSortBy(column);
        setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
      };
      const filteredDrivers = driversData
      .filter((driver) =>
        Object.values(driver).some(
          (value) => value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
      )
      .sort((a, b) => {
        const compareValue = a[sortBy] > b[sortBy] ? 1 : a[sortBy] < b[sortBy] ? -1 : 0;
        return sortOrder === 'asc' ? compareValue : -compareValue;
      });
    const handleDeleteClick = (driverId) => {
        const all = false;
        dispatch(deleteDriver(driverId,all));
    };
    const indexOfLastDriver = currentPage * pageSize;
    const indexOfFirstDriver = indexOfLastDriver - pageSize;
    const currentDrivers = filteredDrivers.slice(indexOfFirstDriver, indexOfLastDriver);
    const handlePageSizeChange = (size) => {
        setPageSize(size);
        setCurrentPage(1);
    };
    const totalPages = Math.ceil(filteredDrivers.length / pageSize);
    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
      };
    const paginationOptions = Array.from( 
        {length: Math.min(10, Math.ceil(filteredDrivers.length / 10))},
        (_, index) => (index + 1) * 10
    );
    useEffect(() => {
        const all = false;
        setIsLoading(true); // Встановіть isLoading на true перед початком завантаження
    
        dispatch(getDrivers(all))
            .then(() => {
                setIsLoading(false); // Встановіть isLoading на false після завершення завантаження
            })
            .catch((error) => {
                setIsLoading(false); // Встановіть isLoading на false у разі помилки
                console.error('Error fetching drivers:', error);
            });
    }, [dispatch]);
    


    const handleRowClick = (driverId) => {
        navigate(`/dispatcher/driver/${driverId}`);
    };
    return (
        <div className='table__wrapper__drivers'>
            {isLoading ? (
            <LoaderComponent /> // Лоадер буде показано, якщо isLoading === true
        ) : (
            <div className='table__container__drivers'>
                <table>
                    <thead>
                    <tr>
                        <th onClick={() => handleSortClick('driverName')}>
                            <div className='table__sort__drivers'>
                                Drivers name
                                <div className='table__sort__icons'>
                                    <div className={`table__sort__less ${sortBy === 'driverName' && sortOrder === 'asc' ? 'active' : ''}`}
                                    style={{ opacity: sortBy === 'driverName' && sortOrder === 'asc' ? 0.5 : 1 }}>
                                        <MdOutlineExpandLess/>
                                    </div>
                                    <div className={`table__sort__more ${sortBy === 'driverName' && sortOrder === 'desc' ? 'active' : ''}`}
                                    style={{ opacity: sortBy === 'driverName' && sortOrder === 'desc' ? 0.5 : 1 }}>
                                        <MdOutlineExpandMore/>
                                    </div>
                                </div>
                            </div>
                        </th>
                        <th onClick={() => handleSortClick('registerDate')}>
                            <div className='table__sort__drivers'>
                                Registered date
                                <div className='table__sort__icons'>
                                    <div className={`table__sort__less ${sortBy === 'registerDate' && sortOrder === 'asc' ? 'active' : ''}`}
                                    style={{ opacity: sortBy === 'registerDate' && sortOrder === 'asc' ? 0.5 : 1 }}>
                                        <MdOutlineExpandLess/>
                                    </div>
                                    <div className={`table__sort__more ${sortBy === 'registerDate' && sortOrder === 'desc' ? 'active' : ''}`}
                                    style={{ opacity: sortBy === 'registerDate' && sortOrder === 'desc' ? 0.5 : 1 }}>
                                        <MdOutlineExpandMore/>
                                    </div>
                                </div>
                            </div>
                        </th>
                        <th onClick={() => handleSortClick('rpm')}>
                            <div className='table__sort__drivers'>
                                RPM
                                <div className='table__sort__icons'>
                                    <div className={`table__sort__less ${sortBy === 'rpm' && sortOrder === 'asc' ? 'active' : ''}`}
                                    style={{ opacity: sortBy === 'rpm' && sortOrder === 'asc' ? 0.5 : 1 }}>
                                        <MdOutlineExpandLess/>
                                    </div>
                                    <div className={`table__sort__more ${sortBy === 'rpm' && sortOrder === 'desc' ? 'active' : ''}`}
                                    style={{ opacity: sortBy === 'rpm' && sortOrder === 'desc' ? 0.5 : 1 }}>
                                        <MdOutlineExpandMore/>
                                    </div>
                                </div>
                            </div>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {currentDrivers.map((driver, index) => (
                        <tr key={index} onClick={() => handleRowClick(driver.id)}>
                            <td>{driver.driverName}</td>
                            <td>{driver.registerDate}</td>
                            <td>{driver.rpm}</td>
                            <td className='table__tools__drivers' data-cell='Actions'>
                                <div className='documents__table__body__item actions__document'>
                                    <div
                                        className='drivers__table__body__item actions__drivers'
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleDeleteClick(driver.id);
                                        }}
                                    >
                                        <GoTrash/>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        )}
            {filteredDrivers.length > 0 ? (
            <div className="pagination">
            <select onChange={(e) => handlePageSizeChange(e.target.value)} className='per__page'>
        {paginationOptions.map((option) => (
            <option key={option} value={option}>
                {`${option} per page`}
            </option>
        ))}
    </select>
        <div className="pagination__pages">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            <MdKeyboardArrowLeft />
          </button>
          <span>{currentPage}</span>
          of {totalPages}
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            <MdKeyboardArrowRight />
          </button>
        </div>

      </div>
      ) : (
        <div className="pagination"></div>
      )}
      </div>
    );
};
