import './DropdownOptionsAddComponent.scss';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { addDropdown } from '../../../../store/actions/adminDropdownsActions';
import { FaCheck } from 'react-icons/fa6';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';

export default function DropdownOptionsAddComponent({ dropdownData }) {
    const [selectedType, setSelectedType] = useState('');
    const [selectedDropdownName, setSelectedDropdownName] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [uniqueDropdownNames, setUniqueDropdownNames] = useState([]);
    const [typeError, setTypeError] = useState('');
    const [dropdownNameError, setDropdownNameError] = useState('');
    const [inputError, setInputError] = useState('');
    const [shouldValidateDropdown, setShouldValidateDropdown] = useState(true);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isSelectOpen, setIsSelectOpen] = useState(false);
    const [isDropdownOpen2, setIsDropdownOpen2] = useState(false);
    const [isSelectOpen2, setIsSelectOpen2] = useState(false);
    const dispatch = useDispatch();

    const toggleCustomSelect = () => {
        setIsDropdownOpen(!isDropdownOpen);
        setIsSelectOpen(!isSelectOpen);
    };

    const toggleCustomSelect2 = () => {
        setIsDropdownOpen2(!isDropdownOpen2);
        setIsSelectOpen2(!isSelectOpen2);
    };

    const handleDropdownChange = (event) => {
        setSelectedType(event.target.value);
    };

    const handleDropdownChange2 = (event) => {
        setSelectedDropdownName(event.target.value);
        setDropdownNameError('');
    };

    useEffect(() => {
        if (selectedType && Array.isArray(dropdownData)) {
            const selectedTypeData = dropdownData.find((item) => item?.type === selectedType);
            const names = selectedTypeData?.value?.map((option) => option.dropdownName) || [];
            const uniqueNames = [...new Set(names)]; // Використовуємо Set для унікальних значень
            setUniqueDropdownNames(uniqueNames);
        }
    }, [selectedType, dropdownData]);

    const handleTypeChange = (event) => {
        const selectedType = event.target.value;
        setSelectedType(selectedType);
        setDropdownNameError('');
        setSelectedDropdownName('');

        // Перевірка, чи потрібно валідувати другий випадаючий список
        setShouldValidateDropdown(
            selectedType !== 'driversFilesDropdowns' &&
            selectedType !== 'trailersFilesDropdowns' &&
            selectedType !== 'trucksFilesDropdowns'
        );
    };

    const handleDropdownNameChange = (event) => {
        setSelectedDropdownName(event.target.value);
        setDropdownNameError('');
    };

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
        setInputError('');
    };

    const handleSaveClick = () => {
        let isValid = true;

        // Перевірка на заповнення обов'язкових полів
        if (!selectedType || selectedType === 'Change value') {
            setTypeError('Оберіть тип');
            isValid = false;
        }

        if (shouldValidateDropdown && selectedType !== 'driversFilesDropdowns' && selectedType !== 'trailersFilesDropdowns' && selectedType !== 'trucksFilesDropdowns') {
            if (!selectedDropdownName || selectedDropdownName === 'Change value') {
                setDropdownNameError('Оберіть назву');
                isValid = false;
            }
        }

        if (!inputValue) {
            setInputError('Введіть значення');
            isValid = false;
        }

        // Збереження в БД, якщо дані валідні
        if (isValid) {
            const newDropdown = {
                type: selectedType,
                dropdownName: shouldValidateDropdown ? selectedDropdownName : '',
                value: inputValue,
            };
            dispatch(addDropdown(newDropdown));
            setSelectedType('');
            setSelectedDropdownName('');
            setInputValue('');
            setTypeError('');
            setDropdownNameError('');
            setInputError('');
        }




};

    return (
        <div className="DropDownOptionsAddBD">
            <div className="AddValuesDropGroup">
                <label>
                    {typeError && <span className="error">{typeError}</span>}
                    Type
                </label>
                <div className={`custom-select admin ${isDropdownOpen ? 'active' : ''}`} onClick={toggleCustomSelect}>
                    <div className="selected-options">
                        {selectedType ? (
                            <div className="selected-option">
                                {selectedType}
                                {isSelectOpen ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
                            </div>
                        ) : (
                            <div className="selected-option">All categories{isSelectOpen ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}</div>
                        )}
                    </div>
                    <div className="select-icon"></div>
                    <div className="options-list admin">
                        {dropdownData?.map((item, index) => (
                            <div
                                key={index}
                                className={`option-item ${item.type === selectedType ? 'active' : ''}`}
                                onClick={() => handleDropdownChange({ target: { value: item.type } })}
                            >
                                <label>{item.type}</label>
                                <div className="checkbox">{item.type === selectedType && <FaCheck />}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {selectedType !== 'trucksFilesDropdowns' && selectedType !== 'trailersFilesDropdowns' && (
                <div className="AddValuesDropGroup">
                    <label>
                        {dropdownNameError && <span className="error">{dropdownNameError}</span>}
                        Value
                    </label>
                    <div className={`custom-select admin ${isDropdownOpen2 ? 'active' : ''}`} onClick={toggleCustomSelect2}>
                        <div className="selected-options">
                            {selectedType ? (
                                <div className="selected-option">
                                    {selectedDropdownName}
                                    {isSelectOpen2 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
                                </div>
                            ) : (
                                <div className="selected-option">All categories{isSelectOpen2 ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}</div>
                            )}
                        </div>
                        <div className="select-icon"></div>
                        <div className="options-list admin">
                            {uniqueDropdownNames?.map((name, index) => (
                                <div
                                    key={index}
                                    className={`option-item ${name === selectedDropdownName ? 'active' : ''}`}
                                    onClick={() => handleDropdownChange2({ target: { value: name } })}
                                >
                                    <label>{name}</label>
                                    <div className="checkbox">{name === selectedDropdownName && <FaCheck />}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
            <div className="AddValuesDropGroup">
                <label>
                    {inputError && <span className="error">{inputError}</span>}
                    Input value
                </label>
                <input className="inputValueType" type="text" value={inputValue} onChange={handleInputChange} />
            </div>
            <div className="BtnAdd" onClick={handleSaveClick}>
                Save
            </div>
        </div>
    );
}
