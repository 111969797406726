import React, { useState } from 'react';
import './EditPassword.scss';
import { GoPencil } from 'react-icons/go';
import EditPasswordModal from "../EditInfo/EditPasswordModal/EditPasswordModal";

export default function EditPasswordProfile() {
    const [showModal, setShowModal] = useState(false);

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleEditClick = () => {
        setShowModal(true);
    };

    return (
        <>
            {showModal && <EditPasswordModal onClose={handleCloseModal} />}

            <div className="header__edit__password">
                <div className="title__edit__password">Security</div>
                <div className="icon__edit__password" onClick={handleEditClick}>
                    <GoPencil />
                </div>
            </div>
            <div className="form__group__edit__password">
                <label htmlFor="password">Password</label>
                <input type="password" id="password" name="password" value="********" readOnly />
            </div>
        </>
    );
}
