import './MainComponent.scss';
import RouteComponent from "./RouteComponent/RouteComponent";
import StatusNoteComponent from "./StatusNoteComponent/StatusNoteComponent";
import PhotoNotificationComponent from "./PhotoNotificationComponent/PhotoNotificationComponent";

export default function MainComponent() {
    return (
        <>
            <div className="main__driver__container">
                <RouteComponent/>
                <PhotoNotificationComponent/>
                <StatusNoteComponent/>
            </div>
        </>
    )
}