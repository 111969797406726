import {createStore, applyMiddleware, combineReducers} from 'redux';
import {thunk} from 'redux-thunk';

import authReducer from './reducers/authReducer';
import settingsReducer from './reducers/settingsReducer';
import customersReducer from "./reducers/customersReducer";
import trackReducer from "./reducers/trackReducer";
import trailerReducer from "./reducers/trailerReducer";
import driverReducer from "./reducers/DriversProfileReducer";
import accidentsReducer from "./reducers/helpReducer";
import alldriversReducer from './reducers/MainReducer';
import analyticsReducer from "./reducers/analyticsReducer";
import forgotPasswordReducer from './reducers/forgotPasswordReducer';
import notificationReducer from "./reducers/notificationReducer";
import adminAddDispatcherReducer from "./reducers/AdminAddDispatcherReducer";
import dropdownReducer from "./reducers/adminDropdownsReducer";
import adminGetDispatchersReducer from "./reducers/adminGetDispatchersReducer";
import adminEditUserReducer from "./reducers/adminEditUserReducer";



const rootReducer = combineReducers({
    auth: authReducer,
    settings: settingsReducer,
    customers: customersReducer,
    track: trackReducer,
    trailer: trailerReducer,
    driver : driverReducer,
    accidents : accidentsReducer, 
    main : alldriversReducer,
    analytics : analyticsReducer,
    forgotPassword: forgotPasswordReducer,
    adminAddDispatcher: adminAddDispatcherReducer,
    notification: notificationReducer,
    dropdowns: dropdownReducer,
    adminGetDispatchers: adminGetDispatchersReducer,
    adminEditUser: adminEditUserReducer,

    // other reducers
});

const store = createStore(rootReducer, applyMiddleware(thunk));
 
export default store;
