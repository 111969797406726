import './TrailersComponent.scss';
import {BsThreeDotsVertical} from "react-icons/bs";
import {useDispatch, useSelector} from 'react-redux';
import {fetchTrailers} from "../../../../store/actions/trailerActions";
import {useNavigate} from 'react-router-dom';
import React, {useRef, useState, useEffect} from 'react';
import {MdKeyboardArrowLeft} from "react-icons/md";
import {MdKeyboardArrowRight} from "react-icons/md";
import LoaderComponent from '../../../LoaderComponent/LoaderComponent';
import noImages from "../../../../assets/img/noImaget.svg";
import {GoAlert} from "react-icons/go";
import {deleteTrailer} from "../../../../store/actions/trailerActions";
import NewTrailer from './NewTrailersComponentModal/NewTrailersComponentModal';
import {IoCloseOutline} from 'react-icons/io5';

export default function TrailersComponent({searchTerm}) {
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const dispatch = useDispatch();

    const trailers = useSelector((state) => state.trailer?.trailers);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [selectedTrailerId, setSelectedTrailerId] = useState(null);
    const [isEditModalContentVisible, setEditModalContentVisibility] = useState(false);

    const toggleMenu = (trailerId) => {
        setIsMenuOpen(!isMenuOpen);
        setSelectedTrailerId(trailerId);
    };

    const handleDocumentClick = (trailerId) => {
        navigate(`/dispatcher/vehicle/trailer/${trailerId}`);
    };

    const handleEditClick = (trailerId) => {
        setIsEditModalOpen(true);
        setSelectedTrailerId(trailerId);
        setEditModalContentVisibility(true);
    };

    const handleDeleteClick = (trailerId) => {
        dispatch(deleteTrailer(trailerId));
    };

    const handleMenuClick = (event, trailerId) => {
        event.stopPropagation();
        toggleMenu(trailerId);
        setModalVisibility(!isModalVisible);

    };

    const includesTerm = (value) => {
        return value !== undefined && value.toLowerCase().includes(searchTerm.toLowerCase());
    };

    const filteredTrailers = trailers.filter((trailer) => {
        const main = trailer.main || {};

        return (
            includesTerm(trailer.comment) ||
            includesTerm(main.vehicleId?.toString()) ||
            includesTerm(main.annualInspection?.toString()) ||
            includesTerm(main.insurance) ||
            includesTerm(main.ventilation) ||
            includesTerm(main.driver) ||
            includesTerm(main.licensePlate) ||
            includesTerm(main.suspension) ||
            includesTerm(main.number?.toString()) ||
            includesTerm(main.structure) ||
            includesTerm(main.truck)
        );
    });


    useEffect(() => {
        dispatch(fetchTrailers());
    }, [dispatch]);

    const handleTrailerClick = (trailerId) => {
        navigate(`/dispatcher/vehicle/trailer/${trailerId}`);
    };

    const handlePageSizeChange = (size) => {
        setPageSize(size);
        setCurrentPage(1);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };
    const indexOfLastItem = currentPage * pageSize;
    const indexOfFirstItem = indexOfLastItem - pageSize;
    const currentItems = filteredTrailers.slice(indexOfFirstItem, indexOfLastItem);

    // Dynamically generate pagination options based on the length of filteredTrucks
    const paginationOptions = Array.from(
        {length: Math.min(10, Math.ceil(filteredTrailers.length / 10))}, // Show options up to a maximum of 10
        (_, index) => (index + 1) * 10
    );
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);

    useEffect(() => {
        dispatch(fetchTrailers())
            .then(() => setIsLoading(false))
            .catch((error) => {
                setIsLoading(false);
            });
    }, [dispatch]);

    const [isModalVisible, setModalVisibility] = useState(false);
    // const [clickedInsideModal, setClickedInsideModal] = useState(false);

    const modalRef = useRef(null);

    useEffect(() => {
        const handleDocumentClick = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setModalVisibility(false);
                setEditModalContentVisibility(false);
                // setClickedInsideModal(false);
            } else {
                // setClickedInsideModal(true);
            }
        };

        document.addEventListener('click', handleDocumentClick);

        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, []);
    return (
        <div className="trailers__table">
            {isLoading ? (
                <LoaderComponent/>
            ) : (
                <div className="trailers__table__body">
                    {currentItems.map((trailer, index) => (
                        <div className="trailers__table__row" key={index}
                             onClick={() => handleTrailerClick(trailer.id)}>
                            <div className='trailer__img__description'>
                                <div className="trailers__table__body__item__img">

                                    <img src={trailer.photo.url ? `//${trailer.photo.url}` : noImages}
                                         alt={`Truck ${trailer.id}`}/>

                                </div>
                                <div className='name__detailed__description'>
                                    <div className='name__description'>
                                        <div className='trailer__name__dots'>
                                            <div className='trailer__name__year'>
                                                <div className="trailers__table__body__item name">
                                                {trailer.main.number}
                                                </div>
                                                {/* <div className="one__trailer__table__body__item name year">
                                                    {trailer.title.year}
                                                </div> */}
                                            </div>
                                            <div className="trailers__table__body__item">
                                                {trailer.title.comment}
                                            </div>
                                            <div className='alert'>
                                                {trailer.title.filesMessages.map((fileMessage, index) => (
                                                    <div key={index} style={{color: fileMessage.color}}>
                                                        <GoAlert/> {fileMessage.message}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="vvvv" onClick={(event) => handleMenuClick(event, trailer.id)}>
                                            <BsThreeDotsVertical/>
                                            {selectedTrailerId === trailer.id && (


                                                <div className="modal__edit__trailer" ref={modalRef}>
                                                    <div
                                                        className="modal-content__edit__trailer"
                                                        style={{
                                                            display: isModalVisible && !isEditModalOpen ? 'block' : 'none',
                                                        }}
                                                    >
                                                        <div className="d__menu__item__trailer"
                                                             onClick={handleDocumentClick}
                                                             style={{
                                                                 display: isModalVisible ? 'block' : (isEditModalContentVisible ? 'none' : 'block')
                                                             }}
                                                        >


                                                        </div>
                                                        <div className="d__menu__item__trailer"
                                                             onClick={() => handleEditClick(trailer.id)}>
                                                            Edit
                                                        </div>

                                                        <div className="d__menu__item__trailer"
                                                             onClick={() => handleDeleteClick(trailer.id)}>
                                                            Delete
                                                        </div>

                                                    </div>
                                                    {isEditModalOpen && (
                                                        <div className={`modal__add__trailer`} ref={modalRef}>
                                                            <div className={`modal-content__add__trailer`}>
                                                                <div className={`heading__modal__trailer`}>
                                                                    <div className={`title__modal__trailer`}>
                                                                        {`Add trailer`}
                                                                    </div>
                                                                    <div className={`close__modal__traileeer`}
                                                                         onClick={() => {
                                                                             setIsEditModalOpen(false);
                                                                             setEditModalContentVisibility(false);
                                                                         }}>
                                                                        <IoCloseOutline/>
                                                                    </div>
                                                                </div>

                                                                <NewTrailer
                                                                    trailerId={selectedTrailerId}
                                                                    trailer={trailer}
                                                                    onClose={() => {
                                                                        setIsEditModalOpen(false);
                                                                        setEditModalContentVisibility(false);

                                                                    }}
                                                                    onSuccess={() => {
                                                                        fetchTrailers();
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>

                                                    )}

                                                </div>
                                            )}

                                        </div>
                                    </div>
                                    <div className='ID__license'>
                                        <div className="model__year">
                                            <div className="trailers__table__body__item upper">
                                                <span>Model</span>
                                                {trailer.title.model}
                                            </div>
                                            <div className="trailers__table__body__item lower">
                                                <span>Year</span>
                                                {trailer.title.year}
                                            </div>
                                            </div>
                                        <div className='detailed__description'>
                                            <div className="trailers__table__body__item upper">
                                                <span>Vehicle ID</span>
                                                {trailer.main.vehicleId}
                                            </div>
                                            <div className="trailers__table__body__item lower">
                                                <span>License plate</span>
                                                {trailer.main.licensePlate}
                                            </div>
                                        </div>
                                        <div className='VIN__Unit'>
                                            <div className="trailers__table__body__item upper">
                                                <span>Insurance</span>
                                                {trailer.main.insurance}
                                            </div>
                                            <div className="trailers__table__body__item lower">
                                                <span>Suspension </span>
                                                {trailer.main.suspension}
                                            </div>
                                        </div>
                                        <div className='insurance__sleeper'>
                                            {/* <div className="trailers__table__body__item upper lower">
                                                <span>Number</span>
                                                {trailer.main.number}
                                            </div> */}
                                            <div className="trailers__table__body__item upper">

                                                <span>Annual Maintenance</span>
                                                {trailer.main.annualInspection}
                                            </div>
                                            <div className="trailers__table__body__item lower">
                                                <span>Structure</span>
                                                {trailer.main.structure}
                                            </div>
                                        </div>

                                        <div className='state__number'>
                                            <div className="trailers__table__body__item upper lower">
                                                <span>Ventilation</span>
                                                {trailer.main.ventilation}
                                            </div>
                                            <div className="trailers__table__body__item lower">
                                                <span>Truck</span>
                                                {trailer.main.truck || 'none'}
                                            </div>
                                        </div>
                                        {/* <div className='DPF__mileage'>

                                            
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {filteredTrailers.length > 0 ? ( // Перевірка наявності елементів
                        <div className="pagination">
                            <select onChange={(e) => handlePageSizeChange(e.target.value)} className='per__page'>
                                {paginationOptions.map((option) => (
                                    <option key={option} value={option}>
                                        {`${option} per page`}
                                    </option>
                                ))}
                            </select>


                            <div className="pagination__pages">
                                <button
                                    onClick={() => handlePageChange(currentPage - 1)}
                                    disabled={currentPage === 1} // Disable the button if it's the first page
                                >
                                    <MdKeyboardArrowLeft/>
                                </button>
                                <span>{currentPage}</span>
                                of {Math.ceil(filteredTrailers.length / pageSize)}
                                <button
                                    onClick={() => handlePageChange(currentPage + 1)}
                                    disabled={currentPage === Math.ceil(filteredTrailers.length / pageSize)} // Disable the button if it's the last page
                                >
                                    <MdKeyboardArrowRight/>
                                </button>
                            </div>

                        </div>
                    ) : (
                        <div className="pagination">

                        </div>
                    )}


                </div>
            )}
        </div>

    )
}