import axios from 'axios';
import {logout} from "./authActions";

export const FETCH_DROPDOWNS_REQUEST = 'FETCH_DROPDOWNS_REQUEST';
export const FETCH_DROPDOWNS_SUCCESS = 'FETCH_DROPDOWNS_SUCCESS';
export const FETCH_DROPDOWNS_FAILURE = 'FETCH_DROPDOWNS_FAILURE';

export const CREATE_ACCIDENT_REPORT_REQUEST = 'CREATE_ACCIDENT_REPORT_REQUEST';
export const CREATE_ACCIDENT_REPORT_SUCCESS = 'CREATE_ACCIDENT_REPORT_SUCCESS';
export const CREATE_ACCIDENT_REPORT_FAILURE = 'CREATE_ACCIDENT_REPORT_FAILURE';

export const FETCH_ALL_REPORTS_REQUEST = 'FETCH_ALL_REPORTS_REQUEST';
export const FETCH_ALL_REPORTS_SUCCESS = 'FETCH_ALL_REPORTS_SUCCESS';
export const FETCH_ALL_REPORTS_FAILURE = 'FETCH_ALL_REPORTS_FAILURE';

export const CHANGE_CURRENT_STATUS_REQUEST = 'CHANGE_CURRENT_STATUS_REQUEST';
export const CHANGE_CURRENT_STATUS_SUCCESS = 'CHANGE_CURRENT_STATUS_SUCCESS';
export const CHANGE_CURRENT_STATUS_FAILURE = 'CHANGE_CURRENT_STATUS_FAILURE';

export const changeCurrentStatusRequest = () => ({ type: CHANGE_CURRENT_STATUS_REQUEST });
export const changeCurrentStatusSuccess = () => ({ type: CHANGE_CURRENT_STATUS_SUCCESS });
export const changeCurrentStatusFailure = (error) => ({ type: CHANGE_CURRENT_STATUS_FAILURE, payload: error });

export const DELETE_REPORT_REQUEST = 'DELETE_REPORT_REQUEST';
export const DELETE_REPORT_SUCCESS = 'DELETE_REPORT_SUCCESS';
export const DELETE_REPORT_FAILURE = 'DELETE_REPORT_FAILURE'; 

export const deleteReportRequest = () => ({type: DELETE_REPORT_REQUEST});
export const deleteReportSuccess = (reportId) => ({type: DELETE_REPORT_SUCCESS, payload: {reportId}});
export const deleteReportFailure = (error) => ({type: DELETE_REPORT_FAILURE, payload: error});

export const fetchDropdowns = () => {
    return (dispatch) => {
        dispatch({ type: FETCH_DROPDOWNS_REQUEST });

        axios.get(`${process.env.REACT_APP_FETCH_DROPDOWNS_API_URL}`, {
            withCredentials: true,
        })
            .then(response => {
                dispatch({
                    type: FETCH_DROPDOWNS_SUCCESS,
                    payload: response.data,
                });
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    dispatch(logout());
                } else {
                    dispatch({
                        type: FETCH_DROPDOWNS_FAILURE,
                        payload: error.message,
                    });
                }
            });
    };
};

export const createAccidentReport = (formData) => {
    return (dispatch) => {
        dispatch({ type: CREATE_ACCIDENT_REPORT_REQUEST });

        return axios.post(`${process.env.REACT_APP_CREATE_ACCIDENT_REPORT_API_URL}`, formData, {
            withCredentials: true,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
            .then(response => {
                dispatch({
                    type: CREATE_ACCIDENT_REPORT_SUCCESS,
                    payload: response.data,
                });
                return response;
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    dispatch(logout());
                } else {
                    dispatch({
                        type: CREATE_ACCIDENT_REPORT_FAILURE,
                        payload: error.message,
                    });
                }
                throw error;
            });
    };
};



// Action для отримання всіх звітів
export const fetchAllReports = () => {
    return async (dispatch) => {
        dispatch({ type: FETCH_ALL_REPORTS_REQUEST });

        try {
            const response = await axios.get(`${process.env.REACT_APP_FETCH_ALL_REPORTS_API_URL}`, {
                withCredentials: true,
            });

            dispatch({
                type: FETCH_ALL_REPORTS_SUCCESS,
                payload: response.data,
            });
        } catch (error) {
            if (error.response && error.response.status === 401) {
                dispatch(logout());
            } else {
                dispatch({
                    type: FETCH_ALL_REPORTS_FAILURE,
                    payload: error.message,
                });
            }
        }
    };
};

export const changeCurrentStatus = ({ reportId, statusId }) => {
    return async (dispatch) => {
        dispatch(changeCurrentStatusRequest());

        try {
            await axios.post(
                `${process.env.REACT_APP_CHANGE_CURRENT_STATUS_API_URL}`,
                { reportId, statusId },
                {
                    withCredentials: true,
                }
            );
            dispatch(fetchAllReports());
            dispatch(changeCurrentStatusSuccess());
        } catch (error) {
            if (error.response && error.response.status === 401) {
                dispatch(logout());
            } else {
                dispatch(changeCurrentStatusFailure(error.message));
            }
        }
    };
};

export const deleteReport = (reportId) => {
    return async (dispatch) => {
        dispatch(deleteReportRequest());

        try {
            await axios.delete(`${process.env.REACT_APP_DELETE_REPORT_API_URL}/${reportId}`, {
                withCredentials: true,
            });
            dispatch(fetchAllReports());
            dispatch(deleteReportSuccess(reportId));
        } catch (error) {
            if (error.response && error.response.status === 401) {
                dispatch(logout());
            } else {
                dispatch(deleteReportFailure(error.message));
            }
        }
    };
};
