import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import './NewTrailerComponentModal.scss';
import {createTrailerAction, fetchDropdowns, fetchTrailers} from '../../../../../store/actions/trailerActions';
import {FaCheck} from "react-icons/fa6";
import {MdKeyboardArrowDown} from "react-icons/md";
import {MdKeyboardArrowUp} from "react-icons/md";
import {updateTrailer} from '../../../../../store/actions/trailerActions';
import {CiCircleAlert} from "react-icons/ci";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import LoaderComponent from '../../../../LoaderComponent/LoaderComponent';

export default function NewTrailer({onClose, onCancel, onSuccess, trailer, trailerId}) {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    const dropdowns = useSelector((state) => state.trailer.dropdowns);
    const dropdows = useSelector((state) => state.trailer);
    console.log(dropdows)
    const [isInsuranceDropdownOpen, setIsInsuranceDropdownOpen] = useState(false);
    const [isSuspensionDropdownOpen, setIsSuspensionDropdownOpen] = useState(false);
    const [isStructureDropdownOpen, setIsStructureDropdownOpen] = useState(false);
    const [isVentilationDropdownOpen, setIsVentilationDropdownOpen] = useState(false);
    const [isTruckDropdownOpen, setIsTruckDropdownOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [selectedInsuranceOption, setSelectedInsuranceOption] = useState('');
    const [selectedSuspensionOption, setSelectedSuspensionOption] = useState('');
    const [selectedStructureOption, setSelectedStructureOption] = useState('');
    const [selectedVentilationOption, setSelectedVentilationOption] = useState('');
    const [selectedTruckOption, setSelectedTruckOption] = useState('');
    console.log(selectedTruckOption)
    const [errors, setErrors] = useState({});
    const [annualInspection, setAnnualInspection] = useState(trailer?.main?.annualInspection || null)
    console.log(annualInspection)
    const scrollToTop = () => {
        const modalContainer = document.querySelector('.form__modal__trailer__add');
        if (modalContainer) {
            modalContainer.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }
    };
    const toggleCustomSelect = (dropdown) => {
        switch (dropdown) {
            case 'insurance':
                setIsInsuranceDropdownOpen(prevState => !prevState);
                break;
            case 'suspension':
                setIsSuspensionDropdownOpen(prevState => !prevState);
                break;
            case 'structure':
                setIsStructureDropdownOpen(prevState => !prevState);
                break;
            case 'ventilation':
                setIsVentilationDropdownOpen(prevState => !prevState);
                break;
            case 'truck':
                setIsTruckDropdownOpen(prevState => !prevState);
                break;
            default:
                break;
        }
    };

    useEffect(() => {

        dispatch(fetchDropdowns());
    }, [dispatch]);

    const [formData, setFormData] = useState({
        model: '',
        year: '',
        insurance: '',
        suspension: '',
        structure: '',
        annualInspection: '',
        comment: '',
        vehicleId: '',
        licensePlate: '',
        ventilation: '',
        number: '',
        truck: '',
        truckId: '',
    });
    const dropdownFields = [
        "insurance",
        "suspension",
        "structure",
        "ventilation",
        "truck"

    ];
    useEffect(() => {
        if (trailer) {
            setFormData(trailer);
        }
    }, [trailer]);
    useEffect(() => {
        const fetchData = async () => {
            if (trailer) {
                const selectedInsurance = dropdowns?.insurance?.find(item => item.value === trailer?.main?.insurance);
                if (selectedInsurance) {
                    setSelectedInsuranceOption(selectedInsurance.id);
                }

                const selectedSuspension = dropdowns?.suspension?.find(item => item.value === trailer?.main?.suspension);
                if (selectedSuspension) {
                    setSelectedSuspensionOption(selectedSuspension.id);
                }

                const selectedStructure = dropdowns?.structure?.find(item => item.value === trailer?.main?.structure);
                if (selectedStructure) {
                    setSelectedStructureOption(selectedStructure.id);
                }

                const selectedVentilation = dropdowns?.ventilation?.find(item => item.value === trailer?.main?.ventilation);
                if (selectedVentilation) {
                    setSelectedVentilationOption(selectedVentilation.id);
                }

                const selectedTruck = dropdowns?.truck?.find(item => item.value === trailer?.main?.truck);
                if (selectedTruck) {
                    setSelectedTruckOption(selectedTruck.id);
                }

            }
        };


        fetchData();
    }, [trailer, dropdowns]);
    useEffect(() => {
        if (dropdowns) {
            if (trailer) {
                const InsuranceMatch = dropdowns?.insurance?.find(item => item.value === trailer?.main?.insurance);
                const formInsurance = (InsuranceMatch?.id);
                const SuspensionMatch = dropdowns?.suspension?.find(item => item.value === trailer?.main?.suspension);
                const formSuspension = (SuspensionMatch?.id);
                const StructureMatch = dropdowns?.structure?.find(item => item.value === trailer?.main?.structure);
                const formStructure = (StructureMatch?.id);
                const VentilationMatch = dropdowns?.ventilation?.find(item => item.value === trailer?.main?.ventilation);
                const formVentilation = (VentilationMatch?.id);
                const TruckMatch = dropdowns?.truck?.find(item => item.value === trailer?.main?.truck);
                const formTruck = (TruckMatch?.id);
                
        
                setFormData({
                    id: trailer.id,
                    model: trailer.title?.model,
                    dropdowns: {
                        insurance: formInsurance,
                        suspension: formSuspension,
                        structure: formStructure,
                        ventilation: formVentilation,
                    },
                    annualInspection: trailer.annualInspection || '',
                    comment: trailer.title?.comment || '',
                    year: parseInt(trailer.title?.year, 10) || '',
                    vehicleId: trailer.main?.vehicleId || '',
                    licensePlate: trailer.main?.licensePlate || '',
                    number: trailer.main?.number || '',
                    truckId: parseInt(formTruck, 10) || '',
                });
            }
        }
    }, [annualInspection, dropdowns, trailer]);

    const handleDateChange = (date) => {
        // Оновлюємо дату при зміні вибраної дати
        setAnnualInspection(date);

        // Оновлюємо внутрішній стан formData
        setFormData((prevData) => ({
            ...prevData,
            annualInspection: date ? date.toISOString().split('T')[0] : '', // Якщо дата не вибрана, встановлюємо порожній рядок
        }));
    };
    useEffect(() => {
        // Конвертуємо дату з формату строки до об'єкту Date
        if (trailer?.main?.annualInspection) {
            setAnnualInspection(new Date(trailer.main.annualInspection));
        }
    }, [trailer]);

    const handleInputChange = (e) => {

        const {name, value} = e.target;
        console.log(name, value);
        if (dropdownFields.includes(name)) {
            switch (name) {
                case 'insurance':
                    setSelectedInsuranceOption(value);
                    setFormData((prevData) => ({
                        ...prevData,
                        [name]: value,
                    }));
                    if (trailer) {
                        setFormData((prevData) => ({
                            ...prevData,
                            dropdowns: {
                                ...prevData.dropdowns,
                                [name]: value,
                            }

                        }));
                    }
                    break;
                case 'suspension':
                    setSelectedSuspensionOption(value);
                    setFormData((prevData) => ({
                        ...prevData,
                        [name]: value,
                    }));
                    if (trailer) {
                        setFormData((prevData) => ({
                            ...prevData,
                            dropdowns: {
                                ...prevData.dropdowns,
                                [name]: value,
                            }

                        }));
                    }
                    break;
                case 'structure':
                    setSelectedStructureOption(value);
                    setFormData((prevData) => ({
                        ...prevData,
                        [name]: value,
                    }));
                    if (trailer) {
                        setFormData((prevData) => ({
                            ...prevData,
                            dropdowns: {
                                ...prevData.dropdowns,
                                [name]: value,
                            }

                        }));
                    }
                    break;
                case 'ventilation':
                    setSelectedVentilationOption(value);
                    setFormData((prevData) => ({
                        ...prevData,
                        [name]: value,
                    }));
                    if (trailer) {
                        setFormData((prevData) => ({
                            ...prevData,
                            dropdowns: {
                                ...prevData.dropdowns,
                                [name]: value,
                            }

                        }));
                    }
                    break;
                case 'truck':
                    setSelectedTruckOption(value);
                    setFormData((prevData) => ({
                        ...prevData,
                        truckId: value,
                    }));

                    break;

                default:
                    break;
            }
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));

        }

        // Обновите остальные поля данных формы
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        // Удалите сообщение об ошибке при начале ввод
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));
    };
    const handleChange = (event) => {
        setFormData({...formData, [event.target.name]: event.target.value});
        setErrors((prevErrors) => ({
            ...prevErrors,
            [event.target.name]: '',
        }));
    };

    const handleSelectChange = (event) => {
        const dropdownName = event.target.name;
        const selectedValue = event.target.value;
        setFormData({
            ...formData,
            [dropdownName]: selectedValue,
        });
    };
    const formatDateTime = (date) => {
        const formattedDate = date ? `${date.getUTCFullYear()}-${(date.getUTCMonth() + 1).toString().padStart(2, '0')}-${date.getUTCDate().toString().padStart(2, '0')}` : '';
        console.log(formattedDate);
        return formattedDate;
    };

    const handleSubmit = async (event) => {
        console.log(formData)
        event.preventDefault();

        if (validateForm()) {
            try {

                if (trailer) {
                    
                    const formDataCopy = {...formData};

                    // Заберіть коментар, якщо він порожній або null
                    if (!formDataCopy.comment) {
                        delete formDataCopy.comment;
                    }
                    if (annualInspection) {
                        // Конвертація дати з європейського часового поясу (якщо вона є) до американського часового поясу
                        const adjustedDate = new Date(annualInspection.getTime() - (annualInspection.getTimezoneOffset() * 60000));
                        console.log("adjustedDate", adjustedDate);
                        // Встановіть дату для американського часового поясу
                        formDataCopy.annualInspection = adjustedDate.toISOString().split('T')[0];
                    }
            
                    if (validateForm()) {
                        setIsLoading(true);
                        await dispatch(updateTrailer(trailerId, formDataCopy));
                        await dispatch(fetchTrailers());
                        onSuccess();
                        onClose();
                    } else {
                        // Scroll to the top if there are form errors
                        scrollToTop();
                    }

                } else {
                    
                    const transformedFormData = {

                        model: formData.model,
                        year: parseInt(formData.year, 10) || null,
                        vehicleId: formData.vehicleId,
                        licensePlate: formData.licensePlate,
                        annualInspection: formData.annualInspection,
                        number: formData.number,
                        comment: formData.comment,
                        truckId: dropdowns.truck.find((item) => item.value === formData.truck)?.id || null,
                        dropdowns: {
                            insurance: formData.insurance,
                            suspension: formData.suspension,
                            structure: formData.structure,
                            ventilation: formData.ventilation,
                        },
                    };
                    if (annualInspection) {
                        // Конвертація дати з європейського часового поясу (якщо вона є) до американського часового поясу
                        const adjustedDate = new Date(annualInspection.getTime() - (annualInspection.getTimezoneOffset() * 60000));
                        console.log("adjustedDate", adjustedDate);
                        // Встановіть дату для американського часового поясу
                        transformedFormData.annualInspection = adjustedDate.toISOString().split('T')[0];
                    }
                    console.log(transformedFormData);
                    setIsLoading(true);
                    await dispatch(createTrailerAction(transformedFormData));
                    await dispatch(fetchTrailers());
                    onClose();
                }
            } catch (error) {
                console.error('Error creating trailer:', error);
            }
        } else {
            scrollToTop();
        }
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.model) {
            newErrors.model = 'Model is required';
        } else if (formData.model.length > 30) {
            newErrors.model = 'must be less than 30 characters';
        }

        if (!formData.year) {
            newErrors.year = 'Year is required';
        } else if (isNaN(formData.year)) {
            newErrors.year = 'must be a valid integer';
        } else if (formData.year.toString().length !== 4) {
            newErrors.year = 'must be a four-digit number';
        }

        if (!formData.licensePlate) {
            newErrors.licensePlate = 'License Plate is required';
        } else if (formData.licensePlate.length > 30) {
            newErrors.licensePlate = 'must be less than 30 characters';
        }
// if (formData.annualInspection) {
//     newErrors.annualInspection = 'This field is required';
// }
        if (!formData.number) {
            newErrors.number = 'Number is required';
        } else if (formData.number.length > 30) {
            newErrors.number = 'Number must be less than 30 characters';
        }

        if (trailer) {
            if (!formData?.dropdowns?.insurance) {
                newErrors.insurance = 'This field is required';
            }
            if (!formData?.dropdowns?.suspension) {
                newErrors.suspension = 'This field is required';
            }
            if (!formData?.dropdowns?.structure) {
                newErrors.structure = 'This field is required';
            }
            if (!formData?.dropdowns?.ventilation) {
                newErrors.ventilation = 'This field is required';
            }
        } else {
            if (!formData?.insurance) {
                newErrors.insurance = 'This field is required';
            }
            if (!formData?.suspension) {
                newErrors.suspension = 'This field is required';
            }
            if (!formData?.structure) {
                newErrors.structure = 'This field is required';
            }
            if (!formData?.ventilation) {
                newErrors.ventilation = 'This field is required';
            }
        }


        if (!formData.vehicleId) {
            newErrors.vehicleId = 'This field is required';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    console.log(errors)

    return (
        <div className="add_trailers">
            <div className="form__modal__trailer__add">
                <form onSubmit={handleSubmit}>
                    <div className='left__right__columns'>
                        <div className='left__column'>
                            <div className="form__group__add__trailer">
                                <label>Model{errors.model &&
                                    <p className="error-message">{errors.model}<CiCircleAlert/></p>}</label>
                                <input
                                    type="text"
                                    name="model"
                                    value={formData.model}
                                    onChange={handleChange}
                                    placeholder="Enter trailer model"

                                />
                            </div>

                            <div className="form__group__add__trailer">
                                <label>Insurance{errors.insurance &&
                                    <p className="error-message">{errors.insurance}<CiCircleAlert/></p>}</label>
                                <div
                                    className={` vehicle__select custom-select ${isInsuranceDropdownOpen ? 'active' : ''}`}
                                    onClick={() => toggleCustomSelect('insurance')}>
                                    <div className="selected-options">
                                        <div
                                            className="selected-option">{selectedInsuranceOption ? dropdowns.insurance && dropdowns.insurance.find((item) => item.id === selectedInsuranceOption)?.value : 'Not selected'}</div>
                                        <div className="selected-option vehicle__arrow">{isDropdownOpen ?
                                            <MdKeyboardArrowUp/> : <MdKeyboardArrowDown/>}</div>
                                    </div>
                                    <div className="select-icon">
                                    </div>
                                    <div className="options-list  vehicle__list">
                                        {dropdowns.insurance && dropdowns.insurance.map((item) => (
                                            <div key={item.id}
                                                 className={`option-item ${item.id === selectedInsuranceOption ? 'active' : ''}`}
                                                 onClick={(e) => handleInputChange({
                                                     target: {
                                                         value: item.id,
                                                         name: 'insurance'
                                                     }
                                                 })}>
                                                <label>
                                                    {item.value}
                                                </label>
                                                <div className="checkbox">
                                                    {item.id === selectedInsuranceOption && <FaCheck/>}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                            </div>
                            <div className="form__group__add__trailer">
                                <label>Suspension {errors.suspension &&
                                    <p className="error-message">{errors.suspension}<CiCircleAlert/></p>}</label>
                                <div
                                    className={` vehicle__select custom-select ${isSuspensionDropdownOpen ? 'active' : ''}`}
                                    onClick={() => toggleCustomSelect('suspension')}>
                                    <div className="selected-options">
                                        <div
                                            className="selected-option">{selectedSuspensionOption ? dropdowns.suspension && dropdowns.suspension.find((item) => item.id === selectedSuspensionOption)?.value : 'Not selected'}</div>
                                        <div className="selected-option vehicle__arrow">{isDropdownOpen ?
                                            <MdKeyboardArrowUp/> : <MdKeyboardArrowDown/>}</div>
                                    </div>
                                    <div className="select-icon">
                                    </div>
                                    <div className="options-list  vehicle__list">
                                        {dropdowns.suspension && dropdowns.suspension.map((item) => (
                                            <div key={item.id}
                                                 className={`option-item ${item.id === selectedSuspensionOption ? 'active' : ''}`}
                                                 onClick={(e) => handleInputChange({
                                                     target: {
                                                         value: item.id,
                                                         name: 'suspension'
                                                     }
                                                 })}>
                                                <label>
                                                    {item.value}
                                                </label>
                                                <div className="checkbox">
                                                    {item.id === selectedSuspensionOption && <FaCheck/>}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="form__group__add__trailer">
                                <label>Structure {errors.structure &&
                                    <p className="error-message">{errors.structure}<CiCircleAlert/></p>}</label>
                                <div
                                    className={` vehicle__select custom-select ${isStructureDropdownOpen ? 'active' : ''}`}
                                    onClick={() => toggleCustomSelect('structure')}>
                                    <div className="selected-options">
                                        <div
                                            className="selected-option">{selectedStructureOption ? dropdowns.structure && dropdowns.structure.find((item) => item.id === selectedStructureOption)?.value : 'Not selected'}</div>
                                        <div className="selected-option vehicle__arrow">{isDropdownOpen ?
                                            <MdKeyboardArrowUp/> : <MdKeyboardArrowDown/>}</div>
                                    </div>
                                    <div className="select-icon ">
                                    </div>
                                    <div className="options-list  vehicle__list">
                                        {dropdowns.structure && dropdowns.structure.map((item) => (
                                            <div key={item.id}
                                                 className={`option-item ${item.id === selectedStructureOption ? 'active' : ''}`}
                                                 onClick={(e) => handleInputChange({
                                                     target: {
                                                         value: item.id,
                                                         name: 'structure'
                                                     }
                                                 })}>
                                                <label>
                                                    {item.value}
                                                </label>
                                                <div className="checkbox">
                                                    {item.id === selectedStructureOption && <FaCheck/>}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                            </div>
                            <div className="form__group__add__trailer">
                                <label>Annual Inspection {errors.annualInspection &&
                                    <p className="error-message">{errors.annualInspection}<CiCircleAlert/></p>}</label>
                                <DatePicker
                                    dateFormat="MM.dd.yyy"
                                    placeholderText="Enter trailer Annual Inspection"
                                    name="annualInspection"
                                    selected={annualInspection}
                                    onChange={handleDateChange}
                                />

                            </div>
                            <div className="form__group__add__trailer comment">
                                <label>Comment {errors.comment &&
                                    <p className="error-message">{errors.comment}<CiCircleAlert/></p>}</label>
                                <textarea
                                    name="comment"
                                    value={formData.comment}
                                    onChange={handleChange}
                                    placeholder="Enter trailer comment"

                                ></textarea>
                            </div>
                        </div>
                        <div className='right__column'>
                            <div className="form__group__add__trailer">
                                <label>Year {errors.year &&
                                    <p className="error-message">{errors.year}<CiCircleAlert/></p>}</label>
                                <input

                                    name="year"
                                    value={formData.year}
                                    onChange={handleChange}
                                    placeholder="Enter trailer year"

                                />
                            </div>
                            <div className="form__group__add__trailer">
                                <label>Vehicle ID {errors.vehicleId &&
                                    <p className="error-message">{errors.vehicleId}<CiCircleAlert/></p>}</label>
                                <input

                                    name="vehicleId"
                                    value={formData.vehicleId}
                                    onChange={handleChange}
                                    placeholder="Enter Vehicle ID"

                                />
                            </div>
                            <div className="form__group__add__trailer">
                                <label>License Plate {errors.licensePlate &&
                                    <p className="error-message">{errors.licensePlate}<CiCircleAlert/></p>}</label>
                                <input

                                    name="licensePlate"
                                    value={formData.licensePlate}
                                    onChange={handleChange}
                                    placeholder="Enter License Plate"

                                />
                            </div>
                            <div className="form__group__add__trailer">
                                <label>Ventilation {errors.ventilation &&
                                    <p className="error-message">{errors.ventilation}<CiCircleAlert/></p>}</label>
                                <div
                                    className={` vehicle__select custom-select ${isVentilationDropdownOpen ? 'active' : ''}`}
                                    onClick={() => toggleCustomSelect('ventilation')}>
                                    <div className="selected-options">
                                        <div
                                            className="selected-option">{selectedVentilationOption ? dropdowns.ventilation && dropdowns.ventilation.find((item) => item.id === selectedVentilationOption)?.value : 'Not selected'}</div>
                                        <div className="selected-option vehicle__arrow">{isDropdownOpen ?
                                            <MdKeyboardArrowUp/> : <MdKeyboardArrowDown/>}</div>
                                    </div>
                                    <div className="select-icon">
                                    </div>
                                    <div className="options-list  vehicle__list">
                                        {dropdowns.ventilation && dropdowns.ventilation.map((item) => (
                                            <div key={item.id}
                                                 className={`option-item ${item.id === selectedVentilationOption ? 'active' : ''}`}
                                                 onClick={(e) => handleInputChange({
                                                     target: {
                                                         value: item.id,
                                                         name: 'ventilation'
                                                     }
                                                 })}>
                                                <label>
                                                    {item.value}
                                                </label>
                                                <div className="checkbox">
                                                    {item.id === selectedVentilationOption && <FaCheck/>}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="form__group__add__trailer">
                                <label>Number {errors.number &&
                                    <p className="error-message">{errors.number}<CiCircleAlert/></p>}</label>
                                <input

                                    name="number"
                                    value={formData.number}
                                    onChange={handleChange}
                                    placeholder="Enter trailer number"

                                />
                            </div>
                            <div className="form__group__add__trailer trailer">
                                <label>Truck</label>
                                <div
                                    className={`vehicle__select custom-select ${isTruckDropdownOpen ? 'active' : ''}`}
                                    onClick={() => toggleCustomSelect('truck')}
                                >
                                    <div className="selected-options">
                                        <div className="selected-option">
                                            {selectedTruckOption ? dropdowns.truck && dropdowns.truck.find((item) => item.id === selectedTruckOption)?.value : 'Not selected'}
                                        </div>
                                        <div className="selected-option vehicle__arrow">
                                            {isDropdownOpen ? <MdKeyboardArrowUp/> : <MdKeyboardArrowDown/>}
                                        </div>
                                    </div>
                                    <div className="select-icon"></div>
                                    <div className="options-list vehicle__list">
                                        {/* Додано елемент "Not selected" */}
                                        <div
                                            className={`option-item ${selectedTruckOption === null ? 'active' : ''}`}
                                            onClick={(e) => handleInputChange({
                                                target: {
                                                    value: null, // Використовуйте значення null для позначення "Not selected"
                                                    name: 'truck'
                                                }
                                            })}
                                        >
                                            <label>Not selected</label>
                                            <div className="checkbox">{selectedTruckOption === null && <FaCheck/>}</div>
                                        </div>
                                        {/* Решта варіантів */}
                                        {dropdowns.truck && dropdowns.truck.map((item) => (
                                            <div
                                                key={item.id}
                                                className={`option-item ${item.id === selectedTruckOption ? 'active' : ''}`}
                                                onClick={(e) => handleInputChange({
                                                    target: {
                                                        value: item.id,
                                                        name: 'truck'
                                                    }
                                                })}
                                            >
                                                <label>{item.value}</label>
                                                <div className="checkbox">{item.id === selectedTruckOption &&
                                                    <FaCheck/>}</div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            {isLoading ? (
                                <LoaderComponent/>
                            ) : (
                                <div className='buttons__creaete__cancel'>
                                    <div className="form__group__add__trailer__btn create">

                                        {trailer ? (
                                            <button type="submit">Update trailer</button>
                                        ) : (
                                            <button type="submit">Create trailer</button>
                                        )}
                                    </div>
                                    <div className="form__group__cancel__trailer__btn">
                                        <button type="button" onClick={onClose}>Cancel</button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                </form>
            </div>
        </div>
    );
}
