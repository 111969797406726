// customersReducer.js

import {
    FETCH_CUSTOMERS_REQUEST,
    FETCH_CUSTOMERS_SUCCESS,
    FETCH_CUSTOMERS_FAILURE,
    ADD_CUSTOMER_REQUEST,
    ADD_CUSTOMER_SUCCESS,
    ADD_CUSTOMER_FAILURE,
    DELETE_CUSTOMER_REQUEST,
    DELETE_CUSTOMER_SUCCESS,
    DELETE_CUSTOMER_FAILURE,
    FETCH_ALL_CUSTOMERS_REQUEST,
    FETCH_ALL_CUSTOMERS_SUCCESS,
    FETCH_ALL_CUSTOMERS_FAILURE,
} from '../actions/customersActions';

const initialState = {
    customers: [],
    loading: false,
    error: null,
};

const customersReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CUSTOMERS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case FETCH_CUSTOMERS_SUCCESS:
            return {
                ...state,
                loading: false,
                customers: action.payload, // Assuming payload is the array of customers
            };
        case FETCH_CUSTOMERS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }; case ADD_CUSTOMER_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case ADD_CUSTOMER_SUCCESS:
            return {
                ...state,
                loading: false,
                customers: [...state.customers, action.payload], // додаємо нового клієнта до списку
            };
        case ADD_CUSTOMER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case DELETE_CUSTOMER_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case DELETE_CUSTOMER_SUCCESS:
            const updatedCustomers = state.customers.filter(customer => customer.id !== action.payload);
            return {
                ...state,
                loading: false,
                customers: updatedCustomers,
            };
        case DELETE_CUSTOMER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case FETCH_ALL_CUSTOMERS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case FETCH_ALL_CUSTOMERS_SUCCESS:
            return {
                ...state,
                loading: false,
                customers: action.payload,
            };
        case FETCH_ALL_CUSTOMERS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default customersReducer;
