

import { ADD_DRIVER_REQUEST,
    ADD_DRIVER_SUCCESS,
    ADD_DRIVER_FAILURE,
    GET_DRIVERS_REQUEST,
    GET_DRIVERS_SUCCESS,
    GET_DRIVERS_FAILURE,
    GET_DRIVER_REQUEST,
    GET_DRIVER_SUCCESS,
    GET_DRIVER_FAILURE,
    DELETE_DRIVER_REQUEST,
    DELETE_DRIVER_SUCCESS,
    DELETE_DRIVER_FAILURE,
    GET_DROPDOWNS_REQUEST,
    GET_DROPDOWNS_SUCCESS,
    GET_DROPDOWNS_FAILURE,
    UPLOAD_FILE_REQUEST, 
    UPLOAD_FILE_SUCCESS, 
    UPLOAD_FILE_FAILURE,
    GET_TRUCK_DROPDOWNS_REQUEST,
    GET_TRUCK_DROPDOWNS_SUCCESS,
    GET_TRUCK_DROPDOWNS_FAILURE,
    CHANGE_CURRENT_TRUCK_REQUEST,
    CHANGE_CURRENT_TRUCK_SUCCESS,
    CHANGE_CURRENT_TRUCK_FAILURE,
    FETCH_TRAILER_DROPDOWNS_FAILURE,
    FETCH_TRAILER_DROPDOWNS_SUCCESS,
    FETCH_TRAILER_DROPDOWNS_REQUEST,
    CHANGE_CURRENT_TRAILER_REQUEST,
    CHANGE_CURRENT_TRAILER_SUCCESS,
    CHANGE_CURRENT_TRAILER_FAILURE,
    GET_USER_FILES_REQUEST,
    GET_USER_FILES_SUCCESS,
    GET_USER_FILES_FAILURE,
    DELETE_FILE_REQUEST,
    DELETE_FILE_SUCCESS,
    DELETE_FILE_FAILURE,

} from '../actions/DriversProfileActions.js';

const initialState = {
    data: null,
    loading: false,
    error: null,
    drivers: [],
    driver: {},
    dropdownData: [],
    truckDropdowns: [],
    trailerDropdowns: [],
    changeTruckLoading: false,
    userFiles: []
};

const maindriverReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_DRIVER_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case ADD_DRIVER_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
            };
        case ADD_DRIVER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_DRIVERS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case GET_DRIVERS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                drivers: action.payload,
            };
        case GET_DRIVERS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_DRIVER_REQUEST:
            return { ...state, loading: true, error: null };

        case GET_DRIVER_SUCCESS:
            return { ...state, loading: false, driver: action.payload, error: null };

        case GET_DRIVER_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case DELETE_DRIVER_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case DELETE_DRIVER_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                drivers: state.drivers.filter(driver => driver.id !== action.payload.driverId),
            };
        case DELETE_DRIVER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_DROPDOWNS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case GET_DROPDOWNS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                dropdownData: action.payload,
            };
        case GET_DROPDOWNS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case UPLOAD_FILE_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case UPLOAD_FILE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
            };
        case UPLOAD_FILE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_TRUCK_DROPDOWNS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case GET_TRUCK_DROPDOWNS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                truckDropdowns: action.payload,
            };
        case GET_TRUCK_DROPDOWNS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case CHANGE_CURRENT_TRUCK_REQUEST:
            return {
                ...state,
                changeTruckLoading: true,
                error: null,
            };
        case CHANGE_CURRENT_TRUCK_SUCCESS:
            return {
                ...state,
                changeTruckLoading: false,
                error: null,
            };
        case CHANGE_CURRENT_TRUCK_FAILURE:
            return {
                ...state,
                changeTruckLoading: false,
                error: action.payload,
            };
        case FETCH_TRAILER_DROPDOWNS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case FETCH_TRAILER_DROPDOWNS_SUCCESS:
            return {
                ...state,
                loading: false,
                trailerDropdowns: action.payload,
                error: null,
            };
        case FETCH_TRAILER_DROPDOWNS_FAILURE:
            return {
                ...state,
                loading: false, 
                error: action.payload,
            };
            case CHANGE_CURRENT_TRAILER_REQUEST:
            return {
                ...state,
                changeTrailerLoading: true,
                error: null,
            };
        case CHANGE_CURRENT_TRAILER_SUCCESS:
            return {
                ...state,
                changeTrailerLoading: false,
                error: null,
            };
        case CHANGE_CURRENT_TRAILER_FAILURE:
            return {
                ...state,
                changeTrailerLoading: false,
                error: action.payload,
            };
        case GET_USER_FILES_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };

        case GET_USER_FILES_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                userFiles: action.payload,
            };

        case GET_USER_FILES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case 'UPDATE_DRIVER_REQUEST':
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        case 'UPDATE_DRIVER_SUCCESS':
            return {
                ...state,
                data: action.payload,
                isLoading: false,
                error: null,
            };
        case 'UPDATE_DRIVER_FAILURE':
            return {
                ...state,
                isLoading: false, 
                error: action.payload,
            };
            case DELETE_FILE_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case DELETE_FILE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                drivers: state.driver.driver.files.filter(file => file.id !== action.payload.fileId),
            };
        case DELETE_FILE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        default:
            return state;
    }
};

export default maindriverReducer;
