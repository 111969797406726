import {useTranslation} from 'react-i18next';
import {useState} from 'react';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {loginUser} from '../../../store/actions/authActions';
import showIcon from '../../../assets/svg/show.svg';
import hideIcon from '../../../assets/svg/hide.svg';
import {CiCircleAlert} from 'react-icons/ci';
import LoaderComponent from "../../LoaderComponent/LoaderComponent";

export default function Form() {
    const {error} = useSelector((state) => state.auth);
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [showPassword, setShowPassword] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [rememberMe, setRememberMe] = useState(false);

    const handleForgotPasswordClick = () => {
        navigate('/forgot-password');
    };

    const togglePassword = () => {
        setShowPassword(!showPassword);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        await dispatch(loginUser(email, password, rememberMe));

        setLoading(false);
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="form_group__container">
                <label htmlFor="email">{t('Email')}</label>
                <input
                    type="email"
                    name="email"
                    id="email"
                    autoComplete="username"
                    value={email}
                    onChange={handleEmailChange}
                    required
                />
            </div>

            <div className="form_group__container password__type">
                <label htmlFor="password">{t('Password')}</label>
                <div className="password-wrapper">
                    <input
                        type={showPassword ? 'text' : 'password'}
                        name="password"
                        id="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={handlePasswordChange}
                        required
                    />
                    <span className="toggle-icon" onClick={togglePassword}>
                        {showPassword ? (
                            <img src={showIcon} alt="hide-password"/>
                        ) : (
                            <img src={hideIcon} alt="show-password"/>
                        )}
                    </span>
                </div>
            </div>

            <div className="form_group__middle__container">
                <div className="form_group__remember__container">
                    <input
                        type="checkbox"
                        id="myCheckbox"
                        name="myCheckbox"
                        checked={rememberMe}
                        onChange={() => setRememberMe(!rememberMe)}
                    />
                    <label htmlFor="myCheckbox" style={ {cursor: 'pointer'} }>  {t('Remember me')}</label>
                </div>
                <div className="form_group__forgot__container" onClick={handleForgotPasswordClick}>
                    {t('Forgot password?')}
                </div>
            </div>

            <div className="form_groupcheckrole__container">
                {error && (
                    <div className="invalid-credentials-message">
                        {t(error)}
                        <CiCircleAlert/>
                    </div>
                )}
                {loading && <LoaderComponent/>}
            </div>

            <div className="form_group__button__container">
                <button type="submit"> {t('Sign in')}</button>
            </div>
        </form>
    );
}
