import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import './AddDocumentComponent.scss';
import {uploadFile} from "../../../../store/actions/DriversProfileActions";
import {IoClose} from 'react-icons/io5';
import {FaCheck} from "react-icons/fa6";
import {MdKeyboardArrowDown} from "react-icons/md";
import {MdKeyboardArrowUp} from "react-icons/md";
import {getDriver} from '../../../../store/actions/DriversProfileActions';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function AddDocumentComponent({onClose, dropdownData, driverId}) {
    const dispatch = useDispatch();
    const [selectedDocumentType, setSelectedDocumentType] = useState('');
    const [fileName, setFileName] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isSelectOpen, setIsSelectOpen] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [expirationDate, setExpirationDate] = useState(new Date());

    //eslint-disable-next-line
    const [selectedDropdownOption, setSelectedDropdownOption] = useState('');
    const [categoryError, setCategoryError] = useState('');
    const [fileError, setFileError] = useState('');
    const [dateError, setDateError] = useState('');

    const [uploaded, setUploaded] = useState(false);

    useEffect(() => {
        if (!expirationDate) {
            setDateError('Please select an expiration date.');
        } else {
            setDateError('');
        }
    }, [expirationDate]);
    const handleFileChange = async (event) => {
        setSelectedFile(event.target.files[0]);
        setFileName(event.target.files[0] ? event.target.files[0].name : '');
        // Оновлення стану помилки файлу
        if (!event.target.files[0]) {
            await setFileError('Please choose a file.');
        } else {
            setFileError('');
        }
    };


    const toggleCustomSelect = () => {
        setIsDropdownOpen(!isDropdownOpen);
        setIsSelectOpen(!isSelectOpen);
    };
    const handleCategoryChange = async (event) => {
        const category = event.target.value;
        setSelectedCategory(category);
        setSelectedDocumentType(category);
        setSelectedDropdownOption(category);

        // Оновлення стану помилки категорії
        if (!category) {
            await setCategoryError('Select a category.');
        } else {
            setCategoryError('');
        }
    };


    useEffect( () => {
        if (!selectedCategory) {
            setCategoryError('Select a category.');
        } else {
            setCategoryError('');
        }
    }, [selectedCategory]);

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        // Перевірка наявності обраної категорії та файлу
        if (!selectedDocumentType) {
            setCategoryError('Select a category.');
        } else {
            setCategoryError('');
        }

        if (!selectedFile) {
            setFileError('Please choose a file.');
        } else {
            setFileError('');
        }

        if (!expirationDate) {
            setDateError('Please select an expiration date.');
        } else {
            setDateError('');
        }

        // Якщо немає помилок, відправляємо форму
        try {
            if (selectedDocumentType && selectedFile && expirationDate && !uploaded) {
                setUploaded(true)
                const formData = new FormData();
                formData.append('documentType', selectedDocumentType);
                formData.append('file', selectedFile);
                formData.append('expirationDate', expirationDate.toISOString().slice(0, 10)); // Записуємо дату як рядок у форматі YYYY-MM-DD
                const selectedDropdown = dropdownData.find(item => item.value === selectedDocumentType);

                if (selectedDropdown) {
                    formData.append('dropdownId', selectedDropdown.id);
                }

                await dispatch(uploadFile(driverId, formData))
                    .then(() => setUploaded(false))
                    .catch(err => {
                        throw new Error(err)
                    })

                onClose();
                setFileError('')
                dispatch(getDriver(driverId));
            }
        } catch (err) {
            setFileError('Server error, please try again.')
        } finally {
            setUploaded(false)
            // onClose();
        }
    };

    // const handleFileDelete = () => {
    //     setSelectedFile(null);
    //     setFileName('');
    // };

    return (
        <>
            <div className="modal-container_add_driver__doc">
                <div className="backdrop_add_driver__doc" onClick={onClose}></div>
                <div className="modal_add_driver__doc">
                    <div className="modal_add_driver__header">
                        <div className="modal_add_driver__title">
                            Upload document
                        </div>
                        <div className="modal_add_driver__close">
                            <div onClick={onClose}><IoClose/></div>
                        </div>
                    </div>
                    <form onSubmit={handleFormSubmit}>
                        <span style={{display: 'flex', justifyContent: 'space-between'}}>Document category
                            {categoryError && <div className="error-message">{categoryError}</div>}
                        </span>
                        <div className="form-group_add__document__driver">
                            <div className={`custom-select doc_category__main ${isDropdownOpen ? 'active' : ''}`}
                                 onClick={toggleCustomSelect}>
                                <div className="selected-options doc__category">
                                    <div
                                        className="selected-option doc__font">{selectedDropdownOption ? dropdownData.find(item => item.value === selectedDropdownOption)?.value : 'Select category'}</div>
                                    <div className="selected-option doc__category">{isDropdownOpen ?
                                        <MdKeyboardArrowUp/> : <MdKeyboardArrowDown/>}</div>
                                </div>


                                <div className="select-icon">
                                </div>
                                <div className="options-list doc">
                                    {dropdownData.map((item) => (
                                        <div key={item.id}
                                             className={`option-item ${item.id === selectedDropdownOption ? 'active' : ''}`}
                                             onClick={() => handleCategoryChange({target: {value: item.value}})}>
                                            <label>
                                                {item.value}
                                            </label>
                                            <div className="checkbox">
                                                {item.value === selectedDropdownOption && <FaCheck/>}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                        <div className="form-group_add__document__driver">
                            <label>Expiration Date:</label>
                            <DatePicker selected={expirationDate}  onChange={(date) => setExpirationDate(date)}/>
                        </div>
                        <div className="form-group_add__document__driver">
                            {dateError && <div className="error-message">{dateError}</div>}
                        </div>

                        <div className="form-group_add__document__driver">
                            <label htmlFor="file">
                                {fileError && <div className="error-message">{fileError}</div>}
                                {fileName && <div className="selected-file-name">{fileName}</div>}
                            </label>
                            <div className="add__document_file-input-container">
                                <input
                                    type="file"
                                    name="file"
                                    id="file"
                                    accept=".png, .jpeg, .jpg"
                                    className="file-input-add__document"
                                    onChange={handleFileChange}
                                    disabled={!!fileName}
                                />
                                {!fileName && (
                                    <span className="document-upload-text">
                Upload File
            </span>
                                )}
                            </div>
                        </div>


                        <div className="form-buttons_add__document__driver">
                            <button disabled={uploaded} className="add__document-btn-add" type="submit">{uploaded ? 'Loading...' : 'Add File'}</button>
                            <button className="add__document-btn-cancel" type="button" onClick={onClose}>
                                Cancel
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}