import Navigation from "../../../Components/Navigation/Navigation";
import './MainPage.scss'
import MainComponent from "../../../Components/Driver/MainComponent/MainComponent";


export default function MainPageDriver() {

    return (
        <div className="main__page__driver">
            <Navigation />
            <MainComponent />
        </div>
    )
}