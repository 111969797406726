import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {fetchAllCustomers} from "../../../../../store/actions/customersActions";
import {IoIosArrowDown} from "react-icons/io";
import InputMask from 'react-input-mask';
import {CiCircleAlert} from "react-icons/ci";
import {
    createLoad,
    fetchLoadStatuses,
    fetchLoad,
    updateLoad,
    fetchDriverFindMainContent, getMainContent
} from "../../../../../store/actions/MainActions";
import {IoClose} from "react-icons/io5";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import './AddLoadModalComponent.scss';
import {format, parse} from 'date-fns';
import LoaderComponent from "../../../../LoaderComponent/LoaderComponent";


export default function AddLoadModalComponent({closeModal, driverId, loadId}) {
    const dispatch = useDispatch();
    const customers = useSelector((state) => state.customers.customers);
    const [selectedStatus, setSelectedStatus] = useState({});
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isSelectOpen, setIsSelectOpen] = useState(false);
    const loadStatusState = useSelector((state) => state?.main?.statuses);
    //eslint-disable-next-line
    const [isLoading, setIsLoading] = useState(false);
    const [fromTime, setFromTime] = useState(null);
    const [toTime, setToTime] = useState(null);
    const [fromTime2, setFromTime2] = useState(null);
    const [toTime2, setToTime2] = useState(null);
    const [pickUpFromHour, setPickUpFromHour] = useState('');
    const [pickUpToHour, setPickUpToHour] = useState('');
    const [deliveryFromHour, setDeliveryFromHour] = useState('');
    const [deliveryToHour, setDeliveryToHour] = useState('');
    const loadData = useSelector(state => state.main.load);
console.log(loadData);

    useEffect(() => {
        if (loadId) {
            dispatch(fetchLoad(loadId));
        }
    }, [dispatch, loadId]);

    useEffect(() => {
        if (loadId && loadData) {
            const {pointsList, loadDetails, clientContacts} = loadData;

            const pickUpPoint = pointsList.find(point => point.typeOfPointId === 1);
            const deliveryPoint = pointsList.find(point => point.typeOfPointId === 2);


            if (pickUpPoint) {

                if (pickUpPoint) {
                    const fromDate = pickUpPoint.pickUpDateFrom ? parse(pickUpPoint.pickUpDateFrom, 'dd.MM.yyyy', new Date()) : '';
                    const toDate = pickUpPoint.pickUpDateTo ? parse(pickUpPoint.pickUpDateTo, 'dd.MM.yyyy', new Date()) : '';
                    const fromDate2 = deliveryPoint.deliveryDateFrom ? parse(deliveryPoint.deliveryDateFrom, 'dd.MM.yyyy', new Date()) : '';
                    const toDate2 = deliveryPoint.deliveryDateTo ? parse(deliveryPoint.deliveryDateTo, 'dd.MM.yyyy', new Date()) : '';

                    const parsedTime = pickUpPoint.pickUpTimeFrom ? parse(pickUpPoint.pickUpTimeFrom, 'h:mm a', new Date()) : '';
                    const parsedTimeTo = pickUpPoint.pickUpTimeTo ? parse(pickUpPoint.pickUpTimeTo, 'h:mm a', new Date()) : '';
                    const parsedDeliveryFromTime = deliveryPoint.deliveryTimeFrom ? parse(deliveryPoint.deliveryTimeFrom, 'h:mm a', new Date()) : '';


                    setDeliveryFromHour(format(parsedDeliveryFromTime, 'h:mm a', new Date()));
                    setPickUpToHour(parsedTimeTo ? format(parsedTimeTo, 'h:mm a', new Date()) : '');

                    setPickUpFromHour(format(parsedTime, 'h:mm a', new Date()));
                    setToTime2(toDate2);
                    setFromTime2(fromDate2);
                    setFromTime(fromDate);
                    setToTime(toDate);
                }

                setPickUpFormState(prevState => ({
                    ...prevState,
                    pickUp: pickUpPoint.location,
                    streetAddress: pickUpPoint.street,
                    zipPickUp: pickUpPoint.zip.toString(),
                    timeInterval: true,
                    amPm: '',
                    commodity: loadDetails.commodity,
                    weight: loadDetails.weight.toString(),
                    miles: loadDetails.miles.toString(),
                    gross: loadDetails.gross.toString(),
                    selectedClient: clientContacts.clientName,
                    width: loadDetails.sizeW.toString(),
                    length: loadDetails.sizeL.toString(),
                    height: loadDetails.sizeH.toString(),
                    loadId: loadId,
                    clientContacts: {
                        ...prevState.clientContacts,
                        clientName: clientContacts.clientName,
                        clientPhone: clientContacts.phoneNumber,
                        companyName: clientContacts.companyName,
                        clientEmail: clientContacts.clientEmail,
                        mcNumber: clientContacts.mcNumber,
                    },
                    hideLoadFromDriver: loadDetails.hidden === 1,
                    sizeW: loadDetails.sizeW.toString(),
                    sizeL: loadDetails.sizeL.toString(),
                    sizeH: loadDetails.sizeH.toString(),
                }));
            }

            if (deliveryPoint) {
                const parsedTimeTo = deliveryPoint.deliveryTimeTo ? parse(deliveryPoint.deliveryTimeTo, 'h:mm a', new Date()) : '';

                setDeliveryToHour(parsedTimeTo ? format(parsedTimeTo, 'h:mm a', new Date()) : '');


                setDeliveryFormState(prevState => ({
                    ...prevState,
                    delivery: deliveryPoint.location,
                    streetAddressDelivery: deliveryPoint.street,
                    ZIPdelivery: deliveryPoint.zip.toString(),
                    deliveryTimeInterval: true,
                    commodity: loadDetails.commodity,
                    weight: loadDetails.weight.toString(),
                    miles: loadDetails.miles.toString(),
                    gross: loadDetails.gross.toString(),
                    selectedClient: clientContacts.clientName,
                    hideLoadFromDriver: loadDetails.hidden === 1,
                    sizeW: loadDetails.sizeW.toString(),
                    sizeL: loadDetails.sizeL.toString(),
                    sizeH: loadDetails.sizeH.toString(),
                }));
            }
        }
    }, [loadId, loadData]);


    const handleTimeChange = (field, time) => {
        if (time) {
            const formattedTime = new Date(`01/01/2022 ${time}`).toLocaleTimeString('en-US', {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
            });

            switch (field) {
                case 'pickUpFromHour':
                    setPickUpFromHour(formattedTime);


                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        pickUpFromHour: false,
                    }));
                    break;
                case 'pickUpToHour':
                    setPickUpToHour(formattedTime);
                    break;
                case 'deliveryFromHour':
                    setDeliveryFromHour(formattedTime);
                    break;
                case 'deliveryToHour':
                    setDeliveryToHour(formattedTime);
                    break;
                default:
                    break;
            }
        } else {

            setErrors((prevErrors) => ({
                ...prevErrors,
                pickUpFromHour: true,
            }));
        }
    };


    const formatDateTimeWithHour = (date, time) => {
        const formattedDate = `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()}`;
        const formattedTime = new Date(`01/01/2022 ${time}`).toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        });
        return `${formattedDate} ${formattedTime}`;
    };


    useEffect(() => {
        dispatch(fetchLoadStatuses());
    }, [dispatch]);


    const [pickUpFormState, setPickUpFormState] = useState({
        pickUp: '',
        streetAddress: '',
        zipPickUp: '',
        timeInterval: false,
        amPm: '',
        commodity: '',
        weight: '',
        miles: '',
        gross: '',
        selectedClient: '',
        clientContacts: {
            clientName: '',
            clientPhone: '',
            companyName: '',
            clientEmail: '',
            mcNumber: '',
        },
        hideLoadFromDriver: false,
    });

    const [deliveryFormState, setDeliveryFormState] = useState({
        delivery: '',
        streetAddressDelivery: '',
        ZIPdelivery: '',
        deliveryTimeInterval: false,
        commodity: '',
        weight: '',
        miles: '',
        gross: '',
        selectedClient: '',
        clientContacts: {
            clientName: '',
            clientPhone: '',
            companyName: '',
            clientEmail: '',
            mcNumber: '',
        },
        hideLoadFromDriver: false,
    });


    const [searchTerm, setSearchTerm] = useState('');
    const [errors, setErrors] = useState({
        pickUp: false,
        pickUpLength: false,
        streetAddress: false,
        streetAddressLength: false,
        zipPickUp: false,
        zipPickUpLength: false,
        delivery: false,
        deliveryLength: false,
        streetAddressDelivery: false,
        streetAddressDeliveryLength: false,
        ZIPdelivery: false,
        ZIPdeliveryLength: false,
        commodity: false,
        commodityLength: false,
        weight: false,
        length: false,
        miles: false,
        gross: false,
        timeInterval: false,
        deliveryTimeInterval: false,
        amPm: false,
        amPm2: false,
        timeInterval2: false,
        width: false,
        height: false,
        fromTime: false,
        FromHour: false,
        'Full Load': false,
        Partial: false,
        clientName: false,
        clientPhone: false,
        companyName: false,
        clientEmail: false,
        mcNumber: false,
        mcNumberMessage: '',
        pickUpFromHour: false,
        fromTime2: false,
        deliveryFromHour: false,
    });


    useEffect(() => {
        dispatch(fetchLoadStatuses());
        dispatch(fetchAllCustomers());
    }, [dispatch]);

    const filteredCustomers = customers.filter((customer) =>
        customer.clientName.toLowerCase().includes(searchTerm.toLowerCase())
    );


    const handleStatusChange = (status) => {
        setSelectedStatus((prevSelectedStatus) => {
            const updatedStatus = {...prevSelectedStatus};

            if (status.type === 'Selective') {
                Object.keys(updatedStatus)
                    .filter((id) => loadStatusState.find((s) => s.id === parseInt(id))?.type === 'Selective')
                    .forEach((id) => {
                        delete updatedStatus[id];
                    });
            }

            if (updatedStatus[status.id]) {
                delete updatedStatus[status.id];
            } else {
                updatedStatus[status.id] = status;
            }

            return updatedStatus;
        });
    };

    const handleInputChange = (name, value, isDelivery) => {
        let formattedValue = '';


        if (name === 'clientName' || name === 'clientPhone' || name === 'companyName' || name === 'clientEmail') {
            formattedValue = value.trim();

            setPickUpFormState((prevState) => ({
                ...prevState,
                clientContacts: {
                    ...prevState.clientContacts,
                    [name]: formattedValue,
                },
            }));

            // Оновіть стан помилок
            setErrors((prevErrors) => ({
                ...prevErrors,
                clientName: name === 'clientName' ? !formattedValue : prevErrors.clientName,
            }));
        }

        if (name === 'miles' || name === 'weight' || name === 'width' || name === 'length' || name === 'height') {
            const numericValue = value.replace(/[^\d]/g, '');
            formattedValue = numericValue !== '' ? `${(+numericValue).toLocaleString()} ${name === 'miles' ? 'mi' : (name === 'weight' ? 'lb' : 'ft')}` : '';
        } else if (name === 'zipPickUp' || name === 'ZIPdelivery') {
            const numericValue = value.replace(/[^\d]/g, '');
            formattedValue = numericValue;
        } else if (name === 'gross') {
            const numericValue = value.replace(/[^\d]/g, '');
            formattedValue = numericValue !== '' ? `${(+numericValue).toLocaleString()}$` : '';
        } else {
            formattedValue = value;
        }


        if (isDelivery) {
            setDeliveryFormState((prevState) => ({
                ...prevState,
                [name]: formattedValue,
            }));
        } else {
            setPickUpFormState((prevState) => ({
                ...prevState,
                [name]: formattedValue,
            }));

            if (name === 'pickUp' || name === 'streetAddress') {
                setPickUpFormState((prevState) => ({
                    ...prevState,
                    selectedClient: '',
                }));
            }

            if (name === 'clientName') {
                const selectedClient = filteredCustomers.find((customer) => customer.clientName === value);

                setPickUpFormState((prevState) => ({
                    ...prevState,
                    selectedClient: value,
                    isNewClient: !selectedClient,
                    clientContacts: {
                        clientName: value,
                        clientPhone: selectedClient ? selectedClient.phoneNumber : '',
                        companyName: selectedClient ? selectedClient.companyName : '',
                        clientEmail: selectedClient ? selectedClient.clientEmail : '',
                        mcNumber: selectedClient ? selectedClient.mcNumber : '',
                    },
                }));
            } else if (name === 'clientPhone' || name === 'companyName' || name === 'clientEmail' || name === 'mcNumber') {
                setPickUpFormState((prevState) => ({
                    ...prevState,
                    clientContacts: {
                        ...prevState.clientContacts,
                        [name]: formattedValue,
                    },
                }));
            }
        }
    };


    const validateForm = (isDelivery) => {
        const newErrors = {
            fromTime: !fromTime,
            fromTime2: !fromTime2,
            deliveryFromHour: !deliveryFromHour,
        };

        if (!pickUpFromHour) {
            newErrors.pickUpFromHour = true;
        }


        if (!pickUpFormState.pickUp.trim()) {
            newErrors.pickUp = true;
        } else if (pickUpFormState.pickUp.length > 120) {
            newErrors.pickUpLength = true;
        }


        if (!pickUpFormState.streetAddress.trim()) {
            newErrors.streetAddress = true;
        } else if (pickUpFormState.streetAddress.length > 120) {
            newErrors.streetAddressLength = true;
        }


        if (!pickUpFormState.zipPickUp.trim()) {
            newErrors.zipPickUp = true;
        } else if (!/^\d{5}$/.test(pickUpFormState.zipPickUp)) {
            newErrors.zipPickUpLength = true;
        }


        if (!pickUpFormState.commodity.trim()) {
            newErrors.commodity = true;
        } else if (pickUpFormState.commodity.length > 120) {
            newErrors.commodityLength = true;
        }


        if (!pickUpFormState.weight) {
            newErrors.weight = true;
        }

        if (!pickUpFormState.width) {
            newErrors.width = true;
        }

        if (!pickUpFormState.length) {
            newErrors.length = true;
        }

        if (!pickUpFormState.height) {
            newErrors.height = true;
        }

        if (!pickUpFormState.miles) {
            newErrors.miles = true;
        }

        if (!deliveryFormState.ZIPdelivery.trim()) {
            newErrors.ZIPdelivery = true;
        } else if (deliveryFormState.ZIPdelivery.length > 5) {
            newErrors.ZIPdeliveryLength = true;
        }

        if (!deliveryFromHour) {
            newErrors.deliveryFromHour = true;
        }


        if (!pickUpFormState.gross) {
            newErrors.gross = true;
        }

        if (!deliveryFormState.delivery.trim()) {
            newErrors.delivery = true;
        } else if (deliveryFormState.delivery.length > 120) {
            newErrors.deliveryLength = true;
        }

        // В функції validateForm
        if (!pickUpFormState.clientContacts.clientName.trim()) {
            newErrors.clientName = true;
            newErrors.clientNameMessage = 'This field is empty';
        } else {
            newErrors.clientNameMessage = '';
        }


        if (!pickUpFormState.clientContacts.clientPhone.trim()) {
            newErrors.clientPhone = true;
        }

        if (!pickUpFormState.clientContacts.companyName.trim()) {
            newErrors.companyName = true;
            newErrors.companyNameMessage = 'This field is empty';
        } else if (pickUpFormState.clientContacts.companyName.length > 20) {
            newErrors.companyNameMessage = 'Maximum length is 20 characters';
        } else {
            newErrors.companyNameMessage = '';
        }


        if (!pickUpFormState.clientContacts.clientEmail.trim()) {
            newErrors.clientEmail = true;
        }

        if (!pickUpFormState.clientContacts.mcNumber) {
            newErrors.mcNumber = true;
            newErrors.mcNumberMessage = 'This field is empty';
        } else if (pickUpFormState.clientContacts.mcNumber.length > 20) {
            newErrors.mcNumberMessage = 'Maximum length is 20 characters';
        } else {
            newErrors.mcNumberMessage = '';
        }


        if (!deliveryFormState.streetAddressDelivery.trim()) {
            newErrors.streetAddressDelivery = true;
        } else if (deliveryFormState.streetAddressDelivery.length > 120) {
            newErrors.streetAddressDeliveryLength = true;
        }


        if (!fromTime) {
            newErrors.fromTime = true;
        } else {
            newErrors.fromTime = false;
        }


        setErrors(newErrors);

        return Object.values(newErrors).every((error) => !error);
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        if (validateForm()) {
            try {
                const pickUpPayload = {
                    location: pickUpFormState.pickUp,
                    street: pickUpFormState.streetAddress,
                    zip: pickUpFormState.zipPickUp,
                    typeOfPointId: 1,

                    timeIntervalFrom: formatDateTimeWithHour(new Date(fromTime), pickUpFromHour),
                    timeIntervalTo: pickUpFormState.timeInterval ? formatDateTimeWithHour(new Date(toTime), pickUpToHour) : '',
                };

                const deliveryPayload = {
                    location: deliveryFormState.delivery,
                    street: deliveryFormState.streetAddressDelivery,
                    zip: deliveryFormState.ZIPdelivery,
                    typeOfPointId: 2,

                    timeIntervalFrom: formatDateTimeWithHour(new Date(fromTime2), deliveryFromHour),
                    timeIntervalTo: deliveryFormState.deliveryTimeInterval ? formatDateTimeWithHour(new Date(toTime2), deliveryToHour) : '',
                };

                const payload = {
                    driverId: driverId,
                    pointsList: [pickUpPayload, deliveryPayload],
                    statusesIdsList: Object.keys(selectedStatus).map((statusId) => parseInt(statusId)),
                    loadDetails: {
                        ...(loadId && {loadId: loadId}),
                        commodity: pickUpFormState.commodity || deliveryFormState.commodity,
                        weight: parseFloat(pickUpFormState.weight || deliveryFormState.weight),
                        sizeW: parseFloat(pickUpFormState.width || deliveryFormState.width),
                        sizeL: parseFloat(pickUpFormState.length || deliveryFormState.length),
                        sizeH: parseFloat(pickUpFormState.height || deliveryFormState.height),
                        miles: parseInt(pickUpFormState.miles || deliveryFormState.miles),
                        gross: parseFloat((pickUpFormState.gross || deliveryFormState.gross).replace(/[^\d.]/g, '')),
                        hidden: pickUpFormState.hideLoadFromDriver,
                    },
                    clientContacts: {
                        clientName: deliveryFormState.selectedClient || pickUpFormState.selectedClient || '',
                        clientEmail: pickUpFormState.clientContacts?.clientEmail || deliveryFormState.clientContacts?.clientEmail || '',
                        phoneNumber: pickUpFormState.clientContacts?.clientPhone || deliveryFormState.clientContacts?.clientPhone || '',
                        mcNumber: pickUpFormState.clientContacts?.mcNumber || deliveryFormState.clientContacts?.mcNumber || '',
                        companyName: pickUpFormState.clientContacts?.companyName || deliveryFormState.clientContacts?.companyName || '',
                    },
                };

                if (loadId) {
                    try {
                        await dispatch(updateLoad(payload));
                        console.log('Updating load:', payload);
                    } catch (error) {
                        console.error('Error updating load:', error);
                    }
                } else {
                    try {
                        await dispatch(createLoad(payload));
                        console.log('Creating load:', payload);
                    } catch (error) {
                        console.error('Error creating load:', error);
                    }
                }

                const limit = 3;
                dispatch(getMainContent(driverId, false, limit));
                console.log('Submitting payload:', payload);
            } catch (error) {
                console.error('Error creating load:', error);
            } finally {
                setIsLoading(false);
                closeModal();
            }
        } else {
            console.log('Form validation failed');
            setIsLoading(false);
        }
    };

    const handlePickUpCheckboxChange = (e) => {
        setPickUpFormState((prevState) => ({
            ...prevState,
            timeInterval: e.target.checked,
        }));
    };

    const handleDeliveryCheckboxChange = (e) => {
        setDeliveryFormState((prevState) => ({
            ...prevState,
            deliveryTimeInterval: e.target.checked,
        }));
    };

    const toggleCustomSelect = () => {
        setIsDropdownOpen(!isDropdownOpen);
        setIsSelectOpen(!isSelectOpen);
    };

    const handleInputClick = () => {
        setIsDropdownOpen(true);
    };

    const handleSelectOption = (selectedClient) => {
        setPickUpFormState((prevState) => ({
            ...prevState,
            selectedClient: selectedClient.clientName,
            isNewClient: false,
            clientContacts: {
                clientName: selectedClient.clientName || '',
                clientEmail: selectedClient.clientEmail || '',
                clientPhone: selectedClient.phoneNumber || '',
                mcNumber: selectedClient.mcNumber || '',
                companyName: selectedClient.companyName || '',
                comment: selectedClient.comment || '',
            },
        }));

        setSearchTerm('');

        setIsDropdownOpen(false);
        setIsSelectOpen(false);
    };


    return (
        <>

            <div className="modal-overlay__add__load">
                <div className="modal-content__add__load">
                    <div className="modal__add__load__head">
                        <div className="add__load__title__modal">
                            Location and time
                        </div>
                        <div className='modal__close__load' onClick={closeModal}><IoClose/></div>
                    </div>
                    <div className="modal__add__load__body">
                        <form className="add_load__form" onSubmit={handleSubmit}>
                            <div className="modal__add__load__form__left">
                                <div className="form__group__add__load">
                                    <div className="label__error">
                                        <label htmlFor="Pick up">Pick up
                                            {errors.pickUp &&
                                                <span
                                                    className="error-message">This field is empty <CiCircleAlert/></span>}
                                            {errors.pickUpLength &&
                                                <span className="error-message">Maximum 120 characters <CiCircleAlert/></span>}
                                        </label>

                                    </div>
                                    <input
                                        type="text"
                                        name="pickUp"
                                        placeholder="State, city"
                                        value={pickUpFormState.pickUp}
                                        onChange={(e) => handleInputChange('pickUp', e.target.value)}
                                        style={{border: errors.pickUp ? '1px solid #FF3939' : '1px solid #ccc'}}
                                    />

                                </div>
                                <div className="form__group__add__load">
                                    <label htmlFor="StreetAddress">Street Address
                                        {errors.streetAddress &&
                                            <span className="error-message">This field is empty <CiCircleAlert/></span>}
                                        {errors.streetAddressLength &&
                                            <span
                                                className="error-message">Maximum 120 characters <CiCircleAlert/></span>}
                                    </label>

                                    <input
                                        type="text"
                                        name="streetAddress"
                                        placeholder="Street address"
                                        value={pickUpFormState.streetAddress}
                                        onChange={(e) => handleInputChange('streetAddress', e.target.value)}
                                        style={{border: errors.streetAddress ? '1px solid #FF3939' : '1px solid #ccc'}}
                                    />
                                </div>
                                <div className="form__group__add__load">
                                    <label htmlFor="ZIP">ZIP code
                                        {errors.zipPickUp &&
                                            <span className="error-message">This field is empty <CiCircleAlert/></span>}
                                        {errors.zipPickUpLength &&
                                            <span className="error-message">ZIP code should be 5 digits <CiCircleAlert/></span>}
                                    </label>

                                    <input
                                        type="text"
                                        name="zipPickUp"
                                        value={pickUpFormState.zipPickUp}
                                        onChange={(e) => handleInputChange('zipPickUp', e.target.value)}
                                        style={{border: errors.zipPickUp ? '1px solid #FF3939' : '1px solid #ccc'}}
                                    />
                                </div>

                                <div className="form__group__add__load__checkbox">
                                    <div className="form__group__add__load__checkbox">
                                        <div className="form_group__remember__container"
                                             onChange={handlePickUpCheckboxChange}>
                                            <input
                                                onChange={handlePickUpCheckboxChange}
                                                type="checkbox"
                                                id="pickUpCheckbox"
                                                name="pickUpCheckbox"
                                            />
                                            <label htmlFor="pickUpCheckbox">time interval
                                                {errors.timeInterval &&
                                                    <span
                                                        className="error-message">This field is empty <CiCircleAlert/></span>}
                                            </label>
                                        </div>
                                    </div>


                                    <div className="form__group__add__load__time">
                                        <div className="add__load__from__to">
                                            {errors.fromTime &&
                                                <span
                                                    className="error-message">This field is empty <CiCircleAlert/></span>}
                                            <div className="add__load__from">
                                                <div className="add__load__from__date">
                                                    <div className='add__load__from__date__label'>from
                                                    </div>
                                                    <DatePicker
                                                        name="fromTime"
                                                        selected={fromTime}
                                                        onChange={(date) => setFromTime(date)}
                                                        dateFormat="MM.dd.yyyy"
                                                        placeholderText="01.01.1970"
                                                        value={fromTime ? format(fromTime, 'MM.dd.yyyy') : ''}
                                                    />

                                                </div>
                                                <div className="add__load__from__time">

                                                    <div className="add__load__from__time__error">
                                                        {errors.pickUpFromHour && (
                                                            <span
                                                                className="error-message">This field is empty <CiCircleAlert/></span>
                                                        )}
                                                    </div>

                                                    <TimePicker
                                                        name="fromHour"
                                                        disableClock={true}
                                                        format="h:mm a"
                                                        placeholder="10:00"
                                                        format12Hours
                                                        clearIcon={null}
                                                        onChange={(time) => {
                                                            handleTimeChange('pickUpFromHour', time);
                                                            const isTimeValid = !!time;
                                                            setErrors((prevErrors) => ({
                                                                ...prevErrors,
                                                                pickUpFromHour: !isTimeValid,
                                                            }));
                                                        }}
                                                        value={pickUpFromHour || null}
                                                    />

                                                </div>
                                            </div>

                                            <div className="add__load__to" disabled={!pickUpFormState.timeInterval}>
                                                <div className="add__load__to__date">
                                                    <div className='add__load__from__date__label '>to</div>
                                                    <DatePicker
                                                        selected={toTime}
                                                        onChange={(date) => setToTime(date)}
                                                        dateFormat="MM.dd.yyyy"
                                                        placeholderText="07.08.2023"
                                                        disabled={!pickUpFormState.timeInterval}
                                                        name="toTime"
                                                        value={toTime ? format(toTime, 'MM.dd.yyyy') : ''}
                                                    />


                                                </div>
                                                <div className="add__load__to__time">
                                                    <TimePicker
                                                        disableClock={true}
                                                        format="h:mm a"
                                                        placeholder="10:00"
                                                        format12Hours
                                                        clearIcon={null}
                                                        onChange={(time) => handleTimeChange('pickUpToHour', time)}
                                                        value={pickUpToHour || null}
                                                    />


                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="title__add__load">
                                    Load details
                                </div>

                                <div className="add__load__status">

                                    {loadStatusState.map((status) => (
                                        <label
                                            key={status.id}
                                            className="add_load__status"
                                            style={{
                                                backgroundColor: selectedStatus[status.id] ? '#FFF' : '',
                                                color: selectedStatus[status.id] ? status.color : '#000',
                                                border: selectedStatus[status.id] ? `1px solid ${status.color}` : '1px solid transparent',
                                                borderRadius: selectedStatus[status.id] ? '12px' : '',
                                            }}
                                        >
                                            <input
                                                type="checkbox"
                                                value={status.value}
                                                checked={selectedStatus[status.id] !== undefined}
                                                onChange={() => handleStatusChange(status)}
                                                name={status.value}
                                            />
                                            {status.value}
                                        </label>
                                    ))}

                                </div>


                                <div className="form__group__add__load">
                                    <label htmlFor="Commodity">Commodity
                                        {errors.commodity &&
                                            <span className="error-message">This field is empty <CiCircleAlert/></span>}
                                        {errors.commodityLength &&
                                            <span
                                                className="error-message">Maximum 120 characters <CiCircleAlert/></span>}
                                    </label>

                                    <input
                                        type="text"
                                        name="commodity"
                                        placeholder="Enter commodity"
                                        value={pickUpFormState.commodity}
                                        style={{border: errors.commodity ? '1px solid #FF3939' : '1px solid #ccc'}}
                                        onChange={(e) => handleInputChange('commodity', e.target.value)}
                                    />
                                </div>
                                <div className="form__group__add__load">
                                    <label htmlFor="Weight">Weight
                                        {errors.weight &&
                                            <span
                                                className="error-message">This field is empty <CiCircleAlert/> </span>}
                                    </label>
                                    <input
                                        type="text"
                                        name="1,000 lb"
                                        placeholder="Enter the Weight"
                                        style={{border: errors.weight ? '1px solid #FF3939' : '1px solid #ccc'}}
                                        value={pickUpFormState.weight}
                                        onChange={(e) => handleInputChange('weight', e.target.value)}
                                    />
                                </div>
                                <div className="form__group__add__load__sizes">
                                    <label htmlFor="Sizes">Sizes

                                    </label>
                                    <div className="sizes__container">
                                        <div className="sizes__weight">
                                            <div className="weight__block">
                                                W
                                            </div>
                                            <div className="add__load__weight__input">
                                                <input
                                                    placeholder='0 ft'
                                                    type="text"
                                                    value={pickUpFormState.width !== undefined ? pickUpFormState.width : ''}
                                                    style={{border: errors.width ? '1px solid #FF3939' : '1px solid #ccc'}}
                                                    onChange={(e) => handleInputChange('width', e.target.value)}
                                                />

                                            </div>
                                        </div>
                                        <div className="sizes__length">
                                            <div className="length__block">
                                                L

                                            </div>
                                            <div className="add__load__length__input">
                                                <input
                                                    placeholder='0 ft'
                                                    type="text"
                                                    style={{border: errors.length ? '1px solid #FF3939' : '1px solid #ccc'}}
                                                    value={pickUpFormState.length || ''}
                                                    onChange={(e) => handleInputChange('length', e.target.value)}
                                                />

                                            </div>
                                        </div>
                                        <div className="sizes__height">
                                            <div className="height__block">
                                                H
                                            </div>
                                            <div className="add__load__height__input">
                                                <input
                                                    placeholder='0 ft'
                                                    type="text"
                                                    style={{border: errors.height ? '1px solid #FF3939' : '1px solid #ccc'}}
                                                    value={pickUpFormState.height !== undefined ? pickUpFormState.height : ''}
                                                    onChange={(e) => handleInputChange('height', e.target.value)}
                                                />

                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="form__group__add__load">
                                    <label htmlFor="Miles">Miles
                                        {errors.miles &&
                                            <span className="error-message">This field is empty <CiCircleAlert/></span>}
                                    </label>
                                    <input
                                        type="text"
                                        name="miles"
                                        style={{border: errors.miles ? '1px solid #FF3939' : '1px solid #ccc'}}
                                        placeholder="0 mi"
                                        value={pickUpFormState.miles}
                                        onChange={(e) => handleInputChange('miles', e.target.value)}
                                    />
                                </div>
                                <div className="form__group__add__load">
                                    <label htmlFor="Gross">Gross
                                        {errors.gross &&
                                            <span className="error-message">This field is empty <CiCircleAlert/></span>}
                                    </label>
                                    <input
                                        type="text"
                                        name="gross"
                                        style={{border: errors.gross ? '1px solid #FF3939' : '1px solid #ccc'}}
                                        placeholder="0 $"
                                        value={pickUpFormState.gross}
                                        onChange={(e) => handleInputChange('gross', e.target.value)}
                                    />
                                </div>
                                {/*<div className="form_group__remember__container">*/}
                                {/*    <div className="form__group__remember__checkbox">*/}
                                {/*        <input*/}
                                {/*            onChange={handleDeliveryCheckboxChange}*/}
                                {/*            type="checkbox"*/}
                                {/*            id="deliveryCheckbox"*/}
                                {/*            name="deliveryCheckbox"*/}
                                {/*            checked={deliveryFormState.deliveryTimeInterval} // Додано 'checked' атрибут*/}
                                {/*        />*/}
                                {/*        <label htmlFor="myCheckbox">Hide this load from driver*/}
                                {/*        </label>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>
                            <div className="modal__add__load__form__right">
                                <div className="form__group__add__load">
                                    <label htmlFor="Delivery">Delivery
                                        {errors.delivery &&
                                            <span className="error-message">This field is empty <CiCircleAlert/></span>}
                                        {errors.deliveryLength &&
                                            <span
                                                className="error-message">Maximum 120 characters <CiCircleAlert/></span>}
                                    </label>

                                    <input
                                        type="text"
                                        name="delivery"
                                        placeholder="State, city"
                                        value={deliveryFormState.delivery}
                                        onChange={(e) => handleInputChange('delivery', e.target.value, true)}
                                        style={{border: errors.delivery ? '1px solid #FF3939' : '1px solid #ccc'}}
                                    />
                                </div>
                                <div className="form__group__add__load">
                                    <label htmlFor="Street address">Street address
                                        {errors.streetAddressDelivery &&
                                            <span className="error-message">This field is empty <CiCircleAlert/></span>}
                                        {errors.streetAddressDeliveryLength &&
                                            <span
                                                className="error-message">Maximum 120 characters <CiCircleAlert/></span>}
                                    </label>

                                    <input
                                        type="text"
                                        name="streetAddressDelivery"
                                        placeholder="Street address"
                                        value={deliveryFormState.streetAddressDelivery}
                                        style={{border: errors.streetAddressDelivery ? '1px solid #FF3939' : '1px solid #ccc'}}
                                        onChange={(e) => handleInputChange('streetAddressDelivery', e.target.value, true)}
                                    />
                                </div>
                                <div className="form__group__add__load">
                                    <label htmlFor="ZIPdelivery">ZIP Code
                                        {errors.ZIPdelivery &&
                                            <span className="error-message">This field is empty <CiCircleAlert/></span>}
                                        {errors.ZIPdeliveryLength &&
                                            <span
                                                className="error-message">ZIP code should be 5 digits
 <CiCircleAlert/></span>}
                                    </label>

                                    <input
                                        type="text"
                                        name="ZIPdelivery"
                                        value={deliveryFormState.ZIPdelivery}
                                        onChange={(e) => handleInputChange('ZIPdelivery', e.target.value, true)}
                                        style={{border: errors.ZIPdelivery ? '1px solid #FF3939' : '1px solid #ccc'}}
                                    />
                                </div>
                                <div className="form__group__add__load__checkbox">
                                    <div className="form_group__remember__container"
                                         onChange={handleDeliveryCheckboxChange}>
                                        <input
                                            onChange={handleDeliveryCheckboxChange}
                                            type="checkbox"
                                            id="deliveryCheckbox"
                                            name="deliveryCheckbox"
                                        />
                                        <label htmlFor="deliveryCheckbox">time interval</label>
                                    </div>

                                    <div className="form__group__add__load__time">
                                        <div className="add__load__from__to">
                                            {errors.fromTime2 && (
                                                <span
                                                    style={{color: 'red'}}>This field is empty <CiCircleAlert/> </span>
                                            )}
                                            <div className="add__load__from">

                                                <div className="add__load__from__date">

                                                    <div className='add__load__from__date__label'>from</div>
                                                    <DatePicker
                                                        selected={fromTime2}
                                                        onChange={(date) => {
                                                            setFromTime2(date);
                                                            const isDateValid = !!date;
                                                            setErrors((prevErrors) => ({
                                                                ...prevErrors,
                                                                fromTime2: !isDateValid,
                                                            }));
                                                        }}
                                                        dateFormat="MM.dd.yyyy"
                                                        placeholderText="07.08.2023"
                                                        value={fromTime2 ? format(fromTime2, 'MM.dd.yyyy') : ''}
                                                    />


                                                </div>
                                                <div className="add__load__from__time">
                                                    {errors.deliveryFromHour && (
                                                        <span
                                                            style={{color: 'red'}}>This field is empty <CiCircleAlert/> </span>
                                                    )}
                                                    <TimePicker
                                                        disableClock={true}
                                                        format="h:mm a"
                                                        placeholder="10:00"
                                                        format12Hours
                                                        clearIcon={null}
                                                        onChange={(time) => {
                                                            handleTimeChange('deliveryFromHour', time);

                                                            // Validation and display error message
                                                            const isTimeValid = !!time;
                                                            setErrors((prevErrors) => ({
                                                                ...prevErrors,
                                                                deliveryFromHour: !isTimeValid,
                                                            }));
                                                        }}
                                                        value={deliveryFromHour || null}
                                                    />


                                                </div>
                                            </div>

                                            <div className="add__load__to"
                                                 disabled={!deliveryFormState.deliveryTimeInterval}>
                                                <div className="add__load__to__date">

                                                    <div className='add__load__from__date__label '>to</div>
                                                    <DatePicker
                                                        selected={toTime2}
                                                        onChange={(date) => setToTime2(date)}
                                                        dateFormat="MM.dd.yyyy"
                                                        placeholderText="07.08.2023"
                                                        disabled={!deliveryFormState.deliveryTimeInterval}
                                                        value={toTime2 ? format(toTime2, 'MM.dd.yyyy') : ''}
                                                    />

                                                </div>
                                                <div className="add__load__to__time">
                                                    <TimePicker
                                                        disableClock={true}
                                                        format="h:mm a"
                                                        placeholder="10:00"
                                                        format12Hours
                                                        clearIcon={null}
                                                        onChange={(time) => {
                                                            handleTimeChange('deliveryToHour', time);

                                                            // Validation and display error message
                                                            const isTimeValid = !!time;
                                                            setErrors((prevErrors) => ({
                                                                ...prevErrors,
                                                                deliveryToHour: !isTimeValid,
                                                            }));
                                                        }}
                                                        value={deliveryToHour || null}
                                                    />


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="title__client__contacts">
                                    Client contacts
                                </div>

                                <div className="form__group__add__load">

                                    {errors.clientName && (
                                        <span className="error-message">
        {errors.clientName ? 'This field is empty' : errors.clientNameMessage}
                                            <CiCircleAlert/>
    </span>
                                    )}
                                    {customers.some(customer => customer.clientName === pickUpFormState.clientContacts.clientName && customer.blacklist === "true") && (
                                        <p style={{color: 'red'}}>Blacklist client <CiCircleAlert/></p>
                                    )}
                                    <div className='customers__search__component'
                                         style={customers.some(customer => customer.clientName === pickUpFormState.clientContacts.clientName && customer.blacklist === "true") ? {border: "1px solid red"} : {border: "1px solid #ccc"}}
                                    >
                                        <input
                                            type="text"
                                            placeholder="Enter client name"
                                            style={{border: errors.clientName ? '1px solid #FF3939' : 'none'}}
                                            value={pickUpFormState.clientContacts.clientName}
                                            onChange={(e) => handleInputChange('clientName', e.target.value)}
                                            onClick={handleInputClick}
                                        />


                                        <div className={`custom-select ${isDropdownOpen ? 'active' : ''}`}
                                             onClick={toggleCustomSelect}>
                                            <IoIosArrowDown/>
                                            <div className="options-list">
                                                {filteredCustomers.slice(0, 5).map((customer, index) => (
                                                    <div key={index} className={`option-item`}
                                                         onClick={() => handleSelectOption(customer)}>
                                                        <label>{customer.clientName}</label>
                                                        <div className="checkbox"></div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="manual-input-section">
                                        <div className="form__group__add__load">
                                            <label htmlFor="Client Phone">Client Phone
                                                {errors.clientPhone &&
                                                    <span
                                                        className="error-message">This field is empty <CiCircleAlert/></span>}
                                            </label>
                                            <InputMask
                                                mask="+1 (999) 999 9999, + 1 (999) 999 9999"
                                                maskChar=" "
                                                type="text"
                                                name="clientPhone"
                                                placeholder="+555 1234 699"
                                                style={{
                                                    border: (errors.clientPhone || customers.some(customer => customer.clientName === pickUpFormState.clientContacts.clientName && customer.blacklist === "true")) ? '1px solid red' : '1px solid #ccc',
                                                }}

                                                value={pickUpFormState.clientContacts.clientPhone}
                                                onChange={(e) => handleInputChange('clientPhone', e.target.value)}
                                            />

                                        </div>
                                        <div className="form__group__add__load">
                                            <label htmlFor="Company name">Company name
                                                {(errors.companyName || errors.companyNameMessage) &&
                                                    <span className="error-message">
            {errors.companyName ? 'This field is empty' : errors.companyNameMessage}
                                                        <CiCircleAlert/>
        </span>}
                                            </label>
                                            <input
                                                type="text"
                                                name="companyName"
                                                style={{
                                                    border: (errors.companyName || errors.companyNameMessage || (customers.some(customer => customer.companyName === pickUpFormState.clientContacts.companyName && customer.blacklist === "true"))) ? '1px solid #FF3939' : '1px solid #ccc',
                                                }}
                                                placeholder="TQL Logistics"
                                                value={pickUpFormState.clientContacts.companyName}
                                                onChange={(e) => handleInputChange('companyName', e.target.value)}
                                            />
                                        </div>
                                        <div className="form__group__add__load">
                                            <label htmlFor="Client Email">username@mail.com
                                                {errors.clientEmail &&
                                                    <span
                                                        className="error-message">This field is empty <CiCircleAlert/></span>}
                                            </label>
                                            <input
                                                type="text"
                                                style={{
                                                    border: (errors.clientEmail || (customers.some(customer => customer.clientEmail === pickUpFormState.clientContacts.clientEmail && customer.blacklist === "true"))) ? '1px solid #FF3939' : '1px solid #ccc',
                                                }}
                                                name="clientEmail"
                                                placeholder="Enter the Client Email"
                                                value={pickUpFormState.clientContacts.clientEmail}
                                                onChange={(e) => handleInputChange('clientEmail', e.target.value)}
                                            />

                                        </div>
                                        <div className="form__group__add__load">
                                            <label htmlFor="MC Number">MC Number
                                                {(errors.mcNumber || errors.mcNumberMessage) &&
                                                    <span className="error-message">
            {errors.mcNumber ? 'This field is empty' : errors.mcNumberMessage}<CiCircleAlert/>
        </span>}
                                            </label>
                                            <input
                                                type="text"
                                                name="mcNumber"
                                                style={{
                                                    border: (errors.mcNumber || errors.mcNumberMessage || (customers.some(customer => customer.mcNumber === pickUpFormState.clientContacts.mcNumber && customer.blacklist === "true"))) ? '1px solid #FF3939' : '1px solid #ccc',
                                                }}
                                                placeholder="293829839"
                                                value={pickUpFormState.clientContacts.mcNumber}
                                                onChange={(e) => handleInputChange('mcNumber', e.target.value)}
                                            />

                                        </div>
                                    </div>
                                </div>
                                {customers.some(customer => customer.clientName === pickUpFormState.clientContacts.clientName && customer.blacklist === "true") && (
                                    <div className="messageWhyCustomerBlackList">
                                        <p>{pickUpFormState.clientContacts.comment}</p>
                                    </div>
                                )}
                                <div className="form__group__add__load__btn">

                                    <button className="add__load__btn" type="submit" onClick={handleSubmit}
                                            disabled={isLoading}>
                                        {isLoading ?
                                            <LoaderComponent/> : null}
                                        {isLoading ? 'Creating...' : (loadId ? 'Edit load' : 'Add load')}
                                    </button>


                                    <button className="cancel__load__btn" onClick={closeModal} type="button">Cancel
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </>

    )
}