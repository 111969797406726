import NoteComponent from "../NoteComponent/NoteComponent";
import StatusComponent from "../StatusComponent/StatusComponent";
import './StatusNoteComponent.scss'


export default function StatusNoteComponent() {
    return (
        <>
        <div className="status__note__driver__wrapper">
            <NoteComponent/>
            <StatusComponent/>
            </div>
        </>
    )
}