import React, {useState, useEffect, useRef} from 'react';
import './Navigation.scss';
import {NavLink, useLocation} from 'react-router-dom';
import logoMenuIcon from '../../assets/svg/logoNav.svg';
import {useTranslation} from 'react-i18next';
import LogOutIcon from '../../assets/svg/dispatcher/logOut.svg';
import {BsList} from 'react-icons/bs';
import {BsX} from 'react-icons/bs';
import {useSelector, useDispatch} from 'react-redux';
import {logout} from '../../store/actions/authActions';
import {DispatcherSidebarData, DriverSidebarData, AdminSidebarData} from './NavData';
import {fetchUserSettings} from "../../store/actions/settingsActions";
import profileImg from "../../assets/svg/profile.svg";

const Navigation = React.memo(() => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const location = useLocation();
    const [activeItem, setActiveItem] = useState(null);
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
    const userRole = useSelector((state) => state.auth.userData?.roleName);
    const userPhoto = useSelector((state) => state.settings?.settingsData?.profileIcon);
    const userData = useSelector((state) => state.settings.settingsData);
    const [hasFetchedSettings, setHasFetchedSettings] = useState(false);

    useEffect(() => {
        if (!hasFetchedSettings) {
            dispatch(fetchUserSettings())
                .then(() => {
                    setHasFetchedSettings(true);
                })
                .catch(() => {
                    // Обробка помилки при отриманні налаштувань користувача
                });
        }
    }, [dispatch, hasFetchedSettings]);


    const SidebarDataMobile = isMobileMenuOpen
        ? (userRole === 'DISPATCHER' ? DispatcherSidebarData :
            userRole === 'DRIVER' ? DriverSidebarData :
                userRole === 'ADMIN' ? AdminSidebarData : [])
        : [];

    const SidebarDataDesktop = (userRole === 'DISPATCHER' ? DispatcherSidebarData :
        userRole === 'DRIVER' ? DriverSidebarData :
            userRole === 'ADMIN' ? AdminSidebarData : []);

    let SidebarData = isMobileMenuOpen ? SidebarDataMobile : SidebarDataDesktop;

    const getProfileLink = () => {
        switch (userRole) {
            case 'DISPATCHER':
                return '/dispatcher/profile';
            case 'DRIVER':
                return '/driver/profile';
            case 'ADMIN':
                return '/admin/profile';
            default:
                return '/';
        }
    };

    const handleLogout = () => {
        dispatch(logout());
    };

    const handleMouseEnter = (index) => {
        setActiveItem(index);
    };

    const handleMouseLeave = () => {
        setActiveItem(null);
    };

    const isCurrentPath = (path) => {
        return location.pathname === path ? 'current' : '';
    };

    const mobileMenuRef = useRef(null);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (mobileMenuRef.current && !mobileMenuRef.current.contains(event.target)) {
                setMobileMenuOpen(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    const toggleMobileMenu = () => {
        setMobileMenuOpen(!isMobileMenuOpen);
    };

    return (
        <>
            <div className={`navigation__dispatcher__mobile ${isMobileMenuOpen ? 'hidden' : ''}`}>
                <div className="navigation__burger" onClick={toggleMobileMenu}>
                    {isMobileMenuOpen ? <BsX/> : <BsList/>}
                </div>
                <div className="navigation__logo__mobile">
                    <img src={logoMenuIcon} alt="Лого"/>
                </div>
            </div>

            <div className={isMobileMenuOpen ? "mobile__menu open" : "mobile__menu"} ref={mobileMenuRef}>
                <div className="close__mobile__icons" onClick={toggleMobileMenu}>
                    <BsX/>
                </div>
                <div className="mobile__menu__container">
                    <div className="mobile__menu__logo">
                        <img src={logoMenuIcon} alt="Лого"/>
                    </div>
                    <div className="mobile__items__menu">
                        {SidebarData.map((item, index) => (
                            <div className="menu__item" key={index}>
                                <NavLink
                                    to={item.path}
                                    className={`menu__link ${isCurrentPath(item.path)}`}
                                    onMouseEnter={() => handleMouseEnter(index)}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    <div
                                        className={`item__left__side ${activeItem === index || isCurrentPath(item.path) ? 'active current' : ''}`}></div>
                                    <div className='menu__without__panels'>
                                        <div className="left__menu__item">
                                            <div
                                                className={`menu__icon ${activeItem === index ? 'active' : ''}`}>{item.icon}</div>
                                            <span
                                                className={`menu__title ${activeItem === index ? 'active' : ''}`}>{item.title}</span>
                                        </div>
                                    </div>
                                    <div
                                        className={`item__right__side ${activeItem === index || isCurrentPath(item.path) ? 'active current' : ''}`}></div>
                                </NavLink>
                            </div>
                        ))}
                    </div>
                    <div className="logout__item__mobile" onClick={handleLogout}>
                        <img src={LogOutIcon} alt="logout"/>
                        <span className="logout__text">{t('Log out')}</span>
                    </div>
                </div>
            </div>

            <div className="navigation__dispatcher">
                <div className="navigation__logo">
                    <img src={logoMenuIcon} alt="Лого"/>
                </div>
                <div className="menu__container">
                    <div className="navigation__profile">
                        <NavLink to={getProfileLink()}
                                 className={`menu__link__profile ${isCurrentPath(getProfileLink())}`}>
                            <div className="profile__photo__nav__menu">
                                {userPhoto ? (
                                    <img src={`//${userPhoto}`} alt="Profile"/>
                                ) : (
                                    <img src={profileImg} alt="Profile"/>
                                )}
                            </div>
                            <div className="name__profile">{userData?.firstName}</div>
                        </NavLink>
                    </div>

                    <div className="navigation__menu">
                        {SidebarData.map((item, index) => (
                            <div className="menu__item" key={index}>
                                <NavLink
                                    to={item.path}
                                    className={`menu__link ${isCurrentPath(item.path)}`}
                                    onMouseEnter={() => handleMouseEnter(index)}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    <div
                                        className={`item__left__side ${activeItem === index || isCurrentPath(item.path) ? 'active current' : ''}`}></div>
                                    <div className="left__menu__item">
                                        <div
                                            className={`menu__icon ${activeItem === index ? 'active' : ''}`}>{item.icon}</div>
                                        <span
                                            className={`menu__title ${activeItem === index ? 'active' : ''}`}>{item.title}</span>
                                    </div>
                                    <div
                                        className={`item__right__side ${activeItem === index || isCurrentPath(item.path) ? 'active current' : ''}`}></div>
                                </NavLink>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="logout__item" onClick={handleLogout}>
                    <img src={LogOutIcon} alt="logout"/>
                    <span className="logout__text ">{t('Log out')}</span>
                </div>
            </div>
        </>


    );
});
export default Navigation;