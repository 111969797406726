

import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const PrivateRouteMiddleware = ({ children, role, allowedRoles }) => {
    const { isLoggedIn } = useSelector((state) => state.auth);
    const isAuthorized = isLoggedIn && allowedRoles.includes(role);

    return isAuthorized ? children : <Navigate to="/" />;
};

export default PrivateRouteMiddleware;
