import React, {useEffect, useState} from 'react';
import './AddFIlesComponentTrailersModal.scss';
import {useDispatch, useSelector} from 'react-redux';
import {fetchFilesDropdowns, uploadFile} from "../../../../../store/actions/trailerActions";
import DatePicker from "react-datepicker";
import {IoClose} from 'react-icons/io5';
import {FaCheck} from "react-icons/fa6";
import {MdKeyboardArrowDown} from "react-icons/md";
import {MdKeyboardArrowUp} from "react-icons/md";
import LoaderComponent from '../../../../LoaderComponent/LoaderComponent';
   
const AddFilesTrailersComponentModal = ({closeModal, trailerId}) => {
    const dispatch = useDispatch();
    const filesDropdowns = useSelector(state => state.trailer.singleTrailer.filesDropdowns);
    const [selectedDropdownId, setSelectedDropdownId] = useState('');
    const [nextMaintenance, setNextMaintenance] = useState('');
    const [fileDescription, setFileDescription] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isSelectOpen, setIsSelectOpen] = useState(false);
    const [selectedDocumentType, setSelectedDocumentType] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [validationErrors, setValidationErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [selectedFileName, setSelectedFileName] = useState('');
    const [fileUploaded, setFileUploaded] = useState(false);
    useEffect(() => {
        dispatch(fetchFilesDropdowns());
    }, [dispatch]);
    const handleNextMaintenanceChange = (date) => {

        const addZero = (num) => (num < 10 ? `0${num}` : num);
        const year = date.getFullYear();
        const month = addZero(date.getMonth() + 1);
        const day = addZero(date.getDate());
        const formattedDate = `${year}-${month}-${day}`;
       setNextMaintenance(date);
        
        
        setValidationErrors(prevErrors => ({...prevErrors, nextMaintenance: ''}));
    };
    const toggleCustomSelect = () => {
        setIsDropdownOpen(!isDropdownOpen);
        setIsSelectOpen(!isSelectOpen);
    };


    const handleFileDescriptionChange = (e) => {
        setFileDescription(e.target.value);
        setValidationErrors(prevErrors => ({...prevErrors, fileDescription: ''}));
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setSelectedFile(selectedFile);
        setSelectedFileName(selectedFile.name); 
        setValidationErrors(prevErrors => ({...prevErrors, selectedFile: ''}));
        setFileUploaded(true); 
    };
    const handleCategoryChange = async (event) => {
        const category = event.target.value;
        setSelectedCategory(category);
        setSelectedDocumentType(category);
        setSelectedDropdownId(category);
        console.log(selectedDropdownId);
        setValidationErrors(prevErrors => ({...prevErrors, selectedDropdownId: ''}));
    }
    const validateFields = () => {
        let errors = {};
        let isValid = true;
    
        if (!selectedDocumentType) {
            errors.selectedDropdownId = 'Please select a document category';
            isValid = false;
        }
    
        if (!nextMaintenance) {
            errors.nextMaintenance = 'Please select the next maintenance date';
            isValid = false;
        }
    
        if (!fileDescription.trim()) {
            errors.fileDescription = 'Please enter a file description';
            isValid = false;
        }
    
        if (!selectedFile) {
            errors.selectedFile = 'Please select a file to upload';
            isValid = false;
        }
    
        setValidationErrors(errors);
    
        return isValid;
    };
    
    
    const handleSubmit = async () => {
        if (validateFields()) {
            setIsLoading(true);
            try {
                const formattedDate = nextMaintenance.toISOString().split('T')[0]; // Форматування дати в формат "YYYY-MM-DD"
                console.log(formattedDate);
                await dispatch(uploadFile(selectedFile, fileDescription, formattedDate, selectedDropdownId, trailerId))
                closeModal();
            } catch (error) {
                console.error("Error uploading files:", error);
            } finally {
                setIsLoading(false); // Після відправки (незалежно від результату) встановлюємо isLoading в false
            }
        }
    };


    return (
        <div className="modal-overlay__add__files__trailer" onClick={closeModal}>
            <div className="modal-content__add__files__trailer" onClick={(e) => e.stopPropagation()}>
                <div className="modal-content__head__add__files__trailer">
                    <div>
                        Add document
                        
                    </div>
                    <div className='modal-content__close' onClick={closeModal}><IoClose/></div>
                </div>

                <div className="add__files__trailer">
                    <div className="form__group__add__files__trailer">
                        <label htmlFor="fileType">Document category</label>
                        
                        {validationErrors.selectedDropdownId &&
                            <span className="error-message">{validationErrors.selectedDropdownId}</span>}
                        <div className="form-group_add__document__driver">
                            <div className={`custom-select doc_category__main ${isDropdownOpen ? 'active' : ''}`}
                                 onClick={toggleCustomSelect}>
                                <div className="selected-options doc__category">
                                    <div
                                        className="selected-option doc__font">{selectedDocumentType ? filesDropdowns.find(item => item.id === selectedDocumentType)?.value : 'Select category'}</div>
                                    <div className="selected-option doc__category">{isDropdownOpen ?
                                        <MdKeyboardArrowUp/> : <MdKeyboardArrowDown/>}</div>
                                </div>
                                <div className="select-icon">
                                </div>
                                <div className="options-list doc">
                                    {filesDropdowns.map((item) => (
                                        <div key={item.id}
                                             className={`option-item ${item.id === selectedDocumentType ? 'active' : ''}`}
                                             onClick={(e) => handleCategoryChange({target: {value: item.id}})}>
                                            <label>
                                                {item.value}
                                            </label>
                                            <div className="checkbox">
                                                {item.id === selectedDocumentType && <FaCheck/>}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form__group__add__files__trailer">
                        <label htmlFor="nextMaintenance">Next maintenance</label>
                        {validationErrors.nextMaintenance &&
                            <span className="error-message">{validationErrors.nextMaintenance}</span>}
                        <DatePicker className='date__picker__add__files__trailer'
                                    selected={nextMaintenance}
                                    onChange={handleNextMaintenanceChange}
                                    dateFormat="MM-dd-yyyy"
                                    showYearDropdown
                        />
                    </div>
                    <div className="form__group__add__files__trailer">
                        <label htmlFor="fileDescription">File description</label>
                        {validationErrors.fileDescription &&
                            <span className="error-message">{validationErrors.fileDescription}</span>}
                        <textarea name="fileDescription" value={fileDescription}
                                  onChange={handleFileDescriptionChange}></textarea>
                    </div>

                    <div className="file-input-container">
                        {!selectedFileName && ( // Перевірка, чи файл не обраний
                            <div>
                                <input
                                    type="file"
                                    name="file"
                                    id="file"
                                    className="file-input"
                                    onChange={handleFileChange}
                                />
                                <label htmlFor="file" className="file-upload-text">
                                    Upload File
                                </label>
                            </div>
                        )}
                        {selectedFileName && ( // Перевірка, чи файл обраний
                            <div >
                                <label htmlFor="file" className="file-upload-text">
                                    {selectedFileName}
                                    <div className="deleteFileTrailer" onClick={() => setSelectedFileName('')}><IoClose/>
                                    </div>
                                </label>


                            </div>
                        )}
                        {validationErrors.selectedFile && (
                            <span className="error-message">{validationErrors.selectedFile}</span>
                        )}
                    </div>


                    <div className="btn_add__files__trailer">
                        {isLoading ? (
                            <LoaderComponent/>
                        ) : (
                            <button className="add_file__trailer" onClick={handleSubmit}>Add file</button>
                        )}
                        <button className="cancel_file__trailer" onClick={closeModal}>Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddFilesTrailersComponentModal;
