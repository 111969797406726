import axios from 'axios';
import {logout} from "./authActions";

export const fetchSettingsRequest = () => ({
    type: 'FETCH_SETTINGS_REQUEST',
});

export const fetchSettingsSuccess = (settingsData) => ({
    type: 'FETCH_SETTINGS_SUCCESS',
    payload: settingsData,
});

export const fetchSettingsFailure = (error) => ({
    type: 'FETCH_SETTINGS_FAILURE',
    payload: error,
});




export const fetchUserSettings = () => {
    return async (dispatch) => {
        dispatch(fetchSettingsRequest());
        try {
            const {data} = await axios.get(`${process.env.REACT_APP_SETTINGS_API_URL}`, {
                withCredentials: true,
            });
            dispatch(fetchSettingsSuccess(data));
        } catch (error) {
            if (error.response && error.response.status === 401) {
                dispatch(logout());
            }
            dispatch(fetchSettingsFailure(error.response ? error.response.data : 'Unknown error'));
        }
    };
};



export const updateSettingsRequest = () => ({
    type: 'UPDATE_SETTINGS_REQUEST',
});

export const updateSettingsSuccess = (updatedData) => ({
    type: 'UPDATE_SETTINGS_SUCCESS',
    payload: updatedData,
});

export const updateSettingsFailure = (error) => ({
    type: 'UPDATE_SETTINGS_FAILURE',
    payload: error,
});

export const updateUserSettings = (updatedData) => {
    return async (dispatch) => {
        dispatch(updateSettingsRequest());

        try {
            const response = await axios.put(`${process.env.REACT_APP_UPDATE_SETTINGS_API_URL}`, updatedData, {
                withCredentials: true,
            });
            const { data } = response;
            dispatch(updateSettingsSuccess(data));
        } catch (error) {
            if (error.response && error.response.status === 401) {
                dispatch(logout());
            } else {
                if (error.response && error.response.data) {
                    dispatch(updateSettingsFailure(error.response.data));
                } else {
                    dispatch(updateSettingsFailure("An error occurred while updating settings."));
                }
            }
        }
    };
};


export const updateUserPasswordRequest = () => ({
    type: 'UPDATE_USER_PASSWORD_REQUEST',
});

export const updateUserPasswordSuccess = (updatedData) => ({
    type: 'UPDATE_USER_PASSWORD_SUCCESS',
    payload: updatedData,
});

export const updateUserPasswordFailure = (error) => ({
    type: 'UPDATE_USER_PASSWORD_FAILURE',
    payload: error,
});

// Ваш action creator
// Ваш action creator
export const updateUserPassword = (oldPassword, newPassword) => {
    return async (dispatch) => {
        dispatch(updateUserPasswordRequest());

        const passwordData = {
            oldPassword: oldPassword,
            newPassword: newPassword
        };

        try {
            const response = await axios.put(
                `${process.env.REACT_APP_UPDATE_PASSWORD_API_URL}`,
                passwordData,
                {
                    withCredentials: true,
                }
            );

            if (response.status === 200) {
                dispatch(updateUserPasswordSuccess(response.data));
                return response;

            }
            if (response.status === 230) {
                dispatch(updateUserPasswordSuccess(response.data));
                return response;

            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                dispatch(logout());
            } else
            dispatch(updateUserPasswordFailure('Помилка під час оновлення паролю'));
            throw error;
        }
    };
};







export const updateProfilePhotoRequest = () => ({
    type: 'UPDATE_PROFILE_PHOTO_REQUEST',
});

export const updateProfilePhotoSuccess = (updatedData) => ({
    type: 'UPDATE_PROFILE_PHOTO_SUCCESS',
    payload: updatedData,
});

export const updateProfilePhotoFailure = (error) => ({
    type: 'UPDATE_PROFILE_PHOTO_FAILURE',
    payload: error,
});

export const updateProfilePhoto = (formData) => {
    return async (dispatch) => {
        dispatch(updateProfilePhotoRequest());
        try {
            const response = await axios.post(`${process.env.REACT_APP_UPDATE_PHOTO_PROFILE_API_URL}`, formData, {
                withCredentials: true,
            });
            const { data } = response;
            dispatch(updateProfilePhotoSuccess(data));
        } catch (error) {
            if (error.response && error.response.data) {
                dispatch(updateProfilePhotoFailure(error.response.data));
            } else {
                dispatch(updateProfilePhotoFailure('Сталася помилка при оновленні фото профілю.'));
            }
        }
    };
};
