import Navigation from "../../../Components/Navigation/Navigation";
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getUserFiles, getDropdowns} from "../../../store/actions/DriversProfileActions";
import SliderDocumentsComponent from "../../../Components/SliderDocumentsComponent/SliderDocumentsComponent";
import './DriverProfilePage.scss';
import {FaCheck} from "react-icons/fa6";
import {MdKeyboardArrowDown, MdKeyboardArrowUp} from "react-icons/md";

export default function DriverProfilePage() {
    const dispatch = useDispatch();
    // const userFiles = useSelector(state => state.driver.userFiles.files); // це зразу файли
    const driverData = useSelector(state => state.driver);
    const files = useSelector(state => state.driver.userFiles.files);
    const dropdownData = useSelector(state => state.driver.dropdownData);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isSelectOpen, setIsSelectOpen] = useState(false);
    const [selectedDropdownOption, setSelectedDropdownOption] = useState('');
    useEffect(() => {
        dispatch(getDropdowns());
        dispatch(getUserFiles());
    }, [dispatch]);
    const handleCategoryChange = (event) => {
        const category = event.target.value;
        setSelectedCategory(category);
        setSelectedDropdownOption(event.target.value);
    };
    const toggleCustomSelect = (dropdown) => {

        switch (dropdown) {
            case 'categories':
                setIsSelectOpen(prevState => !prevState);
                setIsDropdownOpen(prevState => !prevState);

                break;
            default:
                break;
        }


    };
    return (
        <div className='dp'>
            <div className="driver__profile__page">
                <Navigation/>
                <div className="drivers__profile__component__content custom-scroll">

                    <div className="driver__documents__container">
                        <div className="drivers__profile__docyments__select">
                            <div className="select__documents">
                                {driverData.userFiles.files && driverData.userFiles.files.length > 0 && (

                                    <div className={`custom-select driver_profile ${isDropdownOpen ? 'active' : ''}`}
                                         onClick={() => toggleCustomSelect('categories')}>
                                        <div className="selected-options">
                                            {selectedDropdownOption ? (
                                                <div className="selected-option">
                                                    {dropdownData.find(option => option.id === selectedDropdownOption)?.value}{isSelectOpen ?
                                                    <MdKeyboardArrowUp/> : <MdKeyboardArrowDown/>}
                                                </div>
                                            ) : (
                                                <div className="selected-option">All
                                                    categories{isSelectOpen ? <MdKeyboardArrowUp/> :
                                                        <MdKeyboardArrowDown/>}</div>
                                            )}
                                        </div>
                                        <div className="select-icon">

                                        </div>
                                        <div className="options-list driver_profile">
                                            {dropdownData.map(option => (
                                                <div key={option.id}
                                                     className={`option-item ${option.id === selectedDropdownOption ? 'active' : ''}`}
                                                     onClick={() => handleCategoryChange({target: {value: option.id}})}>

                                                    <label>
                                                        {option.value}
                                                    </label>
                                                    <div className="checkbox">
                                                        {option.id === selectedDropdownOption && <FaCheck/>}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>

                            <SliderDocumentsComponent files={files} selectedCategory={selectedCategory} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}