import './AddCustomerBlackList.scss';
import {IoClose} from "react-icons/io5";
import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {addToBlacklist, fetchCustomers} from "../../../../store/actions/customersActions";

export default function AddCustomerBlackList({closeModal, customerId}) {
    const [customerIdState, setCustomerIdState] = useState(null);
    const dispatch = useDispatch();
    useEffect(() => {
        if (customerId !== null && customerId !== undefined) {
            setCustomerIdState(customerId);
        }
    }, [customerId]);

    const [reason, setReason] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleReasonChange = (event) => {
        const inputValue = event.target.value;
        if (inputValue.length <= 230) {
            setReason(inputValue);
            setErrorMessage('');
        } else {
            setErrorMessage('Maximum characters limit exceeded');
        }
    };

    const handleAddToBlacklist = () => {
        dispatch(addToBlacklist(customerIdState, reason));
        dispatch(fetchCustomers(false));
        closeModal();
    };

    return (
        <div className="modal__add__customerBlackList">
            <div className="modal-content__add__customerBlackList">
                <div className="heading__modal__customerBlackList">
                    <div className="title__modal__customerBlackList">
                        Add to blacklist
                    </div>
                    <div className="close__modal__customerBlackList" onClick={closeModal}>
                        <IoClose/>
                    </div>
                </div>
                <div className="bodyAddBlackListModal">
                    <div className="reasonContainerAddBlackList">
                        <label htmlFor="reasonTextArea">Reason:
                            {errorMessage && <div className="error-message">{errorMessage}</div>}
                        </label>
                        <textarea
                            id="reasonTextArea"
                            value={reason}
                            onChange={handleReasonChange}
                        ></textarea>
                    </div>
                    <div className="btnContainerAddBlackList">
                        <div className="btnAddBlackList" onClick={handleAddToBlacklist}>Add to blacklist</div>
                        <div className="btnCancellackList" onClick={closeModal}>Cancel</div>
                    </div>
                </div>
            </div>
        </div>
    );
}
