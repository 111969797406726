import Navigation from "../../../Components/Navigation/Navigation";
import AnalyticsComponent from "../../../Components/Dispathcer/Analytics/AnalyticsComponents/AnalyticsComponent";
import './AnalyticsPage.scss'

export default function AnalyticsPage() {
    return (
        <div className="analyticts__page__container">
            <Navigation/>
           <AnalyticsComponent/>
        </div>
    );
}