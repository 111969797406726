// reducer.js
import { BLOCK_USER_REQUEST, BLOCK_USER_SUCCESS, BLOCK_USER_FAILURE,CHANGE_PASSWORD_REQUEST, CHANGE_PASSWORD_SUCCESS, CHANGE_PASSWORD_FAILURE} from '../actions/adminEditUserActions';

const initialState = {
    loading: false,
    data: null,
    error: null
};

const blockUserReducer = (state = initialState, action) => {
    switch (action.type) {
        case BLOCK_USER_REQUEST:
            return { ...state, loading: true, error: null };
        case BLOCK_USER_SUCCESS:
            return { ...state, loading: false, data: action.payload, error: null };
        case BLOCK_USER_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case CHANGE_PASSWORD_REQUEST:
            return { ...state, loading: true, error: null };
        case CHANGE_PASSWORD_SUCCESS:
            return { ...state, loading: false, data: action.payload, error: null };
        case CHANGE_PASSWORD_FAILURE:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

export default blockUserReducer;
