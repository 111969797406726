import axios from 'axios';
import {logout} from "./authActions";

export const ADD_DRIVER_REQUEST = 'ADD_DRIVER_REQUEST';
export const ADD_DRIVER_SUCCESS = 'ADD_DRIVER_SUCCESS';
export const ADD_DRIVER_FAILURE = 'ADD_DRIVER_FAILURE';

export const GET_TRUCK_DROPDOWNS_REQUEST = 'GET_TRUCK_DROPDOWNS_REQUEST';
export const GET_TRUCK_DROPDOWNS_SUCCESS = 'GET_TRUCK_DROPDOWNS_SUCCESS';
export const GET_TRUCK_DROPDOWNS_FAILURE = 'GET_TRUCK_DROPDOWNS_FAILURE';
export const getTruckDropdownsRequest = () => ({ type: GET_TRUCK_DROPDOWNS_REQUEST });
export const getTruckDropdownsSuccess = (dropdownData) => ({ type: GET_TRUCK_DROPDOWNS_SUCCESS, payload: dropdownData });
export const getTruckDropdownsFailure = (error) => ({ type: GET_TRUCK_DROPDOWNS_FAILURE, payload: error });



export const addDriverRequest = () => ({type: ADD_DRIVER_REQUEST});
export const addDriverSuccess = () => ({type: ADD_DRIVER_SUCCESS});
export const addDriverFailure = (error) => ({type: ADD_DRIVER_FAILURE, payload: error});

export const GET_DRIVERS_REQUEST = 'GET_DRIVERS_REQUEST'; 
export const GET_DRIVERS_SUCCESS = 'GET_DRIVERS_SUCCESS';
export const GET_DRIVERS_FAILURE = 'GET_DRIVERS_FAILURE';

export const getDriversRequest = () => ({type: GET_DRIVERS_REQUEST});
export const getDriversSuccess = (drivers) => ({type: GET_DRIVERS_SUCCESS, payload: drivers});
export const getDriversFailure = (error) => ({type: GET_DRIVERS_FAILURE, payload: error});

export const GET_DRIVER_REQUEST = 'GET_DRIVER_REQUEST'; 
export const GET_DRIVER_SUCCESS = 'GET_DRIVER_SUCCESS';
export const GET_DRIVER_FAILURE = 'GET_DRIVER_FAILURE';

export const getDriverRequest = () => ({
    type: GET_DRIVER_REQUEST
});

export const getDriverSuccess = (driver) => ({
    type: GET_DRIVER_SUCCESS,
    payload: driver
});

export const getDriverFailure = (error) => ({
    type: GET_DRIVER_FAILURE,
    payload: error
});


export const DELETE_DRIVER_REQUEST = 'DELETE_DRIVER_REQUEST';
export const DELETE_DRIVER_SUCCESS = 'DELETE_DRIVER_SUCCESS';
export const DELETE_DRIVER_FAILURE = 'DELETE_DRIVER_FAILURE';

export const deleteDriverRequest = () => ({type: DELETE_DRIVER_REQUEST});
export const deleteDriverSuccess = (driverId) => ({type: DELETE_DRIVER_SUCCESS, payload: {driverId}});
export const deleteDriverFailure = (error) => ({type: DELETE_DRIVER_FAILURE, payload: error});


export const GET_DROPDOWNS_REQUEST = 'GET_DROPDOWNS_REQUEST';
export const GET_DROPDOWNS_SUCCESS = 'GET_DROPDOWNS_SUCCESS';
export const GET_DROPDOWNS_FAILURE = 'GET_DROPDOWNS_FAILURE';

export const getDropdownsRequest = () => ({type: GET_DROPDOWNS_REQUEST});
export const getDropdownsSuccess = (dropdownData) => ({type: GET_DROPDOWNS_SUCCESS, payload: dropdownData});
export const getDropdownsFailure = (error) => ({type: GET_DROPDOWNS_FAILURE, payload: error});


export const UPLOAD_FILE_REQUEST = 'UPLOAD_FILE_REQUEST';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_FAILURE = 'UPLOAD_FILE_FAILURE';

export const uploadFileRequest = () => ({ type: UPLOAD_FILE_REQUEST });
export const uploadFileSuccess = () => ({ type: UPLOAD_FILE_SUCCESS });
export const uploadFileFailure = (error) => ({ type: UPLOAD_FILE_FAILURE, payload: error });

export const DELETE_FILE_REQUEST = 'DELETE_FILE_REQUEST';
export const DELETE_FILE_SUCCESS = 'DELETE_FILE_SUCCESS';
export const DELETE_FILE_FAILURE = 'DELETE_FILE_FAILURE';

export const deleteFileRequest = () => ({type: DELETE_FILE_REQUEST});
export const deletefileSuccess = () => ({type: DELETE_FILE_SUCCESS});
export const deletefileFailure = (error) => ({type: DELETE_FILE_FAILURE, payload: error});


export const CHANGE_CURRENT_TRUCK_REQUEST = 'CHANGE_CURRENT_TRUCK_REQUEST';
export const CHANGE_CURRENT_TRUCK_SUCCESS = 'CHANGE_CURRENT_TRUCK_SUCCESS';
export const CHANGE_CURRENT_TRUCK_FAILURE = 'CHANGE_CURRENT_TRUCK_FAILURE';


export const changeCurrentTruckRequest = () => ({ type: CHANGE_CURRENT_TRUCK_REQUEST });
export const changeCurrentTruckSuccess = () => ({ type: CHANGE_CURRENT_TRUCK_SUCCESS });
export const changeCurrentTruckFailure = (error) => ({ type: CHANGE_CURRENT_TRUCK_FAILURE, payload: error });

export const CHANGE_CURRENT_TRAILER_REQUEST = 'CHANGE_CURRENT_TRAILER_REQUEST';
export const CHANGE_CURRENT_TRAILER_SUCCESS = 'CHANGE_CURRENT_TRAILER_SUCCESS';
export const CHANGE_CURRENT_TRAILER_FAILURE = 'CHANGE_CURRENT_TRAILER_FAILURE';


export const changeCurrentTrailerRequest = () => ({ type: CHANGE_CURRENT_TRAILER_REQUEST });
export const changeCurrentTrailerSuccess = () => ({ type: CHANGE_CURRENT_TRAILER_SUCCESS });
export const changeCurrentTrailerFailure = (error) => ({ type: CHANGE_CURRENT_TRAILER_FAILURE, payload: error });


export const FETCH_TRAILER_DROPDOWNS_REQUEST = 'FETCH_TRAILER_DROPDOWNS_REQUEST';
export const FETCH_TRAILER_DROPDOWNS_SUCCESS = 'FETCH_TRAILER_DROPDOWNS_SUCCESS';
export const FETCH_TRAILER_DROPDOWNS_FAILURE = 'FETCH_TRAILER_DROPDOWNS_FAILURE';

export const fetchTrailerDropdownsRequest = () => ({
    type: FETCH_TRAILER_DROPDOWNS_REQUEST,
});

export const fetchTrailerDropdownsSuccess = (data) => ({
    type: FETCH_TRAILER_DROPDOWNS_SUCCESS,
    payload: data,
});

export const fetchTrailerDropdownsFailure = (error) => ({
    type: FETCH_TRAILER_DROPDOWNS_FAILURE,
    payload: error,
});


export const GET_USER_FILES_REQUEST = 'GET_USER_FILES_REQUEST';
export const GET_USER_FILES_SUCCESS = 'GET_USER_FILES_SUCCESS';
export const GET_USER_FILES_FAILURE = 'GET_USER_FILES_FAILURE';

export const getUserFilesRequest = () => ({ type: GET_USER_FILES_REQUEST });
export const getUserFilesSuccess = (files) => ({ type: GET_USER_FILES_SUCCESS, payload: files });
export const getUserFilesFailure = (error) => ({ type: GET_USER_FILES_FAILURE, payload: error });


export const addDriver = (driverData) => {
    return async (dispatch) => {
        dispatch(addDriverRequest());

        try {
            await axios.post(`${process.env.REACT_APP_ADD_DRIVER_API_URL}`, driverData, {
                withCredentials: true,
            });
            dispatch(addDriverSuccess());
            dispatch(getDrivers(false));
        } catch (error) {
            if (error.response && error.response.status === 401) {
                dispatch(logout());
            } else {
                dispatch(addDriverFailure(error.message));
            }
        }
    };
};

export const getDrivers = (all) => {
    return async (dispatch) => {
        dispatch(getDriversRequest());

        try {
            const response = await axios.get(`${process.env.REACT_APP_GET_DRIVERS_API_URL}?all=${all}`, {
                withCredentials: true,
            });

            dispatch(getDriversSuccess(response.data));
        } catch (error) {
            if (error.response && error.response.status === 401) {
                dispatch(logout());
            } else {
                dispatch(getDriversFailure(error.message));
            }
        }
    };
};

export const getDriver = (id) => {
    return async (dispatch) => {
        dispatch(getDriverRequest());

        try {
            const response = await axios.get(`${process.env.REACT_APP_GET_DRIVER_API_URL}/${id}`, {
                withCredentials: true,
            });

            dispatch(getDriverSuccess(response.data));
        } catch (error) {
            if (error.response && error.response.status === 401) {
                dispatch(logout());
            } else {
                dispatch(getDriverFailure(error.message));
            }
        }
    };
};


export const deleteDriver = (driverId, all) => {
    return async (dispatch) => {
        dispatch(deleteDriverRequest());

        try {
            await axios.delete(`${process.env.REACT_APP_DELETE_DRIVER_API_URL}/${driverId}?all=${all}`, {
                withCredentials: true,
            });

            dispatch(deleteDriverSuccess(driverId));
        } catch (error) {
            if (error.response && error.response.status === 401) {
                dispatch(logout());
            } else {
                dispatch(deleteDriverFailure(error.message));
            }
        }
    };
};


export const getDropdowns = () => {
    return async (dispatch) => {
        dispatch(getDropdownsRequest());

        try {
            const response = await axios.get(`${process.env.REACT_APP_GET_DROPDOWNS_API_URL}`, {
                withCredentials: true,
            });

            dispatch(getDropdownsSuccess(response.data));
        } catch (error) {
            if (error.response && error.response.status === 401) {
                dispatch(logout());
            } else {
                dispatch(getDropdownsFailure(error.message));
            }
        }
    };
};


export const uploadFile = (driverId, formData) => {
    return async (dispatch) => {
        dispatch(uploadFileRequest());

        try {
            await axios.post(`${process.env.REACT_APP_UPLOAD_FILE_API_URL}/${driverId}/uploadFiles`, formData, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            dispatch(uploadFileSuccess());
        } catch (error) {
            if (error.response && error.response.status === 401) {
                dispatch(logout());
            } else {
                dispatch(uploadFileFailure(error.message));
            }
            throw new Error(error)
        }
    };
};

export const deleteFile = (fileId) => {
    return async (dispatch) => {
        dispatch(deleteFileRequest());

        try {
            await axios.delete(`${process.env.REACT_APP_BASE_URL}/drivers/file/${fileId}`, {
                withCredentials: true,
            }); 

            dispatch(deleteDriverSuccess(fileId));
        } catch (error) {
            if (error.response && error.response.status === 401) {
                dispatch(logout());
            } else {
                dispatch(deleteDriverFailure(error.message));
            }
        }
    };
};

export const getTruckDropdowns = () => {
    return async (dispatch) => {
        dispatch(getTruckDropdownsRequest());

        try {
            const response = await axios.get(`${process.env.REACT_APP_GET_TRUCK_DROPDOWNS_API_URL}`, {
                withCredentials: true,
            });

            dispatch(getTruckDropdownsSuccess(response.data));
        } catch (error) {
            if (error.response && error.response.status === 401) {
                dispatch(logout());
            } else {
                dispatch(getTruckDropdownsFailure(error.message));
            }
        }
    };
};


export const changeCurrentTruck = ({ driverId, truckId }) => {
    return async (dispatch) => {
        dispatch(changeCurrentTruckRequest());

        try {
            await axios.post(
                `${process.env.REACT_APP_CHANGE_CURRENT_TRUCK_API_URL}`,
                { driverId, truckId },
                {
                    withCredentials: true,
                }
            );
            dispatch(changeCurrentTruckSuccess());
        } catch (error) {
            if (error.response && error.response.status === 401) {
                dispatch(logout());
            } else {
                dispatch(changeCurrentTruckFailure(error.message));
            }
        }
    };
};

export const fetchTrailerDropdowns = () => {
    return async (dispatch) => {
        dispatch(fetchTrailerDropdownsRequest());

        try {
            const response = await axios.get(
                `${process.env.REACT_APP_FETCH_TRAILER_DROPDOWNS_API_URL}`,
                {
                    withCredentials: true,
                }
            );

            dispatch(fetchTrailerDropdownsSuccess(response.data));
        } catch (error) {
            if (error.response && error.response.status === 401) {
                dispatch(logout());
            } else {
                dispatch(fetchTrailerDropdownsFailure(error.message));
            }
        }
    };
};
export const changeCurrentTrailer = ({ truckId, trailerId }) => {
    return async (dispatch) => {
        dispatch(changeCurrentTrailerRequest());
        try {
            await axios.post(
                `${process.env.REACT_APP_CHANGE_CURRENT_TRAILER_API_URL}`,
                { truckId, trailerId },
                {
                    withCredentials: true,
                }
            );
            dispatch(changeCurrentTrailerSuccess());
        } catch (error) {
            if (error.response && error.response.status === 401) {
                dispatch(logout());
            } else {
                dispatch(changeCurrentTrailerFailure(error.message));
            }
        }
    };
};

export const getUserFiles = () => {
    return async (dispatch) => {
        dispatch(getUserFilesRequest());

        try {
            const response = await axios.get(`${process.env.REACT_APP_GET_USER_FILES_API_URL}`, {
                withCredentials: true,
            });

            dispatch(getUserFilesSuccess(response.data));
        } catch (error) {
            if (error.response && error.response.status === 401) {
                dispatch(logout());
            } else {
                dispatch(getUserFilesFailure(error.message));
            }
        }
    };
};

// Update driver info
export const updateDriverRequest = () => ({
    type: 'UPDATE_DRIVER_REQUEST',
});

export const updateDriverSuccess = (updatedDriver) => ({
    type: 'UPDATE_DRIVER_SUCCESS',
    payload: updatedDriver,
});

export const updateDriverFailure = (error) => ({
    type: 'UPDATE_DRIVER_FAILURE',
    payload: error,
});

export const updateDriver = (id, updatedData) => async (dispatch) => {
    dispatch(updateDriverRequest());
    try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/drivers/${id}/update`, updatedData,
        { withCredentials: true }
        );

        if (response.status === 401) {
           dispatch(logout());
        } else {
            dispatch(updateDriverSuccess(response.data));
            dispatch(getDriver(id));
        }
    } catch (error) {
        dispatch(updateDriverFailure(error.message));
    }
};