import './EditPasswordUserComponent.scss';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {changePassword} from "../../../../../store/actions/adminEditUserActions";

export default function EditPasswordUserComponent({ isVisible, onClose, email }) {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const dispatch = useDispatch();
    const passwordState = useSelector(state => state.password); // Стан з Redux для слідкування за зміною пароля

    const handlePasswordChange = (e) => {
        setNewPassword(e.target.value);
        updateErrorMessage(e.target.value, confirmPassword);
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
        updateErrorMessage(newPassword, e.target.value);
    };

    const updateErrorMessage = (newPass, confirmPass) => {
        if (newPass && confirmPass && newPass !== confirmPass) {
            setErrorMessage('Passwords do not match!');
        } else if (!newPass) {
            setErrorMessage('Password cannot be empty.');
        } else {
            setErrorMessage(''); // Clear error message if passwords match
        }
    };

    const handleSaveNewPassword = () => {
        if (newPassword === confirmPassword && newPassword !== '') {
            dispatch(changePassword(email, newPassword)); // Використання action для зміни пароля
            setErrorMessage('Password change request sent.'); // Опційне повідомлення про відправлення запиту
            onClose(); // Закриваємо модальне вікно після спроби відправки запиту
        } else {
            setErrorMessage('Please make sure the passwords match and are not empty.'); // Уточнення повідомлення про помилку
        }
    };

    if (!isVisible) return null;

    return (
        <div className="modalOverlayPasswordAdmin">
            <div className="modalPasswordUser">
                <h2>Change password for</h2>
                <p>Email: {email}</p>
                <div className="inputContainerChangePasAdmin">
                    <input
                        type="password"
                        value={newPassword}
                        onChange={handlePasswordChange}
                        placeholder="Enter new password"
                    />
                    <input
                        type="password"
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                        placeholder="Confirm new password"
                    />
                </div>

                {errorMessage && <p className="error-message">{errorMessage}</p>}
                <div className="btnContainerPasswordAdmin">
                    <div className={`saveNewPasswordAdm ${errorMessage ? 'disabled' : ''}`} onClick={handleSaveNewPassword}>Save New Password</div>
                    <div className="closeBtnChangePasAdmin" onClick={onClose}>Close</div>
                </div>
            </div>
        </div>
    );
}