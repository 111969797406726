import './ModalResetPasswordComponent.scss';
export default function ModalResetPasswordComponent({  onClose }) {
    return (
        <div className="modal-overlay__reset__password">
            <div className="modal-content__reset__password">
                <span className="close-modal__reset__password" onClick={onClose}>&times;</span>
                <p>Інший контент модального вікна може бути тут.</p>
            </div>
        </div>
    );
}