import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getDriver, getDropdowns,} from "../../../../store/actions/DriversProfileActions";
import {getMainContent} from '../../../../store/actions/MainActions';
import SliderDocumentsComponent from "../../../SliderDocumentsComponent/SliderDocumentsComponent";
import './DriverInformationComponent.scss';
import LoaderComponent from '../../../LoaderComponent/LoaderComponent';
import {FaCheck} from "react-icons/fa6";
import {MdKeyboardArrowDown, MdKeyboardArrowUp} from "react-icons/md";

export default function DriverInformation({driverId}) {
    const dispatch = useDispatch();
    const driverData = useSelector(state => state.main.main_content);
    const dropdownData = useSelector(state => state.driver.dropdownData);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [driverInformation, setDriverInformation] = useState(true);
    const [dataNotAvailable, setDataNotAvailable] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isSelectOpen, setIsSelectOpen] = useState(false);
    const [selectedDropdownOption, setSelectedDropdownOption] = useState('');

    const handleCategoryChange = (event) => {
        const category = event.target.value;
        setSelectedCategory(category);
        setSelectedDropdownOption(event.target.value);
    };

    useEffect(() => {
        async function fetchData() {
            setIsLoading(true);
            await dispatch(getMainContent(driverId, driverInformation, dispatch));
            setIsLoading(false);
        }

        if (driverId !== null) {
            fetchData();
        }
    }, [dispatch, driverId, driverInformation]);

    useEffect(() => {
        setIsLoading(driverId === null || !driverData.main);
    }, [driverId, driverData]);
    useEffect(() => {
        dispatch(getDropdowns());
    }, [dispatch]);
    useEffect(() => {
        dispatch(getDriver(driverId));
    }, [dispatch, driverId]);

    useEffect(() => {
        if (driverId !== null) {
            getMainContent(driverId, driverInformation, dispatch);
        }
    }, [dispatch, driverId, driverInformation]);

    useEffect(() => {

        setDataNotAvailable(driverId === null || !driverData.main);
    }, [driverId, driverData]);

    // useEffect(()=>{
    //     driverData.files.forEach(driver => {
    //         console.log(driver)
    //     })
    // },[activeExpirationDate])

    const toggleCustomSelect = (dropdown) => {
        switch (dropdown) {
            case 'categories':
                setIsSelectOpen(prevState => !prevState);
                setIsDropdownOpen(prevState => !prevState);

                break;
            default:
                break;
        }
    };
    function convertDateToDaysCount(dateStr) {
        const currentDate = new Date();
        const targetDate = new Date(dateStr);
        const differenceInTime = targetDate.getTime() - currentDate.getTime();
        const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
        return differenceInDays;
    }

    const getDropDownName = (itemDropdownId, dropdownArray) => {
        let value = null;
        dropdownArray.forEach((item) => {
            if(item.id === itemDropdownId){
                value = item.value;
            }
        })
        return value;
    }

    return (

        <div className="drivers__main__component__content custom-scroll">
            {isLoading ? (
                <LoaderComponent className='loader__component__main'/>
            ) : (
                <div className="drivers__main__driver__dispathcer">

                    <div className="drivers__main__driver__info">
                        <div className="drivers__main__driver__name">
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40"
                                 fill="none">
                                <rect width="40" height="40" rx="4.92219" fill="#F2F8FA"/>
                                <path
                                    d="M20.506 21.0506C23.3951 21.0506 25.7351 18.6461 25.7351 15.7022C25.7351 12.8034 23.3951 10.5 20.506 10.5C17.6288 10.5 15.2649 12.8371 15.2769 15.7247C15.2888 18.6573 17.6168 21.0506 20.506 21.0506ZM20.506 19.0843C18.8584 19.0843 17.4616 17.6124 17.4616 15.7247C17.4497 13.882 18.8465 12.4663 20.506 12.4663C22.1774 12.4663 23.5503 13.8596 23.5503 15.7022C23.5503 17.5899 22.1654 19.0843 20.506 19.0843ZM13.0563 31.5H27.9437C30.0091 31.5 31 30.882 31 29.5562C31 26.4663 26.9051 22.3539 20.506 22.3539C14.1069 22.3539 10 26.4663 10 29.5562C10 30.882 10.9909 31.5 13.0563 31.5ZM12.6862 29.5337C12.3997 29.5337 12.2922 29.4438 12.2922 29.2416C12.2922 27.5225 15.241 24.3202 20.506 24.3202C25.759 24.3202 28.7078 27.5225 28.7078 29.2416C28.7078 29.4438 28.6003 29.5337 28.3138 29.5337H12.6862Z"
                                    fill="#0E91AF"/>
                            </svg>
                            {driverData.main?.driverName}
                            <div className='status__container'>
                                {driverData.statuses?.map((status, index) => (
                                    status ? (
                                        <span key={index} style={{color: status.color}}>{status?.value}</span>
                                    ) : null
                                ))}
                            </div>
                        </div>
                        <div className='drivers__information'>
                            <div className="drivers__main__driver__dis">
                                <div className="dis__label__dr">Dispatcher</div>
                                <div className="dis__driver__dr">{driverData.main?.dispatcherName}</div>
                            </div>
                            <div className="drivers__main__driver__phone">
                                <div className="phone__label__dr">Phone Number</div>
                                <div className="phone__number__dr">{driverData.main?.phoneNumber}</div>
                            </div>
                            <div className="drivers__main__driver__email">
                                <div className="email__label__dr">Email Address</div>
                                <div className="email__address__dr">{driverData.main?.email}</div>
                            </div>
                        </div>

                    </div>

                    <div className="drivers__main__vehicle__title">

                    </div>
                    <div className="drivers__main__vehicle__info">
                        <div className="drivers__main__vehicle__d">

                            <div className='vehicle__upper__details'>


                                <div className='vehicle__title'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="41" height="34"
                                         viewBox="0 0 41 34" fill="none">
                                        <path
                                            d="M35.0068 15.9433L34.413 7.19688H18.9798M28.5236 7.19801C25.342 2.52791 18.9798 2.66251 18.9798 2.66251V23.1062M18.9798 23.1062H25.8159M18.9798 23.1062H10.2877V25.8117M18.9798 23.1062H6.93528M10.2877 25.8117H10.2244C10.0749 25.0492 9.66512 24.3623 9.06514 23.8686C8.46517 23.3749 7.71228 23.105 6.93528 23.1051M10.2877 25.8117L10.2866 27.3171H18.9798V25.8117H22.9113C23.0615 25.0501 23.4715 24.3642 24.0712 23.8713C24.6708 23.3783 25.423 23.1089 26.1993 23.1089C26.9756 23.1089 27.7278 23.3783 28.3274 23.8713C28.9271 24.3642 29.337 25.0501 29.4873 25.8117H36.2396V16.0643L27.3043 15.1843V11.1239M3.64732 25.8117C3.79676 25.0494 4.20637 24.3627 4.80611 23.869C5.40585 23.3753 6.15847 23.1052 6.93528 23.1051M6.93528 23.1051C7.82425 23.1051 8.67737 23.4577 9.30597 24.0862C9.93457 24.7148 10.2877 25.5674 10.2877 26.4564C10.2877 26.8965 10.201 27.3324 10.0326 27.7391C9.86412 28.1457 9.61722 28.5152 9.30597 28.8265C8.99472 29.1377 8.62521 29.3846 8.21855 29.5531C7.81188 29.7215 7.37602 29.8082 6.93585 29.8082C6.49567 29.8082 6.05981 29.7215 5.65314 29.5531C5.24648 29.3846 4.87697 29.1377 4.56572 28.8265C4.25447 28.5152 4.00758 28.1457 3.83913 27.7391C3.67068 27.3324 3.58398 26.8965 3.58398 26.4564C3.58398 25.5674 3.93713 24.7148 4.56572 24.0862C5.19432 23.4577 6.04631 23.1051 6.93528 23.1051ZM22.848 26.4564C22.848 27.3452 23.2011 28.1976 23.8296 28.8261C24.4581 29.4546 25.3105 29.8077 26.1993 29.8077C27.0881 29.8077 27.9405 29.4546 28.569 28.8261C29.1975 28.1976 29.5506 27.3452 29.5506 26.4564C29.5506 25.5676 29.1975 24.7151 28.569 24.0866C27.9405 23.4582 27.0881 23.1051 26.1993 23.1051C25.3105 23.1051 24.4581 23.4582 23.8296 24.0866C23.2011 24.7151 22.848 25.5676 22.848 26.4564Z"
                                            stroke="#0E91AF" strokeWidth="1.69657" strokeMiterlimit="10"/>
                                    </svg>
                                    <div className='vehicle__details__d'>
                                        {driverData.vehicleInformation?.truckInformation?.truckName ? (
                                            <span>{driverData.vehicleInformation?.truckInformation?.truckName}</span>
                                        ) : (
                                            <span className='none__vehicles'>None</span>
                                        )}
                                        Truck
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className="drivers__main__treiler__d">
                            <div className='vehicle__upper__details'>

                                <div className='vehicle__title'>
                                    <svg width="61" height="36" viewBox="0 0 61 36" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M16.9536 29.9085H19.7655M26.2512 29.9085H28.9495M35.7725 29.9085H54.0836M51.8423 33.8483V29.9073M2.2002 4.83594H56.8375V27.2368H2.2002V4.83594ZM26.2705 30.0148C26.34 30.4433 26.3244 30.8813 26.2246 31.3037C26.1248 31.7262 25.9427 32.1248 25.6889 32.4769C25.435 32.829 25.1143 33.1276 24.745 33.3558C24.3757 33.5839 23.9651 33.7371 23.5366 33.8065C23.1081 33.876 22.6702 33.8604 22.2477 33.7606C21.8253 33.6608 21.4266 33.4787 21.0746 33.2249C20.7225 32.971 20.4238 32.6503 20.1957 32.281C19.9675 31.9117 19.8144 31.5011 19.7449 31.0726C19.6046 30.2073 19.8139 29.3216 20.3266 28.6106C20.8393 27.8995 21.6135 27.4212 22.4788 27.2809C23.3442 27.1407 24.2298 27.3499 24.9409 27.8626C25.652 28.3753 26.1303 29.1495 26.2705 30.0148ZM16.9741 30.0148C17.0436 30.4434 17.0279 30.8814 16.9281 31.304C16.8283 31.7265 16.6462 32.1252 16.3923 32.4773C16.1384 32.8295 15.8176 33.1282 15.4482 33.3563C15.0788 33.5845 14.6682 33.7377 14.2396 33.8072C13.811 33.8766 13.373 33.861 12.9505 33.7611C12.528 33.6613 12.1293 33.4792 11.7771 33.2253C11.0659 32.7125 10.5876 31.9381 10.4473 31.0726C10.307 30.2073 10.5163 29.3216 11.029 28.6106C11.5417 27.8995 12.3159 27.4212 13.1812 27.2809C14.0466 27.1407 14.9322 27.3499 15.6433 27.8626C16.3544 28.3753 16.8339 29.1495 16.9741 30.0148ZM35.5174 30.0148C35.5868 30.4434 35.5712 30.8814 35.4714 31.304C35.3715 31.7265 35.1895 32.1252 34.9355 32.4773C34.6816 32.8295 34.3608 33.1282 33.9915 33.3563C33.6221 33.5845 33.2114 33.7377 32.7829 33.8072C32.3543 33.8766 31.9163 33.861 31.4938 33.7611C31.0712 33.6613 30.6725 33.4792 30.3204 33.2253C29.6092 32.7125 29.1308 31.9381 28.9906 31.0726C28.8503 30.2071 29.0596 29.3213 29.5724 28.6101C30.0852 27.8989 30.8596 27.4206 31.7251 27.2803C32.5906 27.14 33.4764 27.3493 34.1876 27.8622C34.8988 28.375 35.3771 29.1493 35.5174 30.0148Z"
                                            stroke="#0E91AF" strokeWidth="1.81335" strokeMiterlimit="10"/>
                                    </svg>
                                    <div className='vehicle__details__d'>
                                        {driverData.vehicleInformation?.trailerInformation?.trailerName ? (
                                            <span>{driverData.vehicleInformation?.trailerInformation?.trailerName}</span>
                                        ) : (
                                            <span className='none__vehicles'>None</span>
                                        )}
                                        Trailer
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                    <div className="drivers__main__docyments__info">
                        <div className="drivers__main__docyments__title__left">
                            Documents
                        </div>
                        <div className="drivers__main__docyments__title__right">
                            <div className="drivers__main__docyments__select">
                                {driverData.files && driverData.files.length > 0 && (

                                    <div className={`custom-select ${isDropdownOpen ? 'active' : ''}`}
                                         onClick={() => toggleCustomSelect('categories')}>
                                        <div className="selected-options">
                                            {selectedDropdownOption ? (
                                                <div className="selected-option">
                                                    {dropdownData.find(option => option.id === selectedDropdownOption)?.value}{isSelectOpen ?
                                                    <MdKeyboardArrowUp/> : <MdKeyboardArrowDown/>}
                                                </div>
                                            ) : (
                                                <div className="selected-option">All
                                                    categories{isSelectOpen ? <MdKeyboardArrowUp/> :
                                                        <MdKeyboardArrowDown/>}</div>
                                            )}
                                        </div>
                                        <div className="select-icon">

                                        </div>
                                        <div className="options-list">
                                            {dropdownData.map(option => (
                                                <div key={option.id}
                                                     className={`option-item ${option.id === selectedDropdownOption ? 'active' : ''}`}
                                                     onClick={() => handleCategoryChange({target: {value: option.id}})}>

                                                    <label>
                                                        {option.value}
                                                    </label>
                                                    <div className="checkbox">
                                                        {option.id === selectedDropdownOption && <FaCheck/>}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                )}
                            </div>
                        </div>
                    </div>
                    <div className="drivers__profile__docyments__expiration_date_container">
                        {driverData.files.length > 0 && driverData.files.map(item => {
                            if (convertDateToDaysCount(item.expirationDate) <= 30) {
                                if(convertDateToDaysCount(item.expirationDate) <= 0){
                                    return (<div key={item.id} className="expiration_date_container__messsage_container expiration_date_red">
                                        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.47694 12.6289C9.02623 12.6289 8.64844 13.0166 8.64844 13.4792C8.64844 13.9418 9.02623 14.3295 9.47694 14.3295C9.91107 14.3295 10.3054 13.9418 10.2856 13.4996C10.3054 13.0132 9.93096 12.6289 9.47694 12.6289Z" fill="#F40000"/><path d="M17.1461 15.6423C17.6664 14.7205 17.6697 13.6219 17.1527 12.7036L11.963 3.47953C11.4493 2.551 10.5214 2 9.4808 2C8.44021 2 7.51229 2.5544 6.99862 3.47613L1.80226 12.7104C1.28528 13.639 1.28859 14.7443 1.8122 15.6661C2.32919 16.5776 3.25379 17.1252 4.28776 17.1252H14.654C15.6912 17.1252 16.6225 16.5708 17.1461 15.6423ZM16.0193 14.9756C15.731 15.4858 15.2207 15.7885 14.6506 15.7885H4.28445C3.72107 15.7885 3.21403 15.4926 2.93234 14.9926C2.64733 14.4859 2.64402 13.8804 2.92902 13.3703L8.12538 4.13936C8.40707 3.63258 8.9108 3.33328 9.4808 3.33328C10.0475 3.33328 10.5545 3.63598 10.8362 4.14276L16.0293 13.3737C16.3076 13.8702 16.3043 14.4688 16.0193 14.9756Z" fill="#F40000"/><path d="M9.27241 6.65877C8.87805 6.77441 8.63281 7.14174 8.63281 7.5873C8.6527 7.856 8.66927 8.1281 8.68915 8.39679C8.74549 9.42056 8.80183 10.4239 8.85816 11.4477C8.87805 11.7946 9.13985 12.0463 9.47788 12.0463C9.81591 12.0463 10.081 11.7776 10.0976 11.4273C10.0976 11.2164 10.0976 11.0225 10.1175 10.8083C10.1539 10.1518 10.1937 9.49538 10.2302 8.83895C10.25 8.4138 10.2865 7.98865 10.3064 7.56349C10.3064 7.41044 10.2865 7.27439 10.2302 7.13834C10.0611 6.75741 9.66678 6.56354 9.27241 6.65877Z" fill="#F40000"/></svg>
                                        The {getDropDownName(item.dropdownId, dropdownData)} has been
                                        expired {convertDateToDaysCount(item.expirationDate) * -1} days ago
                                    </div>);
                                } else {
                                    return (<div key={item.id} className="expiration_date_container__messsage_container expiration_date_yello">
                                        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.47694 12.6289C9.02623 12.6289 8.64844 13.0166 8.64844 13.4792C8.64844 13.9418 9.02623 14.3295 9.47694 14.3295C9.91107 14.3295 10.3054 13.9418 10.2856 13.4996C10.3054 13.0132 9.93096 12.6289 9.47694 12.6289Z" fill="#F89400"/><path d="M17.1461 15.6423C17.6664 14.7205 17.6697 13.6219 17.1527 12.7036L11.963 3.47953C11.4493 2.551 10.5214 2 9.4808 2C8.44021 2 7.51229 2.5544 6.99862 3.47613L1.80226 12.7104C1.28528 13.639 1.28859 14.7443 1.8122 15.6661C2.32919 16.5776 3.25379 17.1252 4.28776 17.1252H14.654C15.6912 17.1252 16.6225 16.5708 17.1461 15.6423ZM16.0193 14.9756C15.731 15.4858 15.2207 15.7885 14.6506 15.7885H4.28445C3.72107 15.7885 3.21403 15.4926 2.93234 14.9926C2.64733 14.4859 2.64402 13.8804 2.92902 13.3703L8.12538 4.13936C8.40707 3.63258 8.9108 3.33328 9.4808 3.33328C10.0475 3.33328 10.5545 3.63598 10.8362 4.14276L16.0293 13.3737C16.3076 13.8702 16.3043 14.4688 16.0193 14.9756Z" fill="#F89400"/><path d="M9.27241 6.65877C8.87805 6.77441 8.63281 7.14174 8.63281 7.5873C8.6527 7.856 8.66927 8.1281 8.68915 8.39679C8.74549 9.42056 8.80183 10.4239 8.85816 11.4477C8.87805 11.7946 9.13985 12.0463 9.47788 12.0463C9.81591 12.0463 10.081 11.7776 10.0976 11.4273C10.0976 11.2164 10.0976 11.0225 10.1175 10.8083C10.1539 10.1518 10.1937 9.49538 10.2302 8.83895C10.25 8.4138 10.2865 7.98865 10.3064 7.56349C10.3064 7.41044 10.2865 7.27439 10.2302 7.13834C10.0611 6.75741 9.66678 6.56354 9.27241 6.65877Z" fill="#F89400"/></svg>
                                        The {getDropDownName(item.dropdownId, dropdownData)} will be overdue
                                        in {convertDateToDaysCount(item.expirationDate)} days
                                    </div>);
                                }

                            }
                            return null;
                        })}
                    </div>
                    <div className="drivers__main__docyment">
                        <SliderDocumentsComponent
                            className="slider-component-driver"
                            files={driverData.files}
                            selectedCategory={selectedCategory}
                        />
                    </div>
                </div>
            )}
        </div>

    );
}
