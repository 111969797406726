import './UserContainerComponent.scss';
import {BiSearch} from "react-icons/bi";
import React, {useState} from 'react';
import AddUserComponent from "../AddUserComponent/AddUserComponent";
import DispatchersTableComponent from "../DispatchersTableComponent/DispatchersTableComponent";

export default function UserContainerComponent() {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };
    return (
        <div className="adminUserContainer">

            <div className="adminUsersHeadInfo">
                <div className="admimUsersTitle">
                    Dispatchers
                </div>
                <div className="adminUsersHeadRight">
                    <div className="adminUsersHeadAddUser" onClick={openModal}>
                        Add dispatcher
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path
                                d="M7.74902 13.5691C7.33095 13.5691 6.99219 13.2303 6.99219 12.8123V3.18652C6.99219 2.76845 7.33095 2.42969 7.74902 2.42969C8.1671 2.42969 8.50586 2.76845 8.50586 3.18652V12.8123C8.50586 13.2303 8.1671 13.5691 7.74902 13.5691Z"
                                fill="black"/>
                            <path
                                d="M12.5623 8.75586H2.93652C2.51845 8.75586 2.17969 8.4171 2.17969 7.99902C2.17969 7.58095 2.51845 7.24219 2.93652 7.24219H12.5623C12.9803 7.24219 13.3191 7.58095 13.3191 7.99902C13.3191 8.4171 12.9803 8.75586 12.5623 8.75586Z"
                                fill="black"/>
                        </svg>
                    </div>
                    {isModalOpen && <AddUserComponent closeModal={closeModal}/>}
                    <div className="adminUsersHeadSearch">
                        <BiSearch/>
                        <input type="text" placeholder="Search"/>
                    </div>
                </div>
            </div>
            <div className="adminDispatchersTable">
                <DispatchersTableComponent/>
            </div>
        </div>
    )
}