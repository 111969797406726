import React, {useState, useEffect} from 'react';
import {MdDone} from "react-icons/md";
import {useDispatch} from 'react-redux';
import {updateDropdown} from "../../../../store/actions/adminDropdownsActions";
import LoaderComponent from "../../../LoaderComponent/LoaderComponent";
import { deleteValue } from '../../../../store/actions/adminDropdownsActions';
import {IoClose} from "react-icons/io5";

export default function DropdownOptionsDriverFilesComponent({dropdownData}) {
    const dispatch = useDispatch();
    const [editedValue, setEditedValue] = useState(null);
    const [loading, setLoading] = useState(false);   
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [deleteType, setDeleteType] = useState(null);
    const [deleteValues, setDeleteValues] = useState(null);
    const [showDeleteMessage, setShowDeleteMessage] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    useEffect(() => {
        if (showDeleteMessage) {
            const timer = setTimeout(() => {
                setShowDeleteMessage(false);
            }, 2600);
    
            return () => clearTimeout(timer);
        }
    }, [showDeleteMessage]);

    if (!dropdownData || !Array.isArray(dropdownData)) {
        return null;
    }

    const driversFilesDropdowns = dropdownData.find(item => item.type === 'driversFilesDropdowns');

    if (!driversFilesDropdowns) {
        return null;
    }

    const handleEditValue = (id, value) => {
        setEditedValue({id, value});
    };

    const handleInputChange = (event) => {
        setEditedValue({...editedValue, value: event.target.value});
    };

    const handleUpdateValue = async () => {
        if (editedValue) {
            setLoading(true);
            try {
                await dispatch(updateDropdown({
                    id: editedValue.id,
                    type: driversFilesDropdowns.type,
                    dropdownName: 'driversFilesDropdowns',
                    value: editedValue.value,
                }));
                setEditedValue(null);
            } catch (error) {
                console.error('Помилка при оновленні значення:', error);
            } finally {
                setLoading(false);
            }
        }
    };

    const handleDoneClick = () => {
        handleUpdateValue();
    };
    const handleDeleteConfirmation = (type, id, value) => {
        setDeleteId(id);
        setDeleteType(type);
        setDeleteValues(value);
        setConfirmDelete(true);
    };

    const handleDeleteValue = async (type, id) => {
        setLoading(true);
        try {
            await dispatch(deleteValue(type, id));
            setShowDeleteMessage(true);
            
            
        } catch (error) {
            if (error?.response?.status === 500) {
console.log("afht")
setShowErrorMessage(true);
            } 
        } finally {
            setLoading(false);
            setConfirmDelete(false); 
        }
    };

    return (
        <>
            {loading && <LoaderComponent/>}
            <div className="typeTitleDriverFiles">Driver documents category</div>
            <div className="driverDocymentsBlock">
                <div className="dropdownOptionsDriverFiles">
                    <div className="typeDriverFiles">type: {driversFilesDropdowns.type}</div>
                    {driversFilesDropdowns.value.map(item => (
                        <div className='DropItemsTrailer__list__c'>
                        <div key={item.id} className="DropItemsDriverFiles">
                            {editedValue && editedValue.id === item.id ? (
                                <div>
                                    <input
                                        type="text"
                                        value={editedValue.value}
                                        onChange={handleInputChange}
                                        autoFocus
                                    />
                                    <MdDone onClick={handleDoneClick}/>
                                </div>
                            ) : (
                                <div className="DropItemsDriverFiles__type"
                                     onClick={() => handleEditValue(item.id, item.value)}>
                                    <span>{item.value}</span>
                                </div>
                            )}
                            
                        </div>
                        {driversFilesDropdowns.value.length > 1 && (
                            <div className="delete__cross">
                                            <IoClose onClick={() => handleDeleteConfirmation(driversFilesDropdowns.type, item?.id, item.value)} />
                                            </div>
                        
                        )}
                        </div>
                    ))}
                </div>
                {confirmDelete && (
                <div className="admin__modal">
                    <div className="modal-content">
                        <span className="close" onClick={() => setConfirmDelete(false)}><IoClose/></span>
                        <span className="modal__text">Confirm deletion of "{deleteValues}"?</span>
                        <div className="modal__confirm" onClick={() => handleDeleteValue(deleteType, deleteId)}>Delete</div>
                    </div>
                </div>
            )}
            {showDeleteMessage && (
    <div className="admin__modal">
        <div className="modal-content">
            <div className='modal__title'>
            <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/></svg>
    </div>
    <div className='modal__text'>
Deleted successfully!
        </div>
        </div>
    </div>
)}
{showErrorMessage && (
    <div className="admin__modal">
        <div className="modal-content">
        <span className="close" onClick={() => setShowErrorMessage(false)}><IoClose/></span>   
        <div className="modal__text"> 
        <span>Error</span>
        </div>
        <div className='modal__second__text'>
        <span>Unbind the value before deleting</span>
        </div>
        </div>
    </div>
)}
            </div>
        </>
    );
}
