import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import './MyDriversComponent.scss';
import {MdOutlinePlace} from "react-icons/md";
import {getAllDrivers} from '../../../../store/actions/MainActions';
import LoaderComponent from '../../../LoaderComponent/LoaderComponent';

const MyDriversComponent = ({searchQuery, onDriverClick}) => {
    const dispatch = useDispatch();
    const driversData = useSelector(state => state.main);
    //eslint-disable-next-line
    const [hoveredDriverId, setHoveredDriverId] = useState(null);
    const [selectedDriverId, setSelectedDriverId] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await dispatch(getAllDrivers(false));
                setIsLoading(false);
            } catch (error) {
                console.error('Error loading drivers:', error);
                setIsLoading(false);
            }
        };

        fetchData(); // Викликаємо функцію без затримки

        // Не потрібно більше інтервалу, тому його можна вилучити

    }, [dispatch]);


    const handleClick = (driverId) => {
        onDriverClick(driverId);
        setSelectedDriverId(driverId);
    };

    const filteredDrivers = driversData.drivers?.filter(
        (driver) =>
            driver.driverName?.toLowerCase().includes(searchQuery.toLowerCase())
    );


    return (
        <div className='routes__container'>
            {isLoading ? (
                <LoaderComponent/>
            ) : (
                filteredDrivers.length > 0 ? (
                    filteredDrivers.map((driver) => (
                        <div
                            className={`route ${selectedDriverId === driver.id ? 'selected' : ''} ${hoveredDriverId === driver.id ? 'opaque' : ''}`}
                            key={driver.id}
                            onClick={() => handleClick(driver.id)}
                        >
                            <div className='single__route__container'>
                                <div className='route__name__status'>
                                    <div className='route__name'>
                                        Driver:
                                        <span>{driver.driverName}</span>
                                    </div>
                                    {driver.statuses && driver.statuses.some(status => status !== null) ? (
                                        <div className='route__statuses'>
                                            {driver.statuses.map((status, index) => (
                                                status !== null && (
                                                    <div
                                                        key={index}
                                                        className='route__status'
                                                        style={{color: status.color}}
                                                    >
                                                        {status.value}
                                                    </div>
                                                )
                                            ))}
                                        </div>
                                    ) : null}
                                </div>
                                {driver.currentLocation !== null && (
                                    <div className='route__location'>
                                        <MdOutlinePlace/> {driver.currentLocation}
                                    </div>
                                )}
                            </div>
                        </div>
                    ))
                ) : (
                    <p>No matching drivers found.</p>
                )
            )}
        </div>
    );
};

export default MyDriversComponent;
