// AdminAddDispatcherActionTypes.js
import axios from 'axios';
import {logout} from "./authActions";

export const ADD_DISPATCHER_REQUEST = 'ADD_DISPATCHER_REQUEST';
export const ADD_DISPATCHER_SUCCESS = 'ADD_DISPATCHER_SUCCESS';
export const ADD_DISPATCHER_FAILURE = 'ADD_DISPATCHER_FAILURE';


export const addDispatcherRequest = () => ({
    type: ADD_DISPATCHER_REQUEST
});

export const addDispatcherSuccess = () => ({
    type: ADD_DISPATCHER_SUCCESS
});

export const addDispatcherFailure = (error) => ({
    type: ADD_DISPATCHER_FAILURE,
    payload: error
});

export const addDispatcher = (data) => {
    return (dispatch) => {
        dispatch(addDispatcherRequest());
        axios.post(`${process.env.REACT_APP_BASE_URL}/admin/createDispatcher`, data, {
            withCredentials: true
        })
            .then((response) => {
                dispatch(addDispatcherSuccess());
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    dispatch(logout());
                } else {
                    dispatch(addDispatcherFailure(error.message));
                }
            });
    };
};
