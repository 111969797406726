import './DispatchersTableComponent.scss';
import {fetchDispatchers} from '../../../../store/actions/adminGetDispatchersActions';
import {useDispatch, useSelector} from 'react-redux';
import React, {useEffect, useState} from 'react';
import LoaderComponent from '../../../LoaderComponent/LoaderComponent';
import {MdKeyboardArrowDown, MdKeyboardArrowUp, MdOutlinePassword, MdBlock} from 'react-icons/md';
import BlockUserComponent from "./BlockUserComponent/BlockUserComponent";
import EditPasswordUserComponent from "./EditPasswordUserComponent/EditPasswordUserComponent";

export default function DispatchersTableComponent() {
    const dispatch = useDispatch();
    const dispatchersState = useSelector((state) => state.adminGetDispatchers);
    const { loading, dispatchers } = dispatchersState;
    const [selectedDispatcher, setSelectedDispatcher] = useState(null);
    const [showBlockModal, setShowBlockModal] = useState(false);
    const [blockEmail, setBlockEmail] = useState(null);
    const [isBlocked, setIsBlocked] = useState(false);  // State to track block status
    const [showEditPasswordModal, setShowEditPasswordModal] = useState(false);
    const [currentEmail, setCurrentEmail] = useState('');

    useEffect(() => {
        dispatch(fetchDispatchers());
    }, [dispatch]);

    const handleDispatcherClick = (dispatcherId) => {
        setSelectedDispatcher(selectedDispatcher === dispatcherId ? null : dispatcherId);
    };

    const handleCloseModal = () => {
        setShowBlockModal(false);
    };

    const handleBlockClick = (e, email, isBlocked) => {
        e.stopPropagation();
        setBlockEmail(email);
        setIsBlocked(isBlocked); // Set the block status
        setShowBlockModal(true);
    };

    const handlePasswordIconClick = (e, email) => {
        e.stopPropagation();
        setCurrentEmail(email);
        setShowEditPasswordModal(true);
    };

    return (
        <div>
            <div className='table__wrapper__dispatchers'>
                <div className='table__container__dispatchers'>
                    <table>
                        <thead>
                        <tr>
                            <th>Dispatcher name</th>
                            <th>Dispatcher email</th>
                            <th>Phone number</th>
                            <th style={{display: 'flex', alignItems: 'center', width: 'auto'}}>Date of birth</th>
                            <th>Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {loading ? (
                            <tr>
                                <td colSpan='5'>
                                    <LoaderComponent/>
                                </td>
                            </tr>
                        ) : Array.isArray(dispatchers) && dispatchers.length ? (
                            dispatchers.map((dispatcher) => (
                                <React.Fragment key={dispatcher.dispatcherId}>
                                    <tr
                                        style={{cursor: 'pointer'}}
                                        onClick={() => handleDispatcherClick(dispatcher.dispatcherId)}>
                                        <td>
                                            {dispatcher.dispatcherName}
                                            {selectedDispatcher === dispatcher.dispatcherId ? (
                                                <MdKeyboardArrowUp/>
                                            ) : (
                                                <MdKeyboardArrowDown/>
                                            )}
                                        </td>
                                        <td>{dispatcher.dispatcherEmail}</td>
                                        <td>{dispatcher.dispatcherPhoneNumber}</td>
                                        <td>{dispatcher.dispatcherDateOfBirth}</td>
                                        <td>
                                            <MdBlock style={{cursor: 'pointer', marginRight: 10}}
                                                     onClick={(e) => handleBlockClick(e, dispatcher.dispatcherEmail, dispatcher.blocked === 1)}/>
                                            <MdOutlinePassword style={{cursor: 'pointer'}}
                                                               onClick={(e) => handlePasswordIconClick(e, dispatcher.dispatcherEmail)}/>
                                        </td>
                                    </tr>
                                    {selectedDispatcher === dispatcher.dispatcherId &&
                                        dispatcher.drivers.map((driver) => (
                                            <tr className='nested__row' key={driver.driverId}>
                                                <td className='nested__cell'>{driver.driverName}</td>
                                                <td className='nested__cell'>{driver.driverEmail}</td>
                                                <td className='nested__cell'>{driver.driverPhoneNumber}</td>
                                                <td className='nested__cell'>{driver.driverDateOfBirth}</td>
                                                <td>
                                                    <MdBlock style={{cursor: 'pointer', marginRight: 10}}
                                                             onClick={(e) => handleBlockClick(e, driver.driverEmail, driver.blocked === 1)}/>
                                                    <MdOutlinePassword style={{cursor: 'pointer'}}
                                                                       onClick={(e) => handlePasswordIconClick(e, driver.driverEmail)}/>
                                                </td>
                                            </tr>
                                        ))}
                                </React.Fragment>
                            ))
                        ) : (
                            <tr>
                                <td colSpan='5'>No data available</td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            </div>
            <BlockUserComponent isVisible={showBlockModal} email={blockEmail} isBlocked={isBlocked} onClose={handleCloseModal}/>
            <EditPasswordUserComponent
                isVisible={showEditPasswordModal}
                onClose={() => setShowEditPasswordModal(false)}
                email={currentEmail}
            />
        </div>
    );
}