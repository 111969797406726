import axios from 'axios';
import {logout} from "./authActions";
import {debounce} from 'lodash';

export const GET_ALL_DRIVERS_REQUEST = 'GET_ALL_DRIVERS_REQUEST';
export const GET_ALL_DRIVERS_SUCCESS = 'GET_ALL_DRIVERS_SUCCESS';
export const GET_ALL_DRIVERS_FAILURE = 'GET_ALL_DRIVERS_FAILURE';

export const getAllDriversRequest = () => ({type: GET_ALL_DRIVERS_REQUEST});
export const getAllDriversSuccess = (data) => ({
    type: GET_ALL_DRIVERS_SUCCESS,
    payload: data,
});
export const getAllDriversFailure = (error) => ({type: GET_ALL_DRIVERS_FAILURE, payload: error});


export const GET_MAIN_CONTENT_REQUEST = 'GET_MAIN_CONTENT_REQUEST';
export const GET_MAIN_CONTENT_SUCCESS = 'GET_MAIN_CONTENT_SUCCESS';
export const GET_MAIN_CONTENT_FAILURE = 'GET_MAIN_CONTENT_FAILURE';

export const getMainContentRequest = () => ({type: GET_MAIN_CONTENT_REQUEST});
export const getMainContentSuccess = (main_content) => ({type: GET_MAIN_CONTENT_SUCCESS, payload: main_content});
export const getMainContentFailure = (error) => ({type: GET_MAIN_CONTENT_FAILURE, payload: error});

export const getAllDrivers = (all) => {
    return async (dispatch) => {
        dispatch(getMainContentRequest()); // Додайте або видаліть цей виклик в залежності від ваших потреб
        try {
            const response = await axios.get(`${process.env.REACT_APP_FETCH_DRIVERS_API_URL}?all=${all}`, {
                withCredentials: true,
            });

            if (response.status === 200) {
                dispatch(getAllDriversSuccess(response.data));
            } else {
                if (response.status === 401) {
                    dispatch(logout());
                }
                dispatch(getAllDriversFailure(`Request failed with status: ${response.status}`));
            }
        } catch (error) {
            dispatch(getAllDriversFailure(error.message));
        }

        // Додайте затримку між запитами, наприклад, 1000 мілісекунд (1 секунда)
        await new Promise(resolve => setTimeout(resolve, 2000));
    };
};


export const getMainContent = (id, driverInformation, limit) => {
    limit = limit || 3;
    return async (dispatch) => {
        if (typeof limit !== 'undefined') {
            dispatch(getMainContentRequest());
            console.log(limit);
            try {
                const response = await fetch(`${process.env.REACT_APP_FETCH_MAIN_CONTENT_API_URL}/dispatcher?driverId=${id}&driverInformation=${driverInformation}&limit=${limit}`, {
                    credentials: 'include',
                });

                if (response.status === 200) {
                    const data = await response.json();
                    dispatch(getMainContentSuccess(data));
                    return data;

                } else {
                    console.error(`Отримано статус відповіді ${response.status}`);
                    if (response.status === 401) {
                        dispatch(logout());
                    }
                    dispatch(getMainContentFailure(`Отримано статус відповіді ${response.status}`));
                }
            } catch (error) {
                dispatch(getMainContentFailure(error.message));
            }

            // Додайте виклик функції через 1 секунду
            setTimeout(() => {
                dispatch(getMainContent(id, driverInformation, limit));
            }, 1000);
        } else {
            // Обробляйте випадок, коли limit ще не визначений
            console.log('Очікується значення limit');
        }
    };
};


export const optimizedGetMainContent = debounce(getMainContent, 500);


export const UPDATE_DRIVER_NOTES_REQUEST = 'UPDATE_DRIVER_NOTES_REQUEST';
export const UPDATE_DRIVER_NOTES_SUCCESS = 'UPDATE_DRIVER_NOTES_SUCCESS';
export const UPDATE_DRIVER_NOTES_FAILURE = 'UPDATE_DRIVER_NOTES_FAILURE';

// MainActions.js
export const updateDriverNotesRequest = () => ({
    type: UPDATE_DRIVER_NOTES_REQUEST,
});

export const updateDriverNotesSuccess = (updatedNotes) => ({
    type: UPDATE_DRIVER_NOTES_SUCCESS,
    payload: updatedNotes,
});

export const updateDriverNotesFailure = (error) => ({
    type: UPDATE_DRIVER_NOTES_FAILURE,
    payload: error,
});


export const updateNotesAction = (notesData) => {
    return async (dispatch) => {
        try {
            dispatch(updateDriverNotesRequest(notesData));
            const response = await fetch(`${process.env.REACT_APP_UPDATE_NOTES_API_URL}`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(notesData),
                credentials: 'include',
            });

            if (response.status === 401) {
                dispatch(logout());
                throw new Error('Unauthorized: User not logged in');
            }

            if (!response.ok) {
                throw new Error('Failed to update notes');
            }

            const updatedNotes = await response.json();
            dispatch(updateDriverNotesSuccess(updatedNotes));
        } catch (error) {
            dispatch(updateDriverNotesFailure(error.message));
        }
    };
};


export const DRIVER_FIND_MAIN_CONTENT_REQUEST = 'DRIVER_FIND_MAIN_CONTENT_REQUEST';
export const DRIVER_FIND_MAIN_CONTENT_SUCCESS = 'DRIVER_FIND_MAIN_CONTENT_SUCCESS';
export const DRIVER_FIND_MAIN_CONTENT_FAILURE = 'DRIVER_FIND_MAIN_CONTENT_FAILURE';

export const driverFindMainContentRequest = () => ({
    type: DRIVER_FIND_MAIN_CONTENT_REQUEST,
});

export const driverFindMainContentSuccess = (data) => ({
    type: DRIVER_FIND_MAIN_CONTENT_SUCCESS,
    payload: data,
});

export const driverFindMainContentFailure = (error) => ({
    type: DRIVER_FIND_MAIN_CONTENT_FAILURE,
    payload: error,
});


export const fetchDriverFindMainContent = (limit) => {
    return async (dispatch) => {
        dispatch(driverFindMainContentRequest());
        try {
            const response = await fetch(`${process.env.REACT_APP_FETCH_DRIVER_MAIN_CONTENT_API_URL}?limit=${limit}`, {
                method: 'GET',
                credentials: 'include',
            });

            if (response.status === 401) {
                dispatch(logout());
                throw new Error('Unauthorized: User not logged in');
            }

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            dispatch(driverFindMainContentSuccess(data));
        } catch (error) {
            dispatch(driverFindMainContentFailure(error.message));
        }
    };
};


export const GET_DRIVERS_STATUSES_REQUEST = 'GET_DRIVERS_STATUSES_REQUEST';
export const GET_DRIVERS_STATUSES_SUCCESS = 'GET_DRIVERS_STATUSES_SUCCESS';
export const GET_DRIVERS_STATUSES_FAILURE = 'GET_DRIVERS_STATUSES_FAILURE';

export const driversStatusesRequest = () => ({
    type: GET_DRIVERS_STATUSES_REQUEST,
});

export const driversStatusesSuccess = (data) => ({
    type: GET_DRIVERS_STATUSES_SUCCESS,
    payload: data,
});

export const driversStatusesFailure = (error) => ({
    type: GET_DRIVERS_STATUSES_FAILURE,
    payload: error,
});


export const fetchDriversStatuses = () => {
    return async (dispatch) => {
        dispatch(driversStatusesRequest());
        try {
            const response = await fetch(`${process.env.REACT_APP_FETCH_DRIVERS_STATUSES_API_URL}`, {
                method: 'GET',
                credentials: 'include',
            });

            if (response.status === 401) {
                dispatch(logout());
                throw new Error('Unauthorized: User not logged in');
            }

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            dispatch(driversStatusesSuccess(data));
        } catch (error) {
            dispatch(driversStatusesFailure(error.message));
        }
    };
};

export const FIND_NOTES_REQUEST = 'FINT_NOTES_REQUEST';
export const FIND_NOTES_SUCCESS = 'FINT_NOTES_SUCCESS';
export const FIND_NOTES_FAILURE = 'FIND_NOTES_FAILURE';

export const findNotesRequest = () => ({
    type: FIND_NOTES_REQUEST,
});

export const findNotesSuccess = (data) => ({
    type: FIND_NOTES_SUCCESS,
    payload: data,
});

export const findNotesFailure = (error) => ({
    type: FIND_NOTES_FAILURE,
    payload: error,
});


export const fetchFindNotes = () => {
    return async (dispatch) => {
        dispatch(findNotesRequest());
        try {
            const response = await fetch(`${process.env.REACT_APP_FIND_NOTES_API_URL}`, {
                method: 'GET',
                credentials: 'include',
            });

            if (response.status === 401) {
                dispatch(logout());
                throw new Error('Unauthorized: User not logged in');
            }

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            dispatch(findNotesSuccess(data));
        } catch (error) {
            dispatch(findNotesFailure(error.message));
        }
    };
};


export const SET_DRIVER_STATUS_REQUEST = 'SET_DRIVER_STATUS_REQUEST';
export const SET_DRIVER_STATUS_SUCCESS = 'SET_DRIVER_STATUS_SUCCESS';
export const SET_DRIVER_STATUS_FAILURE = 'SET_DRIVER_STATUS_FAILURE';

// MainActions.js
export const setDriverStatusRequest = () => ({
    type: SET_DRIVER_STATUS_REQUEST,
});

export const setDriverStatusSuccess = (updatedStatus) => ({
    type: SET_DRIVER_STATUS_SUCCESS,
    payload: updatedStatus,
});

export const setDriverStatusFailure = (error) => ({
    type: SET_DRIVER_STATUS_FAILURE,
    payload: error,
});


export const setDriverStatusAction = (statusData) => {
    return async (dispatch) => {
        try {
            dispatch(setDriverStatusRequest(statusData));
            const response = await fetch(`${process.env.REACT_APP_SET_DRIVER_STATUS_API_URL}`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(statusData),
                credentials: 'include',
            });

            if (response.status === 401) {
                dispatch(logout());
                throw new Error('Unauthorized: User not logged in');
            }

            if (!response.ok) {
                throw new Error('Failed to set driver status');
            }

            const updatedStatus = await response.json();
            dispatch(setDriverStatusSuccess(updatedStatus));
        } catch (error) {
            dispatch(setDriverStatusFailure(error.message));
        }
    };
};


// create load
export const CREATE_LOAD_REQUEST = 'CREATE_LOAD_REQUEST';
export const CREATE_LOAD_SUCCESS = 'CREATE_LOAD_SUCCESS';
export const CREATE_LOAD_FAILURE = 'CREATE_LOAD_FAILURE';

const createLoadRequest = () => ({
    type: CREATE_LOAD_REQUEST
});

const createLoadSuccess = (data) => ({
    type: CREATE_LOAD_SUCCESS,
    payload: data
});

const createLoadFailure = (error) => ({
    type: CREATE_LOAD_FAILURE,
    payload: error
});

export const createLoad = (loadData) => async (dispatch) => {
    dispatch(createLoadRequest());
    try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/main/createLoad`, loadData, {
            withCredentials: true,
        });

        if (response.status === 401) {
            dispatch(logout());
            throw new Error('Unauthorized: User not logged in');
        }

        if (response.data.success) {
            dispatch(createLoadSuccess(response.data));
        } else {
            dispatch(createLoadFailure('Failed to create load. Please try again.'));
        }
    } catch (error) {
        let errorMessage = 'An error occurred while creating the load.';

        if (error.response) {
            errorMessage = error.response.data.message || error.response.statusText;
        } else if (error.request) {
            errorMessage = 'No response received from the server.';
        } else {
            errorMessage = error.message;
        }
        dispatch(createLoadFailure(errorMessage));
    }
};


export const FETCH_LOAD_STATUSES_REQUEST = 'FETCH_LOAD_STATUSES_REQUEST';
export const FETCH_LOAD_STATUSES_SUCCESS = 'FETCH_LOAD_STATUSES_SUCCESS';
export const FETCH_LOAD_STATUSES_FAILURE = 'FETCH_LOAD_STATUSES_FAILURE';

export const fetchLoadStatuses = () => async (dispatch) => {
    dispatch({type: FETCH_LOAD_STATUSES_REQUEST});

    try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/main/loads/getStatusesDropdowns`, {
            withCredentials: true,
        });

        if (response.status === 401) {
            dispatch(logout());
            throw new Error('Unauthorized: User not logged in');
        }

        if (response.data) {
            dispatch({
                type: FETCH_LOAD_STATUSES_SUCCESS,
                payload: response.data,
            });
        } else {
            dispatch({
                type: FETCH_LOAD_STATUSES_FAILURE,
                payload: 'No data received from the server',
            });
        }
    } catch (error) {
        dispatch({
            type: FETCH_LOAD_STATUSES_FAILURE,
            payload: error.message,
        });
    }
};


export const GET_POINT_REQUEST = 'GET_POINT_REQUEST';
export const GET_POINT_SUCCESS = 'GET_POINT_SUCCESS';
export const GET_POINT_FAILURE = 'GET_POINT_FAILURE';

export const getPointRequest = () => ({type: GET_POINT_REQUEST});
export const getPointSuccess = (main_content) => ({type: GET_POINT_SUCCESS, payload: main_content});
export const getPointFailure = (error) => ({type: GET_POINT_FAILURE, payload: error});


export const getPoint = (id) => {
    return async (dispatch) => {
        dispatch(getPointRequest());
        try {
            const response = await fetch(`${process.env.REACT_APP_GET_POINT_API_URL}/${id}`, {
                credentials: 'include',
            });

            if (response.status === 401) {
                dispatch(logout());
                throw new Error('Unauthorized: User not logged in');
            }

            const data = await response.json();
            dispatch(getPointSuccess(data));
        } catch (error) {
            dispatch(getPointFailure(error.message));
        }
    };
};


export const CREATE_PHOTO_REPORT_REQUEST = 'CREATE_PHOTO_REPORT_REQUEST';
export const CREATE_PHOTO_REPORT_SUCCESS = 'CREATE_PHOTO_REPORT_SUCCESS';
export const CREATE_PHOTO_REPORT_FAILURE = 'CREATE_PHOTO_REPORT_FAILURE';

export const createPhotoReportRequest = () => ({
    type: CREATE_PHOTO_REPORT_REQUEST,
});

export const createPhotoReportSuccess = (data) => ({
    type: CREATE_PHOTO_REPORT_SUCCESS,
    payload: data,
});

export const createPhotoReportFailure = (error) => ({
    type: CREATE_PHOTO_REPORT_FAILURE,
    payload: error,
});

export const createPhotoReport = (pointId, formData) => async (dispatch) => {
    dispatch(createPhotoReportRequest());
    try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/main/driver/point/${pointId}/uploadPhotos`, formData, {
            withCredentials: true,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        if (response.status === 401) {
            dispatch(logout());
            throw new Error('Unauthorized: User not logged in');
        }

        if (response.data.success) {
            dispatch(createPhotoReportSuccess(response.data));
        } else {
            dispatch(createPhotoReportFailure('Failed to create photo report. Please try again.'));
        }
    } catch (error) {
        let errorMessage = 'An error occurred while creating the photo report.';

        if (error.response) {
            errorMessage = error.response.data.message || error.response.statusText;
        } else if (error.request) {
            errorMessage = 'No response received from the server.';
        } else {
            errorMessage = error.message;
        }

        dispatch(createPhotoReportFailure(errorMessage));
    }
};


export const APPROVE_POINT_REQUEST = 'APPROVE_POINT_REQUEST';
export const APPROVE_POINT_SUCCESS = 'APPROVE_POINT_SUCCESS';
export const APPROVE_POINT_FAILURE = 'APPROVE_POINT_FAILURE';

export const approvePointRequest = () => ({type: APPROVE_POINT_REQUEST});
export const approvePointSuccess = (data) => ({type: APPROVE_POINT_SUCCESS, payload: data});
export const approvePointFailure = (error) => ({type: APPROVE_POINT_FAILURE, payload: error});

export const approvePoint = (pointId, status, comment) => {
    return async (dispatch) => {
        dispatch(approvePointRequest());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/main/dispatcher/point/${pointId}/accept`,
                {status, comment},
                {withCredentials: true}
            );

            if (response.status === 401) {
                dispatch(logout());
                throw new Error('Unauthorized: User not logged in');
            }

            dispatch(approvePointSuccess(response.data));
            // Додаткові дії або екшени в разі успіху
        } catch (error) {
            dispatch(approvePointFailure(error.message));
        }
    };
};


export const HIDE_LOAD_REQUEST = 'HIDE_LOAD_REQUEST';
export const HIDE_LOAD_SUCCESS = 'HIDE_LOAD_REPORT_SUCCESS';
export const HIDE_LOAD_FAILURE = 'HIDE_LOAD_REPORT_FAILURE';

export const hideLoadRequest = () => ({
    type: HIDE_LOAD_REQUEST,
}); 

export const hideLoadSuccess = (data) => ({
    type: HIDE_LOAD_SUCCESS,
    payload: data,
});

export const hideLoadFailure = (error) => ({
    type: HIDE_LOAD_FAILURE,
    payload: error,
});

export const hideLoadReport = (loadId, hidden) => async (dispatch) => {
    dispatch(hideLoadRequest());

    try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/main/dispatcher/load/${loadId}/hide`, {
            hidden: hidden
        }, {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            }
        });

        if (response.status === 401) {
            dispatch(logout());
            throw new Error('Unauthorized: User not logged in');
        }

        if (response.data.success) {
            dispatch(hideLoadSuccess(response.data));
        } else {
            dispatch(hideLoadFailure('Failed. Please try again.'));
        }
    } catch (error) {
        let errorMessage = 'An error occurred.';

        if (error.response) {
            errorMessage = error.response.data.message || error.response.statusText;
        } else if (error.request) {
            errorMessage = 'No response received from the server.';
        } else {
            errorMessage = error.message;
        }

        dispatch(hideLoadFailure(errorMessage));
    }
};


export const UPLOAD_FILE_FOR_LOAD_REQUEST = 'UPLOAD_FILE_FOR_LOAD_REQUEST';
export const UPLOAD_FILE_FOR_LOAD_SUCCESS = 'UPLOAD_FILE_FOR_LOAD_SUCCESS';
export const UPLOAD_FILE_FOR_LOAD_FAILURE = 'UPLOAD_FILE_FOR_LOAD_FAILURE';
export const uploadFileForLoadRequest = () => ({
    type: UPLOAD_FILE_FOR_LOAD_REQUEST,
});

export const uploadFileForLoadSuccess = () => ({
    type: UPLOAD_FILE_FOR_LOAD_SUCCESS,
});

export const uploadFileForLoadFailure = (error) => ({
    type: UPLOAD_FILE_FOR_LOAD_FAILURE,
    payload: error,
});

export const uploadFileForLoad = (loadId, file) => {
    return async (dispatch) => {
        dispatch(uploadFileForLoadRequest());
        try {
            const formData = new FormData();
            formData.append('file', file);

            const response = await fetch(`${process.env.REACT_APP_UPLOAD_FILE_FOR_LOAD_API_URL}/${loadId}/uploadFile`, {
                method: 'POST',
                body: formData,
                credentials: 'include',
            });

            if (response.status === 401) {
                dispatch(logout());
                throw new Error('Unauthorized: User not logged in');
            }

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            dispatch(uploadFileForLoadSuccess());
        } catch (error) {
            dispatch(uploadFileForLoadFailure(error.message));
        }
    };
};


//Change driver load
export const CHANGE_DRIVER_REQUEST = 'CHANGE_DRIVER_REQUEST';
export const CHANGE_DRIVER_SUCCESS = 'CHANGE_DRIVER_SUCCESS';
export const CHANGE_DRIVER_FAILURE = 'CHANGE_DRIVER_FAILURE';
export const changeDriverRequest = () => ({
    type: CHANGE_DRIVER_REQUEST,
});

export const changeDriverSuccess = () => ({
    type: CHANGE_DRIVER_SUCCESS,
});

export const changeDriverFailure = error => ({
    type: CHANGE_DRIVER_FAILURE,
    payload: error,
});

export const changeDriver = (loadId, driverId) => {
    return async dispatch => {
        dispatch(changeDriverRequest());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/main/dispatcher/load/${loadId}/changeDriver`,
                {
                    driverId,
                },
                {
                    withCredentials: true,
                }
            );

            dispatch(changeDriverSuccess(response.data));
        } catch (error) {
            if (error.response && error.response.status === 401) {
                dispatch(logout());
            } else {
                dispatch(changeDriverFailure(error.message));
            }
        }
    };
};


// Find OneLoad
// types.js
export const FETCH_LOAD_REQUEST = 'FETCH_LOAD_REQUEST';
export const FETCH_LOAD_SUCCESS = 'FETCH_LOAD_SUCCESS';
export const FETCH_LOAD_FAILURE = 'FETCH_LOAD_FAILURE';
const fetchLoadRequest = () => ({
    type: FETCH_LOAD_REQUEST,
});

const fetchLoadSuccess = (loadData) => ({
    type: FETCH_LOAD_SUCCESS,
    payload: loadData,
});

const fetchLoadFailure = (error) => ({
    type: FETCH_LOAD_FAILURE,
    payload: error,
});

export const fetchLoad = (loadId, withCredentials = false) => async (dispatch) => {
    dispatch(fetchLoadRequest());
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/main/dispatcher/load/${loadId}/findOne`,
            {
                withCredentials: true,
            }
        );

        if (response.status === 200) {
            const loadData = response.data;
            dispatch(fetchLoadSuccess(loadData));
        } else {
            console.error('Request failed with status:', response.status);
        }
    } catch (error) {
        if (error.response && error.response.status === 401) {
            dispatch(logout());
        }

        dispatch(fetchLoadFailure(error.message));
    }
};


// Update Load


export const UPDATE_LOAD_REQUEST = 'UPDATE_LOAD_REQUEST';
export const UPDATE_LOAD_SUCCESS = 'UPDATE_LOAD_SUCCESS';
export const UPDATE_LOAD_FAILURE = 'UPDATE_LOAD_FAILURE';

export const updateLoadRequest = () => ({
    type: UPDATE_LOAD_REQUEST,
});

export const updateLoadSuccess = (payload) => ({
    type: UPDATE_LOAD_SUCCESS,
    payload,
});

export const updateLoadFailure = (error) => ({
    type: UPDATE_LOAD_FAILURE,
    payload: error,
});

export const updateLoad = (loadData) => async (dispatch) => {
    dispatch(updateLoadRequest());
    try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/main/updateLoad`, loadData, {
            withCredentials: true,
        });

        if (response.status === 401) {
            dispatch(logout());
        }

        if (response.data.success) {
            dispatch(updateLoadSuccess(response.data));
        } else {
            dispatch(updateLoadFailure('Failed to update load. Please try again.'));
        }
    } catch (error) {
        let errorMessage = 'An error occurred while updating the load.';

        if (error.response) {
            errorMessage = error.response.data.message || error.response.statusText;
        } else if (error.request) {
            errorMessage = 'No response received from the server.';
        } else {
            errorMessage = error.message;
        }
        dispatch(updateLoadFailure(errorMessage));
    }
};

export const DELETE_LOAD_REQUEST = 'DELETE_LOAD_REQUEST';
export const DELETE_LOAD_SUCCESS = 'DELETE_LOAD_SUCCESS';
export const DELETE_LOAD_FAILURE = 'DELETE_LOAD_FAILURE';

export const deleteLoadRequest = () => ({type: DELETE_LOAD_REQUEST});
export const deleteLoadSuccess = (loadId) => ({type: DELETE_LOAD_SUCCESS, payload: {loadId}});
export const deleteLoadFailure = (error) => ({type: DELETE_LOAD_FAILURE, payload: error});

export const deleteLoad = (loadId) => {
    return async (dispatch) => {
        dispatch(deleteLoadRequest());

        try {
            await axios.delete(`${process.env.REACT_APP_BASE_URL}/main/dispatcher/load/${loadId}/delete`, {
                withCredentials: true,
            });

            dispatch(deleteLoadSuccess(loadId));
        } catch (error) {
            if (error.response && error.response.status === 401) {
                dispatch(logout());
            } else {
                dispatch(deleteLoadFailure(error.message));
            }
        }
    };
};