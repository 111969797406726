import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {fetchUserSettings} from '../../../store/actions/settingsActions';
import './InfoProfileComponent.scss';
import LoaderComponent from "../../LoaderComponent/LoaderComponent";
import EditPhotoProfile from "./EditPhoto/EditPhoto";
import EditInfoProfile from "./EditInfo/EditInfo";
import EditPasswordProfile from "./EditPassword/EditPassword";

export default function InfoProfileComponent() {
    const dispatch = useDispatch();
    const settingsData = useSelector((state) => state.settings.settingsData);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        dispatch(fetchUserSettings())
            .then(() => setLoading(false))
            .catch(() => setLoading(false));
    }, [dispatch]);


    if (loading) {
        return <LoaderComponent/>;
    }
    return (
        <>
            <div className="info__profile">
                <div className="title__page__profile">
                    Profile settings
                </div>
                <div className="photo__profile__container">
                    <EditPhotoProfile/>
                </div>
                <div className="info__profile__container">
                    <div className="user__name__profile">
                        {settingsData.firstName} {settingsData.lastName}
                    </div>
                </div>
                <div className="personal__information">
                    <EditInfoProfile/>
                </div>
                <div className="password__container">
                    <EditPasswordProfile/>
                </div>
            </div>
        </>
    );
}
