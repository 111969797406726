import {React, useState} from 'react';
import './Routes.scss';
import {LuListFilter} from 'react-icons/lu';
import {BiSearch} from 'react-icons/bi';
import AllDriversComponent from '../AllDriversComponent/AllDriversComponent'
import MyDriversComponent from '../MyDriversComponent/MyDriversComponent'

export default function Routes({onDriverClick}) {
    const [selectedTab, setSelectedTab] = useState('my');
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedDriverId, setSelectedDriverId] = useState(null);
    const [isTabChanging, setIsTabChanging] = useState(false);

    const handleTabChange = (tab) => {
        if (!isTabChanging) {
            setIsTabChanging(true);
            setSelectedTab(tab);
            setIsTabChanging(false);
        }
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleDriverClick = (driverId) => {
        setSelectedDriverId(driverId);
        onDriverClick(driverId);
    };

    return (
        <div className="routes__inner__container">
            <div className="main__drivers__panel">
                <div className="main__drivers__choice">
                    <button
                        className={`main__drivers__my ${selectedTab === 'my' ? 'active' : ''}`}
                        onClick={() => handleTabChange('my')}
                    >
                        My drivers
                    </button>
                    <button
                        className={`main__drivers__all ${selectedTab === 'all' ? 'active' : ''}`}
                        onClick={() => handleTabChange('all')}
                    >
                        All
                    </button>
                </div>
                <div className="main__drivers__search">
                    <BiSearch/>
                    <input
                        type="text"
                        placeholder="Search"
                        value={searchQuery}
                        onChange={handleSearchChange}
                    />
                </div>
                {/*<div className="main__drivers__right__item">*/}
                {/*    <div className="main__drivers__filter">*/}
                {/*        <LuListFilter />*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
            <div className="routes__items__container">
                {selectedTab === 'my' ? (
                    <MyDriversComponent searchQuery={searchQuery} onDriverClick={handleDriverClick}/>
                ) : (
                    <AllDriversComponent searchQuery={searchQuery} onDriverClick={handleDriverClick}/>
                )}
            </div>
        </div>
    );
}