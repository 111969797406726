import './ChangeDriverComponent.scss'
import {useDispatch, useSelector} from "react-redux";
import {getAllDrivers, changeDriver} from "../../../../../store/actions/MainActions";
import React, {useEffect, useState} from 'react';
import { FaCheck } from "react-icons/fa6";
import { MdKeyboardArrowDown } from "react-icons/md";
import { MdKeyboardArrowUp } from "react-icons/md";
import {IoCloseOutline} from 'react-icons/io5';
import LoaderComponent from '../../../../LoaderComponent/LoaderComponent';
export default function ChangeDriverComponent({onClose, loadId}) {
    const dispatch = useDispatch();
    const drivers = useSelector(state => state.main.drivers);
    const [selectedDriverId, setSelectedDriverId] = useState('');
    const driverData = useSelector((state) => state?.main?.main_content.routeDetails.driverName);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isSelectOpen, setIsSelectOpen] = useState(false);

    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedDropdownOption, setSelectedDropdownOption] = useState(driverData);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
    }, [loadId]);

    useEffect(() => {
        const fetchDrivers = async () => {
            try {
                await dispatch(getAllDrivers(true));
            } catch (error) {
                console.error('Error fetching drivers:', error);
            }
        };
        fetchDrivers();
    }, [dispatch]);

    const handleChangeDriver = () => {
        if (selectedDriverId) {
            setIsLoading(true); // Встановлюємо isLoading на true перед відправкою запиту
            dispatch(changeDriver(loadId, selectedDriverId))
                .then(() => {
                    onClose();
                    setIsLoading(false); // Після успішного виконання встановлюємо isLoading на false
                })
                .catch((error) => {
                    console.error('Error changing driver:', error);
                    setIsLoading(false); // Після помилки встановлюємо isLoading на false
                });
        }
    };
    const toggleCustomSelect = () => {
        setIsDropdownOpen(!isDropdownOpen);
        setIsSelectOpen(!isSelectOpen);
    };
    const handleCategoryChange = (event) => {
        const category = event.target.value;
        setSelectedCategory(category);
        setSelectedDropdownOption(event.target.value);
        setSelectedDriverId(event.target.value);
    };
    useEffect(() => {

    }, [selectedCategory]);
    return (
        <div className="modal-overlay__change__driver__load" onClick={onClose}>
            <div className="modal-content__change__driver__load" onClick={(e) => e.stopPropagation()}>
                <div className="change__driver__heading">
                    <div className="title__change__driver"> Change Driver</div>
                    <div className="close-button" onClick={onClose}>
                       < IoCloseOutline/>
                    </div>
                </div>
                <div className="change__driver__body">
                    <div className="selected__driver">
                        
                        <div className={`custom-select change__driver ${isDropdownOpen ? 'active' : ''}`} onClick={toggleCustomSelect}>
                        <div className="selected-options">
    <div className="selected-option">{selectedDropdownOption ? drivers.find(driver => driver.id === selectedDropdownOption)?.driverName || driverData : driverData}</div>
    <div className="selected-option change__driver__svg">{isDropdownOpen ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}</div>
</div>
    <div className="select-icon">
    </div>
    <div className="options-list change__driver__options">
        {drivers.map(driver => ( 
            <div key={driver.id} className={`option-item ${driver.id === selectedDropdownOption ? 'active' : ''}`} onClick={(e) =>  handleCategoryChange({ target: { value: driver.id } })}>
                <label>
                    {driver.driverName}
                </label>
                <div className="checkbox">
                    {driver.id === selectedDropdownOption && <FaCheck />}
                </div>
            </div>
        ))}
    </div>
</div>

                        

                    </div>
                    <div className="btn__change__driver__container">
                    {isLoading && <LoaderComponent />}
                        <div className="btn__change__driver__change" onClick={handleChangeDriver}>
                            Change
                        </div>
                        <div onClick={onClose} className="btn__change__driver__cancel">Cancel</div>
                    </div>
                </div>
            </div>
        </div>
    );
}