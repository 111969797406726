import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.scss';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import Login from './Pages/LoginPage/Login';
import NotFound from "./Pages/NotFoundPage/NotFound";
import MainPage from "./Pages/Dispatcher/MainPage/MainPage";
import VehiclePage from "./Pages/Dispatcher/VehiclePage/VehiclePage";
import HelpPage from "./Pages/Dispatcher/HelpPage/HelpPage";
import AnalyticsPage from "./Pages/Dispatcher/AnalyticsPage/AnalyticsPage";
import CustomersPage from "./Pages/Dispatcher/CustomersPage/CustomersPage";
import DriversProfilePage from "./Pages/Dispatcher/DriversProfilePage/DriversProfilePage";
import PrivateRoute from './PrivateRoute';
import { Provider } from 'react-redux';
import store from './store/store';
import ProfilePage from "./Pages/ProfilePage/ProfilePage";
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import HelpPageDriver from "./Pages/Driver/HelpPage/HelpPage";
import MainPageDriver from "./Pages/Driver/MainPage/MainPage";
import MainPageAdmin from "./Pages/Admin/MainPage/MainPageAdmin";
import TruckProfileComponent from "./Components/Dispathcer/Vehicle/Trucks/TruckProfileComponent/TruckProfileComponent";
import TrailerProfileComponent
    from "./Components/Dispathcer/Vehicle/Trailers/TrailersProfileComponent/TrailersProfileComponent";
import DriverProfileComponent from "./Components/Dispathcer/DriversProfile/DriverProfileComponent";
import DriverProfilePage from "./Pages/Driver/DriverProfilePage/DriverProfilePage";
import ForgotPasswordComponent
    from "./Components/ForgotPasswordComponents/ForgotPasswordComponent/ForgotPasswordComponent";
import ResetPasswordComponent
    from "./Components/ForgotPasswordComponents/ResetPasswordComponent/ResetPasswordComponent";
import PhotoReportComponent from "./Components/Driver/MainComponent/PhotoReportComponent/PhotoReportComponent";
import UsersComponent from "./Components/Admin/UsersComponent/UsersComponent";
import DropdownOptionsContainerComponent from "./Components/Admin/DropdownOptionsComponent/DropdownOptionsContainerComponent";


const selectAuth = state => state.auth;

const selectLoggedInAndRole = createSelector(
    selectAuth,
    auth => ({
        isLoggedIn: auth.isLoggedIn,
        roleName: (auth.userData || {}).roleName || ''
    })
);

function App() {
    const { roleName } = useSelector(selectLoggedInAndRole);

    return (
        <Provider store={store}>
            <I18nextProvider i18n={i18n}>
                <BrowserRouter> 
                    <Routes>
                        <Route path="/" element={<Login />} />
                        <Route path="*" element={<NotFound />} />
                        <Route path="/forgot-password" element={<ForgotPasswordComponent />} />
                        <Route path="/reset-password/" element={<ResetPasswordComponent />} />

                        <Route path="/dispatcher/profile" element={<PrivateRoute role={roleName} allowedRoles={['DISPATCHER']}><ProfilePage /></PrivateRoute>} />
                        <Route path="/dispatcher/main" element={<PrivateRoute role={roleName} allowedRoles={['DISPATCHER']}><MainPage /></PrivateRoute>} />
                        <Route path="/dispatcher/vehicle" element={<PrivateRoute role={roleName} allowedRoles={['DISPATCHER']}><VehiclePage /></PrivateRoute>} />
                        <Route path="/dispatcher/vehicle/track/:trackId" element={<PrivateRoute role={roleName} allowedRoles={['DISPATCHER']}><TruckProfileComponent /></PrivateRoute>} />
                        <Route path="/dispatcher/vehicle/trailer/:trailerId" element={<PrivateRoute role={roleName} allowedRoles={['DISPATCHER']}><TrailerProfileComponent /></PrivateRoute>} />

                        <Route path="/dispatcher/help" element={<PrivateRoute role={roleName} allowedRoles={['DISPATCHER']}><HelpPage /></PrivateRoute>} />
                        <Route path="/dispatcher/analytics" element={<PrivateRoute role={roleName} allowedRoles={['DISPATCHER']}><AnalyticsPage /></PrivateRoute>} />
                        <Route path="/dispatcher/customers" element={<PrivateRoute role={roleName} allowedRoles={['DISPATCHER']}><CustomersPage /></PrivateRoute>} />
                        <Route path="/dispatcher/drivers" element={<PrivateRoute role={roleName} allowedRoles={['DISPATCHER']}><DriversProfilePage /></PrivateRoute>} />
                        <Route path="/dispatcher/driver/:id" element={<PrivateRoute role={roleName} allowedRoles={['DISPATCHER']}><DriverProfileComponent /></PrivateRoute>} />

                        <Route path="/admin/main" element={<PrivateRoute role={roleName} allowedRoles={['ADMIN']}><MainPageAdmin /></PrivateRoute>} />
                        <Route path="/admin/profile" element={<PrivateRoute role={roleName} allowedRoles={['ADMIN']}><ProfilePage /></PrivateRoute>} />
                        <Route path="/admin/dispatchers" element={<PrivateRoute role={roleName} allowedRoles={['ADMIN']}><UsersComponent /></PrivateRoute>} />
                        <Route path="/admin/dropdownoptions" element={<PrivateRoute role={roleName} allowedRoles={['ADMIN']}><DropdownOptionsContainerComponent /></PrivateRoute>} />

                        <Route path="/driver/main" element={<PrivateRoute role={roleName} allowedRoles={['DRIVER']}><MainPageDriver /></PrivateRoute>} />
                        <Route path="/driver/main/photo-report/:id" element={<PrivateRoute role={roleName} allowedRoles={['DRIVER']}><PhotoReportComponent /></PrivateRoute>} />
                        <Route path="/driver/profile" element={<PrivateRoute role={roleName} allowedRoles={['DRIVER']}><ProfilePage /></PrivateRoute>} />
                        <Route path="/driver/documents" element={<PrivateRoute role={roleName} allowedRoles={['DRIVER']}><DriverProfilePage /></PrivateRoute>} />
                        <Route path="/driver/help" element={<PrivateRoute role={roleName} allowedRoles={['DRIVER']}><HelpPageDriver /></PrivateRoute>} />
                    </Routes>
                </BrowserRouter>
            </I18nextProvider>
        </Provider>
    );
}

export default App;
