// AdminAddDispatcherReducer.js
import {
    ADD_DISPATCHER_REQUEST,
    ADD_DISPATCHER_SUCCESS,
    ADD_DISPATCHER_FAILURE
} from '../actions/AdminAddDispatcherActions';

const initialState = {
    loading: false,
    error: null
};

const adminAddDispatcherReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_DISPATCHER_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };

        case ADD_DISPATCHER_SUCCESS:
            return {
                ...state,
                loading: false
            };

        case ADD_DISPATCHER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        default:
            return state;
    }
};

export default adminAddDispatcherReducer;
