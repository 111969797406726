import React, {useEffect, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {fetchUserSettings} from '../../../../store/actions/settingsActions';
import './EditInfo.scss';
import {GoPencil} from "react-icons/go";
import Modal from './EditInfoModal/EditInfoModal';


export default function EditInfoProfileContainer() {
    const dispatch = useDispatch();
    const settingsData = useSelector((state) => state.settings.settingsData);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        dispatch(fetchUserSettings());
    }, [dispatch]);
    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };
    return (
        <>
            <div className="title__perfonal__info">
                <div className="personal__title">
                    Personal information
                </div>
                <div className="edit__personal__info" onClick={openModal}>
                    <GoPencil/>
                </div>
            </div>
            <Modal isOpen={isModalOpen} onClose={closeModal} settingsData={settingsData} />
            <div className="form__group__profile">
                <label htmlFor="firstName">First Name</label>
                <input type="text" id="firstName" name="firstName" value={settingsData?.firstName || ''} readOnly/>
            </div>
            <div className="form__group__profile">
                <label htmlFor="lastName">Last Name</label>
                <input type="text" id="lastName" name="lastName" value={settingsData?.lastName || ''} readOnly/>
            </div>
            <div className="form__group__profile">
                <label htmlFor="dateOfBirth">Date of Birth</label>
                <input type="date" id="dateOfBirth" name="dateOfBirth" value={settingsData?.dateOfBirth || ''}
                       readOnly/>
            </div>
            <div className="form__group__profile">
                <label htmlFor="email">Email</label>
                <input type="email" id="email" name="email" value={settingsData?.email || ''} readOnly/>
            </div>
            <div className="form__group__profile">
                <label htmlFor="phoneNumber">Phone Number</label>
                <input type="tel" id="phoneNumber" name="phoneNumber" value={settingsData?.phoneNumber || ''} readOnly/>
            </div>

        </>
    );
}
