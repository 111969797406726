import axios from 'axios';
import {logout} from "./authActions";

export const FETCH_TRUCK_DROPDOWNS_SUCCESS = 'FETCH_TRUCK_DROPDOWNS_SUCCESS';

export const fetchTruckDropdownsSuccess = (data) => ({
    type: FETCH_TRUCK_DROPDOWNS_SUCCESS,
    payload: data,
});

export const FETCH_TRACK_INFO_SUCCESS = 'FETCH_TRACK_INFO_SUCCESS';

export const fetchTrackInfoSuccess = (data) => ({
    type: FETCH_TRACK_INFO_SUCCESS,
    payload: data,
});

export const FETCH_SINGLE_TRACK_SUCCESS = 'FETCH_SINGLE_TRACK_SUCCESS';

export const fetchSingleTrackSuccess = (data) => ({
    type: FETCH_SINGLE_TRACK_SUCCESS,
    payload: data,
});

export const FETCH_TRACK_PHOTOS_SUCCESS = 'FETCH_TRACK_PHOTOS_SUCCESS';

// Action creator для успішного отримання фото трека
export const fetchTrackPhotosSuccess = (photos) => ({
    type: FETCH_TRACK_PHOTOS_SUCCESS,
    payload: photos,
});


// Додайте цей екшн до вашого файлу з екшнами
export const photosSavedSuccessfully = () => {
    return {
        type: 'PHOTOS_SAVED_SUCCESSFULLY',
    };
};


export const fetchTruckDropdowns = () => {
    return async (dispatch) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_CREATE_TRACK_DROPDOWNS_API_URL}`, {
                credentials: 'include',
            });

            if (response.ok) {
                const data = await response.json();
                dispatch(fetchTruckDropdownsSuccess(data));
            }
            else if (response.status === 401) {
                dispatch(logout());
            }
        } catch (error) {
            console.error('Error fetching truck dropdowns', error);
        }
    };
};


export const createTrack = (trackData) => {
    return async (dispatch) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_CREATE_TRACK_API_URL}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(trackData),
                credentials: 'include',
            });
            if (response.ok) {

            } else {
                if (response.status === 401) {
                    dispatch(logout());
                } else {
                    throw new Error('Failed to create track');
                }
            }
        } catch (error) {
            console.error('Error creating track:', error);
        }
    };
};

export const fetchTrackInfo = () => {
    return async (dispatch) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_GET_TRACK_INFO_API_URL}`, {
                credentials: 'include',
            });

            if (response.ok) {
                const data = await response.json();
                dispatch(fetchTrackInfoSuccess(data));
            } else {
                if (response.status === 401) {
                    dispatch(logout());
                } else {
                    throw new Error('Error fetching track info');
                }
            }
        } catch (error) {
            console.error('Error fetching track info:', error);
        }
    };
};


export const deleteTrack = (trackId) => {
    return async (dispatch) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_DELETE_TRACK_INFO_API_URL}/${trackId}`, {
                method: 'DELETE',
                credentials: 'include',
            });

            if (response.ok) {
                dispatch(fetchTrackInfo());
            } else {
                if (response.status === 401) {
                    dispatch(logout());
                } else {
                    throw new Error('Failed to delete track');
                }
            }
        } catch (error) {
            console.error('Error deleting track:', error);
        }
    };
};

export const UPDATE_TRACK_SUCCESS = 'UPDATE_TRACK_SUCCESS';

export const updateTrackSuccess = () => ({
    type: UPDATE_TRACK_SUCCESS,
});


export const updateTrack = (trackData) => {
    console.log("trackData2", trackData);
    return async (dispatch) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_UPDATE_TRACK_API_URL}`, trackData, {
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            });

            if (response.status === 200) {
                const updatedTrack = response.data;
                dispatch(updateTrackSuccess(updatedTrack));
                await dispatch(fetchTrackInfo());
            } else {
                if (response.status === 401) {
                    dispatch(logout());
                } else {
                    console.log(trackData);
                    throw new Error('Failed to update track');
                }
            }
        } catch (error) {

            console.error('Error updating track:', error);
        }
    };
};


export const fetchSingleTrack = (trackId) => {
    return async (dispatch) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_FETCH_SINGLE_TRACK_API_URL}/${trackId}`, {
                credentials: 'include',
            });

            if (response.ok) {
                const data = await response.json();
                dispatch(fetchSingleTrackSuccess(data));
            } else {
                if (response.status === 401) {
                    dispatch(logout());
                } else {

                    throw new Error('Error fetching single track');
                }
            }
        } catch (error) {
            console.error('Error fetching single track:', error);
        }
    };
};

// photo track

// Action для отримання фото для конкретного трека
export const fetchTrackPhotos = (trackId) => {
    return async (dispatch) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_FETCH_TRACK_PHOTOS_API_URL}/${trackId}/managePhotos`, {
                credentials: 'include',
            });

            if (response.ok) {
                const data = await response.json();
                dispatch(fetchTrackPhotosSuccess(data));
                return data;
            } else {
                if (response.status === 401) {
                    dispatch(logout());
                } else {

                    throw new Error('Error fetching track photos');
                }
            }
        } catch (error) {
            console.error('Error fetching track photos:', error);
            throw error;
        }
    };
};

export const uploadPhotosTruck = (trackId, photos) => {
    return async (dispatch) => {
        try {
            const formData = new FormData();

            photos.forEach((photo, index) => {
                formData.append('photo', photo.file);
            });

            const response = await axios.post(`${process.env.REACT_APP_UPLOAD_PHOTOS_API_URL}/${trackId}/uploadPhotos`, formData, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.status === 200) {
                const responseData = response.data;
                return responseData;
            } else {
                if (response.status === 401) {
                    dispatch(logout());
                } else {
                    throw new Error('Failed to upload photos');
                }
            }
        } catch (error) {
            console.error('Error uploading photos:', error);
            throw error;
        }
    };
};


export const savePhotos = (trackId, photos) => {
    return async (dispatch) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_SAVE_PHOTOS_API_URL}/${trackId}/managePhotos`, photos, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json'
                }
            });


            if (response.status === 200) {
                dispatch(photosSavedSuccessfully());
            } else {
                if (response.status === 401) {
                    dispatch(logout());
                } else {

                    throw new Error('Failed to save photos');
                }
            }
        } catch (error) {
            console.error('Error saving photos:', error);
        }
    };
};


export const FETCH_DROPDOWNS_FILES_TRUCK_REQUEST = 'FETCH_DROPDOWNS_FILES_TRUCK_REQUEST';
export const FETCH_DROPDOWNS_FILES_TRUCK_SUCCESS = 'FETCH_DROPDOWNS_FILES_TRUCK_SUCCESS';
export const FETCH_DROPDOWNS_FILES_TRUCK_FAILURE = 'FETCH_DROPDOWNS_FILES_TRUCK_FAILURE';

export const fetchDropdownsFilesTruckRequest = () => ({
    type: FETCH_DROPDOWNS_FILES_TRUCK_REQUEST,
});

export const fetchDropdownsFilesTruckSuccess = (data) => ({
    type: FETCH_DROPDOWNS_FILES_TRUCK_SUCCESS,
    payload: data,
});

export const fetchDropdownsFilesTruckFailure = (error) => ({
    type: FETCH_DROPDOWNS_FILES_TRUCK_FAILURE,
    payload: error,
});


export const fetchDropdownsFilesTruck = () => {
    return async (dispatch) => {
        dispatch(fetchDropdownsFilesTruckRequest());
        try {
            const response = await fetch(`${process.env.REACT_APP_FETCH_DROPDOWNS_FILES_TRUCK_API_URL}`, {
                method: 'GET',
                credentials: 'include',
            });

            if (!response.ok) {
                if (response.status === 401) {
                    dispatch(logout());
                } else {
                    throw new Error('Network response was not ok');
                }
            }

            const data = await response.json();
            dispatch(fetchDropdownsFilesTruckSuccess(data));
        } catch (error) {
            console.error('Error fetching dropdowns files for truck:', error);
            dispatch(fetchDropdownsFilesTruckFailure(error.message));
        }
    };
};

export const UPLOAD_FILES_REQUEST = 'UPLOAD_FILES_REQUEST';
export const UPLOAD_FILES_SUCCESS = 'UPLOAD_FILES_SUCCESS';
export const UPLOAD_FILES_FAILURE = 'UPLOAD_FILES_FAILURE';
export const uploadFilesRequest = () => ({
    type: UPLOAD_FILES_REQUEST,
});

export const uploadFilesSuccess = () => ({
    type: UPLOAD_FILES_SUCCESS,
});

export const uploadFilesFailure = (error) => ({
    type: UPLOAD_FILES_FAILURE,
    payload: error,
});

export const uploadFiles = (trackId, file, description, nextMaintenance, dropdownId) => {
    return async (dispatch) => {
        dispatch(uploadFilesRequest());
        try {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('description', description);
            formData.append('nextMaintenance', nextMaintenance);
            formData.append('dropdownId', dropdownId);

            const response = await fetch(`${process.env.REACT_APP_UPLOAD_FILES_API_URL}/${trackId}/uploadFiles`, {
                method: 'POST',
                body: formData,
                credentials: 'include',
            });

            if (!response.ok) {
                if (response.status === 401) {
                    dispatch(logout());
                } else {
                    throw new Error('Network response was not ok');
                }
            }

            dispatch(uploadFilesSuccess());
        } catch (error) {
            console.error('Error uploading files:', error);
            dispatch(uploadFilesFailure(error.message));
        }
    };
};


export const DELETE_FILE_REQUEST = 'DELETE_FILE_REQUEST';
export const DELETE_FILE_SUCCESS = 'DELETE_FILE_SUCCESS';
export const DELETE_FILE_FAILURE = 'DELETE_FILE_FAILURE';

export const deleteFileRequest = () => ({
    type: DELETE_FILE_REQUEST,
});

export const deleteFileSuccess = (file) => ({
    type: DELETE_FILE_SUCCESS,
    payload: file,
});

export const deleteFileFailure = (error) => ({
    type: DELETE_FILE_FAILURE,
    payload: error,
});

export const deleteFile = (file) => {
    return async (dispatch) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/trucks/file/${file}`, {
                method: 'DELETE',
                credentials: 'include',
            });
            if (response.ok) {
                const responseData = await response.json();
                console.log('Server response after file deletion:', responseData);
                dispatch({type: 'FILE_DELETED_SUCCESS', file});
            } else {

                if (response.status === 401) {
                    dispatch(logout());
                } else {
                    throw new Error('Failed to delete file');
                }
            }
        } catch (error) {
            console.error('Error deleting file:', error);
            dispatch({type: 'FILE_DELETED_FAILURE', error});
        }
    };
};




export const DELETE_PHOTO_REQUEST = 'DELETE_PHOTO_REQUEST';
export const DELETE_PHOTO_SUCCESS = 'DELETE_PHOTO_SUCCESS';
export const DELETE_PHOTO_FAILURE = 'DELETE_PHOTO_FAILURE';

export const deletePhotoRequest = () => ({
    type: DELETE_PHOTO_REQUEST,
});

export const deletePhotoSuccess = (photoId) => ({
    type: DELETE_PHOTO_SUCCESS,
    payload: photoId,
});

export const deletePhotoFailure = (error) => ({
    type: DELETE_PHOTO_FAILURE,
    payload: error,
});

export const deletePhoto = (trackId, photoId) => {
    return async (dispatch) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_DELETE_PHOTO_API_URL}/${photoId}`, {
                method: 'DELETE',
                credentials: 'include',
            });
            if (response.ok) {
                const responseData = await response.json();
                console.log('Server response after photo deletion:', responseData);
                dispatch({type: 'PHOTO_DELETED_SUCCESS', photoId});
            } else {

                if (response.status === 401) {
                    dispatch(logout());
                } else {
                    throw new Error('Failed to delete photo');
                }
            }
        } catch (error) {
            console.error('Error deleting photo:', error);
            dispatch({type: 'PHOTO_DELETED_FAILURE', error});
        }
    };
};
