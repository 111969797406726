import './TrailersProfileComponent.scss'
import Navigation from "../../../../Navigation/Navigation";
import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {fetchSingleTrailer} from "../../../../../store/actions/trailerActions";
import {useDispatch, useSelector} from 'react-redux';
import {BsThreeDotsVertical} from "react-icons/bs";
import {GoAlert} from "react-icons/go";
import {BiSearch} from "react-icons/bi";
import {LuListFilter} from "react-icons/lu";
import {LuDownload} from "react-icons/lu";
import {GoTrash} from "react-icons/go";

import {BsCardList} from "react-icons/bs";
import {MdOutlineExpandLess} from "react-icons/md";
import {MdOutlineExpandMore} from "react-icons/md";
import {MdKeyboardArrowLeft} from "react-icons/md";
import {MdKeyboardArrowRight} from "react-icons/md";
import {FaArrowLeft} from "react-icons/fa6";
import {useNavigate} from 'react-router-dom';
import LoaderComponent from '../../../../LoaderComponent/LoaderComponent';
import SliderPhotostComponent from "../../SliderPhotostComponent/SliderPhotostComponent";
import AddFIlesTrailersComponentModal from "../AddFIlesComponentModal/AddFIlesComponentTrailersModal";
import {deleteFile} from "../../../../../store/actions/trailerActions";
import EditPhotoTrailerComponentModal from "../EditPhotoTrailerComponentModal/EditPhotoTrailerComponentModal";
import {FaCheck} from "react-icons/fa6";
import {MdKeyboardArrowDown} from "react-icons/md";
import {MdKeyboardArrowUp} from "react-icons/md";
import {IoCloseOutline} from 'react-icons/io5';
import NewTrailer from '../NewTrailersComponentModal/NewTrailersComponentModal';


export default function TrailerProfileComponent() {
    const {trailerId} = useParams();
    const dispatch = useDispatch();
    const singleTrailer = useSelector(state => state.trailer.singleTrailer);
    const singleTraile = useSelector(state => state.trailer);
    const navigate = useNavigate();
    const [isMenuOpen, setIsMenuOpen] = useState(true);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isEditPhotoModalOpen, setIsEditPhotoModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isAddDocumentModalOpen, setIsAddDocumentModalOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredDocuments, setFilteredDocuments] = useState([]);
    const filesDropdowns = useSelector(state => state.trailer.singleTrailer.filesDropdowns);
    const [selectedDropdownOption, setSelectedDropdownOption] = useState('');
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isSelectOpen, setIsSelectOpen] = useState(false);
    // const [selectedSortOption, setSelectedSortOption] = useState('');
    const toggleCustomSelect = () => {
        setIsDropdownOpen(!isDropdownOpen);
        setIsSelectOpen(!isSelectOpen);
    };
    const handleDropdownChange = (event) => {
        setSelectedDropdownOption(event.target.value);

    };
    const filepath = useSelector(state => state.trailer.singleTrailer);


    const managerPhotosClick = () => {
        setIsEditPhotoModalOpen(true);
    };

    const handleDownloadClick = (fileId) => {
        const file = filepath.files.find(file => file.id === fileId);

        if (file && file.link) {
            const protocolLink = `//${file.link}`;

            // Fetch the file content with credentials included
            fetch(protocolLink, {
                credentials: 'include'
            })
                .then(response => response.blob())
                .then(blob => {
                    // Create a blob from the file content
                    const blobUrl = URL.createObjectURL(blob);

                    // Create a temporary link element
                    const link = document.createElement('a');
                    link.href = blobUrl;
                    link.target = '_blank'; // Open in a new tab/window
                    link.download = file.documentName; // Set the downloaded file name
                    document.body.appendChild(link);

                    // Trigger a click event on the link to start the download
                    link.click();

                    // Remove the link element from the DOM
                    document.body.removeChild(link);

                    // Revoke the blob URL to free up resources
                    URL.revokeObjectURL(blobUrl);
                })
                .catch(error => {
                    console.error('Error fetching file content:', error);
                });
        } else {
            console.error('File or link not found');
        }
    };


    const handleDelClick = async (fileId) => {
        await dispatch(deleteFile(fileId));
        handleFileListUpdate();
    };


    const handleAddDocumentClick = () => {
        setIsAddDocumentModalOpen(true);
    };
    const handleFileListUpdate = () => {
        dispatch(fetchSingleTrailer(trailerId));

    };

    const handleBackClick = () => {
        navigate('/dispatcher/vehicle');
    };

    useEffect(() => {
        dispatch(fetchSingleTrailer(trailerId))
            .then(() => setIsLoading(false))
            .catch((error) => {
                setIsLoading(false);
            });

        if (!isEditModalOpen) {
        } 
    }, [dispatch, trailerId, isEditModalOpen]);


    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleEditClick = () => {
        setIsEditModalOpen(true);
    };


    const trailer = singleTrailer;
    const handleSaveChanges = () => {
        // Fetch the updated track information, or any other logic to refresh images
        dispatch(fetchSingleTrailer(trailerId));
    };
    useEffect(() => {
        if (!isEditModalOpen) {
        }
    }, [isEditModalOpen]);


    const handleCheckboxChange = (fileId) => {
        // Toggle the selected state of the checkbox
        if (selectedCheckboxes.includes(fileId)) {
            setSelectedCheckboxes(selectedCheckboxes.filter(id => id !== fileId));
        } else {
            setSelectedCheckboxes([...selectedCheckboxes, fileId]);
        }
    };
    const handleSelectAllCheckboxChange = () => {
        // Toggle the selected state of the "Select All" checkbox
        setSelectAll(!selectAll);

        // If "Select All" is checked, select all checkboxes; otherwise, clear all checkboxes
        setSelectedCheckboxes(selectAll ? [] : filteredDocuments.map(file => file.id));
    };

    const handleBatchDeleteClick = async () => {
        // Perform batch delete for selected checkboxes
        for (const fileId of selectedCheckboxes) {
            await dispatch(deleteFile(fileId));
        }

        // Clear the selected checkboxes
        setSelectedCheckboxes([]);

        // Fetch the updated list of documents
        handleFileListUpdate();
    };
    const [currentPage, setCurrentPage] = useState(1);
    const [documentsPerPage, setPageSize] = useState(10);

    // Calculate start and end indices for the current page
    const startIndex = (currentPage - 1) * documentsPerPage;
    const endIndex = startIndex + documentsPerPage;

    // Get documents for the current page
    const currentDocuments = filteredDocuments.slice(startIndex, endIndex);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const paginationOptions = Array.from(
        {length: Math.min(10, Math.ceil(filteredDocuments.length / 10))}, // Show options up to a maximum of 10
        (_, index) => (index + 1) * 10
    );
    const handlePageSizeChange = (size) => {
        setPageSize(size);
        setCurrentPage(1);
    };

    const [sortOrder, setSortOrder] = useState('asc');
    const [sortField, setSortField] = useState('');

    const handleSortIconClick = (field, direction) => {
        if (sortField === field && sortOrder === direction) {
            // Prevent double-click on the same icon
            return;
        }
        setSortOrder(direction);
        setSortField(field);
    };

    useEffect(() => {
        if (trailer.files) {
            // Filter documents based on the search term
            const filtered = trailer.files.filter(file =>
                (file.documentName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    file.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    file.uploadData.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    file.nextMaintenance.toLowerCase().includes(searchTerm.toLowerCase())) &&
                (!selectedDropdownOption || file.dropdownId === parseInt(selectedDropdownOption))
            );

            // Sort the filtered documents based on the selected field and order
            const sorted = filtered.slice().sort((a, b) => {
                if (sortField === 'documentName') {
                    return sortOrder === 'asc' ? a.documentName.localeCompare(b.documentName) : b.documentName.localeCompare(a.documentName);
                } else if (sortField === 'description') {
                    return sortOrder === 'asc' ? a.description.localeCompare(b.description) : b.description.localeCompare(a.description);
                } else if (sortField === 'uploadData') {
                    return sortOrder === 'asc' ? a.uploadData.localeCompare(b.uploadData) : b.uploadData.localeCompare(a.uploadData);
                } else if (sortField === 'to') {
                    return sortOrder === 'asc' ? a.nextMaintenance.localeCompare(b.nextMaintenance) : b.nextMaintenance.localeCompare(a.nextMaintenance);
                }
                return 0; // Default case
            });

            setFilteredDocuments(sorted);
        }
    }, [searchTerm, trailer.files, selectedDropdownOption, sortField, sortOrder]);


    const handleBatchDownloadClick = () => {
        if (selectedCheckboxes.length === 0) {
            console.error('No files selected for batch download');
            return;
        }

        // Collect file IDs for selected checkboxes
        const filesToDownload = selectedCheckboxes.map(fileId => {
            const file = filepath.files.find(f => f.id === fileId);
            return file;
        });

        // Download each selected file
        filesToDownload.forEach(file => {
            if (file && file.link) {
                const protocolLink = `//${file.link}`;

                // Fetch the file content with credentials included
                fetch(protocolLink, {
                    credentials: 'include'
                })
                    .then(response => response.blob())
                    .then(blob => {
                        // Create a blob from the file content
                        const blobUrl = URL.createObjectURL(blob);

                        // Create a temporary link element
                        const link = document.createElement('a');
                        link.href = blobUrl;
                        link.target = '_blank'; // Open in a new tab/window
                        link.download = file.documentName; // Set the downloaded file name
                        document.body.appendChild(link);

                        // Trigger a click event on the link to start the download
                        link.click();

                        // Remove the link element from the DOM
                        document.body.removeChild(link);

                        // Revoke the blob URL to free up resources
                        URL.revokeObjectURL(blobUrl);
                    })
                    .catch(error => {
                        console.error(`Error fetching file content for ${file.documentName}:`, error);
                    });
            } else {
                console.error('File or link not found');
            }
        });

        // Clear the selected checkboxes
        setSelectedCheckboxes([]);
    };

    const modalEditTrailerStyle = {
        display: isMenuOpen ? 'none' : 'block'
    };


    const handleTdClick = (file) => {
        const link = `//${file.link}`;
        window.open(link, '_blank');
    };


    return (
        <>
            {isLoading ? (
                <LoaderComponent/>
            ) : (
                <div className="one__trailer__page">
                    <Navigation/>
                    <div className="one__trailer__container">
                        <div className="one__trailer__title">
                            <button onClick={handleBackClick}><FaArrowLeft/></button>
                            Documents
                        </div>
                        <div className="one__trailer__table">
                            <div className="one__trailer__table__body">

                                <div key={trailer.id} className="one__trailer__table__row">
                                    <div className='one__trailer__img__description'>
                                        <div className="one__trailer__table__body__item__img">
                                            <SliderPhotostComponent photos={trailer.photos}/>
                                        </div>
                                        <div className='name__detailed__description'>
                                            <div className='name__description'>
                                                <div className='one__trailer__name__dots'>
                                                    <div className='trailer__name__year'>
                                                        <div className="one__trailer__table__body__item name">
                                                            {trailer.main.number}
                                                        </div>
                                                        {/* <div className="one__trailer__table__body__item name year">
                                                            {trailer.title?.year}
                                                        </div> */}
                                                    </div>
                                                    <div className="one__trailer__table__body__item">
                                                        {trailer.title ? trailer.title.comment : "No Comment"}
                                                    </div>

                                                </div>
                                                <div className="vvvv" onClick={() => toggleMenu(trailerId)}>
                                                    <BsThreeDotsVertical/>
                                                    <div className="modal__edit__trailer" style={modalEditTrailerStyle}>
                                                        <div className="modal-content__edit__trailer">
                                                            <div className="d__menu__track">
                                                                <div className="d__menu__item__trailer"
                                                                     onClick={managerPhotosClick}>
                                                                    Manage photos
                                                                </div>
                                                                <div className="d__menu__item__trailer"
                                                                     onClick={() => handleEditClick(trailer.id)}>
                                                                    Edit information
                                                                </div>

                                                                <div className="d__menu__item__trailer">
                                                                    Delete this vehicle
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {isEditPhotoModalOpen && (
                                                    <EditPhotoTrailerComponentModal
                                                        onClose={() => setIsEditPhotoModalOpen(false)}
                                                        onSaveChanges={handleSaveChanges}
                                                    />
                                                )}

                                                {isEditModalOpen && (
                                                    <div className={`modal__add__trailer`}>
                                                        <div className={`modal-content__add__trailer`}>
                                                            <div className={`heading__modal__trailer`}>
                                                                <div className={`title__modal__trailer`}>
                                                                    {`Add trailer`}
                                                                </div>
                                                                <div className={`close__modal__traileeer`}
                                                                     onClick={() => {
                                                                         setIsEditModalOpen(false);

                                                                     }}>
                                                                    <IoCloseOutline/>
                                                                </div>
                                                            </div>

                                                            <NewTrailer
                                                                trailerId={trailerId}
                                                                trailer={trailer}
                                                                onClose={() => setIsEditModalOpen(false)}
                                                                onSuccess={() => {
                                                                    fetchSingleTrailer(trailerId);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>

                                                )}
                                            </div>
                                            <div className='ID__license'>
                                        <div className="model__year">
                                            <div className="trailers__table__body__item upper">
                                                <span>Model</span>
                                                {trailer.title.model}
                                            </div>
                                            <div className="trailers__table__body__item lower">
                                                <span>Year</span>
                                                {trailer.title.year}
                                            </div>
                                            </div>
                                        <div className='detailed__description'>
                                            <div className="trailers__table__body__item upper">
                                                <span>Vehicle ID</span>
                                                {trailer.main.vehicleId}
                                            </div>
                                            <div className="trailers__table__body__item lower">
                                                <span>License plate</span>
                                                {trailer.main.licensePlate}
                                            </div>
                                        </div>
                                        <div className='VIN__Unit'>
                                            <div className="trailers__table__body__item upper">
                                                <span>Insurance</span>
                                                {trailer.main.insurance}
                                            </div>
                                            <div className="trailers__table__body__item lower">
                                                <span>Suspension </span>
                                                {trailer.main.suspension}
                                            </div>
                                        </div>
                                        <div className='insurance__sleeper'>
                                            {/* <div className="trailers__table__body__item upper lower">
                                                <span>Number</span>
                                                {trailer.main.number}
                                            </div> */}
                                            <div className="trailers__table__body__item upper">

                                                <span>Annual Maintenance</span>
                                                {trailer.main.annualInspection}
                                            </div>
                                            <div className="trailers__table__body__item lower">
                                                <span>Structure</span>
                                                {trailer.main.structure}
                                            </div>
                                        </div>

                                        <div className='state__number'>
                                            <div className="trailers__table__body__item upper lower">
                                                <span>Ventilation</span>
                                                {trailer.main.ventilation}
                                            </div>
                                            <div className="trailers__table__body__item lower">
                                                <span>Truck</span>
                                                {trailer.main.truck || 'none'}
                                            </div>
                                        </div>
                                        {/* <div className='DPF__mileage'>

                                            
                                        </div> */}
                                    </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                        <div className='alertMessageTrailerPofile'>
                            {trailer.title.filesMessages.map((fileMessage, index) => (
                                <div className='alert__trailer__profile' key={index} style={{color: fileMessage.color}}>
                                     {fileMessage.message} <GoAlert/>
                                </div>
                            ))}
                        </div>
                        <div className='tools__panel'>

                            <div className="one__trailer__right__item">

                                <div className='drivers__search__component'>
                                <div className={`custom-select ${isDropdownOpen ? 'active' : ''}`}
     onClick={toggleCustomSelect}>
    <div className="selected-options">
        {selectedDropdownOption ? (
            <div className="selected-option">
                {filesDropdowns.find(option => option.id === selectedDropdownOption)?.value}{isSelectOpen ?
                <MdKeyboardArrowUp/> : <MdKeyboardArrowDown/>}
            </div>
        ) : (
            <div className="selected-option">All categories{isSelectOpen ?
                <MdKeyboardArrowUp/> : <MdKeyboardArrowDown/>}</div>
        )}
    </div>
    <div className="select-icon"></div>
    <div className="options-list">
        <div key="all-categories" // Додано ключ для "All categories"
             className={`option-item ${!selectedDropdownOption ? 'active' : ''}`}
             onClick={() => handleDropdownChange({target: {value: null}})}> 
            <label>
                All categories 
            </label>
            <div className="checkbox">
                {!selectedDropdownOption && <FaCheck/>} 
            </div>
        </div>
        {filesDropdowns?.map(option => (
            <div key={option.id}
                 className={`option-item ${option.id === selectedDropdownOption ? 'active' : ''}`}
                 onClick={() => handleDropdownChange({target: {value: option.id}})}>

                <label>
                    {option.value}
                </label>
                <div className="checkbox">
                    {option.id === selectedDropdownOption && <FaCheck/>}
                </div>
            </div>
        ))}
    </div>
</div>

                                </div>

                                <div className="one__trailer__search">
                                    <BiSearch/>
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                    />
                                </div>
                                <div className="one__trailer__filter">
                                    <LuListFilter/>
                                    <p>Filters</p> 
                                </div>
                            </div>
                            <div className='one__trailer__rightright__item'>
                                <div className='icons__dwlnd__trsh'>
                                    <LuDownload onClick={handleBatchDownloadClick}/>
                                    <GoTrash onClick={handleBatchDeleteClick}/>
                                </div>
                                <div className='add__document' onClick={handleAddDocumentClick}>
                                    Add document
                                    <BsCardList/>
                                </div>

                            </div>


                        </div>

                        <div className='table__wrapper__docs'>
                            <div className='table__container__docs'>
                                <table>
                                    <thead>
                                    <tr>

                                        <th onClick={(event) => {
                                            // Перевірка, чи клікнуто на чекбокс
                                            if (event.target.type === 'checkbox') {
                                                return;
                                            }

                                            handleSortIconClick('documentName', sortOrder === 'asc' ? 'desc' : 'asc');
                                        }}>
                                            <div className='table__sort__docs'>
                                                <div className="form_group__remember__container">
                                                    <input
                                                        type="checkbox"
                                                        id="selectAllCheckbox"
                                                        name="selectAllCheckbox"
                                                        checked={selectAll}
                                                        onChange={handleSelectAllCheckboxChange}
                                                    />
                                                    <label htmlFor="selectAllCheckbox"></label>
                                                </div>
                                                Document name
                                                <div className='table__sort__icons'>
                                                    <div
                                                        className={`table__sort__less ${sortField === 'documentName' && sortOrder === 'desc' ? 'active' : ''}`}
                                                        style={{opacity: sortField === 'documentName' && sortOrder === 'desc' ? 0.5 : 1}}
                                                    >
                                                        <MdOutlineExpandLess/>
                                                    </div>
                                                    <div
                                                        className={`table__sort__more ${sortField === 'documentName' && sortOrder === 'asc' ? 'active' : ''}`}
                                                        style={{opacity: sortField === 'documentName' && sortOrder === 'asc' ? 0.5 : 1}}
                                                    >
                                                        <MdOutlineExpandMore/>
                                                    </div>
                                                </div>
                                            </div>
                                        </th>
                                        <th onClick={() => handleSortIconClick('description', sortOrder === 'asc' ? 'desc' : 'asc')}>
                                            <div className='table__sort__docs'>
                                                Description
                                                <div className='table__sort__icons'>
                                                    <div
                                                        className={`table__sort__less ${sortField === 'description' && sortOrder === 'desc' ? 'active' : ''}`}
                                                        style={{opacity: sortField === 'description' && sortOrder === 'asc' ? 0.5 : 1}}
                                                    >
                                                        <MdOutlineExpandLess/>
                                                    </div>
                                                    <div
                                                        className={`table__sort__more ${sortField === 'description' && sortOrder === 'asc' ? 'active' : ''}`}
                                                        style={{opacity: sortField === 'description' && sortOrder === 'desc' ? 0.5 : 1}}
                                                    >
                                                        <MdOutlineExpandMore/>
                                                    </div>
                                                </div>
                                            </div>
                                        </th>
                                        <th onClick={() => handleSortIconClick('to', sortOrder === 'asc' ? 'desc' : 'asc')}>
                                            <div className='table__sort__docs'>
                                                To be updated
                                                <div className='table__sort__icons'>
                                                    <div
                                                        className={`table__sort__less ${sortField === 'to' && sortOrder === 'desc' ? 'active' : ''}`}
                                                        style={{opacity: sortField === 'to' && sortOrder === 'asc' ? 0.5 : 1}}
                                                    >
                                                        <MdOutlineExpandLess/>
                                                    </div>
                                                    <div
                                                        className={`table__sort__more ${sortField === 'to' && sortOrder === 'asc' ? 'active' : ''}`}
                                                        style={{opacity: sortField === 'to' && sortOrder === 'desc' ? 0.5 : 1}}
                                                    >
                                                        <MdOutlineExpandMore/>
                                                    </div>
                                                </div>
                                            </div>
                                        </th>

                                        <th onClick={() => handleSortIconClick('uploadData', sortOrder === 'asc' ? 'desc' : 'asc')}>
                                            <div className='table__sort__docs'>
                                                Upload date
                                                <div className='table__sort__icons'>
                                                    <div
                                                        className={`table__sort__less ${sortField === 'uploadData' && sortOrder === 'asc' ? 'active' : ''}`}
                                                        style={{opacity: sortField === 'uploadData' && sortOrder === 'desc' ? 0.5 : 1}}
                                                    >
                                                        <MdOutlineExpandLess/>
                                                    </div>
                                                    <div
                                                        className={`table__sort__more ${sortField === 'uploadData' && sortOrder === 'desc' ? 'active' : ''}`}
                                                        style={{opacity: sortField === 'uploadData' && sortOrder === 'asc' ? 0.5 : 1}}
                                                    >
                                                        <MdOutlineExpandMore/>
                                                    </div>
                                                </div>
                                            </div>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {currentDocuments?.map((file) => (
                                        <tr key={file.id}>
                                            <td data-cell="MC Number" >
                                                <div className="form_group__remember__container">
                                                    <input
                                                        type="checkbox"
                                                        id={`checkbox_${file.id}`}
                                                        name={`checkbox_${file.id}`}
                                                        checked={selectedCheckboxes.includes(file.id)}
                                                        onChange={() => handleCheckboxChange(file.id)}
                                                    />
                                                    <label  onClick={() => handleTdClick(file)} style={{cursor: 'pointer'}}
                                                  htmlFor={`checkbox_${file.id}`}>{file.documentName}</label>
                                                </div>
                                            </td>
                                            <td data-cell="Company Name">{file?.description}</td>
                                            <td data-cell="Phone Number">{file?.nextMaintenance}</td>
                                            <td data-cell="Register Date">{file?.uploadData}</td> 
                                            <td className='table__tools__docs' data-cell="Actions">
                                                <div className="documents__table__body__item actions__document">
                                                    <div className="actions__document__download"
                                                         onClick={() => handleDownloadClick(file.id)}>
                                                        <LuDownload/>
                                                    </div>
                                                    <div className="actions__document__trash"
                                                         onClick={() => handleDelClick(file.id)}>
                                                        <GoTrash/>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {filteredDocuments.length > 0 && (
                            <div className="pagination">
                                <select onChange={(e) => handlePageSizeChange(e.target.value)} className='per__page'>
                                    {paginationOptions.map((option) => ( 
                                        <option key={option} value={option}>
                                            {`${option} per page`}
                                        </option>
                                    ))}
                                </select>

                                <div className="pagination__pages">
                                    <button
                                        onClick={() => handlePageChange(currentPage - 1)}
                                        disabled={currentPage === 1} // Disable the button if it's the first page
                                    >
                                        <MdKeyboardArrowLeft/>
                                    </button>
                                    <span>{currentPage}</span>
                                    of {Math.ceil(filteredDocuments.length / documentsPerPage)}
                                    <button
                                        onClick={() => handlePageChange(currentPage + 1)}
                                        disabled={currentPage === Math.ceil(filteredDocuments.length / documentsPerPage)} // Disable the button if it's the last page
                                    >
                                        <MdKeyboardArrowRight/>
                                    </button>
                                </div>
                            </div>
                        )}

                    </div>
                </div>

            )}
            {isAddDocumentModalOpen && (
                <AddFIlesTrailersComponentModal
                    closeModal={() => {
                        setIsAddDocumentModalOpen(false);
                        handleFileListUpdate();
                    }}
                    trailerId={trailerId}
                />
            )}
        </>
    )
}
