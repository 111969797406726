import Navigation from "../../../Components/Navigation/Navigation";
import './HelpPage.scss';
import HelpComponents from "../../../Components/Driver/HelpComponents/HelpComponents";

export default function HelpPageDriver() {

    return (
        <div className="help__page__driver">
            <Navigation />
            <HelpComponents />
        </div>
    )

} 