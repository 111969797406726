import React, {useRef, useEffect, useState} from 'react';
// ... other imports
import {useDispatch} from 'react-redux';
import Swiper from 'swiper';
import 'swiper/swiper-bundle.css';
import './SliderDocumentsComponent.scss';
import noImagess from '../../assets/img/noImaget.svg';
import {LuDownload} from "react-icons/lu";
import {GoTrash} from 'react-icons/go';
import {GoScreenFull} from "react-icons/go";
import {HiMagnifyingGlassMinus} from "react-icons/hi2";
import {HiMagnifyingGlassPlus} from "react-icons/hi2";
import {deleteFile} from '../../store/actions/DriversProfileActions';
import {getDriver} from '../../store/actions/DriversProfileActions';

const SliderDocumentsComponent = ({files, selectedCategory, id}) => {
    const [filteredFiles, setFilteredFiles] = useState(files);
    const dispatch = useDispatch();
    const [expirationDate, setExpirationDate] = useState(null);

    useEffect(() => {
        const categoryId = parseInt(selectedCategory, 10);

        const newFilteredFiles = selectedCategory
            ? files.filter((file) => parseInt(file.dropdownId, 10) === categoryId)
            : files;

        setFilteredFiles(newFilteredFiles);
    }, [files, selectedCategory]);
    const swiperRef = useRef(null);
    const modalSwiperRef = useRef(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalPhotoIndex, setModalPhotoIndex] = useState(2);
    // Додайте це після useState для modalPhotoIndex
    const [modalActiveIndex, setModalActiveIndex] = useState(modalPhotoIndex);
    const [fullscreenImageIndex, setFullscreenImageIndex] = useState(0);
    const [zoomLevel, setZoomLevel] = useState(1);
    const zoomDuration = 0.3; // Час анімації в секундах
    const zoomStep = 0.1; // Крок збільшення та зменшення масштабу

    const [lastUpdateDate, setLastUpdateDate] = useState(filteredFiles && filteredFiles[0] && filteredFiles[0].lastUpdate ? filteredFiles[0].lastUpdate : null);
    useEffect(() => {
        if (files?.length > 0 && filteredFiles) {
            setExpirationDate(filteredFiles[0].expirationDate);
            setLastUpdateDate(filteredFiles[0].lastUpdate);
        }
    }, [files]);

    useEffect(() => {
        setActiveIndex(0)
    }, [filteredFiles]);

    const handleZoomIn = () => {
        const newZoomLevel = zoomLevel + 0.1; // можна налаштувати бажаний крок збільшення
        setZoomLevel(newZoomLevel);
    };

    const handleZoomOut = () => {
        const newZoomLevel = Math.max(zoomLevel - zoomStep, 0.1); // Мінімальний рівень масштабування
        setZoomLevel(newZoomLevel);
    };

    const handleDeleteClick = () => {
        const activeSlide = document.querySelector('.swiper-slide-active.driver.fl__slider');
        if (activeSlide) {
            const activeIndex = Array.from(activeSlide.parentNode.children).indexOf(activeSlide);
            const selectedPhoto = filteredFiles[activeIndex];
            const fileId = selectedPhoto.id;

            // Виклик екшну deleteFile з переданим fileId
            dispatch(deleteFile(fileId))
                .then(() => {
                    // Після успішного видалення файлу онови дані про фотографії
                    dispatch(getDriver(id));

                })
                .then(() => setActiveIndex(0))
                .catch((error) => {
                    console.error('Помилка видалення файлу:', error);
                });
        }
    }


    const handleDownloadClick2 = () => {
        const activeSlide = document.querySelector('.swiper-slide-active.driver.fl__slider');

        if (activeSlide) {
            const activeIndex = Array.from(activeSlide.parentNode.children).indexOf(activeSlide);
            const selectedPhoto = filteredFiles[activeIndex];


            if (selectedPhoto && selectedPhoto.link) {
                const protocolLink = `//${selectedPhoto.link}`;

                fetch(protocolLink, {
                    credentials: 'include'
                })
                    .then(response => response.blob())
                    .then(blob => {
                        const blobUrl = URL.createObjectURL(blob);

                        const link = document.createElement('a');
                        link.href = blobUrl;
                        link.target = '_blank';
                        link.download = `downloaded_photo_${activeIndex}.jpg`;
                        document.body.appendChild(link);

                        link.click();

                        document.body.removeChild(link);
                        URL.revokeObjectURL(blobUrl);
                    })
                    .catch(error => {
                        console.error('Error fetching file content:', error);
                    });
            } else {
                console.error('Selected photo or link not found');
            }
        } else {
            console.error('Active swiper slide not found');
        }
    };
    const handleDownloadClick = () => {
        const selectedPhoto = files[modalActiveIndex];

        if (selectedPhoto && selectedPhoto.link) {
            const protocolLink = `//${selectedPhoto.link}`;

            fetch(protocolLink, {
                credentials: 'include'
            })
                .then(response => response.blob())
                .then(blob => {
                    const blobUrl = URL.createObjectURL(blob);

                    const link = document.createElement('a');
                    link.href = blobUrl;
                    link.target = '_blank';
                    link.download = `downloaded_photo_${modalActiveIndex}.jpg`;
                    document.body.appendChild(link);

                    link.click();

                    document.body.removeChild(link);
                    URL.revokeObjectURL(blobUrl);
                })
                .catch(error => {
                    console.error('Error fetching file content:', error);
                });
        } else {
            console.error('Selected photo or link not found');
        }
    };


    useEffect(() => {
        let swiperInstance;

        if (filteredFiles?.length > 0 && swiperRef.current) {
            swiperInstance = new Swiper(swiperRef.current, {
                on: {
                    slideChange: () => {
                        const newIndex = swiperInstance.activeIndex;
                        setFullscreenImageIndex(newIndex);
                        setActiveIndex(newIndex);

                        if (filteredFiles[newIndex] && filteredFiles[newIndex].lastUpdate) {
                            setLastUpdateDate(filteredFiles[newIndex].lastUpdate);
                            setExpirationDate(filteredFiles[newIndex].expirationDate); // оновлюємо дату закінчення дії
                        }
                    },
                },
            });

            return () => {
                swiperInstance.destroy();
            };
        }
    }, [filteredFiles]);

    const goToPrevSlide = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev();
            const newIndex = swiperRef.current.swiper.activeIndex;
            setFullscreenImageIndex(newIndex);
        }
    };

    const goToNextSlide = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext();
            const newIndex = swiperRef.current.swiper.activeIndex;
            setFullscreenImageIndex(newIndex);
        }
    };
    const handlePaginationClick = (index) => {
        if (swiperRef.current && swiperRef.current?.swiper) {
            swiperRef.current.swiper.slideTo(index);
            setFullscreenImageIndex(index); // Додайте цей рядок
        }
    };
    const openModal = (index) => {
        setModalOpen(true);
        setModalPhotoIndex(index);
        setModalActiveIndex(index);

        setTimeout(() => {
            modalSwiperRef.current = new Swiper('.modal-content__slider__photo .swiper-container', {
                initialSlide: index,
                loop: false,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                on: {
                    slideChange: () => {
                        const activeIndex = modalSwiperRef.current?.activeIndex;
                        setModalActiveIndex(activeIndex);
                        setFullscreenImageIndex(activeIndex);
                    },
                },
            });
        }, 0);
    };


    const closeModal = () => {
        setModalOpen(false);
        if (modalSwiperRef.current) {
            modalSwiperRef.current.destroy();
        }
    };


    return (
        <>
            <div className="custom-slider driver">
                {filteredFiles?.length > 0 ? (
                    <div className="swiper-container driver hh_slider" ref={swiperRef}>
                        <div className="swiper-wrapper driver">
                            {filteredFiles?.map((photo, index) => (
                                <div key={index} className="swiper-slide driver fl__slider">
                                    <img
                                        src={photo.link ? `//${photo.link}` : noImagess}
                                        alt={`Truck ${photo.id}`}
                                        style={{
                                            transform: `scale(${zoomLevel})`,
                                            transition: `transform ${zoomDuration}s ease-in-out`,
                                            userSelect: 'none',
                                        }}
                                    />


                                </div>
                            ))}
                        </div>

                        <div className="swiper-button-prev driver" onClick={goToPrevSlide}></div>
                        <div className="swiper-button-next driver" onClick={goToNextSlide}></div>
                        <div className="swiper-pagination inner__tools">
                            <div>

                            </div>
                            <div className="inner__zoom">
                                <HiMagnifyingGlassMinus onClick={handleZoomOut}/>
                                <HiMagnifyingGlassPlus onClick={handleZoomIn}/>

                            </div>
                            <div className="inner__fulscreen">
                                <GoScreenFull onClick={() => openModal(fullscreenImageIndex)}/>

                            </div>
                        </div>

                    </div>
                ) : (
                    <div className="no-image">
                        <img src={noImagess} alt="Landscape view"/>
                    </div>
                )}

            </div>

            <div className='outer main'>

                <div className="swiper-pagination driver">
                    {filteredFiles?.map((photo, index) => (
                        <span
                            key={index}
                            className={`swiper-pagination-bullet${
                                index === activeIndex ? ' active' : ''
                            }`}
                            onClick={() => handlePaginationClick(index)}
                        ></span>
                    ))}
                </div>
            </div>
            {filteredFiles?.length > 0 && (
                <div className='outer__container'>
                    <div>
                        Last Update {lastUpdateDate && <span>{lastUpdateDate}</span>}
                        /Expiration Date {expirationDate && <span>{expirationDate}</span>}
                    </div>
                    <div className='outer__tools'>
                        <div className='trash__tools' onClick={handleDeleteClick}><GoTrash className='gotrash'/></div>
                        <div className='download__tools' onClick={handleDownloadClick2}><LuDownload/></div>
                    </div>
                </div>
            )}

            {modalOpen && (
                <div className="modal__slider__photo" onClick={closeModal}>
                    <div className="modal-content__slider__photo" onClick={(e) => e.stopPropagation()}>
                        {/* <span className="close__slider__photo" onClick={closeModal}>
              &times;
          </span> */}
                        <div className="swiper-container">
                            <div className="swiper-wrapper">
                                {filteredFiles.map((photo, index) => (
                                    <div key={index}
                                         className={`swiper-slide${index === modalPhotoIndex ? ' active' : ''}`}>
                                        <div className='swiper__button_container'>
                                          <span className="close__slider__photo" onClick={closeModal}>
              &times;
          </span>
                                            <img className="modal__slide__show__main"
                                                 src={photo.link ? `//${photo.link}` : noImagess}
                                                 alt={`Truck ${photo.id}`}
                                            />


                                            <LuDownload onClick={handleDownloadClick}/>

                                        </div>
                                    </div>
                                ))}

                            </div>
                            <div className="swiper-pagination"></div>
                            <div className="swiper-button-prev"
                                 onClick={() => modalSwiperRef.current.slidePrev()}></div>
                            <div className="swiper-button-next"
                                 onClick={() => modalSwiperRef.current.slideNext()}></div>

                        </div>

                        <div className="thumbnail-container">
                            {filteredFiles.map((photo, index) => (
                                <div
                                    key={index}
                                    className={`thumbnail${index === modalActiveIndex ? ' active' : ''}`}
                                    onClick={() => modalSwiperRef.current.slideTo(index)}
                                >
                                    <img className="modal__slide__show__img"
                                         src={photo.link ? `//${photo.link}` : noImagess}
                                         alt={`Truck ${photo.id}`}
                                    />
                                </div>
                            ))}
                        </div>

                    </div>
                </div>
            )}

        </>

    );

};

export default SliderDocumentsComponent;
