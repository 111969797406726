import React, {useState, useRef, useEffect} from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import './EditPhoto.scss';
import {updateProfilePhoto, fetchUserSettings} from '../../../../store/actions/settingsActions';
import {MdAddAPhoto} from 'react-icons/md';
import {IoCloseOutline} from 'react-icons/io5';
import {useDispatch, useSelector} from 'react-redux';

const EditPhotoProfile = () => {
    const dispatch = useDispatch();
    const [selectedFile, setSelectedFile] = useState(null);
    const [cropper, setCropper] = useState();
    const settingsData = useSelector((state) => state.settings.settingsData);
    const [imageUrl, setImageUrl] = useState(
        settingsData && settingsData.profileIcon ? `//${settingsData.profileIcon}` : null
    );
    const cropperRef = useRef();
    const fileInputRef = useRef();
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (settingsData?.profileIcon) {
            setImageUrl(`//${settingsData.profileIcon}`)
        }
    }, [settingsData?.profileIcon]);

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    const handleSelectFile = () => {
        fileInputRef.current.click();
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleCrop = async () => {
        if (cropper && selectedFile) {
            try {
                setLoading(true)
                const croppedCanvas = cropper.getCroppedCanvas();
                const blob = await new Promise((resolve) => croppedCanvas.toBlob(resolve, selectedFile.type));
                const file = new File([blob], 'fileName.jpg', {
                    type: selectedFile.type,
                    lastModified: Date.now(),
                });
                const formData = new FormData();
                formData.append('icon', file);

                await dispatch(updateProfilePhoto(formData));
                await dispatch(fetchUserSettings());
                handleCloseModal();
            } catch (err) {
                console.error('Помилка під час оновлення фото:', err);
            } finally {
                setLoading(false)
            }
        }
    };

    const cropperReady = (cropper) => {
        setCropper(cropper);
    };

    return (
        <div className="photo__profile">
            <input type="file" onChange={handleFileChange} ref={fileInputRef} style={{ display: 'none' }} />

            <div className="photo-container" onClick={handleSelectFile}>
                {imageUrl && <img src={imageUrl} alt="" className="profile-image" />}
                <div className="file-icon">
                    <MdAddAPhoto />
                </div>
            </div>

            {selectedFile && showModal && (
                <div className="modal__add__photo">
                    <div className="modal-content__add__photo">
                        <div className="heading__modal__photo">
                            <div className="title__modal__photo">Change photo</div>
                            <div className="close__modal__photo" onClick={handleCloseModal}>
                                <IoCloseOutline />
                            </div>
                        </div>
                        <div style={{ height: 330, width: 330, position: 'relative', overflow: 'hidden' }}>
                            <Cropper
                                src={URL.createObjectURL(selectedFile)}
                                style={{ height: '100%', width: '100%' }}
                                aspectRatio={1}
                                guides={false}
                                movable={true}
                                viewMode={3}
                                dragMode="move"
                                scalable={true}
                                cropBoxResizable={true}
                                cropBoxMovable={true}
                                autoCropArea={1}
                                background={false}
                                ref={cropperRef}
                                onInitialized={cropperReady}
                            />
                        </div>
                        <div className="button__container__photo">
                            <div className="form__group__add__photo__btn">
                                <button disabled={loading} onClick={handleCrop} className="update-button">
                                    {loading ? 'Loading...' : 'Save'}
                                </button>
                            </div>
                            <div className="form__group__cancel__photo__btn">
                                <button onClick={handleCloseModal}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default EditPhotoProfile;