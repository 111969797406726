import './ModalForgotPasswordComponent.scss'
import {IoClose} from "react-icons/io5";
import React from "react";
import {useNavigate} from "react-router-dom";

export default function ModalForgotPasswordComponent( ) {
    const navigate = useNavigate();

    const handleBackToSiteClick = () => {
        navigate('/');
    };
    return (
        <>
            <div className="modal__overlay__forgot__password">
                <div className="modal__content__forgot__password">
                    <div className="modal__header__forgot__password">
                        <div className="modal__title__forgot__password">
                            Forgot password
                        </div>
                        <div className="modal__close__forgot__password">
                            <div onClick={handleBackToSiteClick} ><IoClose/></div>
                        </div>
                    </div>
                    <div className="modal__body__forgot__password">
                        <div className="modal__text__forgot__password">
                            Check your email to reset your password
                        </div>

                        <div className="modal__form__forgot__password__done">
                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19"
                                 fill="none">
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M16.3931 4.98179C16.5415 5.13025 16.6249 5.33158 16.6249 5.5415C16.6249 5.75142 16.5415 5.95275 16.3931 6.10121L8.47642 14.0179C8.32796 14.1663 8.12663 14.2497 7.91671 14.2497C7.70679 14.2497 7.50546 14.1663 7.357 14.0179L3.39867 10.0595C3.32306 9.98651 3.26275 9.89915 3.22126 9.80257C3.17976 9.70598 3.15793 9.6021 3.15701 9.49698C3.1561 9.39186 3.17613 9.28762 3.21593 9.19033C3.25574 9.09303 3.31452 9.00464 3.38886 8.93031C3.46319 8.85598 3.55158 8.79719 3.64887 8.75739C3.74616 8.71758 3.85041 8.69755 3.95553 8.69847C4.06065 8.69938 4.16453 8.72122 4.26111 8.76271C4.3577 8.8042 4.44506 8.86451 4.51809 8.94012L7.91671 12.3387L15.2737 4.98179C15.4221 4.83337 15.6235 4.75 15.8334 4.75C16.0433 4.75 16.2446 4.83337 16.3931 4.98179Z"
                                      fill="#0E91AF"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}