// customerAction.js
import axios from 'axios';
import {logout} from "./authActions";
export const FETCH_CUSTOMERS_REQUEST = 'FETCH_CUSTOMERS_REQUEST';
export const FETCH_CUSTOMERS_SUCCESS = 'FETCH_CUSTOMERS_SUCCESS';
export const FETCH_CUSTOMERS_FAILURE = 'FETCH_CUSTOMERS_FAILURE';

export const ADD_CUSTOMER_REQUEST = 'ADD_CUSTOMER_REQUEST';
export const ADD_CUSTOMER_SUCCESS = 'ADD_CUSTOMER_SUCCESS';
export const ADD_CUSTOMER_FAILURE = 'ADD_CUSTOMER_FAILURE';

export const DELETE_CUSTOMER_REQUEST = 'DELETE_CUSTOMER_REQUEST';
export const DELETE_CUSTOMER_SUCCESS = 'DELETE_CUSTOMER_SUCCESS';
export const DELETE_CUSTOMER_FAILURE = 'DELETE_CUSTOMER_FAILURE';




export const fetchCustomers = (isBlacklist) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_CUSTOMERS_REQUEST });

        try {
            const response = await axios.get(`${process.env.REACT_APP_CUSTOMERS_API_URL}/findAll?blacklistPage=${isBlacklist}`, {
                withCredentials: true,
            });

            const data = response.data;
            dispatch({
                type: FETCH_CUSTOMERS_SUCCESS,
                payload: data,
            });
        } catch (error) {
            if (error.response && error.response.status === 401) {
                dispatch(logout());
            } else {
                dispatch({
                    type: FETCH_CUSTOMERS_FAILURE,
                    payload: error.message,
                });
            }
        }
    };
};

export const addCustomer = (customerData) => {
    return async (dispatch) => {
        dispatch({ type: ADD_CUSTOMER_REQUEST });

        try {
            const response = await axios.post(`${process.env.REACT_APP_CUSTOMERS_API_URL}/create`, customerData, {
                withCredentials: true,
            });

            const data = response.data;
            console.log(data);
            dispatch({
                type: ADD_CUSTOMER_SUCCESS,
                payload: data,
            });
        } catch (error) {
            if (error.response && error.response.status === 401) {
                dispatch(logout());
            } else {
                dispatch({
                    type: ADD_CUSTOMER_FAILURE,
                    payload: error.message,
                });
            }
        }
    };
};

export const addToBlacklist = (customerId, comment) => {
    return async (dispatch) => {
        dispatch({ type: ADD_CUSTOMER_REQUEST });

        try {
            const response = await axios.put(`${process.env.REACT_APP_CUSTOMERS_API_URL}/changeStatus/${customerId}?status=true&blacklistPage=true`, { comment: comment }, {
                withCredentials: true,
            });

            const data = response.data;
            dispatch({
                type: ADD_CUSTOMER_SUCCESS,
                payload: data,
            });
        } catch (error) {
            if (error.response && error.response.status === 401) {
                dispatch(logout());
            } else {
                dispatch({
                    type: ADD_CUSTOMER_FAILURE,
                    payload: error.message,
                });
            }
        }
    };
};


export const deleteCustomer = (customerId, isBlacklist) => {
    return async (dispatch) => {
        dispatch({ type: DELETE_CUSTOMER_REQUEST });

        try {
            await axios.delete(`${process.env.REACT_APP_CUSTOMERS_API_URL}/${customerId}?blacklistPage=${isBlacklist}`, {
                withCredentials: true,
            });

            dispatch({
                type: DELETE_CUSTOMER_SUCCESS,
                payload: customerId,
            });
        } catch (error) {
            if (error.response && error.response.status === 401) {
                dispatch(logout());
            } else {
                dispatch({
                    type: DELETE_CUSTOMER_FAILURE,
                    payload: error.message,
                });
            }
        }
    };
};


export const FETCH_ALL_CUSTOMERS_REQUEST = 'FETCH_ALL_CUSTOMERS_REQUEST';
export const FETCH_ALL_CUSTOMERS_SUCCESS = 'FETCH_ALL_CUSTOMERS_SUCCESS';
export const FETCH_ALL_CUSTOMERS_FAILURE = 'FETCH_ALL_CUSTOMERS_FAILURE';

export const fetchAllCustomers = () => {
    return async (dispatch) => {
        dispatch({ type: FETCH_ALL_CUSTOMERS_REQUEST });

        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/customers/findAll`, {
                withCredentials: true,
            });

            const data = response.data;
            dispatch({
                type: FETCH_ALL_CUSTOMERS_SUCCESS,
                payload: data,
            });
        } catch (error) {
            if (error.response && error.response.status === 401) {
                dispatch(logout());
            } else {
                dispatch({
                    type: FETCH_ALL_CUSTOMERS_FAILURE,
                    payload: error.message,
                });
            }
        }
    };
};