import './ForgotPasswordComponent.scss';
import logoLogin from '../../../assets/img/logo/kpo-login.png';
import ModalForgotPasswordComponent from "./ModalForgotPasswordComponent/ModalForgotPasswordComponent";
import React, {useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Navigate, useNavigate} from 'react-router-dom';
import {CiCircleAlert} from "react-icons/ci";
import {sendForgotPasswordLink} from "../../../store/actions/forgotPasswordActions";

export default function ForgotPasswordComponent() {
    const [isModalVisible, setModalVisibility] = useState(false);
    const [email, setEmail] = useState('');
    const [isEmailEmpty, setIsEmailEmpty] = useState(false);
    const [isEmailInvalid, setIsEmailInvalid] = useState(false);
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.forgotPassword.loading);
    const navigate = useNavigate();
    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
    const userRole = useSelector((state) => state.auth.userData?.roleName);


    if (isLoggedIn) {
        switch (userRole) {
            case 'ADMIN':
                return <Navigate to="/admin/main"/>;
            case 'DISPATCHER':
                return <Navigate to="/dispatcher/main"/>;
            case 'DRIVER':
                return <Navigate to="/driver/main"/>;
            default:
                return <Navigate to="/dashboard"/>;

        }
    }

    const handleBackToSiteClick = () => {
        navigate('/');
    };

    const handleForgotPasswordClick = () => {
        if (email.trim() === '') {
            setIsEmailEmpty(true);
            setIsEmailInvalid(false);
        } else if (!isValidEmail(email)) {
            setIsEmailEmpty(false);
            setIsEmailInvalid(true);
        } else {
            setIsEmailEmpty(false);
            setIsEmailInvalid(false);
            setModalVisibility(true);
            dispatch(sendForgotPasswordLink(email));
        }
    };


    const closeModal = () => {
        setModalVisibility(false);
    };

    const isValidEmail = (value) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(value);
    };

    return (
        <div className="forgot__password__container">
            <div className="forgot__password__logo__container">
                <img src={logoLogin} alt="logo"/>
            </div>

            <div className="forgot__password__text__container">Forgot password</div>
            <div className="form__container__forgot__password">
                <div
                    className={`forgot__password__form form__group ${isEmailEmpty ? 'empty' : ''} ${isEmailInvalid ? 'invalid' : ''}`}>
                    <div className="form__group__label">
                        <label htmlFor="email">Email:</label>
                        {isEmailEmpty && <div className="error-message">Email is empty <CiCircleAlert/></div>}
                        {isEmailInvalid && <div className="error-message">Invalid email format <CiCircleAlert/></div>}
                    </div>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className={`form__group__input ${isEmailEmpty || isEmailInvalid ? 'invalid' : ''}`}
                    />

                </div>
                <div className="form__group__btn__container">
                    <button type="button" onClick={handleForgotPasswordClick} disabled={loading}>
                    {loading ? 'Loading...' : 'Get reset link'}
                    </button>
                    <div className="back" onClick={handleBackToSiteClick}>
                        Back to site
                    </div>
                </div>
                {isModalVisible && <ModalForgotPasswordComponent onClose={closeModal}/>}
            </div>
        </div>
    );
}