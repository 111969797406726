import React, {useEffect, useState} from 'react';
import './AddFilesComponentTruckModal.scss';
import {useDispatch, useSelector} from 'react-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {fetchDropdownsFilesTruck} from "../../../../../store/actions/trackActions";
import {uploadFiles} from "../../../../../store/actions/trackActions";
import {IoCloseOutline} from 'react-icons/io5';
import {MdKeyboardArrowDown} from "react-icons/md";
import {MdKeyboardArrowUp} from "react-icons/md";
import {FaCheck} from "react-icons/fa6";
import {CiCircleAlert} from "react-icons/ci";
import {IoClose} from 'react-icons/io5';

import LoaderComponent from "../../../../LoaderComponent/LoaderComponent";
const AddFilesTruckComponentModal = ({ closeModal, trackId }) => {
    const dispatch = useDispatch();
    const dropdowns = useSelector((state) => state.track.dropdowns);

    const [file, setFile] = useState(null);
    const [description, setDescription] = useState('');
    const [nextMaintenance, setNextMaintenance] = useState('');
    const [selectedDropdownId, setSelectedDropdownId] = useState('');

    const [fileError, setFileError] = useState('');
    const [descriptionError, setDescriptionError] = useState('');
    const [nextMaintenanceError, setNextMaintenanceError] = useState(''); 
    const [selectedDropdownIdError, setSelectedDropdownIdError] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isSelectOpen, setIsSelectOpen] = useState(false);
    const [selectedDocumentType, setSelectedDocumentType] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [validationErrors, setValidationErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [selectedFileName, setSelectedFileName] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileUploaded, setFileUploaded] = useState(false);


    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setSelectedFile(selectedFile);
        setSelectedFileName(selectedFile.name); // Оновлення імені файлу при виборі
        setValidationErrors(prevErrors => ({...prevErrors, selectedFile: ''}));
        setFileUploaded(true); // Встановлення прапорця, що файл був завантажений
        setFile(selectedFile);
    };

    const validateFields = () => {
        let isValid = true;
    
        if (!file) {
            setFileError('Please select a file');
            isValid = false;
        } else {
            setFileError('');
        }

        if (!description) {
            setDescriptionError('This field is empty');
            isValid = false;
        } else if (description.length > 100) {
            setDescriptionError('Description must be at most 100 characters');
            isValid = false;
        } else {
            setDescriptionError('');
            const truncatedDescription = description.slice(0, 100);
        }
    
        if (!nextMaintenance) {
            setNextMaintenanceError('This field is empty');
            isValid = false;
        } else {
            setNextMaintenanceError('');
        }
    
        if (!selectedDropdownId) {
            setSelectedDropdownIdError('This field is empty');
            isValid = false;
        } else {
            setSelectedDropdownIdError('');
        }
    
        return isValid;
    };
    const toggleCustomSelect = () => {
        setIsDropdownOpen(!isDropdownOpen);
        setIsSelectOpen(!isSelectOpen);
    };
    const formatDate = (date) => {    
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const handleSubmit = async () => {
        if (validateFields()) {
            setIsLoading(true);
            try {
                const formattedDate = nextMaintenance.toISOString().split('T')[0]; // Форматування дати в формат "YYYY-MM-DD"
                console.log(formattedDate);
                await dispatch(uploadFiles(trackId, file, description, formattedDate, selectedDropdownId));
                closeModal();
            } catch (error) {
                console.error("Error uploading files:", error);
            } finally {
                setIsLoading(false); // Після відправки (незалежно від результату) встановлюємо isLoading в false
            }
        }
    };
    
    
    useEffect(() => {
        dispatch(fetchDropdownsFilesTruck());
    }, [dispatch]);
    const handleCategoryChange = async (event) => {
        const category = event.target.value;
        setSelectedCategory(category);
        setSelectedDocumentType(category);
        setSelectedDropdownId(category);
        console.log(selectedDropdownId);
        setValidationErrors(prevErrors => ({ ...prevErrors, selectedDropdownId: '' }));
        setSelectedDropdownId(event.target.value)
    }
    return (
        <div className="modal-overlay__add__files__truck" onClick={closeModal}>
            <div className="modal-content__add__files__truck" onClick={(e) => e.stopPropagation()}>
                <div className="modal-content__head__add__files__truck">
                    <div className="add_docyment__title"> Add document</div>
                    <div className="close__modal__add__files__truck" onClick={closeModal}><IoCloseOutline /></div>
                </div>
                <div className="add__files__truck">
                    <div className="form__group__add__files__truck">
                        <label htmlFor="fileType">Document category
                        
                            {selectedDropdownIdError && <span className="error-message">{selectedDropdownIdError}</span>}
                        </label>
                        <div className="form-group_add__document__driver">
                            <div className={`custom-select doc_category__main ${isDropdownOpen ? 'active' : ''}`}
                                 onClick={toggleCustomSelect}>
                                <div className="selected-options doc__category">
                                    <div
                                        className="selected-option doc__font">{selectedDocumentType ? dropdowns.find(item => item.id === selectedDocumentType)?.value : 'Select category'}</div>
                                    <div className="selected-option doc__category">{isDropdownOpen ?
                                        <MdKeyboardArrowUp/> : <MdKeyboardArrowDown/>}</div>
                                </div>
                                <div className="select-icon">
                                </div>
                                <div className="options-list doc">
                                    {dropdowns.map((item) => (
                                        <div key={item.id}
                                             className={`option-item ${item.id === selectedDocumentType ? 'active' : ''}`}
                                             onClick={(e) => handleCategoryChange({target: {value: item.id}})}>
                                            <label>
                                                {item.value}
                                            </label>
                                            <div className="checkbox">
                                                {item.id === selectedDocumentType && <FaCheck/>}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form__group__add__files__truck">
                        <label htmlFor="nextMaintenance">Next maintenance
                            <div className="error-message">{nextMaintenanceError}</div>
                        </label>
                        <DatePicker className='date-picker-truck'
                            selected={nextMaintenance}
                            onChange={(date) => setNextMaintenance(date)}
                            dateFormat="MM/dd/yyyy"
                            showYearDropdown
                        />

                    </div>
                    <div className="form__group__add__files__truck">
                        <label htmlFor="fileDescription">File description
                            <div className="error-message">{descriptionError}</div>
                        </label>
                        <textarea
                            name="fileDescription"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        ></textarea>

                    </div>
                    {/* <div className="form__group__add__files__truck file-input-container">
                        <div className="error-message">{fileError}</div>
                        <input
                            type="file"
                            name="file"
                            id="file"
                            className="file-input"
                            onChange={handleFileChange}
                        />
                        <span className="file-upload-text">
                            Upload File
                        </span>

                    </div> */}
                    <div className="file-input-container">
                        {!selectedFileName && ( // Перевірка, чи файл не обраний
                            <div>
                                <input
                                    type="file"
                                    name="file"
                                    id="file"
                                    className="file-input"
                                    onChange={handleFileChange}
                                />
                                <label htmlFor="file" className="file-upload-text">
                                    Upload File
                                </label>
                            </div>
                        )}
                        {selectedFileName && ( // Перевірка, чи файл обраний
                            <div >
                                <label htmlFor="file" className="file-upload-text">
                                    {selectedFileName}
                                    <div className="deleteFileTrailer" onClick={() => setSelectedFileName('')}><IoClose/>
                                    </div>
                                </label>


                            </div>
                        )}
                        {validationErrors.selectedFile && (
                            <span className="error-message">{validationErrors.selectedFile}</span>
                        )}
                        <div className="error-message">{fileError}</div>
                    </div>
                    <div className="btn_add__files__truck">
                    {isLoading ? (
        <LoaderComponent /> // Лоадер показується, коли isLoading === true
    ) : (
        
                        <button className="add_file__truck" onClick={handleSubmit}>
                            Add file
                        </button>
    )}
                        <button className="cancel_file__truck" onClick={closeModal}>
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddFilesTruckComponentModal;