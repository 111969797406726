import React, {useState} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import InputMask from 'react-input-mask';
import './AddUserComponent.scss'
import {IoClose, IoEye, IoEyeOff} from "react-icons/io5";
import {useDispatch} from 'react-redux';
import {addDispatcher} from "../../../../store/actions/AdminAddDispatcherActions";
import {CiCircleAlert} from "react-icons/ci";
import LoaderComponent from '../../../LoaderComponent/LoaderComponent';
export default function AddUserComponent({ closeModal }) {
    const [selectedDate, setSelectedDate] = React.useState(null);
    const dispatch = useDispatch();
    const [formErrors, setFormErrors] = useState({});
    const [passwordMatch, setPasswordMatch] = useState(true);
    const [passwords, setPasswords] = useState({ password: '', confirmPassword: '' });
    const [showPassword, setShowPassword] = useState(false);
    const [submitting, setSubmitting] = useState(false); 

    const toggleShowPassword = () => {
        setShowPassword((prev) => !prev);
    };

    const validateForm = (event) => {
        const errors = {};
        const password = passwords.password;
        const confirmPassword = passwords.confirmPassword;

        if (!password) {
            errors.password = 'Password is required';
        } else if (password.length < 8 || password.length > 20) {
            errors.password = 'Password must be between 8 and 20 characters';
        }


        if (!confirmPassword) {
            errors.confirmPassword = 'Confirm password is required';
        } else if (password !== confirmPassword) {
            errors.confirmPassword = 'Passwords do not match';
        }

        const firstName = event.target.elements.FirstName.value;
        if (!firstName) {
            errors.firstName = 'First name is required';
        } else if (firstName.length > 50) {
            errors.firstName = 'First name cannot exceed 50 characters';
        }

        const lastName = event.target.elements.LastName.value;
        if (!lastName) {
            errors.lastName = 'Last name is required';
        } else if (lastName.length > 50) {
            errors.lastName = 'Last name cannot exceed 50 characters';
        }

        const phoneNumber = event.target.elements.phoneNumber.value;
        if (!phoneNumber) {
            errors.phoneNumber = 'Phone number is required';
        }

        const email = event.target.elements.Email.value;
        if (!email) {
            errors.email = 'Email is required';
        } else if (email.length > 50) {
            errors.email = 'Email cannot exceed 50 characters';
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
            errors.email = 'Invalid email format';
        }

        const dateOfBirth = selectedDate;
        if (!dateOfBirth) {
            errors.dateOfBirth = 'Date of birth is required';
        }

        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };


    const handleFormSubmit = async (event) => {
        event.preventDefault();

        if (!validateForm(event)) {
            return;
        }
        setSubmitting(true);
        const formattedDate = selectedDate ? `${selectedDate.getFullYear()}-${(selectedDate.getMonth() + 1).toString().padStart(2, '0')}-${selectedDate.getDate().toString().padStart(2, '0')}` : null;

        const formData = {
            firstName: event.target.elements.FirstName.value,
            lastName: event.target.elements.LastName.value,
            phoneNumber: event.target.elements.phoneNumber.value,
            email: event.target.elements.Email.value,
            password: event.target.elements.password.value,
            dateOfBirth: formattedDate,
        };
        await dispatch(addDispatcher(formData));
        
        setSubmitting(false);
        
        closeModal();
    };


    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const handlePasswordChange = (event) => {
        const {name, value} = event.target;
 
        setPasswords((prevPasswords) => {
            const updatedPasswords = {...prevPasswords, [name]: value};
            setPasswordMatch(updatedPasswords.confirmPassword === updatedPasswords.password);
            return updatedPasswords;
        });
    };


    return (<div className="modalAddUser">
        <div className="modal-contentAddUser">
            <div className="HeadAddUserModal">
                <div className="TitleAddUserModal">
                    New dispatcher
                </div>
                <div className="CloseAddUserModal" onClick={closeModal}>
                    <IoClose/>
                </div>
            </div>

            <div className="BodyAddUserModal">
                <form className="formContainerAddUser" onSubmit={handleFormSubmit}>
                    <div className="AddNewUserFormGroup">
                        <label htmlFor="username">First name:
                            {formErrors.firstName && <span className="error">{formErrors.firstName}<CiCircleAlert/>
</span>}

                        </label>
                        <input type="text" name="FirstName"/>
                    </div>
                    <div className="AddNewUserFormGroup">
                        <label htmlFor="username">Last name:
                            {formErrors.lastName && <span className="error">{formErrors.lastName}<CiCircleAlert/>
</span>}{}
                        </label>
                        <input type="text" name="LastName"/>
                    </div>
                    <div className="AddNewUserFormGroup">
                        <label htmlFor="username">Phone number:
                            {formErrors.phoneNumber && <span className="error">{formErrors.phoneNumber}<CiCircleAlert/>
</span>}

                        </label>
                        <InputMask
                            type="text"
                            name="phoneNumber"
                            mask="+1 (999)999-9999"
                            maskChar="_"
                        />
                    </div>
                    <div className="AddNewUserFormGroup">
                        <label htmlFor="username">Date of birth:
                            {formErrors.dateOfBirth && <span className="error">{formErrors.dateOfBirth}<CiCircleAlert/>
</span>}

                        </label>
                        <DatePicker
                            selected={selectedDate}
                            onChange={handleDateChange}
                            showYearDropdown
                            dateFormat="MM/dd/yyyy"
                            placeholderText="Select date"
                            dropdownMode="select"
                        />
                    </div>
                    <div className="AddNewUserFormGroup">
                        <label htmlFor="username">Email:
                            {formErrors.email && <span className="error">{formErrors.email}<CiCircleAlert/>
</span>}{}
                        </label>
                        <input type="text" name="Email" autoComplete="off"/>
                    </div>
                    <div className="AddNewUserFormGroup">
                        <label htmlFor="username">Password:
                            {formErrors.password && <span className="error">{formErrors.password}<CiCircleAlert/>
</span>}
                        </label>
                        <input
                            type={showPassword ? "text" : "password"}
                            name="password"
                            value={passwords.password}
                            onChange={handlePasswordChange}
                            autoComplete="off"
                        />
                        <span onClick={toggleShowPassword}>
            {showPassword ? <IoEye/> : <IoEyeOff/>}
        </span>
                    </div>
                    <div className="AddNewUserFormGroup">
                        <label htmlFor="username">Confirm password:
                            {formErrors.confirmPassword &&
                                <span className="error">{formErrors.confirmPassword}<CiCircleAlert/>
</span>}
                        </label>
                        <input
                            type={showPassword ? "text" : "password"}
                            name="confirmPassword"
                            value={passwords.confirmPassword}
                            onChange={handlePasswordChange}
                        />
                        <span onClick={toggleShowPassword}>
            {showPassword ? <IoEye/> : <IoEyeOff/>}
        </span>
                    </div>
                    {!passwordMatch && (<div className="ErrorMessageAddNewDis">
                        Passwords do not match <CiCircleAlert/>

                    </div>)}
                    <div className="AddNewUserBtn">
                        <button type="submit" className="AddNewUserBtnSave">Register Dispatcher</button>

                        <div className="AddNewUserBtnClose" onClick={closeModal}>Close</div>
                    </div>
                </form>
            </div>
        </div>
        {submitting && (
                <div className="overlay">
                    <LoaderComponent />
                </div>
            )}
    </div>);
}