import React from 'react';
import './EditPasswordSuccessModal.scss';

const EditPasswordSuccessModal = (onClose) => {


    return (
        <div className="modal__overlay__success">
            <div className="modal__content__success">
                <div className="modal__head__success">
                    <div className="title__success">Password Updated</div>
                    <div className="close__success" onClick={onClose}>
                        Close
                    </div>
                </div>
                <div className="message__success">
                    <p>Your password has been successfully updated.</p>
                </div>
                <button className="btn__close__success" onClick={onClose}>
                    Close
                </button>
            </div>
        </div>
    );
};

export default EditPasswordSuccessModal;
