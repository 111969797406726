import './UserComponent.scss';
import Navigation from "../../Navigation/Navigation";
import UserContainerComponent from "./UserContainerComponent/UserContainerComponent";

export default function UsersComponent() {

    return (
        <div className="adminUsersPage">
            <Navigation/>
            <UserContainerComponent/>
        </div>
    )
}