import axios  from "axios";
import {logout} from "./authActions";

// Типи дій
export const FETCH_DISPATCHERS_REQUEST = 'FETCH_DISPATCHERS_REQUEST';
export const FETCH_DISPATCHERS_SUCCESS = 'FETCH_DISPATCHERS_SUCCESS';
export const FETCH_DISPATCHERS_FAILURE = 'FETCH_DISPATCHERS_FAILURE';

// Action Creators
export const fetchDispatchersRequest = () => ({
    type: FETCH_DISPATCHERS_REQUEST,
});

export const fetchDispatchersSuccess = (dispatchers) => ({
    type: FETCH_DISPATCHERS_SUCCESS,
    payload: dispatchers,
});

export const fetchDispatchersFailure = (error) => ({
    type: FETCH_DISPATCHERS_FAILURE,
    payload: error,
});


export const fetchDispatchers = () => {
    return (dispatch) => {
        dispatch(fetchDispatchersRequest());
        axios.get(`${process.env.REACT_APP_BASE_URL}/dispatchers/findAll`, {
            withCredentials: true,
        })
            .then(response => {
                const dispatchers = response.data;
                dispatch(fetchDispatchersSuccess(dispatchers));
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    dispatch(logout());
                } else {
                    dispatch(fetchDispatchersFailure(error.message));
                }
            });
    };
};

export const FETCH_ANALYTICS_REQUEST = 'FETCH_ANALYTICS_REQUEST';
export const FETCH_ANALYTICS_SUCCESS = 'FETCH_ANALYTICS_SUCCESS';
export const FETCH_ANALYTICS_FAILURE = 'FETCH_ANALYTICS_FAILURE';

export const fetchAnalyticsRequest = () => ({
    type: FETCH_ANALYTICS_REQUEST,
});

export const fetchAnalyticsSuccess = (analyticsData) => ({
    type: FETCH_ANALYTICS_SUCCESS,
    payload: analyticsData,
});

export const fetchAnalyticsFailure = (error) => ({
    type: FETCH_ANALYTICS_FAILURE,
    payload: error,
});

export const fetchAnalytics = (dispatcherId, dateRange) => {
    return (dispatch) => {
        const { startDate, endDate } = dateRange;
        dispatch(fetchAnalyticsRequest());

        axios.get(`${process.env.REACT_APP_BASE_URL}/analytics/get?dispatcherId=${dispatcherId}&dateFrom=${startDate}&dateTo=${endDate}`, {
            withCredentials: true,
        })
            .then(response => {
                const analyticsData = response.data;
                dispatch(fetchAnalyticsSuccess(analyticsData));
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    dispatch(logout());
                } else {
                    dispatch(fetchAnalyticsFailure(error.message));
                }
            });
    };
};


