import axios from 'axios';
import {logout} from "./authActions";
// ActionTypes.js
export const FETCH_DISPATCHERS_REQUEST = 'FETCH_DISPATCHERS_REQUEST';
export const FETCH_DISPATCHERS_SUCCESS = 'FETCH_DISPATCHERS_SUCCESS';
export const FETCH_DISPATCHERS_FAILURE = 'FETCH_DISPATCHERS_FAILURE';
// actions.js



const fetchDispatchersRequest = () => ({
    type: FETCH_DISPATCHERS_REQUEST,
});

const fetchDispatchersSuccess = (dispatchers) => ({
    type: FETCH_DISPATCHERS_SUCCESS,
    payload: dispatchers,
});

const fetchDispatchersFailure = (error) => ({
    type: FETCH_DISPATCHERS_FAILURE,
    payload: error,
});

export const fetchDispatchers = (withCredentials = false) => {
    return (dispatch) => {
        dispatch(fetchDispatchersRequest());
        axios.get(`${process.env.REACT_APP_BASE_URL}/admin/findAllDispatchers`, {
            withCredentials: true,
        })
            .then((response) => {
                const dispatchers = response.data;
                dispatch(fetchDispatchersSuccess(dispatchers));
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                dispatch(logout())
                    console.error('Unauthorized access. Redirecting to login page...');
                } else {
                    dispatch(fetchDispatchersFailure(error.message));
                }
            });
    };
};

