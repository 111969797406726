import './RouteComponent.scss';
import {FiCamera} from "react-icons/fi";
import {FaRegCircle} from "react-icons/fa";
import {MdPlace} from "react-icons/md";
import {useDispatch, useSelector} from 'react-redux';
import {fetchDriverFindMainContent} from '../../../../store/actions/MainActions';
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import LoaderComponent from "../../../LoaderComponent/LoaderComponent";
import PhotoNotificationComponent from "../PhotoNotificationComponent/PhotoNotificationComponent";

export default function RouteComponent() {
    const dispatch = useDispatch();
    const driverData = useSelector(state => state.main.loadsList);
    const [isLoading, setIsLoading] = useState(true);
    const [showDetailedLoadInformation, setShowDetailedLoadInformation] = useState([]);
    const [limit, setLimit] = useState(3);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await dispatch(fetchDriverFindMainContent(limit));
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setIsLoading(false);
            }
        };

        fetchData(); // Викликаємо функцію без затримки

    }, [dispatch, limit]);


    const loadMore = () => {
        // Збільште значення limit при кожному натисканні кнопки "Load more"
        setLimit(prevLimit => prevLimit + 3);
    };

    const toggleDetailedLoadInformation = (index) => {
        const newVisibilityState = [...showDetailedLoadInformation];
        newVisibilityState[index] = !newVisibilityState[index];
        setShowDetailedLoadInformation(newVisibilityState);
    };
    return (
        <>
            {isLoading ? (
                <LoaderComponent/>
            ) : (


                <div className="route__driver__main">
                    <PhotoNotificationComponent driverData={driverData}/>
                    <div className='driver__all__routes'>
                        {driverData.loadsList && driverData.loadsList.length > 0 ? (
                            driverData.loadsList.map((load, index) => (
                                <div key={load.loadId} className="driver__single__route"
                                     onClick={() => toggleDetailedLoadInformation(index)}>
                                    <div className="driver__single__route__statuses">
                                        {load.loadStatuses.map((status, statusIndex) => (
                                            <div key={statusIndex} className='driver__single__route__first__status'
                                                 style={{color: status.color}}>
                                                {status.value}
                                            </div>
                                        ))}

                                    </div>
                                    <div className='grid__wrapper__routes'>
                                        <div className='start__point'>
                                            <div className='start__point__details'>
                                                <div className='start__point__icon__title'>
                                                    <div className='start__point__icon'>
                                                        <FaRegCircle/>
                                                    </div>
                                                    <div className='start__point__title'
                                                         style={{color: load.pointsList[0].color}}>
                                                        {load.pointsList[0].location}
                                                    </div>
                                                </div>
                                                <div className='start__point__description'>
                                                    {load.pointsList[0].street}
                                                    <div className='start__point__location'>
                                                        {load.pointsList[0].zip}
                                                    </div>
                                                </div>

                                            </div>

                                            <div className='start__point__overall__date'>
                                                <div className='date__camera__container'>
                                                    <div className='start__point__date'>
                                                        {load.pointsList[0].timeIntervalFrom}
                                                    </div>
                                                    {!load.completed && (
                                                        <div
                                                            className={`camera__icon ${load.pointsList[0].status === 'pending' || load.pointsList[1].status === 'pending' ? 'gray__cam' : ''}`}
                                                            style={{pointerEvents: load.pointsList[0].status === 'pending' || load.pointsList[1].status === 'pending' ? 'none' : 'auto'}}>

                                                            <Link to={`/driver/main/photo-report/${load.currentPoint}`}
                                                                  className='camera__link'>
                                                                <FiCamera/>
                                                            </Link>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className='start__point__time'>
                                                    {load.pointsList[0].timeIntervalTo}
                                                </div>
                                            </div>

                                        </div>
                                        {showDetailedLoadInformation[index] && (
                                            <div className='detailed__load__information'>
                                                <div className='detailed__load__label'>
                                                    <div>Commodity:</div>
                                                    <div>Weight:</div>
                                                    <div>Dimensions:</div>
                                                </div>
                                                <div className='detailed__load__data'>
                                                    <div>{load.commodity}</div>
                                                    <div>{load.weight} lb</div>
                                                    <div>L {load.sizeL} ft W {load.sizeW} ft H {load.sizeH} ft</div>
                                                </div>
                                            </div>
                                        )}
                                        <div className='end__point'>
                                            <div className='end__point__details'>
                                                <div className='end__point__icon__title'>
                                                    <div className='end__point__icon'>
                                                        <MdPlace/>
                                                    </div>
                                                    <div className='end__point__title'
                                                         style={{color: load.pointsList[1].color}}>
                                                        {load.pointsList[1].location}
                                                    </div>
                                                </div>
                                                <div className='end__point__description'>
                                                    {load.pointsList[1].street}
                                                    <div className='end__point__location'>
                                                        {load.pointsList[1].zip}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='end__point__overall__date'>
                                                <div className='date__camera__container'>
                                                    <div className='start__point__date'>
                                                        {load.pointsList[1].timeIntervalFrom}
                                                    </div>

                                                </div>
                                                <div className='end__point__time'>
                                                    {load.pointsList[1].timeIntervalTo}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {load.comment && (
                                        <div className='driver__single__route__reason__container'>
                                            <div className='driver__single__route__reason__title'>
                                                <p>Reason</p>
                                            </div>
                                            <div className='driver__single__route__reason__comment'>
                                                {load.comment}
                                            </div>
                                        </div>
                                    )}
                                </div>

                            ))
                        ) : (
                            <p>No loads available</p>
                        )}
                    </div>
                    <div className="loadMoreLoadsDriver" onClick={loadMore}>
                        Load more
                    </div>
                </div>

            )}
        </>
    )
}