import React from 'react';
import { HiTruck } from "react-icons/hi";
import { GoHomeFill } from "react-icons/go";
import { AiFillExclamationCircle } from "react-icons/ai";
import { FaFolderOpen, FaUsers  } from "react-icons/fa6";
import { BiSolidUser } from "react-icons/bi";
import { SiGoogleanalytics } from "react-icons/si";
import { RiDropdownList } from "react-icons/ri";



export const DispatcherSidebarData = [
    {
        title: 'Main',
        path: '/dispatcher/main',
        icon: <GoHomeFill />,
        cName: 'nav-text'
    },
    {
        title: 'Drivers profile',
        path: '/dispatcher/drivers',
        icon: <FaFolderOpen />,
        cName: 'nav-text'
    },
    {
        title: 'Help',
        path: '/dispatcher/help',
        icon: <AiFillExclamationCircle />,
        cName: 'nav-text'
    },
    {
        title: 'Analytics',
        path: '/dispatcher/analytics',
        icon: <SiGoogleanalytics />
        ,
        cName: 'nav-text'
    },
    {
        title: 'Vehicle',
        path: '/dispatcher/vehicle',
        icon: <HiTruck />,
        cName: 'nav-text'
    },
    {
        title: 'Customers',
        path: '/dispatcher/customers',
        icon: <BiSolidUser />,
        cName: 'nav-text'
    }
];


// Дані для ролі DRIVER
export const DriverSidebarData = [
    {
        title: 'Main',
        path: '/driver/main',
        icon:   <GoHomeFill />,
        cName: 'nav-text'
    },
    {
        title: 'Driver profile',
        path: '/driver/documents',
        icon:   <GoHomeFill />,
        cName: 'nav-text'
    },
    {
        title: 'Help',
        path: '/driver/help',
        icon:   <GoHomeFill />,
        cName: 'nav-text'
    },
];

// Дані для ролі ADMIN
export const AdminSidebarData = [
    {
        title: 'Main',
        path: '/admin/main',
        icon: <GoHomeFill />,
        cName: 'nav-text'
    },
    {
        title: 'Dispatchers',
        path: '/admin/dispatchers',
        icon: <FaUsers />,
        cName: 'nav-text'
    },
    {
        title: 'DropDown options',
        path: '/admin/dropdownoptions',
        icon: <RiDropdownList />,
        cName: 'nav-text'
    }
];

