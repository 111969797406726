import './BlockUserComponent.scss';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { blockUser } from "../../../../../store/actions/adminEditUserActions";

export default function BlockUserComponent({ isVisible, onClose, email, isBlocked }) {
    const dispatch = useDispatch();
    // Initialize the checkbox based on the isBlocked prop
    const [isBlockedState, setIsBlockedState] = useState(isBlocked);

    // Update local state when the isBlocked prop changes
    useEffect(() => {
        setIsBlockedState(isBlocked);
    }, [isBlocked]);

    if (!isVisible) return null; // Ensure component does not render when not visible

    const handleSave = () => {
        dispatch(blockUser(email, isBlockedState));
        onClose();
    };

    return (
        <div className="modalOverlay">
            <div className="modalBlockUser">
                <h2>User access control</h2>
                <p>{email}</p>
                <div>
                    <label>
                        <input
                            type="checkbox"
                            checked={isBlockedState} // Bind checkbox to local state
                            onChange={(e) => setIsBlockedState(e.target.checked)} // Update local state on change
                        />
                        Block user
                    </label>
                </div>
                <div className="modalBtnContainerBlockUser">
                    <div className="modalBlockUserBtn" onClick={handleSave}>Save</div>
                    <div className="modalBlockUserBtnClose" onClick={onClose}>Close</div>
                </div>
            </div>
        </div>
    );
}