import React, {useEffect, useState, useRef, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getMainContent, approvePoint} from '../../../../store/actions/MainActions';
import './LoadDetailsComponent.scss';
import backKpoSvg from '../../../../../src/assets/svg/back_kpo.svg';
import {FaRegCircle} from "react-icons/fa"; 
import {MdPlace} from "react-icons/md";
import {CiUser} from "react-icons/ci";
import {BsThreeDotsVertical} from "react-icons/bs";
import {FaRegEyeSlash} from "react-icons/fa";
import {hideLoadReport} from '../../../../store/actions/MainActions';
import {PiPaperclipHorizontal} from "react-icons/pi";
import {uploadFileForLoad} from '../../../../store/actions/MainActions';
import noImageLoad from '../../../../../src/assets/img/noImageLoad.png';
import LoaderComponent from '../../../LoaderComponent/LoaderComponent';
import PhotoReportCancelModal from "./PhotoReportCancelModal/PhotoReportCancelModal";
import ChangeDriverComponent from "./ChangeDriverComponent/ChangeDriverComponent";
import SliderPhotostMainComponent from '../SliderPhotosMainComponent/SliderPhotosMainComponent';
import AddLoadModalComponent from "../RouteDetails/AddLoadModalComponent/AddLoadModalComponent";
import DeleteLoadModalComponent from "../RouteDetails/DeleteLoadModal/DeleteLoadModal";
import {askPhotoReport} from "../../../../store/actions/notificationActions";
import MessageModalAskReport from "./MessageModalAskReport/MessageModalAskReport";

export default function LoadDetails({driverId, selectedOption}) {
    const [driverInformation] = useState(false);
    const dispatch = useDispatch();
    const [isOpenEdit, setIsOpenEdit] = useState(false);
    const [isOpenDelete, setIsOpenDelete] = useState(false);
    const driverData = useSelector((state) => state?.main?.main_content.loadsList);
    const driverDat = useSelector((state) => state);
    console.log(driverDat);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);
    const [isChangeDriverModalOpen, setChangeDriverModalOpen] = useState(false);
    const [isModalVisible, setModalVisibility] = useState(false);
    const [isModalVisible2, setModalVisibility2] = useState(false);
    const modalRef = useRef(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [openLoadId, setOpenLoadId] = useState(null);
    const [selectedPoint, setSelectedPoint] = useState(null);
    const [comment, setComment] = useState('');
    const [limit, setLimit] = useState(3);
    const [isAskReportModalOpen, setIsAskReportModalOpen] = useState(false);
    const handleAskPhotoReport = (currentPoint) => {
        dispatch(askPhotoReport(currentPoint));
        setIsAskReportModalOpen(true);
        setModalVisibility(false);
    };

    const handleCloseAskReportModal = () => {
        setIsAskReportModalOpen(false);
    };

    const handleEditLoad = (loadId) => {
        setIsOpenEdit(true);
        // setModalVisibility(false)

    }
    const handleDeleteLoad = (loadId) => {
        setIsOpenDelete(true);
    }
    const closeDelete = (loadId) => {
        setIsOpenDelete(false);
        dispatch(getMainContent(driverId, driverInformation, limit));
    }


    const closeModal = () => {
        setIsOpenEdit(false);
    }


    const handleChangeDriverClick = () => {
        setChangeDriverModalOpen(true);
        
        
    };
    const [loadingImages, setLoadingImages] = useState([]);


    const fetchData = useCallback(async () => {
        try {
            setIsLoadingData(true);
            await dispatch(getMainContent(driverId, driverInformation, limit));
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setIsLoadingData(false);
        }
    }, [dispatch, driverId, driverInformation, limit, setIsLoadingData]);

    useEffect(() => {
        let mounted = true;

        const fetchDataAndUpdate = async () => {
            if (mounted) {
                await fetchData();
            }
        };
        fetchDataAndUpdate();

        // Видалення інтервалу при завершенні компоненту
        return () => {
            mounted = false;
        };
    }, [fetchData]);


    const filteredLoads = driverData?.filter(load => {
        if (selectedOption === 'In transit') {
            return !load.completed;
        } else if (selectedOption === 'Completed') {
            return load.completed;
        }
        return true;
    });

    useEffect(() => {
        const handleClickOutsideModal = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setModalVisibility(false);
            }
        };

        document.addEventListener('mouseup', handleClickOutsideModal);

        return () => {
            document.removeEventListener('mouseup', handleClickOutsideModal);
        };
    }, []);

    const handleApprove = (pointId) => {
        setIsLoadingData(true);

        dispatch(approvePoint(pointId, true))
            .then(() => {

                dispatch(getMainContent(driverId, driverInformation, limit))
                    .then(() => {
                        setIsLoadingData(false);
                    })
                    .catch((error) => {
                        console.error('Error loading data:', error);
                        setIsLoadingData(false);
                    });
            })
            .catch((error) => {
                console.error('Error approving point:', error);
                setIsLoadingData(false);
            });
    };


    const handleCancel = (currentPoint) => {
        setSelectedPoint(currentPoint);
        setModalOpen(true);
    };


    const handleMenuClick = (loadId) => {
        setIsMenuOpen(!isMenuOpen);
        setOpenLoadId(loadId);
        setModalVisibility(!isModalVisible);
    };
    const handleHideLoad = (loadId) => {
        const limit = 3;
        const hidden = true;
        setIsLoadingData(true);
        dispatch(hideLoadReport(loadId, hidden))
            .then(() => {
                dispatch(getMainContent(driverId, driverInformation, limit))
                    .then(() => {
                        setIsLoadingData(false);
                    })
                    .catch((error) => {
                        console.error('Error loading data:', error);
                        setIsLoadingData(false);
                    });
            });
    }; 

    const handleAttachFile = async (loadId) => {
        const input = document.createElement('input');
        input.type = 'file';

        input.addEventListener('change', async () => {
            if (input.files && input.files[0]) {
                const selectedFile = input.files[0];
                dispatch(uploadFileForLoad(loadId, selectedFile))
                    .then(() => {
                        // Перезагрузите данные после успешного прикрепления файла
                        dispatch(getMainContent(driverId, driverInformation, limit))
                            .then(() => {
                                setIsLoadingData(false);
                            })
                            .catch((error) => {
                                console.error('Error loading data:', error);
                                setIsLoadingData(false);
                            });
                    })
                    .catch((error) => {
                        console.error('File upload error:', error);
                        setIsLoadingData(false);
                    });
            }
        });

        input.click();
    };
    const handleHideLoad2 = (loadId) => {
        const hidden = false;
        const limit = 3;
        setIsLoadingData(true);
        dispatch(hideLoadReport(loadId, hidden))
        
            .then(() => {
                dispatch(getMainContent(driverId, driverInformation, limit))
                    .then(() => {
                        setIsLoadingData(false);
                    })
                    .catch((error) => {
                        console.error('Error loading data:', error);
                        setIsLoadingData(false);
                    });
            });
    };

    const handleDownloadFile = (link) => {
        if (link) {
            const protocolLink = `//${link}`;

            fetch(protocolLink, {
                credentials: 'include'
            })
                .then(response => response.blob())
                .then(blob => {
                    const blobUrl = URL.createObjectURL(blob);

                    const link = document.createElement('a');
                    link.href = blobUrl;
                    link.target = '_blank';
                    link.download = `downloaded_file`;
                    document.body.appendChild(link);

                    link.click();

                    document.body.removeChild(link);
                    URL.revokeObjectURL(blobUrl);
                })
                .catch(error => {
                    console.error('Error fetching file content:', error);
                });
        } else {
            console.error('File link not found');
        }
    };


    const handleModalSend = (currentPoint) => {
        setIsLoadingData(true);
        dispatch(approvePoint(currentPoint, false, comment))
            .then(() => {
                setModalOpen(false);
                dispatch(getMainContent(driverId, driverInformation, limit))
                    .then(() => {
                        setIsLoadingData(false);
                    })
                    .catch((error) => {
                        console.error('Помилка завантаження даних:', error);
                        setIsLoadingData(false);
                    });
            })
            .catch((error) => {
                console.error('Помилка підтвердження точки:', error);
                setIsLoadingData(false);
            });
    };


    const handleLoadMore = () => {
        setLimit(prevLimit => prevLimit + 5);
    };

    const handleImageLoad = (index) => {
        setLoadingImages(prevLoadingImages => prevLoadingImages.filter((_, i) => i !== index));
    };
    const handleModalClose = () => {
        setChangeDriverModalOpen(false)
        dispatch(getMainContent(driverId, driverInformation, limit));
    }

    return (
        <div className='all__loads__container'>
            <MessageModalAskReport isOpen={isAskReportModalOpen} onClose={handleCloseAskReportModal}/>


            {isLoadingData && <LoaderComponent/>}
            {driverData?.length > 0 ? (
                filteredLoads?.slice(0, limit).map((load, index) => (
                    <div key={index} className='one__load__container'>

                        <div className='one__load__statuses__more'>
                            <div className='one__load__statuses'>
                                {load.loadStatuses.map((status, statusIndex) => (
                                    <div key={statusIndex} className='one__load__status' style={{color: status.color}}>
                                        {status.value}
                                    </div>
                                ))}
                                {load.hidden && (
                                    <div className='one__load__status__hide'
                                         onClick={() => handleHideLoad2(load.loadId)}>
                                        <div className='one__load__status__svg'>
                                            <FaRegEyeSlash/>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className="vvv">
                                <div onClick={() => handleMenuClick(load.loadId)}>
                                    <BsThreeDotsVertical/></div>
                                {load.loadId === openLoadId && (


                                    <div className="modal__edit__load" ref={modalRef}>
                                        <div
                                            className="modal-content__edit__load"
                                            style={{
                                                display: isModalVisible ? 'block' : 'none',
                                            }}
                                        >
                                            <div className="d__menu__item__load"
                                                 style={{
                                                     display: isModalVisible ? 'block' : 'none',
                                                 }}
                                                 onClick={() => handleEditLoad(load.loadId)}>
                                                Edit load
                                            </div>
                                            <div className="d__menu__item__load"
                                                 style={{
                                                     display: isModalVisible ? 'block' : 'none',
                                                 }}
                                                 onClick={() => handleDeleteLoad(load.loadId)}>
                                                Delete load
                                            </div>
                                            {isOpenEdit && <AddLoadModalComponent onClose={() => setIsOpenEdit(false)}
                                                                                  loadId={load.loadId}
                                                                                  closeModal={closeModal}
                                                                                  driverId={driverId}
                                            />}
                                            <div className="d__menu__item__load"
                                                 onClick={() => handleAttachFile(load.loadId)}>
                                                Attach file
                                            </div>

                                            <div className="d__menu__item__load" onClick={handleChangeDriverClick}
                                            >
                                                Change driver
                                            </div>
                                            
                                            {isOpenDelete && <DeleteLoadModalComponent onClose={() => setIsOpenDelete(false)}
                                                                                  loadId={load.loadId}
                                                                                  closeModal={closeDelete}
                                                                                  driverId={driverId}
                                            />}
                                            {isChangeDriverModalOpen &&
                                                <ChangeDriverComponent onClose={handleModalClose}
                                                                       loadId={load.loadId}/>}
                                                                       


                                            {!load.completed && (
                                                <div className="d__menu__item__load"
                                                     onClick={() => handleAskPhotoReport(load.currentPoint)}>
                                                    Ask photo report
                                                </div>
                                            )}


                                            {!load.hidden && (
                                                <div className="d__menu__item__load"
                                                     onClick={() => handleHideLoad(load.loadId)}>
                                                    Hide load
                                                </div>
                                            )}

                                        </div>
                                    </div>
                                )}

                            </div>
                        </div>


                        {load?.attachedFile ? (
                            <div className='modal-content__file'>
                                <div className='modal-content__file__container'>
                                    <div className='modal-content__file__svg'><PiPaperclipHorizontal/></div>
                                    <div>File attached</div>
                                </div>

                                <div className='modal-content__file__name'
                                     onClick={() => handleDownloadFile(load?.attachedFile?.link)}>
                                    {load?.attachedFile?.documentName}
                                </div>
                            </div>
                        ) : null}
                        <div className='one__load__details__photos__container'>
                            <div className='one__load__details'>
                                <div className='one__load__frist__place__time'>
                                    <div className='one__load__first__place__svg'>
                                        <div className='one__load__first__svg'>
                                            <FaRegCircle/>
                                        </div>
                                        <div className='one__load__first__place'>
                                            <div className='one__load__first__state'
                                                 style={{color: load.pointsList[0].color}}>
                                                {load.pointsList[0].location}
                                            </div>
                                            <div className='one__load__first__street'>
                                                {load.pointsList[0].street}
                                            </div>
                                            <div className='one__load__first__zip'>
                                                {load.pointsList[0].zip}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='one__load__first__date__range'>
                                        <div className='one__load__first__date'>
                                            {load.pointsList[0].date}
                                        </div>
                                        <div className='one__load__first__time'>
                                            {load.pointsList[0].timeInterval}
                                        </div>
                                    </div>

                                </div>
                                <div className='one__load__additional__details'>
                                    <div className='one__load__labels'>
                                        <div className='one__load__rate__per__mile__label'>rate per mile:</div>
                                        <div className='one__load__commodity__label'>commodity:</div>
                                        <div className='one__load__weight__label'>weight:</div>
                                        <div className='one__load__dimensions__label'>dimensions:</div>
                                        <div className='one__load__miles__label'>miles:</div>
                                        <div className='one__load__amount__label'>amount:</div>
                                    </div>
                                    <div className='one__load__values'>
                                        <div className='one__load__rate__per__mile__value'>{load.ratePerMile}</div>
                                        <div className='one__load__commodity__value'>{load.commodity}</div>
                                        <div className='one__load__weight__value'>{load.weight}</div>
                                        <div className='one__load__dimensions__value'>
                                            <span> L </span>{load.sizeL}<span> W </span>{load.sizeW}<span> H </span>{load.sizeH}
                                        </div>
                                        <div className='one__load__miles__value'>{load.miles}</div>
                                        <div className='one__load__amount__value'>{load.amount}</div>
                                    </div>
                                </div>
                                <div className='one__load__last__place__time'>
                                    <div className='one__load__first__place__svg'>
                                        <div className='one__load__last__svg'>
                                            <MdPlace/>
                                        </div>
                                        <div className='one__load__last__place'>
                                            <div className='one__load__last__state'
                                                 style={{color: load.pointsList[1].color}}>
                                                {load.pointsList[1].location}
                                            </div>
                                            <div className='one__load__last__street'>
                                                {load.pointsList[1].street}
                                            </div>
                                            <div className='one__load__last__zip'>
                                                {load.pointsList[1].zip}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='one__load__last__date__range'>
                                        <div className='one__load__last__date'>
                                            {load.pointsList[1].date}
                                        </div>
                                        <div className='one__load__last__time'>
                                            {load.pointsList[1].timeInterval}
                                        </div>
                                    </div>

                                </div>
                                <div className='one__load__driver__details__outer__container'>
                                    <div className='one__load__driver__details'>
                                        <div className='one__load__driver__svg'>
                                            <CiUser/>
                                        </div>
                                        <div className='one__load__driver__name__mc'>
                                            <div className='one__load__driver__name'>
                                                {load.clientInformation.clientName}
                                            </div>
                                            <div className='one__load__driver__mc'>
                                                {load.clientInformation.mcNumber}
                                            </div>
                                        </div>
                                        <div className='one__load__driver__num__em__comp'>
                                            <div className='one__load__driver__num'>
                                                {load.clientInformation.phoneNumber}
                                            </div>
                                            <div className='one__load__driver__em'>
                                                {load.clientInformation.clientEmail}
                                            </div>
                                            <div className='one__load__driver__comp'>
                                                {load.clientInformation.companyName}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className='one__load__photos'>
                                <div className='one__load__photos__pickup'>
                                    <div className='one__load__photos__pickup__label'>
                                        Pick up
                                    </div>
                                    <div className='one__load__photos__pickup__img__container'>
                                        {load.pointsList[0].pickUpPhotos && load.pointsList[0].pickUpPhotos.length > 0 ? (
                                            <SliderPhotostMainComponent photos={load?.pointsList[0]?.pickUpPhotos}/>

                                        ) : (
                                            <div className='alternative__images'>
                                                <div className='one__load__photos__pickup__img'><img src={noImageLoad}
                                                                                                     alt="noLoad"/>
                                                </div>
                                                <div className='one__load__photos__pickup__img'><img src={noImageLoad}
                                                                                                     alt="noLoad"/>
                                                </div>
                                                <div className='one__load__photos__pickup__img'><img src={noImageLoad}
                                                                                                     alt="noLoad"/>
                                                </div>
                                                <div className='one__load__photos__pickup__img'><img src={noImageLoad}
                                                                                                     alt="noLoad"/>
                                                </div>

                                            </div>


                                        )}
                                    </div>


                                    <div className='one__load__photos__pickup__buttons'>
                                        {load.pointsList[0].status === 'accepted' ? (
                                            <div className="accepted-status">
                                                Accepted
                                            </div>
                                        ) : (
                                            <>
                                                <div
                                                    className={`one__load__photos__pickup__button__cancel ${load.pointsList[0].status === 'default' ? 'disabled-btn' : ''}`}
                                                    onClick={() => handleCancel(load.currentPoint)}
                                                >
                                                    Cancel
                                                </div>
                                                <div
                                                    className={`one__load__photos__pickup__button__approve ${load.pointsList[0].status === 'default' ? 'disabled-btn' : load.pointsList[0].status === 'pending' ? 'approve-pending' : ''}`}
                                                    onClick={() => handleApprove(load.currentPoint)}
                                                >
                                                    Approve
                                                </div> 
                                            </>
                                        )}


                                    </div>


                                </div>
                                <div className='one__load__photos__delivery'>
                                    <div className='one__load__photos__delivery__label'>
                                        Delivery
                                    </div>
                                    <div className='one__load__photos__delivery__img__container'>
                                        {load.pointsList[1].deliveryPhotos && load.pointsList[1].deliveryPhotos.length > 0 ? (
                                            <SliderPhotostMainComponent photos={load?.pointsList[1]?.deliveryPhotos}/>
                                        ) : (
                                            <div className='alternative__images'>
                                                <div className='one__load__photos__pickup__img'><img src={noImageLoad}
                                                                                                     alt="NoLoad"/>
                                                </div>
                                                <div className='one__load__photos__pickup__img'><img src={noImageLoad}
                                                                                                     alt="NoLoad"/>
                                                </div>
                                                <div className='one__load__photos__pickup__img'><img src={noImageLoad}
                                                                                                     alt="NoLoad"/>
                                                </div>
                                                <div className='one__load__photos__pickup__img'><img src={noImageLoad}
                                                                                                     alt="NoLoad"/>
                                                </div>

                                            </div>


                                        )}
                                    </div>

                                    {load.pointsList[1].status === 'accepted' ? (
                                        <div className='one__load__photos__pickup__buttons'>
                                            <div className="accepted-status">
                                                Accepted
                                            </div>
                                        </div>
                                    ) : (
                                        <div className='one__load__photos__delivery__buttons'>
                                            <div
                                                className={`one__load__photos__delivery__button__cancel ${load.pointsList[1].status === 'default' ? 'disabled-btn' : ''}`}
                                                onClick={() => handleCancel(load.currentPoint)}
                                            >
                                                Cancel
                                            </div>
                                            {selectedPoint === load.currentPoint && isModalOpen && (
                                                <PhotoReportCancelModal
                                                    currentPoint={selectedPoint}
                                                    onClose={() => setSelectedPoint(null)}
                                                    onSend={() => handleModalSend(selectedPoint)}
                                                    onCommentChange={(value) => setComment(value)}
                                                />
                                            )}
                                            <div
                                                className={`one__load__photos__delivery__button__approve ${load.pointsList[1].status === 'default' ? 'disabled-btn' : load.pointsList[1].status === 'pending' ? 'approve-pending' : ''}`}
                                                onClick={() => handleApprove(load.currentPoint)}
                                            >
                                                Approve
                                            </div>
                                        </div>

                                    )}

                                </div>

                            </div>
                        </div>
                    </div>

                ))
            ) : (
                <div className='no__load__logo'>
                    This driver has no loads in transit
                    <div className="driver__data__not__found__logo">
                        <img src={backKpoSvg} alt="Back KPO"/>
                    </div>
                </div>
            )}
            {filteredLoads && filteredLoads.length > 0 && (
                <div className="load__more__loads">
                    <div className="btn__load__more__loads" onClick={handleLoadMore}>
                        Load More
                    </div>
                </div>
            )}

        </div>

    );
}