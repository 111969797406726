import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import './NoteComponent.scss';
import {fetchFindNotes} from '../../../../store/actions/MainActions';

export default function NoteComponent() {
    const dispatch = useDispatch();
    // eslint-disable-next-line
    const [isLoading, setIsLoading] = useState(true);
    const note = useSelector(state => state.main?.driverNotes?.notes);
    useEffect(() => {
        const fetchData = async () => {
            try {
                await dispatch(fetchFindNotes());
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching notes:', error);
                setIsLoading(false);
            }
        };

        fetchData();
        const intervalId = setInterval(fetchData, 5000);
        return () => clearInterval(intervalId);
    }, [dispatch]);

    return (
        <>
            <div className='note__driver__wrapper'>
                <div className="note__driver__container">
                    <div className='note__driver__title'>
                        Dispatcher note
                    </div>
                    <div className='textarea__read'>
                        {note}
                    </div>
                </div>
            </div>
        </>
    );
}
