import React, {useState, useEffect} from 'react';
import './AnalyticsComponent.scss';
import {BiSearch} from 'react-icons/bi';
import {LuListFilter} from 'react-icons/lu';
import {MdKeyboardArrowLeft} from "react-icons/md";
import {MdKeyboardArrowRight} from "react-icons/md";
import {LuCalendar} from "react-icons/lu";
import * as XLSX from 'xlsx';
import {fetchDispatchers, fetchAnalytics} from "../../../../store/actions/analyticsActions";
import {useDispatch, useSelector} from 'react-redux';
import {DateRangePicker} from 'react-date-range';
import {FaCheck} from "react-icons/fa6";
import {MdKeyboardArrowDown} from "react-icons/md";
import {MdKeyboardArrowUp} from "react-icons/md";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import {CiUser} from 'react-icons/ci';
import backKpoSvg from '../../../../../src/assets/svg/back_kpo.svg'


export default function AnalyticsComponent() {
    const dispatch = useDispatch();
    const dispatchers = useSelector((state) => state.analytics.dispatchers);
    const analytics = useSelector((state) => state.analytics.analyticsData);
    const analytic = useSelector((state) => state.analytics);
    const allDates = Array.from(new Set(analytics?.data?.flatMap(driver => driver?.value?.map(dayData => dayData.date))))
        .sort((a, b) => new Date(a) - new Date(b));

    const [exportDate, setExportDate] = useState(null);
    const [selectedDispatcher, setSelectedDispatcher] = useState(null);
    const [isExportEnabled, setExportEnabled] = useState(false);

    useEffect(() => {
        dispatch(fetchDispatchers());
    }, [dispatch]);


    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');

    const [dateRange, setDateRange] = useState({
        startDate: null,
        endDate: null,
        key: 'selection',
    });
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isSelectOpen, setIsSelectOpen] = useState(false);
    const handleDateChange = (ranges) => {
        setCurrentPage(1);
        const startDayOfWeek = ranges.selection.startDate ? new Date(ranges.selection.startDate).getDay() : null;
        const endDayOfWeek = ranges.selection.endDate ? new Date(ranges.selection.endDate).getDay() : null;

        setSelectedDays({
            ...ranges.selection,
            startDayOfWeek,
            endDayOfWeek,
        });

        setDateRange(ranges.selection);
    };
    const toggleCustomSelect = () => {
        setIsDropdownOpen(!isDropdownOpen);
        setIsSelectOpen(!isSelectOpen);
    };

    const [selectedDateRangeColor] = useState('');

    const dateRangeColorClass = selectedDateRangeColor ? `rdrInRange ${selectedDateRangeColor}` : 'rdrIn';

    //eslint-disable-next-line
    const [selectedDays, setSelectedDays] = useState({
        startDate: null,
        endDate: null,
        key: 'selection',
        startDayOfWeek: null,
        endDayOfWeek: null,
    });


    const handleApplyDateRange = () => {
        setShowDateRangePickerModal(false);
        setShowBackdrop(false);

        setDateRange({
            startDate: dateRange.startDate,
            endDate: dateRange.endDate,
            key: 'selection',
        });

        setExportDate(dateRange.endDate);

        const selectedDispatcherId = selectedDispatcher ? selectedDispatcher.id : null;

        const formattedStartDate = dateRange.startDate
            ? formatDate(dateRange.startDate)
            : '';
        const formattedEndDate = dateRange.endDate
            ? formatDate(dateRange.endDate)
            : '';

        const dateRangeObject = {
            startDate: formattedStartDate,
            endDate: formattedEndDate,
        };
        setExportEnabled(!!selectedDispatcher && !!dateRange.startDate && !!dateRange.endDate);
        dispatch(fetchAnalytics(selectedDispatcherId, dateRangeObject));
    };

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}.${month}.${day}`;
    };


    const [showDateRangePickerModal, setShowDateRangePickerModal] = useState(false);
    const [showBackdrop, setShowBackdrop] = useState(false);
    //eslint-disable-next-line
    const [formattedDateRange, setFormattedDateRange] = useState('');

    const toggleDateRangePickerModal = () => {
        setShowDateRangePickerModal((prev) => !prev);
        setShowBackdrop(!showBackdrop);
    };

    const handleBackdropClick = () => {
        setShowDateRangePickerModal(false);
        setShowBackdrop(false);
        setDateRange({
            startDate: null,
            endDate: null,
            key: 'selection',
        });
    };


    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };


    const handleDispatcherSelect = (selectedDispatcher) => {
        setSelectedDispatcher(selectedDispatcher);
        setIsDropdownOpen(false);
        setIsSelectOpen(false);
        setExportEnabled(!!selectedDispatcher && !!dateRange.startDate && !!dateRange.endDate);
    };


    const renderDispatchers = () => {
        return dispatchers?.map((dispatcher) => (
            <div
                key={dispatcher.id}
                className={`option-item ${selectedDispatcher && selectedDispatcher.id === dispatcher.id ? 'active' : ''}`}
                onClick={() => handleDispatcherSelect(dispatcher)}
            >
                <label>{dispatcher.dispatcherName}</label>
                <div className="checkbox">
                    {dispatcher?.id === selectedDispatcher?.id && <FaCheck/>}

                </div>
            </div>
        ));
    };

    //export
    const exportToExcel = () => {
        if (!analytics || !analytics.data || analytics.data.length === 0) {
            console.error("No data available for export");
            return;
        }
    
        const selectedDispatcherName = selectedDispatcher ? selectedDispatcher.dispatcherName : 'All Dispatchers';
    
        const wsData = [
            ['', 'Type', ...allDates, 'Total', 'Rate', 'Driver Average Gross', 'Milage', '#Trucks'],
            ['UKRAINE KPO DISPATCH TEAM'],
            [selectedDispatcherName],
        ];
    
    
        // Додайте дані для кожного драйвера за певний день
        const amountsByDate = {}; // Об'єкт для зберігання суми сум кожного драйвера за кожну дату
        const driverRows = {};

    analytics.data.forEach((driver) => {
        const driverName = driver.driverName;

        driver.value.forEach((dayData) => {
            const date = dayData.date;
            const totalAmount = dayData.totalAmount;
            const totalMiles = dayData.totalMiles;
            const ratePerMile = driver.ratePerMile;
            const averageAmount = driver.averageAmount;
            const averageMiles = driver.averageMiles;

                console.log(driver)
                console.log(analytics)
    
                // Додайте дані про суму amount та milage під відповідну дату
//                 const driverInfoRow1 = [driverName, 'AMOUNT', ...Array(allDates.length).fill(''), `${totalAmount}$`, `${ratePerMile}`, `${averageAmount}$`, '']; // Починаємо з порожніх клітинок
// const driverInfoRow2 = ['', 'milage', ...Array(allDates.length). // Починаємо з порожніх клітинок
if (!driverRows[driverName]) {
    // Якщо рядка немає, створіть новий рядок
    driverRows[driverName] = {
        amountRow: [driverName, 'AMOUNT', ...Array(allDates.length).fill(''), `${totalAmount}$`, `${ratePerMile}`, `${averageAmount}$`, ''],
        milesRow: ['', 'Milage', ...Array(allDates.length).fill(''), `${totalMiles}`, '', `${averageMiles}`]
    };
    wsData.push(driverRows[driverName].amountRow, driverRows[driverName].milesRow);
}

                // Додаємо суму amount під відповідну дату
                // const indexAmount = allDates.indexOf(date);
                // if (indexAmount !== -1) {
                //     driverInfoRow1[indexAmount + 2] = `${totalAmount}$`;
                // }
    
                // // Додаємо суму milage під відповідну дату
                // const indexmilage = allDates.indexOf(date);
                // if (indexmilage !== -1) {
                //     driverInfoRow2[indexmilage + 2] = `${totalMiles}`;
                // }
    

                const indexAmount = allDates.indexOf(date);
                if (indexAmount !== -1) {
                    driverRows[driverName].amountRow[indexAmount + 2] = `${totalAmount}$`;
                }
    
                const indexMiles = allDates.indexOf(date);
                if (indexMiles !== -1) {
                    driverRows[driverName].milesRow[indexMiles + 2] = `${totalMiles}`;
                }
            });
        });



        //         // Додайте ці рядки до основного масиву даних
        //         wsData.push(driverInfoRow1, driverInfoRow2);
    
        //         // Додайте суму суми до об'єкта amountsByDate
        //         if (!amountsByDate[date]) {
        //             amountsByDate[date] = 0;
        //         }
        //         amountsByDate[date] += parseFloat(totalAmount);
        //     });
        // });
        const totalAmountAllDrivers = analytics.data.reduce((total, driver) => {
            const driverTotal = parseFloat(driver.totalAmount);
            
            if (!isNaN(driverTotal)) {
                return total + driverTotal;
            }
            return total;
        }, 0);
        const totalAmountForAllDrivers = analytics.totalAmountForAllDrivers;



        allDates.forEach((date, index) => {
             // Додаємо суму amount під відповідною датою або порожню комірку, якщо суми не існує
        });
        
        // Додайте фінальну інформацію після даних про кожного водія
        const totalRow = ['Total', '', ...Array(allDates.length).fill(''), '', '', '', '', '']; // Починаємо з порожніх клітинок
    const totalRatePerMile = analytics.data.reduce((total, driver) => total + parseFloat(driver.ratePerMile), 0);
    totalRow[allDates.length + 2] = `${totalAmountForAllDrivers}$`; // Порожня комірка під загальну суму (Total)
    totalRow[allDates.length + 3] = `${totalRatePerMile}`; // Додайте загальний тариф за милю
    // Додайте суми всіх сум під відповідними датами
    allDates.forEach((date, index) => {
        const dailyTotalForDate = analytics.dailyTotal.find(item => item.date === date);
            totalRow[index + 2] = dailyTotalForDate ? `${dailyTotalForDate.totalAmount}$` : ''; // Додайте суму суми під відповідною датою або порожню комірку, якщо суми не існує
    });
    wsData.push(totalRow);
    const totalMilesRow = ['Total Milage', '', ...Array(allDates.length).fill(''), '', '', '', '', '']; // Починаємо з порожніх клітинок
    
    // totalRow[allDates.length + 2] = `${totalAmountForAllDrivers}$`; // Порожня комірка під загальну суму (Total)
    // totalRow[allDates.length + 3] = `${totalRatePerMile}`; // Додайте загальний тариф за милю
    // Додайте суми всіх сум під відповідними датами
    allDates.forEach((date, index) => {
        const dailyTotalForDate = analytics.dailyTotal.find(item => item.date === date);
            totalMilesRow[index + 2] = dailyTotalForDate ? `${dailyTotalForDate.totalMiles}` : ''; // Додайте суму суми під відповідною датою або порожню комірку, якщо суми не існує
    });
    wsData.push(totalMilesRow);

    // Створіть об'єкт ws
    const ws = XLSX.utils.aoa_to_sheet(wsData);

    // Об'єднайте комірки для рядків "UKRAINE KPO DISPATCH TEAM" та "selectedDispatcherName"
    const mergeRange1 = { s: { r: 1, c: 0 }, e: { r: 1, c: allDates.length + 6 } }; // Об'єднуємо від A2 до N2 для "UKRAINE KPO DISPATCH TEAM"
    const mergeRange2 = { s: { r: 2, c: 0 }, e: { r: 2, c: allDates.length + 6 } }; // Об'єднуємо від A3 до N3 для "selectedDispatcherName"
    ws['!merges'] = [mergeRange1, mergeRange2];

    // Налаштуйте стилі для всіх комірок у діапазоні від A1 до N2
    const cellStyle = {
        horizontal: 'center', // Горизонтальне вирівнювання по центру
        vertical: 'middle',   // Вертикальне вирівнювання по центру
    };

    ws['!cols'] = [
        { wpx: 200, ...cellStyle }, // Для колонки з іменем драйвера
        { wpx: 100, ...cellStyle },  // Для колонки "Type"
        ...Array(allDates.length).fill({ wpx: 80, ...cellStyle }), // Для колонок з датами
        { wpx: 100, ...cellStyle },  // Для колонки "Total"
        { wpx: 100, ...cellStyle },  // Для колонки "Rate"
        { wpx: 150, ...cellStyle }, // Для колонки "Weekly Average Gross"
        { wpx: 100, ...cellStyle },  // Для колонки "milage"
        { wpx: 100, ...cellStyle }   // Для колонки "#Trucks"
    ];

    ws['!rows'] = [{ hpx: 25 }, { hpx: 25 }]; // Налаштування висоти рядків для перших двох рядків

    // Збережіть ваш об'єкт ws
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');

    // Збережіть файл Excel
    XLSX.writeFile(wb, `analytics_data_${formatDate(exportDate)}.xlsx`);
};
    
    


    return (
        <>
            <div className={`analytics__page ${dateRangeColorClass}`}>
                <div className="analytics__container">

                    <div className="analytics__panel">
                        <div className="analytics__title">Analytics</div>
                        <div className="analytics__right__item">
                            <div className="analytics__export" onClick={exportToExcel}
                                 style={{visibility: isExportEnabled ? 'visible' : 'hidden'}}>
                                Export
                            </div>
                            <div className='analytics__select'>
                                <div className='analytics__icon'><CiUser/></div>
                                <div className={`custom-select ${isDropdownOpen ? 'active' : ''}`}
                                     onClick={toggleCustomSelect}>
                                    <div className="selected-options">
                                        {selectedDispatcher ? (
                                            <div className="selected-option">
                                                {selectedDispatcher.dispatcherName} {isSelectOpen ?
                                                <MdKeyboardArrowUp/> : <MdKeyboardArrowDown/>}
                                            </div>
                                        ) : (
                                            <div className="selected-option">All dispatchers {isSelectOpen ?
                                                <MdKeyboardArrowUp/> : <MdKeyboardArrowDown/>}</div>
                                        )}
                                    </div>
                                    <div className="select-icon"></div>
                                    <div className="options-list dispatcher__select">{renderDispatchers()}</div>
                                </div>
                            </div>
                            {/*<div className="analytics__filter">*/}
                            {/*    <LuListFilter/>*/}
                            {/*    <p>Filters</p>*/}
                            {/*</div>*/}
                            <div className="analytics__calendar"
                                 onClick={selectedDispatcher ? toggleDateRangePickerModal : null}>
                                <LuCalendar/>{formattedDateRange}
                            </div>
                            {showDateRangePickerModal && (
                                <>
                                    <div className="backdrop" onClick={handleBackdropClick}></div>

                                    <div className="date-range-picker-modal">
                                        <div className='date-range__body'>
                                            <DateRangePicker ranges={[dateRange]} onChange={handleDateChange}/>
                                            <div className='date__button__container'>
                                                <button onClick={handleBackdropClick} className='date__cancel'>Cancel
                                                </button>
                                                <button onClick={handleApplyDateRange} className='date__apply'>Apply
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                            {/*<div className="analytics__search">*/}
                            {/*    <BiSearch/>*/}
                            {/*    <input*/}
                            {/*        type="text"*/}
                            {/*        placeholder="Search"*/}
                            {/*        value={searchQuery}*/}
                            {/*        onChange={(e) => {*/}
                            {/*            setCurrentPage(1);*/}
                            {/*            setSearchQuery(e.target.value);*/}
                            {/*        }}*/}
                            {/*    />*/}
                            {/*</div>*/}
                        </div>
                    </div>
                    {!dateRange.startDate || !dateRange.endDate ? (
                        <div className="analytics__second__part">
                            <div className='driver__data__not__found analytics'>
                                <div className='analytics__no__data__text'>
                                    Please select dispatcher and date range
                                </div>
                                <div className='analytics__no__data__logo'>
                                    <img src={backKpoSvg} alt="Back KPO"/>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className='analytics__second__part'>
                            <div className='table__wrapper__analytics'>
                                <div className='table__container__analytics'>
                                    <table>
                                        <thead>
                                        <tr>
                                            <th>Driver</th>
                                            {allDates.map(date => (
                                                <th key={date}>{date}</th>
                                            ))}
                                            <th>Total</th>
                                        </tr>
                                        </thead>


                                        <tbody>

                                        {analytics?.data?.map((driver) => (
                                            <tr key={driver.driverName}>
                                                <td className='analytics__driver a__td' data-cell="Driver">
                                                    {driver.driverName}
                                                    <span className="divider"></span>
                                                </td>
                                                {allDates.map(date => {
                                                    const dayData = driver.value.find(data => data.date === date);
                                                    return (
                                                        <td key={date} className='days a__td' data-cell={date}>
                                                            {dayData ? (
                                                                <>
                                                                    <span>{dayData.totalAmount}$</span><br/>
                                                                    {dayData.totalMiles}
                                                                </>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </td>
                                                    );
                                                })}
                                                <td className='days analytics__total a__td' data-cell="Total">
                                                    <span className="divider__left"></span>
                                                    <span>{driver.totalAmount}$</span><br/>{driver.totalMiles}
                                                    <span
                                                        className='days analytics__span__numb'> ${driver.ratePerMile}/miles </span>
                                                </td>
                                            </tr>
                                        ))}


                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="analytics__total__container">
                                <div className='analytics__total__content'>
                                    {analytics.totalAmountForAllDrivers}$
                                </div>

                            </div>
                            {/*<div className="pagination">*/}
                            {/*    <select className='per__page'>*/}

                            {/*        <option>*/}
                            {/*            10*/}
                            {/*        </option>*/}
                            {/*        <option>*/}
                            {/*            20*/}
                            {/*        </option>*/}

                            {/*    </select>*/}
                            {/*    <div className="pagination__pages">*/}
                            {/*        <button*/}
                            {/*            onClick={() => handlePageChange(currentPage - 1)}*/}
                            {/*            disabled={currentPage === 1}*/}
                            {/*        >*/}
                            {/*            <MdKeyboardArrowLeft/>*/}
                            {/*        </button>*/}
                            {/*        <span>{currentPage}</span>*/}
                            {/*        of*/}
                            {/*        <button*/}
                            {/*            onClick={() => handlePageChange(currentPage + 1)}*/}

                            {/*        >*/}
                            {/*            <MdKeyboardArrowRight/>*/}
                            {/*        </button>*/}
                            {/*    </div>*/}

                            {/*</div>*/}
                        </div>
                    )}

                </div>
            </div>
        </>
    );
} 