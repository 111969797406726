import axios from 'axios';

export const loginRequest = () => ({
    type: 'LOGIN_REQUEST',
});

export const loginSuccess = (userData) => {
    localStorage.setItem('userData', JSON.stringify(userData));
    localStorage.setItem('isLoggedIn', true);
    return {
        type: 'LOGIN_SUCCESS',
        payload: userData,
    };
};


export const loginFailure = (error) => ({
    type: 'LOGIN_FAILURE',
    payload: error.message || 'Invalid email or password. Please check and try again.',
});

export const logout = () => ({
    type: 'LOGOUT',
});

export const loginUser = (email, password, rememberMe) => {
    return async (dispatch) => {
        dispatch(loginRequest());
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/signIn`, {
                email,
                password,
                rememberMe,
            }, {
                withCredentials: true
            });
            const {data} = response;

            dispatch(loginSuccess(data));
            localStorage.setItem('userData', JSON.stringify(data));

        } catch (error) {
            dispatch(loginFailure(error.response.data.message));
        }
    };
};