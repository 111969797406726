import React, {useRef, useEffect, useState} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './EditInfoModal.scss';
import {IoCloseOutline} from 'react-icons/io5';
import {useDispatch} from 'react-redux';
import {updateUserSettings} from '../../../../../store/actions/settingsActions';
import InputMask from 'react-input-mask';

const Modal = ({isOpen, onClose, settingsData}) => {
    const modalRef = useRef(null);
    const [editedData, setEditedData] = useState({...settingsData});
    const [errors, setErrors] = useState({});
    const dispatch = useDispatch();

    useEffect(() => {
        setEditedData({...settingsData});
    }, [settingsData]);

    useEffect(() => {
        const handleCloseOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                onClose();
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleCloseOutside);
        } else {
            document.removeEventListener('mousedown', handleCloseOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleCloseOutside);
        };
    }, [isOpen, onClose]);

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setEditedData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const validateFields = () => {
        const newErrors = {};

        // Валідація для firstName
        if (!editedData.firstName) {
            newErrors.firstName = 'First Name is required.';
        } else if (editedData.firstName.length > 15) {
            newErrors.firstName = 'First Name cannot exceed 15 characters.';
        }

        // Валідація для lastName
        if (!editedData.lastName) {
            newErrors.lastName = 'Last Name is required.';
        } else if (editedData.lastName.length > 20) {
            newErrors.lastName = 'Last Name cannot exceed 20 characters.';
        }

        setErrors(newErrors);

        // Повертає true, якщо немає помилок
        return Object.keys(newErrors).length === 0;
    };

    const handleSaveChanges = () => {
        // Валідуємо поля перед збереженням
        if (validateFields()) {
            dispatch(updateUserSettings(editedData));
            onClose();
        }
    };

    useEffect(() => {
        setEditedData({...settingsData});
    }, [settingsData]);

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal" ref={modalRef}>
                <div className="modal__head__personal__info">
                    <div className="title">
                        Change information
                    </div>
                    <div className="close" onClick={onClose}>
                        <IoCloseOutline/>
                    </div>
                </div>

                <div className="form__group__profile">
                    <label htmlFor="firstName">
                        First Name:
                        {errors.firstName && <span className="error-message">{errors.firstName}</span>}
                    </label>
                    <input
                        type="text"
                        id="firstName"
                        name="firstName"
                        value={editedData?.firstName || ''}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="form__group__profile">
                    <label htmlFor="lastName">
                        Last Name:
                        {errors.lastName && <span className="error-message">{errors.lastName}</span>}
                    </label>
                    <input
                        type="text"
                        id="lastName"
                        name="lastName"
                        value={editedData?.lastName || ''}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="form__group__profile">
                    <label htmlFor="dateOfBirth">Date of Birth:</label>
                    <DatePicker
                        selected={editedData?.dateOfBirth ? new Date(editedData.dateOfBirth) : null}
                        onChange={(date) => handleInputChange({
                            target: {
                                name: 'dateOfBirth',
                                value: date.toISOString().split('T')[0]
                            }
                        })}
                        dateFormat="MM-dd-yyyy"
                        showYearDropdown
                        yearDropdownItemNumber={100}
                        scrollableYearDropdown
                    />
                </div>
                <div className="form__group__profile">
                    <label htmlFor="phoneNumber">Phone Number:</label>
                    <InputMask
                        mask="+1 (999) 999-99-99"
                        type="tel"
                        id="phoneNumber"
                        name="phoneNumber"
                        value={editedData?.phoneNumber || ''}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="btn__group__edit__info">
                    <button className="btn__save__edit__info" onClick={handleSaveChanges}>Save Changes</button>
                    <button className="btn__close__edit__info" onClick={onClose}>Cancel</button>
                </div>
            </div>
        </div>
    );
};

export default Modal;
