import Navigation from "../../Navigation/Navigation";
import './DropdownOptionsComponent.scss'
import DropdownOptionsHeadComponent from "./DropdownOptionsContainerComponent/DropdownOptionsComponent";

export default function DropdownOptionsContainerComponent() {
    return (
        <>
            <div className="adminDropDownsPage">
                <Navigation/>
                <DropdownOptionsHeadComponent/>
            </div>
        </>
    )
}