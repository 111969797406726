import React, { useState, useEffect, useRef } from 'react';
import './EditPhotoTrailerComponentModal.scss';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTrailerPhotos, uploadPhotosTrailer, savePhotos } from '../../../../../store/actions/trailerActions';
import { useParams } from 'react-router-dom';
import { IoClose } from 'react-icons/io5';
import { TfiClose } from 'react-icons/tfi';
import { deletePhoto } from '../../../../../store/actions/trailerActions';
import LoaderComponent from '../../../../LoaderComponent/LoaderComponent';

export default function EditPhotoTrailerComponentModal({ onClose, onSaveChanges }) {
    const [selectedImages, setSelectedImages] = useState(new Array(4).fill(null));
    const [previewImage, setPreviewImage] = useState(null);
    const [selectedImageIndex, setSelectedImageIndex] = useState(null);
    const dispatch = useDispatch();
    const { trailerId } = useParams();
    const [photos, setPhotos] = useState([]);
    const [newPhotos, setNewPhotos] = useState([]);
    const inputRef = useRef(null);
    const [deletedPhotoIds, setDeletedPhotoIds] = useState([]);
    const [pendingChanges, setPendingChanges] = useState(false);
    const deletingPhoto = useSelector((state) => state.deletingPhoto);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchPhotos = async () => {
            setIsLoading(true);
            try {
                const response = await dispatch(fetchTrailerPhotos(trailerId));
                setPhotos(response);
            } catch (error) {
                console.error('Error fetching trailer photos:', error);
            } finally {
                setIsLoading(false);
            }
        };

        if (trailerId) {
            fetchPhotos();
        }
    }, [dispatch, trailerId]);

    const handleImageSelect = async (event, index) => {
        const file = event.target.files[0];
        if (file) {
            const updatedPhotos = [...photos];
            updatedPhotos[index] = { file };
            console.log(updatedPhotos);
            setPendingChanges(true);
            const reader = new FileReader();
            reader.onload = async () => {
                const updatedSelectedImages = [...selectedImages];
                updatedSelectedImages[index] = reader.result;
                setSelectedImages(updatedSelectedImages);

                try {
                    const response = await uploadPhotosTrailer(trailerId, updatedPhotos);
                    console.log('uploadPhoto', response);
                    if (response) {
                        const existingPhotoIds = photos.map((photo) => photo.id);
                        const newPhotosWithoutDuplicates = response.filter(
                            (newPhoto) => !existingPhotoIds.includes(newPhoto.id)
                        );

                        const updatedPhotos = [...photos, ...newPhotosWithoutDuplicates];
                        setPhotos(updatedPhotos);
                        setNewPhotos([...newPhotos, ...newPhotosWithoutDuplicates]);

                        const newPhotoAtIndex = newPhotosWithoutDuplicates[index];
                        if (newPhotoAtIndex && newPhotoAtIndex.link) {
                            setPreviewImage(newPhotoAtIndex.link);
                        }
                        setSelectedImageIndex(index);

                        if (index === 0 && !selectedImages[index]) {
                            const updatedImages = [...selectedImages];
                            updatedImages[index] = newPhotoAtIndex.id;
                            setSelectedImages(updatedImages);
                        } else if (index === 0 && selectedImages[index] && newPhotoAtIndex.cover !== 1) {
                            const updatedImages = [...selectedImages];
                            updatedImages[index] = null;
                            setSelectedImages(updatedImages);
                        }
                    } else {
                        console.error('No data found in response:', response);
                    }
                } catch (error) {
                    console.error('Error uploading photos:', error);
                }
            };
            reader.readAsDataURL(file);
        }
    };

    const handleDragStart = (event, index) => {
        event.dataTransfer.setData('text/plain', index.toString());
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleDrop = (event, targetIndex) => {
        event.preventDefault();
        const draggedIndex = parseInt(event.dataTransfer.getData('text/plain'), 10);
    
        const updatedPhotos = [...photos];
        const [draggedPhoto] = updatedPhotos.splice(draggedIndex, 1);
        updatedPhotos.splice(targetIndex, 0, draggedPhoto);
    
        // Оновлюємо значення "cover" лише для першої фотографії
        if (targetIndex === 0) {
            updatedPhotos[0].cover = "true";
        } else {
            updatedPhotos[0].cover = "false";
        }
    
        // Оновлюємо значення "priority" для всіх елементів у відповідності до їх позицій у списку
        updatedPhotos.forEach((photo, index) => {
            photo.priority = index + 1;
        });
    
        setPhotos(updatedPhotos);
        setPendingChanges(true);
    
        console.log('Оновлені фотографії:', updatedPhotos);
    };

    const handleSavePhotos = async () => {
        setIsLoading(true);
        try {
            console.log('Відправка оновлених фотографій на сервер:', photos);
            // Оновлення стану фотографій з використанням оновлених фотографій
            const updatedPhotos = photos.map((photo, index) => ({
                ...photo,
                cover: index === 0 ? "true" : "false"
            }));
            console.log('Оновлені фотографіє:', updatedPhotos);
            setPhotos(updatedPhotos);
            setPreviewImage(null);
            setSelectedImageIndex(null);
            setPendingChanges(false);
            dispatch(savePhotos(trailerId, updatedPhotos));
            onSaveChanges();
            onClose();
        } catch (error) {
            console.error('Error occurred while saving photos:', error);
        } finally {
            
            setIsLoading(false);
        }
    };
    

    const deleteSelectedImage = async (photoId, index) => {
        try {
            setDeletedPhotoIds((prevIds) => [...prevIds, photoId]);
            setPreviewImage(null);
            setSelectedImageIndex(null);
            setPendingChanges(true);

            await dispatch(deletePhoto(trailerId, photoId));

            const updatedPhotos = photos.filter((_, i) => i !== index);
            setPhotos(updatedPhotos);

            const updatedNewPhotos = newPhotos.filter((newPhoto) => newPhoto.id !== photoId);
            setNewPhotos(updatedNewPhotos);

            const updatedImages = [...selectedImages];
            updatedImages[index] = null;
            setSelectedImages(updatedImages);
        } catch (error) {
            console.error('Error deleting photo:', error);
        }
    };

    useEffect(() => {
        return () => {
            setPendingChanges(false);
        };
    }, [onClose]);

    return (
        <div className="modal__overlay__edit__photo__trailer">
            <div className="modal__content__edit__photo__trailer">
                <div className="modal__header__edit__photo__trailer">
                    <h3>Manage photo</h3>
                    <div className="modal__close__edit__photo__trailer">
                        <IoClose onClick={onClose} />
                    </div>
                </div>
                <div className={`modal__body__edit__photo__trailer ${previewImage ? 'with-full-preview' : ''}`}>
                    <div className={`preview__body ${previewImage ? 'with-full-preview' : ''}`}>
                        {previewImage && <img src={`//${previewImage}`} alt="Full Preview" className="full-preview-image" />}
                    </div>
                    <div className="title__choose__trailer">
                        <h3>Choose cover photo</h3>
                    </div>
                    <div className="image-upload-elements">
                        {photos.map((photo, index) => (
                            <div
                                key={index}
                                className="upload-square"
                                draggable
                                onDragStart={(event) => handleDragStart(event, index)}
                                onDragOver={(event) => handleDragOver(event)}
                                onDrop={(event) => handleDrop(event, index)}
                            >
                                <button
                                    onClick={() => deleteSelectedImage(photo.id, index)}
                                    className={`clear-button `}
                                    disabled={deletingPhoto}
                                >
                                    <TfiClose />
                                </button>
                                {photo ? (
                                    <div className="image-preview" onClick={() => setPreviewImage(photo.link)}>
                                        <img src={`//${photo.link}`} alt={`Preview ${index}`} className="preview-image" />
                                    </div>
                                ) : (
                                    <label className="file-input-label">
                                        <input
                                            type="file"
                                            accept="image/*"
                                            onChange={(event) => handleImageSelect(event, index)}
                                            ref={inputRef}
                                        />
                                        <span className="upload-text">Upload photo</span>
                                        {selectedImageIndex === index && previewImage && (
                                            <img
                                                src={`//${previewImage}`}
                                                alt="Selected Preview"
                                                className="selected-preview-image"
                                            />
                                        )}
                                    </label>
                                )}
                            </div>
                        ))}
                        {photos.length < 4 &&
                            Array.from({ length: 4 - photos.length }, (_, index) => (
                                <label key={index + photos.length} className="file-input-label">
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={(event) => handleImageSelect(event, index + photos.length)}
                                        ref={inputRef}
                                    />
                                    <span className="upload-text">Upload photo</span>
                                    {selectedImageIndex === index + photos.length && previewImage && (
                                        <img
                                            src={`//${previewImage}`}
                                            alt="Selected Preview"
                                            className="selected-preview-image"
                                        />
                                    )}
                                </label>
                            ))}
                    </div>
                </div>
                <div className="modal__btn__container__edit__photo__trailer">
                    {isLoading ? (
                        <LoaderComponent/>
                    ) : (
                        
                    
                    
                    <button
                        className="save__photo__trailer__btn"
                        onClick={handleSavePhotos}
                        disabled={!pendingChanges}
                    >
                        Save changes
                    </button>
                    )}
                    <button className="cancel__photo__trailer__btn" onClick={onClose}>
                        Cancel
                    </button>
                </div>
            </div>
            {previewImage && <div className="overlay" onClick={() => setPreviewImage(null)}></div>}
        </div>
    );
}