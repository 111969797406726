// trailerReducer.js
import {
    FETCH_TRAILERS_SUCCESS,
    FETCH_SINGLE_TRAILER_SUCCESS,
    CREATE_TRAILER_REQUEST,
    CREATE_TRAILER_SUCCESS,
    CREATE_TRAILER_FAILURE,
    FETCH_DROPDOWNS_REQUEST,
    FETCH_TRAILER_PHOTOS_SUCCESS,
    FETCH_DROPDOWNS_SUCCESS,
    FETCH_DROPDOWNS_FAILURE, 
    UPDATE_TRAILER_REQUEST,
    UPDATE_TRAILER_SUCCESS, 
    UPDATE_TRAILER_FAILURE, 
    FETCH_FILES_DROPDOWNS_REQUEST,
    FETCH_FILES_DROPDOWNS_SUCCESS,
    FETCH_FILES_DROPDOWNS_FAILURE,
    DELETE_PHOTO_REQUEST,
    DELETE_PHOTO_SUCCESS,
    DELETE_PHOTO_FAILURE,
} from '../actions/trailerActions';

const initialState = {
    trailers: [],
    singleTrailer: {},
    dropdowns: {},
    trailerPhotos: [],
    photosSaved: false,
    isCreatingTrailer: false,
    createTrailerError: null,
    isFetchingDropdowns: false,
    fetchDropdownsError: null,
    isUpdatingTrailer: false,
    updateTrailerError: null,
    filesDropdowns: [], 
    loading: false,
    error: '',
    deletingPhoto: false,

};

const trailerReducer = (state = initialState, action) => {
    switch (action.type) { 
        case FETCH_TRAILERS_SUCCESS:
            return {
                ...state, 
                trailers: action.payload,
            };
        case FETCH_SINGLE_TRAILER_SUCCESS:
            return {
                ...state,
                singleTrailer: action.payload,
            };
            case FETCH_TRAILER_PHOTOS_SUCCESS:
            return {
                ...state,
                trailerPhotos: action.payload,
            };
        case CREATE_TRAILER_REQUEST:
            return {
                ...state,
                isCreatingTrailer: true,
                createTrailerError: null,
            };
            case DELETE_PHOTO_REQUEST:
                return {
                    ...state,
                    deletingPhoto: true,
                };
    
            case DELETE_PHOTO_SUCCESS:
                return {
                    ...state,
                    deletingPhoto: false,
                    photos: state.photos.filter(photo => photo.id !== action.payload),
                };
    
            case DELETE_PHOTO_FAILURE:
                return {
                    ...state,
                    deletingPhoto: false,
                };
        case CREATE_TRAILER_SUCCESS:
            return {
                ...state,
                isCreatingTrailer: false,
                trailers: [...state.trailers, action.payload],
                createTrailerError: null,
            };

        case CREATE_TRAILER_FAILURE:
            return {
                ...state,
                isCreatingTrailer: false,
                createTrailerError: action.payload,
            };
        case FETCH_DROPDOWNS_REQUEST:
            return {
                ...state,
                isFetchingDropdowns: true,
                fetchDropdownsError: null,
            };

        case FETCH_DROPDOWNS_SUCCESS:
            return {
                ...state,
                isFetchingDropdowns: false,
                dropdowns: action.payload,
                fetchDropdownsError: null,
            };


        case FETCH_DROPDOWNS_FAILURE:
            return {
                ...state,
                isFetchingDropdowns: false,
                fetchDropdownsError: action.payload,
            };
        case UPDATE_TRAILER_REQUEST:
            return {
                ...state,
                isUpdatingTrailer: true,
                updateTrailerError: null,
            };

        case UPDATE_TRAILER_SUCCESS:
            return {
                ...state,
                isUpdatingTrailer: false,
                // оновіть дію для обробки нових даних, наприклад, додайте їх до стану
                updatedTrailer: action.payload,
                updateTrailerError: null,
            };

        case UPDATE_TRAILER_FAILURE:
            return {
                ...state,
                isUpdatingTrailer: false,
                updateTrailerError: action.payload,
            };
        case FETCH_FILES_DROPDOWNS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case FETCH_FILES_DROPDOWNS_SUCCESS:
            return {
                ...state,
                loading: false,
                filesDropdowns: action.payload,
                error: ''
            };
        case FETCH_FILES_DROPDOWNS_FAILURE:
            return {
                ...state,
                loading: false,
                filesDropdowns: [],
                error: action.payload
            };
        default:
            return state;
    }
};

export default trailerReducer;
